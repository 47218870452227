import { useCallback } from "react";
// @ts-ignore router version update needed
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams
} from "react-router-dom";
import { useDispatch, batch } from "react-redux";
import {
  resetBetting,
  setBetAmount,
  setBetType,
  setSelectionRequest
} from "@tvg/desktop-bet/src/store/actions";
import buildRaceUrl from "@tvg/formatter/url";

export const useNavigateWithBet = () => {
  const { pathname } = useLocation();
  const { trackAbbr: trackAbbrUrlParam } = useParams();
  const [searchParams] = useSearchParams();

  const raceIdSearchParam = searchParams.get("race");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateWithBet = useCallback(
    (
      betType: number,
      betAmount: number,
      betSelections: string[][],
      trackAbbr: string,
      raceId: string,
      trackName: string
    ) => {
      const samePage =
        pathname.includes("racetracks/") &&
        trackAbbrUrlParam === trackAbbr &&
        raceIdSearchParam === raceId;

      if (!samePage) {
        navigate(buildRaceUrl(trackAbbr, trackName, raceId));
      }

      batch(() => {
        dispatch(resetBetting());
        dispatch(setBetType(betType));
        dispatch(setBetAmount(betAmount));
        dispatch(setSelectionRequest(betSelections));
      });
    },
    [trackAbbrUrlParam, raceIdSearchParam]
  );

  return {
    navigateWithBet
  };
};

export const useNavigateWithoutBet = () => {
  const { pathname } = useLocation();
  const { trackAbbr: trackAbbrUrlParam } = useParams();
  const [searchParams] = useSearchParams();

  const raceIdSearchParam = searchParams.get("race");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return {
    navigateWithoutBet: (
      trackAbbr: string,
      raceId: string,
      trackName: string
    ) => {
      const samePage =
        pathname.includes("racetracks/") &&
        trackAbbrUrlParam === trackAbbr &&
        raceIdSearchParam === raceId;

      if (!samePage) {
        dispatch(resetBetting());
        navigate(buildRaceUrl(trackAbbr, trackName, raceId));
      }
    }
  };
};
