import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";
import { Link } from "react-router-dom";

const flexCenter = css`
  display: flex;
  align-items: center;
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  ${flexColumn};
  flex: 1;
  z-index: 1;
  transition: transform 0.125s ease-out;
  position: relative;
  justify-content: center;

  .delete-button {
    pointer-events: none;
    visibility: hidden;
  }

  &:hover {
    .delete-button {
      pointer-events: all;
      visibility: visible;
    }
  }
`;

export const CardContainer = styled(Link)<{
  $viewed: boolean;
  $hasUrl: boolean;
  $isCaptionedImage?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 16px 68px 16px 12px;
  text-decoration: none;
  position: relative;
  ${({ $isCaptionedImage }) =>
    $isCaptionedImage
      ? css`
          border-bottom: 1px solid ${buildColor("blue", "900")};
        `
      : css`
          border-bottom: 1px solid ${buildColor("blue", "000")};
        `}
  ${({ $isCaptionedImage, $viewed }) =>
    $isCaptionedImage
      ? css`
          background-color: ${buildColor("blue", "800")};
        `
      : css`
          background-color: ${$viewed
            ? buildColor("blue_accent", "000")
            : buildColor("white", "900")};
        `}
  ${({ $hasUrl }) =>
    $hasUrl &&
    css`
      cursor: pointer;
    `}

  &:hover {
    background-color: ${({ $isCaptionedImage }) =>
      $isCaptionedImage
        ? buildColor("blue", "900")
        : buildColor("blue_accent", "100")};
  }

  &:active {
    background-color: ${({ $isCaptionedImage }) =>
      $isCaptionedImage
        ? buildColor("blue", "800")
        : buildColor("blue_accent", "300")};
  }
`;

export const DismissContainer = styled.div`
  ${flexCenter};
  position: absolute;
  right: 12px;
`;

export const Content = styled.div`
  ${flexColumn};
  flex-grow: 1;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImageContainer = styled.div`
  width: 46px;
  height: 36px;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const IconWrapper = styled.span`
  position: absolute;
  right: 10px;
`;
