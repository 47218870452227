import React, { useMemo } from "react";
import { uniqueId } from "lodash";
import TransactionListItem from "../../_molecules/TransactionHistoryListItem";
import TransactionList from "./styled-components";

export const renderTransactionList = (transactions) => (
  <TransactionList>
    {transactions.map((transaction) => {
      const transactionId = uniqueId(transaction.type);
      return (
        <TransactionListItem
          key={`transactionList_${transactionId}`}
          transaction={transaction}
        />
      );
    })}
  </TransactionList>
);

const TransactionHistoryList = (props) =>
  useMemo(() => renderTransactionList(props.transactions), [props]);

TransactionHistoryList.defaultProps = {
  transactions: []
};

export default TransactionHistoryList;
