import {
  OPEN_PLAYABLE_BALANCE_MODAL,
  CLOSE_PLAYABLE_BALANCE_MODAL,
  PlayableBalanceOpenModalAction,
  PlayableBalanceCloseModalAction
} from "./types";

export const openPlayableBalanceModal = (): PlayableBalanceOpenModalAction => ({
  type: OPEN_PLAYABLE_BALANCE_MODAL
});

export const closePlayableBalanceModal =
  (): PlayableBalanceCloseModalAction => ({
    type: CLOSE_PLAYABLE_BALANCE_MODAL
  });
