import { useState, useEffect, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import { RACE_ENTITIES } from "@urp/lib-racetracks/src/graphql/queries/RaceEntitiesQuery";
import { HorseResultEntity } from "../types";
import { getDupsHorseResults } from "../utils/races";

function useResultsSearchByHorse({
  initialSearchText = "",
  maxRecords,
  debounceDelay = 300,
  minSearchLength = 3
}: {
  initialSearchText?: string;
  maxRecords?: number;
  debounceDelay?: number;
  minSearchLength?: number;
} = {}) {
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const [selectedHorseOption, setSelectedHorseOption] = useState<{
    value: number;
    label: string;
    title: string;
  }>();
  const [horsesOptions, setHorsesOptions] = useState<
    Array<{ value: number; label: string; title: string }>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultVariables = {
    horse: true,
    jockey: false,
    trainer: false,
    owner: false
  };

  const [getHorsesByName, { loading }] = useLazyQuery(RACE_ENTITIES, {
    onCompleted: (data: {
      raceEntities: {
        runners: Array<HorseResultEntity>;
      };
    }) => {
      const dups = getDupsHorseResults(data.raceEntities.runners);

      const newHorsesOptions = (data.raceEntities.runners || []).map(
        ({ name, dob, entityRunnerId }) => ({
          value: entityRunnerId,
          label: dups.includes(name) ? `${name} (${dob})` : name,
          title: dups.includes(name) ? `${name} (Year of Birth: ${dob})` : name
        })
      );
      setHorsesOptions(newHorsesOptions);
      setIsLoading(false);
    }
  });

  const debouncedGetHorsesByName = useCallback(
    debounce((newSearchText: string) => {
      if (newSearchText.length >= minSearchLength) {
        setIsLoading(true);
        getHorsesByName({
          variables: {
            ...defaultVariables,
            searchText: newSearchText,
            maxRecords
          }
        });
      } else {
        setHorsesOptions([]);
        setIsLoading(false);
      }
    }, debounceDelay),
    [maxRecords, getHorsesByName, minSearchLength]
  );

  useEffect(() => {
    debouncedGetHorsesByName(searchText);

    return () => {
      debouncedGetHorsesByName.cancel();
    };
  }, [searchText, debouncedGetHorsesByName]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleSelectedOptionChange = useCallback(
    (newValue: number) => {
      const newSelectedHorseOption = horsesOptions.find(
        (option) => option.value === newValue
      );

      if (newSelectedHorseOption) {
        setSelectedHorseOption(newSelectedHorseOption);
        setSearchText(newSelectedHorseOption.label);
      }
    },
    [horsesOptions]
  );

  return {
    horsesOptions,
    isLoading,
    searchText,
    selectedHorseOption,
    setSearchText,
    setSelectedHorseOption: handleSelectedOptionChange
  };
}

export default useResultsSearchByHorse;
