import { Dispatch } from "redux";
import { get } from "lodash";
import { getNamespace } from "./utils/messages";

const getRouteMessages = (
  key: string,
  namespacesStore: Record<string, boolean>,
  routes: Record<string, string[]>,
  dispatch: Dispatch,
  initialNamespaces: string[] = []
) => {
  const namespacesByRoute = initialNamespaces.concat(
    get(routes, `[${key.toLowerCase()}]`) || []
  );

  const namespacesNeeded = namespacesByRoute.filter(
    (namespace) => !namespacesStore[namespace]
  );

  return getNamespace(namespacesNeeded.join(","), dispatch);
};

export default getRouteMessages;
