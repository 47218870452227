import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import mediator from "@tvg/mediator";
import { isFDR } from "@tvg/utils/generalUtils";
// @ts-ignore
import { LOGIN_ACTIVE_FLOWS } from "@tvg/sh-lib-account-actions/src/reducers/modalReducer";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import { toggleQuickWithdraw } from "@tvg/sh-lib-paws/redux/slices/quickWithdrawSlice";
import { togglePendingWithdraw } from "@tvg/sh-lib-paws/redux/slices/pendingWithdrawSlice";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import {
  openAWPage,
  AWPath
} from "@tvg/sh-lib-account-wallet/src/utils/AWPages";
import { setEmailReferralOpen } from "../store/actions/modal";

export type Hash = {
  key?: string | undefined;
  name?: string | undefined;
  hash?: string | undefined;
  call: () => unknown;
};

export const Hashes: Array<{
  key: string;
  name: string;
  hash: string;
}> = [
  {
    key: "forgot-credentials",
    name: "Forgot Credentials",
    hash: "#forgot-credentials"
  },
  {
    key: "reset-credentials",
    name: "Credentials Recovery",
    hash: "#reset-credentials"
  },
  {
    key: "login",
    name: "Login",
    hash: "#login"
  },
  {
    key: "quickWithdraw",
    name: "Quick Withdraw",
    hash: "#quick-withdraw"
  },
  {
    key: "quickDeposit",
    name: "Quick Deposit",
    hash: "#quick-deposit"
  },
  {
    key: "pendingWithdrawals",
    name: "Pending Withdrawals",
    hash: "#pending-withdrawals"
  },
  {
    key: "emailReferral",
    name: "Email Referral",
    hash: "#invite"
  },
  {
    key: "myBets",
    name: "My Bets",
    hash: "#my-bets"
  },
  {
    key: "promos",
    name: "promos",
    hash: "#promos"
  }
];

export const loginTriggerAction = {
  "quick-deposit": "quick-deposit",
  login: "login",
  withdraw: "withdraw",
  "pending-withdrawals": "pending_withdrawals"
};

type ModalHashMapParams = {
  dispatch: Dispatch;
  navigate: NavigateFunction;
  location: string;
  isLogged: boolean;
  previousHash?: string;
  isLoginModalOpen: boolean;
  isAccountCompliant: boolean;
  enableAWFlows: boolean;
};

export const modalHashMap = ({
  dispatch,
  navigate,
  location,
  isLogged,
  previousHash,
  isLoginModalOpen,
  isAccountCompliant,
  enableAWFlows
}: ModalHashMapParams): Hash[] => [
  {
    ...Hashes.find((r) => r.key === "forgot-credentials"),
    call: () => {
      const route = Hashes.find((r) => r.key === "forgot-credentials");
      if (isLogged) {
        if (isAccountCompliant) {
          return navigate(location);
        }
        return onOpenComplianceModal();
      }

      if (!isLoginModalOpen || previousHash !== route?.hash) {
        // OPEN_LOGIN -  used to trigger the AW modal
        // OPEN_LOGIN_FLOW - used to trigger the base account flow of TVG
        return mediator.base.dispatch({
          type: enableAWFlows ? "OPEN_LOGIN" : "OPEN_LOGIN_FLOW",
          payload: {
            loginActiveFlow: LOGIN_ACTIVE_FLOWS["forgot-credentials"],
            callback: () => (enableAWFlows ? openAWPage(AWPath.Account) : null)
          }
        });
      }
      return null;
    }
  },
  {
    ...Hashes.find((r) => r.key === "login"),
    call: () => {
      if (!isLogged) {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            triggerAction: loginTriggerAction.login,
            callback: () => (enableAWFlows ? openAWPage(AWPath.Account) : null)
          }
        });
        try {
          navigate(location);
        } catch (e) {
          console.error(e);
        }
      } else if (isAccountCompliant) {
        navigate(location);
      } else {
        onOpenComplianceModal();
      }
    }
  },
  {
    ...Hashes.find((r) => r.key === "quickWithdraw"),
    call: () => {
      if (!isLogged) {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            // path: quickWithdraw
            triggerAction: loginTriggerAction.withdraw,
            callback: () =>
              enableAWFlows ? openAWPage(AWPath.Withdrawals) : null
          }
        });
      } else if (isAccountCompliant) {
        dispatch(toggleQuickWithdraw(true));
      } else {
        onOpenComplianceModal();
      }
    }
  },
  {
    ...Hashes.find((r) => r.key === "quickDeposit"),
    call: () => {
      if (!isLogged) {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            triggerAction: loginTriggerAction["quick-deposit"],
            callback: () => (enableAWFlows ? openAWPage(AWPath.Deposit) : null)
          }
        });
      } else {
        dispatch(toggleQuickDepositModal(true));
      }
    }
  },
  {
    ...Hashes.find((r) => r.key === "pendingWithdrawals"),
    call: () => {
      if (!isLogged) {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            triggerAction: loginTriggerAction["pending-withdrawals"],
            callback: () =>
              enableAWFlows ? openAWPage(AWPath.Withdrawals) : null
          }
        });
      } else if (isAccountCompliant) {
        dispatch(togglePendingWithdraw(true));
      } else {
        onOpenComplianceModal();
      }
    }
  },
  {
    ...Hashes.find((r) => r.key === "emailReferral"),
    call: () => {
      dispatch(setEmailReferralOpen(true));
    }
  },
  {
    ...Hashes.find((r) => r.key === "myBets"),
    call: () => {
      navigate("my-bets");
    }
  },
  {
    ...Hashes.find((r) => r.key === "promos"),
    call: () => {
      const decodedSearch = decodeURIComponent(location)
        .replace("/?promo=", "")
        .replace("&", "?");

      if (isFDR()) {
        setTimeout(() => {
          navigate(`/promos/${decodedSearch}`, { replace: true });
        }, 0);
      }
    }
  }
];
