import { get } from "lodash";
import { ApplicationState } from "./types";
import { TrackData } from "../types";

export const getTracksData = (store: ApplicationState) =>
  get(store, "app.seoTrackList", []);

export const getTodaysTracks = (store: ApplicationState) =>
  get(store, "app.todaysTracks", []);

export const getRaceTrackDefaultMessage = (store: ApplicationState) =>
  get(store, "capi.messages.raceTrackHubDEF", "");

export const getRaceTrackContent = (
  store: unknown,
  trackAbbr: string,
  defaultMessage: string
) => get(store, `capi.messages.raceTrackHub${trackAbbr}`, defaultMessage);

export const getRaces = (store: ApplicationState) =>
  get(store, "mtpStatus.mtpStatus", []);

export const getTracks = (store: ApplicationState): TrackData[] =>
  get(store, "app.seoTrackList", []);

export const getIsFromQuickLinks = (store: ApplicationState) =>
  get(store, "app.fromQuickLinks", false);

export const getEnableSeoRaceTracks = (store: ApplicationState) =>
  get(store, "capi.featureToggles.enableSeoRaceTracks", false);
