import { get } from "lodash";
import { WatchQueryFetchPolicy } from "@apollo/client";
// @ts-ignore will update the queries in future
import { RaceProgram } from "@tvg/ts-types/Race";
import { getPortByBrand } from "@tvg/utils/generalUtils";

const QUERY_VARIABLES = {
  trackAbbr: null,
  withGreyhounds: false
};

const extractInfoFromRace = (processingRace: RaceProgram): RaceProgram =>
  processingRace;

const getRaces = (data: { races: RaceProgram[] }) =>
  (get(data, "races") || []).map((race: RaceProgram) =>
    extractInfoFromRace(race)
  );

export default {
  apolloOptions: (props: {
    wagerProfile: string;
    track: string;
    hasRequestedLogin: boolean;
  }) => {
    const variables = {
      ...QUERY_VARIABLES,
      trackAbbr: (get(props, "track") || "").toUpperCase(),
      wagerProfile: get(props, "wagerProfile") || getPortByBrand()
    };

    return {
      skip: !props.hasRequestedLogin || !props.wagerProfile,
      fetchPolicy: "cache-first" as WatchQueryFetchPolicy,
      ssr: false,
      returnPartialData: true,
      variables
    };
  },
  getRaces
};
