import styled from "styled-components";
import tvgConf from "@tvg/conf";
import { breakpoints, Theme } from "@tvg/design-system";

const getMobilePaddingBottom = (
  isBetSlipOpen: boolean,
  betSlipHeight: string
) => {
  if (tvgConf().brand === "fdr") {
    if (tvgConf().product === "fdrios") {
      return isBetSlipOpen ? "182px" : "78px";
    }
    return isBetSlipOpen ? `${betSlipHeight}px` : "48px";
  }
  return isBetSlipOpen ? "104px" : "12px";
};

export const ToastContainer = styled.div<{
  theme: Theme;
  betSlipOpened: boolean;
  isLhnVisible: boolean;
  isBetSlipCollapsed: boolean;
  betSlipHeight: number;
}>`
  position: fixed;
  left: calc(
    50% -
      ${({ isLhnVisible, isBetSlipCollapsed }) =>
        isLhnVisible ? (isBetSlipCollapsed ? "135px" : "20px") : "175px"}
  );
  bottom: ${({ theme, betSlipOpened, betSlipHeight }) =>
    betSlipOpened ? `${betSlipHeight - 25}px` : theme.space["space-4"]};
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.space["space-3"]};
  z-index: 99999;

  @media screen and ${breakpoints.tablet.max.sm} {
    left: 0;
    right: 0;
    bottom: ${({ betSlipOpened, betSlipHeight }) =>
      getMobilePaddingBottom(betSlipOpened, betSlipHeight.toString())};
  }
`;
