import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import color from "../ColorPalette";

const SVGContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  z-index: 30;
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 620px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${color("blue", "100")};
    stroke-width: 1;
  }
`;

export default class ResultsPageMask extends PureComponent {
  render() {
    return (
      <SVGContainer data-qa-label="tracksMask">
        <SVG>
          <g fill="none">
            <rect width="518" height="931" fill="#FFF" />
            <rect
              width="12"
              height="8"
              x="12"
              y="58"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="86"
              height="8"
              x="12"
              y="24"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="120"
              height="8"
              x="12"
              y="98"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="120"
              height="8"
              x="12"
              y="146"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="136"
              height="8"
              x="12"
              y="194"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="12"
              height="8"
              x="12"
              y="234"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="82"
              height="8"
              x="12"
              y="274"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="130"
              height="8"
              x="12"
              y="322"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="12"
              height="8"
              x="12"
              y="362"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="116"
              height="8"
              x="12"
              y="402"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="116"
              height="8"
              x="12"
              y="499"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="132"
              height="8"
              x="12"
              y="450"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="132"
              height="8"
              x="12"
              y="547"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect width="518" height="1" y="77" fill="#E4E8EE" />
            <rect width="518" height="1" y="125" fill="#E4E8EE" />
            <rect width="518" height="1" y="173" fill="#E4E8EE" />
            <rect width="518" height="1" y="221" fill="#E4E8EE" />
            <rect width="518" height="1" y="253" fill="#E4E8EE" />
            <rect width="518" height="1" y="301" fill="#E4E8EE" />
            <rect width="518" height="1" y="349" fill="#E4E8EE" />
            <rect width="518" height="1" y="381" fill="#E4E8EE" />
            <rect width="518" height="1" y="478" fill="#E4E8EE" />
            <rect width="518" height="1" y="429" fill="#E4E8EE" />
            <rect width="518" height="1" y="526" fill="#E4E8EE" />
            <rect
              width="172"
              height="8"
              x="12"
              y="596"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="132"
              height="8"
              x="12"
              y="644"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect width="518" height="1" y="575" fill="#E4E8EE" />
            <rect width="518" height="1" y="623" fill="#E4E8EE" />
            <rect
              width="116"
              height="8"
              x="12"
              y="693"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="132"
              height="8"
              x="12"
              y="741"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect width="518" height="1" y="672" fill="#E4E8EE" />
            <rect width="518" height="1" y="720" fill="#E4E8EE" />
            <rect
              width="116"
              height="8"
              x="12"
              y="790"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect
              width="188"
              height="8"
              x="12"
              y="838"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect width="518" height="1" y="769" fill="#E4E8EE" />
            <rect width="518" height="1" y="817" fill="#E4E8EE" />
            <rect
              width="116"
              height="8"
              x="12"
              y="887"
              fill="#F0F3F8"
              rx="1"
              className="pulse"
            />
            <rect width="518" height="1" y="866" fill="#E4E8EE" />
            <rect width="518" height="1" y="914" fill="#E4E8EE" />
          </g>
        </SVG>
      </SVGContainer>
    );
  }
}
