import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import tvgConf from "@tvg/conf";
import queryString from "query-string";
import { isFDR } from "@tvg/utils/generalUtils";

const getDepositRedirectPath = (queryParams: string, appVersion: string) => {
  const params = queryString.parse(queryParams);

  if (params.fullPage) {
    delete params.location;
    delete params.product;
    const { callback } = params;
    delete params.callback;

    const depositUrl = tvgConf().buildUrl({
      app: "dma",
      path: "deposit",
      wrapper: true,
      callback: callback || true,
      params: { ...params, appVersion }
    });

    if (typeof window !== "undefined") {
      window.location.assign(depositUrl);
      return "";
    }
    return depositUrl;
  }

  return `/wallet`;
};

const RedirectPage = () => {
  const appVersion = ""; // appVersion: get(store, "ios.init.appVersion", "")

  const location = useLocation();
  const currentPath = location.pathname;
  const queryParams = location.search;

  let redirectPath = "/";
  // Handle redirect rules
  // TODO: Routes to be reviewed in the future.
  switch (currentPath) {
    case "/login":
      redirectPath = `/${queryParams}#login`;
      break;
    case "/signup":
      redirectPath = `/registration${queryParams}`;
      break;
    case "/my-bets":
      redirectPath = "/#my-bets";
      break;
    case "/forgot-credentials":
      redirectPath = `/${queryParams}#forgot-credentials`;
      break;
    case "/reset-credentials":
      redirectPath = `/${queryParams}#reset-credentials`;
      break;
    case "/recover-email":
      redirectPath = `/${queryParams}#recover-email`;
      break;
    case "/my-funds/deposit":
      redirectPath = getDepositRedirectPath(queryParams, appVersion);
      break;
    case "/deposit":
      redirectPath = getDepositRedirectPath(queryParams, appVersion);
      break;
    case "/info/tutorials":
      redirectPath = `/more#tutorials`;
      break;
    case "/info/privacypolicy":
      redirectPath = `/more#privacyPolicy`;
      break;
    case "/info/termsandconditions":
      redirectPath = `/more#termsConditions`;
      break;
    case "/info/wagerresponsibly":
      redirectPath = `/more#wagerResponsibly`;
      break;
    case "/handicapping":
      redirectPath = `/more#handicap-store`;
      break;
    case "/handicapping/handicapping-store":
      redirectPath = `/more#handicap-store`;
      break;
    case "/info/accessibility":
      redirectPath = "/more#accessibility";
      break;
    default: {
      redirectPath = "/";
    }
  }

  /**
   * When is 404 instead of redirecting to /, we redirect to 404 page outside of the app.
   */
  if (redirectPath === "/" && typeof window !== "undefined" && !isFDR()) {
    window.location.href = `https://${window.location.host}/404`;
    return null;
  }

  return <Navigate to={redirectPath} replace />;
};

export default RedirectPage;
