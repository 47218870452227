import {
  OPEN_TRANSACTION_HISTORY_MODAL,
  CLOSE_TRANSACTION_HISTORY_MODAL,
  TransactionHistoryOpenModalAction,
  TransactionHistoryCloseModalAction
} from "./types";

export const openTransactionHistoryModal =
  (): TransactionHistoryOpenModalAction => ({
    type: OPEN_TRANSACTION_HISTORY_MODAL
  });

export const closeTransactionHistoryModal =
  (): TransactionHistoryCloseModalAction => ({
    type: CLOSE_TRANSACTION_HISTORY_MODAL
  });
