import React, { memo } from "react";
import { Paragraph } from "@tvg/design-system/web";
import { useNavigate } from "react-router";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { Button, IconWithBackground } from "@tvg/design-system";
import { Container, Row } from "./styled-components";

const EmptyTrackRaces = () => {
  const tokens = useColorTokens();
  const navigate = useNavigate();

  return (
    <Container>
      <Row>
        <IconWithBackground
          mainIcon={{
            name: "horse",
            backgroundColor: tokens.background.infoSubtle,
            lineColor: tokens.content.info
          }}
          badgeIcon={{
            name: "info"
          }}
          mainCircleBackground={tokens.background.infoSubtle}
        />
        <Paragraph qaLabel="" fontFamily="bold">
          No races available today
        </Paragraph>
      </Row>
      <Button
        variant="tertiary"
        qaLabel="today-races-button"
        iconPosition="end"
        icon="arrowRight"
        onPress={() => navigate("/tracks")}
        paddingLeft={0}
      >
        Today&apos;s races
      </Button>
    </Container>
  );
};

export default memo(EmptyTrackRaces);
