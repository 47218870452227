import { useEffect, useCallback, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import requester from "@tvg/api/requester";
import mediator from "@tvg/mediator";
import { getIsLogged } from "@urp/store-selectors";

// Verify if user session has expired
const useSessionInterceptor = () => {
  const isLogged = useSelector(getIsLogged);
  const navigate = useNavigate();
  const pastLoggedValue = useRef(isLogged);
  const [axiosInterceptor, setAxiosInterceptor] = useState<number>(0);
  const [requesterInterceptor, setRequesterInterceptor] = useState<number>(0);

  const removeInterceptors = useCallback(() => {
    axios.interceptors.response.eject(axiosInterceptor);
    requester().interceptors.response.eject(requesterInterceptor);
  }, [axiosInterceptor, requesterInterceptor]);

  const isWagerpad =
    typeof window !== "undefined" &&
    window.location?.pathname?.includes("wagerpad");

  const validateSessionExpired = useCallback(
    (error: { response: { status: number; data: { code: number } } }) => {
      if (
        isLogged &&
        error?.response?.status === 401 &&
        error?.response?.data &&
        error?.response?.data?.code === 100001 &&
        sessionStorage.getItem("userId")
      ) {
        mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });

        if (!isWagerpad) {
          navigate("/");
        }
        if (typeof window !== "undefined") {
          sessionStorage.setItem("open_login_modal", "true");
          window.location.reload();
        }

        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
    [isLogged]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const shouldOpen = sessionStorage.getItem("open_login_modal");

      if (shouldOpen === "true") {
        mediator.base.dispatch({ type: "TVG_LOGIN:OPEN_LOGIN_MODAL" });
        sessionStorage.removeItem("open_login_modal");
      }
    }
  }, []);

  useEffect(() => {
    if (isLogged !== pastLoggedValue.current) {
      removeInterceptors();
    }

    const interceptorRequester = requester().interceptors.response.use(
      (res) => res,
      (error: { response: { status: number; data: { code: number } } }) =>
        validateSessionExpired(error)
    );
    const interceptorAxios = axios.interceptors.response.use(
      (res) => res,
      (error: { response: { status: number; data: { code: number } } }) =>
        validateSessionExpired(error)
    );

    setAxiosInterceptor(interceptorAxios);
    setRequesterInterceptor(interceptorRequester);
  }, [isLogged, pastLoggedValue]);

  useEffect(() => {
    pastLoggedValue.current = isLogged;
  }, [isLogged]);
};

export default useSessionInterceptor;
