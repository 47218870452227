import { breakpoints } from "@tvg/design-system";
import styled, { css } from "styled-components";

export const Container = styled.div<{ isGlobal: boolean }>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colorTokens.background.surface};
    padding: ${theme.space["space-4"]} ${theme.space["space-5"]};
  `};

  @media screen and ${breakpoints.tablet.max.md} {
    ${({ isGlobal }) =>
      isGlobal &&
      css`
        padding-bottom: 100vh;
        padding-top: 20%;
      `}
  }
`;

export const IconContainer = styled.div`
  & > div > svg {
    width: 43px;
    height: 42px;
  }
`;

export const MessageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.space["space-4"]};
  `};
`;

export const TextContainer = styled.div`
  margin-left: ${({ theme }) => theme.space["space-4"]};
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTokens.content.default};
    font-family: ${theme.fonts.regular};
    font-size: ${theme.fontSizes.s};
    font-weight: 700;
  `};
`;

export const Text = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTokens.content.subtle};
    font-family: ${theme.fonts.regular};
    font-size: ${theme.fontSizes.s};
  `};
`;
