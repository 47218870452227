import styled, { css } from "styled-components";
import { fontNormal, fontBold } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";

export const AccountBalancesWrapper = styled.div`
  padding: 16px;
`;

const columnStyles = css`
  font-family: ${fontNormal};
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
`;

const highlighted = (props) =>
  props.isHighlighted &&
  css`
    font-family: ${fontBold};
    color: ${buildColor("grey", "D4")};
  `;

export const ColumnLabel = styled.div`
  ${columnStyles};
  ${highlighted};
  color: ${buildColor("navy")};
`;

export const ColumnValue = styled.div`
  ${columnStyles};
  ${highlighted};
  text-align: right;
  color: ${buildColor("grey", "D4")};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 0;
  height: 34px;
  border-bottom: 1px solid ${buildColor("grey", "L2")};

  &:last-of-type {
    border-bottom: none;
  }
`;
