import React, { memo } from "react";
import { LinksGroupWrapper, Header } from "./styled-components";
import AccountMenuLink from "../../_molecules/AccountMenuLink";

export const LinksGroup = memo(
  ({
    device = "mobile",
    headerText = "Header",
    links = [],
    qaLabel = "links-group"
  }) => {
    return (
      <LinksGroupWrapper device={device} data-qa-label={qaLabel}>
        <Header data-qa-label={`${qaLabel}-header`}>{headerText}</Header>
        {links.map((menuItem) => (
          <li key={`key-${menuItem.qaLabel}`}>
            <AccountMenuLink
              onTouchStart={menuItem.onTouchStart}
              qaLabel={menuItem.qaLabel}
              to={menuItem.to}
              onClick={menuItem.onClick}
              linkText={menuItem.linkText}
              highlightedText={
                menuItem.highlightedText && menuItem.highlightedText
              }
              hideLeftIcon={!menuItem.leftIcon}
              leftIcon={menuItem.leftIcon ? menuItem.leftIcon : {}}
            />
          </li>
        ))}
      </LinksGroupWrapper>
    );
  }
);

export default LinksGroup;
