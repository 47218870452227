import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";

import { PastRaceResults } from "@tvg/ts-types/Race";
import { getWagerProfile } from "@urp/store-selectors";
import { TRACKS_FILTER_OPTIONS } from "../constants";
import { GET_PAST_RACES_QUERY } from "../graphql/queries/PastRacesQuery";
import { addPastRaceCache } from "../redux/actions/races";
import { getPastRacesCached } from "../redux/selectors";
import { getRaceCacheId } from "../utils/races";

const usePastRaces = ({
  date,
  trackCode,
  raceNumber,
  runnerId,
  isEnabled = true
}: {
  date?: string;
  trackCode?: string;
  raceNumber?: string;
  runnerName?: string;
  runnerId?: string;
  isEnabled?: boolean;
}) => {
  const dispatch = useDispatch();
  const [pastRaces, setPastRaces] = useState<Array<PastRaceResults>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const pastRacesCache = useSelector(getPastRacesCached);
  const byDate = !!date && !trackCode && !raceNumber;
  const byDateTrack = !!date && !!trackCode && !raceNumber;
  const byDateTrackNumber = !!date && !!trackCode && !!raceNumber;
  const byRunnerId = !!runnerId;

  const queryVariables = {
    wagerProfile: useSelector(getWagerProfile),
    filterBy: {},
    byDate,
    byDateTrack,
    date,
    trackCode,
    byDateTrackNumber,
    raceNumber,
    byRunnerId,
    runnerId
  };

  const [getPastRaces, { loading, data }] = useLazyQuery(GET_PAST_RACES_QUERY, {
    variables: { trackCode }
  });

  useEffect(() => {
    setIsLoading(false);
  }, [pastRaces]);

  useEffect(() => {
    if (!isEnabled) {
      setPastRaces([]);
      setIsLoading(false);
      return;
    }

    if (
      (date &&
        trackCode &&
        trackCode !== TRACKS_FILTER_OPTIONS.ALL_TRACKS.value) ||
      runnerId
    ) {
      setIsLoading(true);
      if (!pastRacesCache[getRaceCacheId(trackCode, raceNumber, date)]) {
        getPastRaces({
          variables: queryVariables,
          fetchPolicy: "cache-and-network"
        });
      } else {
        setPastRaces([
          pastRacesCache[getRaceCacheId(trackCode, raceNumber, date)]
        ]);
      }
    } else {
      setPastRaces([]);
    }
  }, [date, trackCode, runnerId, getPastRaces, isEnabled]);

  useEffect(() => {
    if (!isEnabled) return;

    if (!loading && data) {
      if (byDateTrackNumber) {
        const pastRace = data.pastRaceByDateTrackAndNumber[0];
        dispatch(
          addPastRaceCache(
            getRaceCacheId(trackCode, raceNumber, date),
            pastRace
          )
        );
        setPastRaces(data.pastRaceByDateTrackAndNumber);
      }

      setIsLoading(false);
    }
  }, [loading, data, isEnabled]);

  return { pastRaces, isLoading, firstPastRace: pastRaces[0] };
};

export default usePastRaces;
