import React from "react";
import { DialogMessage } from "@tvg/design-system";
import { useSelector } from "react-redux";
import InfoIllustrationIcon from "../../components/InfoIllustrationIcon";
import { Container } from "./styled-components";
import { getFavoritesNotFoundInfo } from "../../redux/selectors";

const FavoritesNotFound: React.FC<{}> = () => {
  const { title, description } = useSelector(getFavoritesNotFoundInfo);

  return (
    <Container>
      <DialogMessage
        illustration={<InfoIllustrationIcon iconName="star" />}
        title={title}
        description={description}
      />
    </Container>
  );
};

export default FavoritesNotFound;
