import parseCAPIMessage from "@tvg/utils/capiUtils";
import type { SeoTrack } from "@tvg/ts-types/Track";
import { get } from "lodash";
import { Store } from "redux";
import {
  tracksAzInfo,
  favoriteTracksInfo,
  tracksEmptyMessage,
  seoTracksPage,
  defaultFiltersConfig,
  horseNotFoundInfo,
  favoritesNotFoundInfo
} from "./defaultValues";
import {
  TracksAzCapiMessage,
  FavoriteTracksMessage,
  TracksEmptyMessageInfo,
  SeoTracksPageProps,
  RacingScheduleConfigMessage,
  HorseNotFoundInfo,
  FavoritesNotFoundInfo
} from "../types";

export const getTracksAZInfo = (store: Store): TracksAzCapiMessage =>
  parseCAPIMessage(store, "capi.messages.tracksAzInfo", tracksAzInfo);

export const getFavoriteTracksInfo = (store: unknown): FavoriteTracksMessage =>
  parseCAPIMessage(store, "capi.messages.tracksFavorite", favoriteTracksInfo);

export const getSeoTracksData = (store: Store): SeoTrack[] =>
  get(store, "app.seoTrackList", []);

export const isSeoTracksEnabled = (store: Store): boolean =>
  get(store, "capi.featureToggles.enableSeoRaceTracks", false);

export const getSeoTILFooterTitle = (store: Store) =>
  get(store, "capi.messages.seoContentTrackListTitle", "");

export const getSeoTILFooterContent = (store: Store) =>
  get(store, "capi.messages.seoContentTrackList", "");

export const getSeoFooterTracksPage =
  (currentTab: string) =>
  (store: Store): SeoTracksPageProps =>
    parseCAPIMessage(
      store,
      `capi.messages.seo_${currentTab}_page`,
      seoTracksPage
    );

export const getRaceTrackContent = (trackAbbr: string) => (store: Store) =>
  get(store, `capi.messages.raceTrackHub${trackAbbr}`);

export const getRaceTrackDefaultMessage = (store: Store) =>
  get(store, "capi.messages.raceTrackHubDEF", "");

export const getEnableSeoRaceTracks = (store: Store): boolean =>
  get(store, "capi.featureToggles.enableSeoRaceTracks", false);
export const getTracksEmptyMessageInfo = (
  store: unknown
): TracksEmptyMessageInfo =>
  parseCAPIMessage(
    store,
    "capi.messages.tracksPageEmptyMessage",
    tracksEmptyMessage
  );

export const getSeoRacesTitle = (store: Store) =>
  get(store, "capi.messages.seoContentRacesTitle");

export const getSeoRacesContent = (store: Store) =>
  get(store, "capi.messages.seoContentRaces");

export const getURPRacingScheduleToggle = (store: Store) =>
  get(store, "capi.featureToggles.useURPRacingSchedule");

export const getURPRacingResultsToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.useURPRacingResults", false);

export const getIsAllFiltersFeatureEnabled = (store: Store) =>
  get(store, "capi.featureToggles.isRacingScheduleAllFilterEnabled", false);

export const getFiltersConfiguration = (
  store: Store
): RacingScheduleConfigMessage[] =>
  parseCAPIMessage(
    store,
    "capi.messages.racingScheduleFiltersConfiguration",
    defaultFiltersConfig
  );

export const getHorseNotFoundInfo = (store: Store): HorseNotFoundInfo =>
  parseCAPIMessage(
    store,
    "capi.messages.tracksResultsHorseNotFound",
    horseNotFoundInfo
  );

export const getFavoritesNotFoundInfo = (store: Store): FavoritesNotFoundInfo =>
  parseCAPIMessage(
    store,
    "capi.messages.tracksResultsFavoritesNotFound",
    favoritesNotFoundInfo
  );
