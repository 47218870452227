import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: var(--fd-space-space-6);

  p {
    color: var(--fd-colors-content-strong);
  }
`;

export const FlagContainer = styled.div`
  display: flex;
  cursor: pointer;

  svg > path {
    stroke: var(--fd-colors-component-button-button-link-content-base);
  }
`;
