import React, { memo } from "react";
import formatCurrency from "@tvg/formatter/currency";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { Icon, useColorTokens } from "@tvg/design-system";

import {
  AccountBalancesSummaryWrapper,
  BalanceValue,
  ShowDetails,
  VisibilityWrapper,
  VisibilityLabel,
  BalanceDetailsHeader,
  BalanceDetailsTitle,
  BalanceHidden
} from "./styled-components";
import { Balance } from "../../types";

interface Props {
  balances: Balance[];
  isVisible: boolean;
  onToggle: CallableFunction;
  qaLabel?: string;
  setCollapsable: UnaryFn<boolean, void>;
  isCollapsableOpen: boolean;
}

export const AccountBalancesSummary = memo(
  ({
    balances = [],
    isVisible,
    onToggle,
    qaLabel = "account-balance-summary",
    setCollapsable,
    isCollapsableOpen
  }: Props) => {
    const colorTokens = useColorTokens();
    const baseBalance = balances[0];

    return (
      <AccountBalancesSummaryWrapper data-qa-label={qaLabel}>
        <BalanceDetailsHeader isVisible={isVisible}>
          <BalanceDetailsTitle>{baseBalance.label}</BalanceDetailsTitle>
          <VisibilityWrapper
            onClick={() => onToggle()}
            data-qa-label={`${qaLabel}-toggle-show`}
          >
            <Icon
              name={isVisible ? "eyeHide" : "eyeShow"}
              size="s"
              qaLabel={`${qaLabel}-${isVisible ? "hide" : "show"}`}
              lineColor={colorTokens.content.onDark}
            />
            <VisibilityLabel>{isVisible ? "Hide" : "Show"}</VisibilityLabel>
          </VisibilityWrapper>
        </BalanceDetailsHeader>

        {isVisible && baseBalance ? (
          <BalanceValue data-qa-label={`${qaLabel}-balances`}>
            {formatCurrency(baseBalance.value)}

            <ShowDetails
              data-qa-label={`${qaLabel}-details`}
              onClick={() => setCollapsable(!isCollapsableOpen)}
            >
              <Icon
                name={isCollapsableOpen ? "chevronUp" : "chevronDown"}
                size="s"
                qaLabel={`${qaLabel}-details-icon`}
                lineColor={colorTokens.content.onDark}
              />
            </ShowDetails>
          </BalanceValue>
        ) : (
          <BalanceValue>
            <BalanceHidden />
          </BalanceValue>
        )}
      </AccountBalancesSummaryWrapper>
    );
  }
);

export default AccountBalancesSummary;
