import React, { forwardRef } from "react";
import { View, Platform } from "react-native";
import { useQaLabel } from "../../hooks/useQaLabel";
import { LogoProps, DEFAULTS, TYPE_LOGO } from "./types";
import { PathIllustration } from "./pathIllustation";
import { PathComplete } from "./pathComplete";
import { getDimensions } from "./utils";
import { StyledSVG } from "./styled-components";

export const Logo = forwardRef<View, LogoProps>(
  (
    {
      height = DEFAULTS.height,
      isDarkMode = false,
      isOnlyIllustration = false,
      accessibilityLabel = "Home page",
      qaLabel = "logo-label",
      brand = "tvg",
      ...props
    },
    ref
  ) => {
    const viewTestProps = useQaLabel(qaLabel);
    const typeLogo = isOnlyIllustration
      ? TYPE_LOGO.illustration
      : TYPE_LOGO.complete;

    const { dimension } = getDimensions(brand, height, typeLogo);

    return (
      <StyledSVG
        {...viewTestProps}
        {...dimension}
        {...props}
        // @ts-ignore
        ref={ref}
        accessible={Platform.OS === "web" ? undefined : true}
        accessibilityRole="image"
        accessibilityLabel={accessibilityLabel}
      >
        {isOnlyIllustration ? (
          <PathIllustration />
        ) : (
          <PathComplete isDarkMode={isDarkMode} brand={brand} />
        )}
      </StyledSVG>
    );
  }
);

export { LogoProps };
