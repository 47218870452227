import styled, { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --lhn-width: 292px;
    --lhn-collapsed-width: 48px;
  }
`;

interface NavProps {
  isPositionFixed: boolean;
  isHeaderVisible: boolean;
  isInsideHamburguerMenu: boolean;
  mediaQuery: string;
  hasFixedBanner: boolean;
}

interface ArrowButtonContainerProps {
  isHamburguerMenu: boolean;
  isCollapsed: boolean;
}

const computeTopPosition = (
  isHeaderVisible: boolean,
  hasFixedBanner: boolean
) => {
  if (hasFixedBanner) {
    return "128px";
  }
  if (isHeaderVisible) {
    return "var(--hdr-height, 76px)";
  }
  return "0";
};

export const Nav = styled.nav<NavProps>`
  background-color: ${({ theme }) => theme.colorTokens.background.secondary};
  ${({ isInsideHamburguerMenu }) =>
    isInsideHamburguerMenu &&
    css`
      height: 100%;
    `}
  ${({ isPositionFixed, isHeaderVisible, hasFixedBanner }) =>
    isPositionFixed &&
    css`
      position: fixed;
      z-index: 999;
      top: ${computeTopPosition(isHeaderVisible, hasFixedBanner)};
      bottom: 0;
      overflow-y: auto;
    `}
  ${({ isInsideHamburguerMenu, mediaQuery }) =>
    !isInsideHamburguerMenu &&
    css`
      @media ${mediaQuery} {
        display: none;
      }
    `}
`;

export const Wrapper = styled.div`
  width: var(--lhn-width, 292px);
`;

export const ArrowButtonContainer = styled.div<ArrowButtonContainerProps>`
  ${({ isHamburguerMenu }) =>
    isHamburguerMenu
      ? css`
          bottom: 24px;
          z-index: 10;
          right: 16px;
        `
      : css`
          bottom: 24px;
          z-index: 1000;
          //large webscroll
          right: 6px;
        `}

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      width: 65px;
    `}      
  position: absolute;
`;

export const ArrowButtonContainerMainLayout = styled.div`
  position: absolute;
  right: 16px;
  bottom: 80px;
`;
