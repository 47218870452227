import React, { ReactNode } from "react";
import { get } from "lodash";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  TRACKS_PATHS,
  useFavoriteWithRedux,
  useSeoTracks
} from "@urp/lib-racetracks";
import { Module } from "@urp/amplitude/src/modules/raceCell/types";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import withApolloContext from "../../hoc/withApolloContext";
import { Container, ContentContainer, Favorite } from "./styled-components";
import { ApolloContextProps } from "../../types";
import TrackDetailsContent from "../TrackDetailsContent";
import TrackIllustration from "../TrackIllustration";
import TrackRaces from "../TrackRaces";
import { sendTrackDetailsBackEvent } from "../../amplitude";
import { getEnableSeoRaceTracks } from "../../redux/selectors";
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";

const TRACK_CODE_IDX = 3;

const TrackDetails = ({ fcpClient, rdaClient }: ApolloContextProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const { results } = useSeoTracks(fcpClient);

  const pathParts = location.pathname.split("/");
  const trackCode = get(pathParts, `${TRACK_CODE_IDX}`);
  const track = results.find((item) => item.code === trackCode);

  const enableSeoRaceTracks = useSelector(getEnableSeoRaceTracks);
  const { isFavorite, toggleFavorite } = useFavoriteWithRedux(Module.TIL);

  if (!enableSeoRaceTracks || !track) return null;

  const onBack = () => {
    sendTrackDetailsBackEvent({ destinationUrl: TRACKS_PATHS.TIL });
    navigate(-1);
  };

  const renderFavorite = (): ReactNode => (
    <Favorite
      isMobile={isMobile}
      trackName={track.name}
      isFavorite={isFavorite(track.code)}
      toggleFavorite={() => toggleFavorite(track.code, track.name)}
    />
  );

  const elementProps = {
    favoriteElem: renderFavorite,
    onBack,
    track
  };

  return (
    <Container>
      {isMobile ? (
        <HeaderMobile {...elementProps} />
      ) : (
        <HeaderDesktop {...elementProps} />
      )}
      <ContentContainer isMobile={isMobile}>
        <TrackIllustration track={track} />
        <TrackRaces track={track} client={rdaClient} />
        <TrackDetailsContent trackCode={track.code} />
      </ContentContainer>
    </Container>
  );
};

export default withApolloContext<ApolloContextProps>(TrackDetails);
