import React from "react";
import {
  IconButton,
  Paragraph,
  Icon,
  useColorTokens,
  ColorTokens
} from "@tvg/design-system";
import {
  CardContainer,
  CardWrapper,
  Content,
  DismissContainer,
  Image,
  ImageContainer,
  IconWrapper
} from "./styled-components";
import { Props } from "./types";
import { QA_LABELS } from "../../utils/constants";
import { getLinkProps } from "../../utils";

const ClassicCard = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      url,
      viewed,
      imageUrl,
      title,
      description,
      linkText,
      dismissible,
      onDismissCard,
      isCaptionedImage = false,
      pinned,
      onClickContentCard
    },
    ref
  ) => {
    const { content } = useColorTokens() as ColorTokens;
    const renderedProps = {
      titleColor: isCaptionedImage ? content.onDark : content.default,
      linkProps: url ? getLinkProps(url) : { as: "div" }
    };

    return (
      <CardWrapper ref={ref} data-qa-label={QA_LABELS.classicCard.wrapper}>
        {/* @ts-ignore */}
        <CardContainer
          {...renderedProps.linkProps}
          data-qa-label={QA_LABELS.classicCard.container}
          $viewed={viewed}
          $hasUrl={!!url}
          $isCaptionedImage={isCaptionedImage}
          onClick={(e) => {
            if (url) {
              e.stopPropagation();
              onClickContentCard();
            }
          }}
        >
          <>
            {imageUrl && (
              <ImageContainer>
                <Image src={imageUrl} alt={title} />
              </ImageContainer>
            )}
            <Content>
              {pinned && (
                <IconWrapper>
                  <Icon
                    qaLabel={QA_LABELS.classicCard.pin}
                    name="announcements"
                    size="s"
                    lineColor={content.link}
                  />
                </IconWrapper>
              )}
              <Paragraph
                qaLabel={QA_LABELS.classicCard.title}
                fontSize="s"
                fontFamily="medium"
                color={!viewed ? "blue_accent.500" : renderedProps.titleColor}
                mb="space-1"
              >
                {title}
              </Paragraph>
              <Paragraph
                qaLabel={QA_LABELS.classicCard.description}
                fontSize="s"
                color={isCaptionedImage ? content.onDark : content.subtle2}
                mb="space-2"
              >
                {description}
              </Paragraph>
              {!!url && (
                <Paragraph
                  qaLabel={QA_LABELS.classicCard.linkText}
                  color={content.link}
                >
                  {linkText}
                </Paragraph>
              )}
            </Content>
          </>
        </CardContainer>
        {dismissible && (
          <DismissContainer className="delete-button">
            <IconButton
              variant="tertiary"
              tertiaryColor="red.600"
              iconName="trash"
              qaLabel={QA_LABELS.classicCard.dismiss}
              onPress={onDismissCard}
              size="m"
            />
          </DismissContainer>
        )}
      </CardWrapper>
    );
  }
);

export default ClassicCard;
