import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";

type Variant = { variant: "light" | "default" };

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  background-color: ${({ variant }: Variant) =>
    variant === "light"
      ? buildColor("blue_accent", "000")
      : buildColor("blue", "700")};
  position: relative;
  box-shadow: inset 0 -1px 0 ${buildColor("grey", "200")};
`;

export const UserInfoContainer = styled.div`
  padding: 15px 20px 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 19px;
`;

export const BalanceContainer = styled.div`
  ${flexColumn}
  align-items: center;
  padding: 0 20px;
`;

export const Balance = styled.h1`
  font-size: 32px;
  line-height: 37px;
  color: ${({ variant }: Variant) =>
    variant === "light"
      ? buildColor("grey", "900")
      : buildColor("white", "100")};
`;

export const HideButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ButtonsContainer = styled.div`
  padding: 32px 20px 22px 20px;
  display: flex;
  justify-content: space-evenly;
`;

export const ButtonsWidthText = styled.div`
  ${flexColumn}
  align-items: center;
`;
