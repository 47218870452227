import React from "react";
import { G, Path, Defs, ClipPath, Rect } from "react-native-svg";

export const Italy: React.FC = () => (
  <>
    <G clipPath="url(#clip0_20811_7054)">
      <G clipPath="url(#clip1_20811_7054)">
        <Path d="M32 -1H0V20.3333H32V-1Z" fill="#009246" />
        <Path d="M32 -1H10.6667V20.3333H32V-1Z" fill="white" />
        <Path d="M32 -1H21.3333V20.3333H32V-1Z" fill="#CE2B37" />
      </G>
    </G>
    <Defs>
      <ClipPath id="clip0_20811_7054">
        <Rect width="32" height="20" rx="2" fill="white" />
      </ClipPath>
      <ClipPath id="clip1_20811_7054">
        <Rect
          width="32"
          height="21.3333"
          fill="white"
          transform="translate(0 -1)"
        />
      </ClipPath>
    </Defs>
  </>
);

export default Italy;
