import { useCallback, useMemo } from "react";

import * as IconPaths from "../../../_static/icons/icon-paths";

const useIsValidIcon = () => {
  const iconNames = useMemo(() => Object.keys(IconPaths), []);

  return useCallback((name: string) => iconNames.includes(name), [iconNames]);
};

export default useIsValidIcon;
