import { TalentPick } from "@tvg/ts-types/TalentPicks";
import { BetGroupStatus, BetStateType } from "../types";
import { Actions } from "./actions";

export type State = {
  betSelections: string[][];
  scratchedSelections: string[][];
  startBetting: boolean;
  betState: BetStateType;
  betAmount: number;
  hasUserSetAmount: boolean;
  betType: number | null | undefined;
  betLeg: number;
  talentPick: TalentPick | null | undefined;
  raceOfBet: string;
  betGroupStatus: BetGroupStatus;
  processingBets: number;
  errorBets: number;
  successBets: number;
  betSlipOpen: boolean;
};

export const initialState = {
  betSelections: [[]],
  scratchedSelections: [[]],
  startBetting: false,
  betState: "none" as BetStateType,
  betAmount: 0,
  hasUserSetAmount: false,
  betType: null,
  betLeg: 0,
  talentPick: null,
  raceOfBet: "",
  betGroupStatus: {},
  processingBets: 0,
  errorBets: 0,
  successBets: 0,
  betSlipOpen: false
};

const resetArray = (matrix: string[][]): string[][] => matrix.map(() => []);

const keepWinResetArray = (
  selections: string[][],
  isScratchedSelections: boolean,
  columnCount?: number
): string[][] => {
  const matrix = isScratchedSelections ? [[]] : [selections[0]];

  if (columnCount) {
    for (let i = 1; i < columnCount; i++) {
      matrix.push([]);
    }
  }
  return matrix;
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "SET_START_BETTING": {
      return {
        ...state,
        startBetting: action.payload
      };
    }
    case "SET_BET_AMOUNT": {
      return {
        ...state,
        betAmount: action.payload
      };
    }
    case "SET_HAS_USER_SET_AMOUNT": {
      return {
        ...state,
        hasUserSetAmount: action.payload
      };
    }
    case "SET_SELECTION_SCRATCHED": {
      return {
        ...state,
        scratchedSelections: action.payload
      };
    }
    case "SET_BET_TYPE": {
      return {
        ...state,
        betType: action.payload
      };
    }
    case "SET_GROUP_BET_TICKET_STATUS": {
      return {
        ...state,
        betGroupStatus: {
          ...state.betGroupStatus,
          ...action.payload.betGroupStatus
        }
      };
    }
    case "SET_GROUP_BET_ADD_PROCESSING": {
      return {
        ...state,
        processingBets: state.processingBets + 1
      };
    }
    case "SET_GROUP_BET_ADD_SUCCESS": {
      return {
        ...state,
        successBets: state.successBets + 1
      };
    }
    case "SET_GROUP_BET_ADD_ERROR": {
      return {
        ...state,
        errorBets: state.errorBets + 1
      };
    }
    case "SET_GROUP_BET_REMOVE_PROCESSING": {
      return {
        ...state,
        processingBets: state.processingBets - 1
      };
    }
    case "SET_GROUP_BET_REMOVE_SUCCESS": {
      return {
        ...state,
        successBets: state.successBets - 1
      };
    }
    case "SET_GROUP_BET_REMOVE_ERROR": {
      return {
        ...state,
        errorBets: state.errorBets - 1
      };
    }
    case "SET_GROUP_BET_TICKET_STATUS_CLEAR_ALL": {
      return {
        ...state,
        betGroupStatus: {},
        processingBets: 0,
        errorBets: 0,
        successBets: 0
      };
    }
    case "SET_GROUP_BET_TICKET_STATUS_CLEAR": {
      return {
        ...state,
        processingBets: 0,
        errorBets: 0,
        successBets: 0
      };
    }
    case "SET_RACE_OF_BET": {
      return {
        ...state,
        raceOfBet: action.payload
      };
    }
    case "SET_BET_SELECTIONS": {
      return {
        ...state,
        betSelections: action.payload
      };
    }
    case "SET_SELECTIONS_MATRIX": {
      return {
        ...state,
        betSelections: action.payload.selections
      };
    }
    case "RESET_BET_SELECTIONS": {
      return {
        ...state,
        betSelections: resetArray(state.betSelections),
        scratchedSelections: resetArray(state.scratchedSelections),
        startBetting: false
      };
    }
    case "RESET_BET_SELECTIONS_KEEP_WIN": {
      return {
        ...state,
        betSelections: keepWinResetArray(
          state.betSelections,
          false,
          action.payload
        ),
        scratchedSelections: keepWinResetArray(
          state.scratchedSelections,
          true,
          action.payload
        )
      };
    }
    case "RESET_BETTING": {
      return {
        ...state,
        betSelections: [[]],
        scratchedSelections: [[]],
        startBetting: false,
        betState: "none" as BetStateType,
        betAmount: 0,
        hasUserSetAmount: false,
        betType: null,
        betLeg: 0,
        talentPick: null,
        raceOfBet: "",
        betSlipOpen: false
      };
    }
    case "SET_BET_STATE": {
      return {
        ...state,
        betState: action.payload
      };
    }
    case "SET_PICK_LEG": {
      return {
        ...state,
        betLeg: action.payload
      };
    }
    case "SET_PICK": {
      return {
        ...state,
        talentPick: action.payload.value
      };
    }
    case "SET_BET_SLIP_OPEN": {
      return {
        ...state,
        betSlipOpen: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
