import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getTodaysTracks } from "@tvg/tracks/src/v2/redux/selectors";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { updateRouter } from "../utils/callbacks";

const useMediatorClassicSharedEvents = () => {
  const navigate = useNavigate();
  const todaysTracks = useSelector(getTodaysTracks);

  useEffect(() => {
    mediatorClassic.subscribe(
      "UPDATE_ROUTER",
      updateRouter(navigate, todaysTracks)
    );

    return () =>
      mediatorClassic.unsubscribe(
        "UPDATE_ROUTER",
        updateRouter(navigate, todaysTracks)
      );
  }, []);
};

export default useMediatorClassicSharedEvents;
