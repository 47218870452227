import styled, { css } from "styled-components";
import { fontNormal } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";

export const AccountBalancesSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${buildColor("grey", "L2")};

  button {
    border-color: ${buildColor("grey", "L2")};
    ${(props) =>
      props.isVisible &&
      css`
        height: 34px;
        width: 34px;
        padding: 0;
      `}
  }
`;

export const Balances = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const BalanceItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;

  &:first-of-type {
    padding-left: 0;
  }

  &:not(:last-of-type) {
    border-right: 1px solid ${buildColor("grey", "L2")};
  }
`;

export const BalanceValue = styled.span`
  font-family: ${fontNormal};
  font-size: 18px;
  line-height: 125%;
  color: ${buildColor("grey", "D4")};
`;

export const ShowLabel = styled.span`
  display: inline-flex;
  align-items: center;

  span {
    padding-left: 8px;
  }
`;

// toggle visibility with display none instead of conditionally rendering
// to prevent pressable state to bubble to other button
export const ToggleVisibility = styled.div`
  ${(props) =>
    !props.isVisible &&
    css`
      display: none;
    `}
  ${(props) =>
    props.isStretched &&
    css`
      width: 100%;
    `}
`;
