import React from "react";
import { Icon, Paragraph, IconNames } from "@tvg/design-system";
import { IconContainer } from "./styled-components";

export interface IconDescriptionProps {
  iconName?: IconNames;
  iconColor?: string;
  text?: string;
  qaLabel: string;
}

const IconDescriptive = ({
  iconName = "info",
  iconColor = "green.400",
  text,
  qaLabel
}: IconDescriptionProps) => (
  <IconContainer data-qa-label={`${qaLabel}-container`}>
    <Icon
      name={iconName}
      size="s"
      lineColor={iconColor}
      backgroundColor="transparent"
    />
    {!!text && (
      <Paragraph
        fontFamily="condensedRegular"
        fontSize="xs"
        qaLabel={`${qaLabel}-text`}
        color="blue.200"
        textTransform="uppercase"
        ml="space-1"
      >
        {text}
      </Paragraph>
    )}
  </IconContainer>
);

export default IconDescriptive;
