type SeoConfig = {
  [key: string]: {
    title: string;
    message: string;
    hasStackedCard: boolean;
  };
};

export const seoConfig: SeoConfig = {
  "/racetracks": {
    title: "seoContentTrackListTitle",
    message: "seoContentTrackList",
    hasStackedCard: true
  },
  "/results": {
    title: "seoContentResultsTitle",
    message: "seoContentResults",
    hasStackedCard: false
  },
  "/handicapping/free-pp": {
    title: "seoContentPastPerformancesTitle",
    message: "seoContentPastPerformances",
    hasStackedCard: false
  },
  "/handicapping/race-replays": {
    title: "seoContentReplaysTitle",
    message: "seoContentReplays",
    hasStackedCard: false
  },
  "/handicapping/talent-picks": {
    title: "seoContentTalentPicksTitle",
    message: "seoContentTalentPicks",
    hasStackedCard: false
  },
  "/live": {
    title: "seoContentLiveTitle",
    message: "seoContentLive",
    hasStackedCard: false
  }
};
