import { RefObject, useEffect, useState } from "react";
import type { NullaryFn } from "@tvg/ts-types/Functional";
import { getClient } from "./utils";

export const UseSwipeButton = (
  targetRef: RefObject<HTMLElement> | null,
  showDismiss: boolean,
  toggleShowDismiss: NullaryFn<void>,
  targetWidth: number,
  threshold: number = targetWidth / 2
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [startPointX, setStartPointX] = useState<number>(0);
  const [startPointY, setStartPointY] = useState<number>(0);

  useEffect(() => {
    if (isOpen && !showDismiss && targetRef?.current) {
      targetRef?.current?.style.setProperty("transform", `translate(0)`);
      setIsOpen(false);
    }
  }, [showDismiss]);

  const { min, abs } = Math;

  const handleTouchStart = (event: TouchEvent) => {
    const [newStartPointX, newStartPointY] = getClient(event);
    setStartPointX(newStartPointX);
    setStartPointY(newStartPointY);
  };

  const handleTouchMove = (event: TouchEvent) => {
    const [currentPointX, currentPointY] = getClient(event);
    const difX = currentPointX - startPointX;
    const difY = currentPointY - startPointY;
    if (currentPointX < startPointX) {
      const offset = abs(difX) > targetWidth ? -targetWidth : difX;
      targetRef?.current?.style.setProperty(
        "transform",
        `translate(${offset}px)`
      );
      setIsOpen(true);
    } else if (showDismiss && difY * -1 < difX && isOpen) {
      const dist = min(abs(targetWidth - difX), targetWidth);
      const offset = dist > threshold ? dist : 0;
      setIsOpen(!!offset);
      targetRef?.current?.style.setProperty(
        "transform",
        `translate(-${offset}px)`
      );
    }
  };

  const handleTouchEnd = (event: TouchEvent) => {
    const [endPoint] = getClient(event);
    const showButton = startPointX > endPoint + threshold;

    if (showButton) {
      toggleShowDismiss();
      targetRef?.current?.style.setProperty(
        "transform",
        `translate(-${targetWidth}px)`
      );
    } else {
      if (showDismiss) {
        toggleShowDismiss();
      }
      targetRef?.current?.style.setProperty("transform", "translate(0)");
    }
  };

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd
  };
};
