import { HdrState } from "./types";

export const initialState = {
  isHamburgerMenuOpen: false
};

type Action = {
  payload: boolean | string;
  type: string;
};

export default function appReducer(
  state: HdrState = initialState,
  action: Action
) {
  switch (action.type) {
    case "HAMBURGER_MENU_STATE": {
      return { ...state, isHamburgerMenuOpen: action.payload };
    }
    default: {
      return state;
    }
  }
}
