import { AppState } from "../types";

export const initialState = {
  pageTitle: "",
  prevPath: "",
  trackCode: "",
  raceNumber: "",
  isRaceDataShown: false,
  isBeta: false,
  isPromoWidgetModalOpen: false,
  promosList: [],
  seoTrackList: [],
  subscription: undefined,
  showPAbetsSplashNotification: false,
  isEmailReferralOpen: false,
  fromQuickLinks: false,
  isBetSlipExpanded: false,
  reloadApp: false
};

type Action = {
  payload: boolean | string;
  type: string;
};

export default function appReducer(
  state: AppState = initialState,
  action: Action
) {
  switch (action.type) {
    case "PROMOS_LIST_SET": {
      return { ...state, promosList: action.payload };
    }
    case "SET_BETA_OPTIN": {
      return { ...state, isBeta: action.payload };
    }
    case "SET_PROMO_WIDGET_MODAL_STATE": {
      return { ...state, isPromoWidgetModalOpen: action.payload };
    }
    case "SET_SEO_TRACK_LIST": {
      return { ...state, seoTrackList: action.payload };
    }
    case "SET_TODAYS_TRACKS": {
      return { ...state, todaysTracks: action.payload };
    }
    case "SET_EMAIL_REFERRAL_OPEN": {
      return { ...state, isEmailReferralOpen: action.payload };
    }
    case "SET_FROM_QUICK_LINKS": {
      return { ...state, fromQuickLinks: action.payload };
    }
    case "SET_BET_SLIP_EXPANDED": {
      return { ...state, isBetSlipExpanded: action.payload };
    }
    case "SET_PAGE_TITLE": {
      return { ...state, pageTitle: action.payload };
    }
    case "SET_RELOAD_APP": {
      return { ...state, reloadApp: action.payload };
    }
    default: {
      return state;
    }
  }
}
