import {
  ModalPayloadType,
  AccountWalletOpenModalAction,
  AccountWalletCloseModalAction,
  SetAwLoginModalOpenAction,
  SetAwLoginModalCloseAction
} from "./types";

export const openAccountWalletModal = (
  payload: ModalPayloadType
): AccountWalletOpenModalAction => ({
  type: "OPEN_AW_MODAL",
  payload
});

export const closeAccountWalletModal = (): AccountWalletCloseModalAction => ({
  type: "CLOSE_AW_MODAL"
});

export const setAWLoginModalOpen = (): SetAwLoginModalOpenAction => ({
  type: "SET_AW_LOGIN_MODAL_OPEN"
});

export const setAWLoginModalClose = (): SetAwLoginModalCloseAction => ({
  type: "SET_AW_LOGIN_MODAL_CLOSE"
});
