import styled from "styled-components";
import { Header } from "@tvg/design-system/web/";

export const BodyHeader = styled.div`
  display: flex;
  padding: var(--fd-space-space-5);
  justify-content: space-between;
  border-bottom: 1px solid var(--fd-colors-border-subtle);
  background-color: var(--fd-colors-background-surface);
`;

export const StyledHeader = styled(Header)`
  color: var(--fd-colors-content-strong);
`;

export const RaceResultsContainer = styled.div`
  background-color: var(--fd-colors-background-surface);
  display: flex;
  flex-flow: row wrap;
`;
