import styled from "styled-components";

export const MyBetsButtonContainer = styled.div`
  position: relative;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 25px;
  z-index: 2;
  pointer-events: none;
`;
