import { get } from "lodash";
import { datadogRum } from "@datadog/browser-rum";
import tvgConf from "@tvg/conf";
import { DatadogRumInit, DatadogRumUser } from "./types";

// "enableFDRDatadogRUM" will be the feature toggle to enable Datadog RUM for both
// frontend-desk and frontend-fdm, but the preloaded state is different for each environment,
// so we need to check both. We will then be able to filter by service name in Datadog.
const isDatadogRumEnabled = () =>
  get(
    window,
    "__PRELOADED_STATE__.capi.featureToggles.enableFDRDatadogRUM",
    false
  ) ||
  get(
    window,
    "__DESKTOP_PRELOADED_STATE__.capi.featureToggles.enableFDRDatadogRUM",
    false
  );

const initDatadogRUM = ({ service }: DatadogRumInit) => {
  const tvgConfig = tvgConf();
  const datadogRumConfig = tvgConfig.config("datadogRum");

  datadogRum.init({
    applicationId: datadogRumConfig.fdrApplicationId, // Datadog RUM application ID.
    clientToken: datadogRumConfig.fdrClientToken, // Datadog client token.
    site: datadogRumConfig.site, // `site` refers to the Datadog site parameter of your organization
    service, // The service name for our application. Follows the tag syntax requirements.
    env: tvgConfig.environment, // Our application’s environment. Follows the tag syntax requirements.
    // Inorder to match the version to the build deployed in production, additional work is required within our App.
    version: "1.0.0", // Default to 1.0.0, since we can't get the build version yet.
    sessionSampleRate: 100, // The percentage of sessions to track: 100 for all, 0 for none. Only tracked sessions send RUM events.
    sessionReplaySampleRate: 20, // The percentage of tracked sessions with Browser RUM & Session Replay pricing features: 100 for all, 0 for none.
    trackUserInteractions: true, // Enables automatic collection of users actions.
    trackResources: true, // Enables collection of resource events.
    trackLongTasks: true, // Enables collection of long task events. A long task event is any task in the browser that blocks the main thread for more than 50ms.
    // Default privacy options for Session Replay are designed to protect end user privacy and prevent sensitive organizational information from being collected.
    defaultPrivacyLevel: "mask-user-input"
  });
};

const initDatadogRumIfEnabled = ({ service }: DatadogRumInit) => {
  if (isDatadogRumEnabled()) {
    initDatadogRUM({ service });
  }
};

const setDatadogRumUser = ({
  userId,
  userName,
  emailAddress
}: DatadogRumUser) => {
  datadogRum.setUser({
    id: userId,
    name: userName,
    email: emailAddress
  });
};

const setDatadogRumUserIfEnabled = ({
  userId,
  userName,
  emailAddress
}: DatadogRumUser) => {
  if (isDatadogRumEnabled()) {
    setDatadogRumUser({ userId, userName, emailAddress });
  }
};

const clearDatadogRumUser = () => {
  if (isDatadogRumEnabled()) {
    datadogRum.clearUser();
  }
};

export {
  isDatadogRumEnabled,
  initDatadogRUM,
  initDatadogRumIfEnabled,
  setDatadogRumUser,
  setDatadogRumUserIfEnabled,
  clearDatadogRumUser
};
