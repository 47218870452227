export const OPEN_TRANSACTION_HISTORY_MODAL = "OPEN_TRANSACTION_HISTORY_MODAL";
export const CLOSE_TRANSACTION_HISTORY_MODAL =
  "CLOSE_TRANSACTION_HISTORY_MODAL";

export interface TransactionHistoryOpenModalAction {
  type: "OPEN_TRANSACTION_HISTORY_MODAL";
}

export interface TransactionHistoryCloseModalAction {
  type: "CLOSE_TRANSACTION_HISTORY_MODAL";
}

export type TransactionHistoryModalActions =
  | TransactionHistoryCloseModalAction
  | TransactionHistoryOpenModalAction;
