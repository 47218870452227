import authentication from "./authentication";
import formCompletion from "./formCompletion";
import infoScreen from "./infoScreen";
import inputRelated from "./inputRelated";
import otherInteractions from "./otherInteractions";
import pageView from "./pageView";
import promoCode from "./promoCode";
import TVGProvider from "./TVGProvider";

const registration = () => {
  authentication();
  formCompletion();
  infoScreen();
  inputRelated();
  otherInteractions();
  pageView();
  promoCode();
  TVGProvider();
};

export default registration;
