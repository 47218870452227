import styled, { css } from "styled-components";
import { fonts } from "@tvg/design-system/src/theming/themes/common/fonts";

export const Container = styled.div<{ isVisible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  box-shadow: 0 1px 8px 0 #00000040;
  ${(props) =>
    !props.isVisible &&
    css`
      display: none;
    `};
`;

export const JoinBarMessage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const JoinBarTitle = styled.span`
  font-family: ${fonts.regular};
  font-size: 14px;
  line-height: 125%;
  color: ${({ theme }) => theme.colorTokens.background.secondary};
`;

export const JoinBarDescription = styled.span`
  font-family: ${fonts.regular};
  font-size: 12px;
  line-height: 125%;
  color: ${({ theme }) => theme.colorTokens.content.neutral};
`;

export const JoinBarActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 150px;

  > button:first-of-type {
    margin-right: 8px;
  }
`;
