import React from "react";
import { Icon } from "../../../../../src/components/icon";
import { Paragraph } from "../../../Typography";
import { WarningContainer } from "./styled-components";
import { Props } from "./types";

const WarningMessage = ({ message, qaLabel }: Props) => (
  <WarningContainer data-qa-label={qaLabel}>
    <Icon name="warning" size="s" />
    <Paragraph qaLabel="warning-message">{message}</Paragraph>
  </WarningContainer>
);

export default WarningMessage;
