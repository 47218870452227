import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import seo from "@tvg/api/seo";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { SeoDataResponse } from "../types";
import { setPageTitle } from "../store/actions/page";

const useSeoData = (callback: NullaryFn<void>) => {
  const [tabTitle, setTabTitle] = useState("");
  const [tabDescription, setTabDescription] = useState("");
  const location = useLocation();
  const defaultTabMessages = useSelector((store) =>
    parseCAPIMessage(store, "capi.messages.seoDefaultTabMessages", "")
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let newPageTitle = defaultTabMessages.tabTitle;
    let newPageDescription = defaultTabMessages.tabDescription;
    seo
      .getSeoData(location.pathname)
      .then((response: SeoDataResponse) => {
        if (response.data.title) {
          newPageTitle = response.data.title;
        }
        if (response.data.description) {
          newPageDescription = response.data.description;
        }
      })
      .finally(() => {
        dispatch(setPageTitle(newPageTitle));
        setTabTitle(newPageTitle);
        setTabDescription(newPageDescription);

        if (typeof callback === "function") {
          setTimeout(callback);
        }
      });
  }, [location.pathname]);

  return { tabTitle, tabDescription };
};

export default useSeoData;
