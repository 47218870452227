import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { Device } from "@tvg/ts-types/Device";
import HomepageDesktopContainer from "@tvg/atomic-ui/_templates/HomepageDesktopContainer";
import SeoFooter from "@tvg/atomic-ui/_static/SeoFooter";
import { isFDR } from "@tvg/utils/generalUtils";
import PromosWidgetComponentLegacy from "@tvg/promos-widget";
import PromosWidgetComponent from "@urp/promos-widget";
import {
  getHasRequestedLogin,
  getUsePromosWidgetDSToggle,
  getPromosWidgetTitle
} from "./store/selectors";
import { seoConfig } from "./seoRouteConfig";

const SeoScreens = () => {
  const [SEOMessage, setSEOMessage] = useState<string>();
  const [SEOTitle, setSEOTitle] = useState<string>();
  const [shouldRenderSEO, setShouldRenderSEO] = useState<boolean>();
  const [hasStackedCard, setHasStackedCard] = useState<boolean>();

  const { pathname } = useLocation();
  const seoRoute = seoConfig[pathname];

  useEffect(() => {
    if (seoRoute) {
      setShouldRenderSEO(true);
      setSEOTitle(seoRoute.title);
      setSEOMessage(seoRoute.message);
      setHasStackedCard(seoRoute.hasStackedCard);
    } else {
      setShouldRenderSEO(false);
    }
  }, [seoRoute]);

  const message = useSelector((store) =>
    get(store, `capi.messages.${SEOMessage}`, "")
  );
  const title = useSelector((store) =>
    get(store, `capi.messages.${SEOTitle}`, "")
  );
  const seoContent = <SeoFooter message={message} title={title} />;

  const hasRequestedLogin = useSelector(getHasRequestedLogin);
  const usePromosWidgetDS = useSelector(getUsePromosWidgetDSToggle);
  const promosWidgetTitle = useSelector(getPromosWidgetTitle);
  const promoWidget = usePromosWidgetDS ? (
    <PromosWidgetComponent
      title={promosWidgetTitle}
      device={"desktop" as Device}
    />
  ) : (
    <PromosWidgetComponentLegacy
      hasRequestedLogin={hasRequestedLogin}
      hasLargeWidth={hasStackedCard}
      device="desktop"
      isWidget
    />
  );

  return (
    <>
      {shouldRenderSEO && (
        <HomepageDesktopContainer
          leftContent={seoContent}
          rightContent={isFDR() ? null : promoWidget}
          hasStackedCards={hasStackedCard}
        />
      )}
    </>
  );
};

export default SeoScreens;
