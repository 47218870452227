import React from "react";

import * as FlagPaths from "./flags";

import { FlagContentProps, Countries } from "./types";

const FlagContent: React.FC<FlagContentProps> = ({ country }) =>
  React.createElement(FlagPaths[country as Countries]);

export default React.memo(FlagContent);
