import styled from "styled-components";

export const Container = styled.div`
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-5"]}`};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colorTokens.border.subtle};
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
`;
