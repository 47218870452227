import {
  RaceTracksMediatorEventType,
  RaceTracksDataPayload
} from "@tvg/ts-types/Amplitude";
import mediator from "@tvg/mediator";

export const sendPageViewEvent = (payload: RaceTracksDataPayload) =>
  mediator.base.dispatch({
    type: RaceTracksMediatorEventType.TRACK_CLICK,
    payload
  });

export const sendRaceClickEvent = (payload: RaceTracksDataPayload) =>
  mediator.base.dispatch({
    type: RaceTracksMediatorEventType.RACE_CLICK,
    payload
  });

export const sendTrackDetailsBackEvent = (payload: RaceTracksDataPayload) =>
  mediator.base.dispatch({
    type: RaceTracksMediatorEventType.RETURN_TO_RACETRACKS,
    payload
  });
