import React, { useMemo } from "react";
import { Button } from "@tvg/design-system";
import { headerWagerPadGtm, wagerPadCTAClickedGtmEvent } from "../../gtm";
import useBuildRaceLink from "../../hooks/useBuildRaceLink";

interface WagerpadButtonProps {
  isTablet?: boolean;
  isNewWagerpad?: boolean;
}

const windowConfig =
  "toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=0,top=0,left=0";

const WagerpadButton = ({ isTablet, isNewWagerpad }: WagerpadButtonProps) => {
  const link = useBuildRaceLink();

  const windowSize = useMemo(
    () =>
      isNewWagerpad ? "width=500px,height=800px" : "width=480px,height=800px",
    [isNewWagerpad]
  );

  return !isTablet ? (
    <Button
      variant="marketing"
      iconPosition="start"
      icon="bets"
      onPress={() => {
        if (isNewWagerpad) {
          wagerPadCTAClickedGtmEvent();
        } else {
          headerWagerPadGtm();
        }
        if (typeof window !== "undefined") {
          window.open(link, "_blank", `${windowConfig},${windowSize}`);
        }
      }}
      qaLabel="wagerpad-button"
      ml="space-5"
      isDisabled={!link}
    >
      Wagerpad
    </Button>
  ) : null;
};

export default WagerpadButton;
