import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { uniqBy, get } from "lodash";
import { useWagerProfile } from "@tvg/custom-hooks";
import { Paragraph } from "@tvg/design-system";
import { animated, useSpring, easings } from "@react-spring/web";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import getOptedInPromos from "@tvg/utils/PromoUtils";
import mediator from "@tvg/mediator";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import useActiveBets from "@tvg/sh-lib-my-bets/hooks/useActiveBets";
import ApolloOptions, { getScheduledRaces } from "./graphql/options.graph";
import { GET_LHN_SCHEDULED_RACES_QUERY } from "./graphql/queries/scheduledRaces";
import Header from "../Header";
import RaceEntry from "../RaceEntry";
import MoreButton from "../MoreButton";
import useLHNTransitionsInOut from "./transitions";
import {
  Container,
  FeaturedContainer,
  FeaturedRaces,
  ListItem,
  ScheduledContainer,
  ScheduledRaces,
  ExpandedMessage
} from "./styled-components";
import { RaceProgram } from "../../types";
import LHNMask from "../Masks/LHN";
import LHNCollapsedMask from "../Masks/LHNCollapsed";
import {
  getPickNumberFromString,
  LhnRacesFilter,
  sortByStatus,
  showDescription,
  removeSimilarTracks,
  filterClosedRaces,
  getTvgTrackAbbrs,
  isRaceWagered,
  hasWagerToday
} from "../../utils/lhnUtils";
import {
  getPickDescriptionLhn,
  getLhnEmptyStateMessage,
  getLhnFeaturedRacesTitle,
  getLhnScheduledRacesTitle,
  getLhnDefaultEmptyMessage,
  getMinutesPastPost,
  getOptedInPromosStore
} from "../../store/selectors";
import { Promo } from "./graphql/types";

export interface FeatureRacesProps {
  isNavCollapsed?: boolean;
  isInsideHamburgerMenu: boolean;
  onClickRaceEntry?: () => void;
}

const AnimatedListItem = animated(ListItem);
const AnimatedEmptyText = animated(ExpandedMessage);

const Races = ({
  isNavCollapsed = false,
  isInsideHamburgerMenu,
  onClickRaceEntry
}: FeatureRacesProps) => {
  const [showMore, setShowMore] = useState(false);
  const [scheduledRaces, setScheduledRaces] = useState<RaceProgram[]>();
  const { notDescriptionLhn, parsePick } = useSelector(getPickDescriptionLhn);
  const [featuredRaces, setFeaturedRaces] = useState<RaceProgram[]>([]);
  const [tvgTracks, setTvgTracks] = useState<string[]>([]);

  const wagerProfile: string = useWagerProfile();

  const minutesPastPost: string = useSelector(getMinutesPastPost);
  const isLogged: boolean = useSelector(getIsLogged);
  const emptyMessage = useSelector(getLhnEmptyStateMessage);
  const defaultEmptyMessage = useSelector(getLhnDefaultEmptyMessage);
  const featuredRacesTitle = useSelector(getLhnFeaturedRacesTitle);
  const scheduledRacesTitle = useSelector(getLhnScheduledRacesTitle);
  const optedInPromos = useSelector(getOptedInPromosStore);
  const accountId = useSelector(getAccountNumber);
  const allRaces = [...(scheduledRaces || []), ...featuredRaces];
  const tvgTrackAbbrs = getTvgTrackAbbrs(allRaces);

  if (tvgTrackAbbrs && tvgTracks.length < tvgTrackAbbrs.length) {
    setTvgTracks(tvgTrackAbbrs);
  }

  const {
    data,
    loading,
    refetch: refetchRaces
  } = useQuery(
    GET_LHN_SCHEDULED_RACES_QUERY,
    ApolloOptions.options({ wagerProfile, accountId })
  );

  const { racesActiveBets, activeBetsTrackCode } = useActiveBets();

  useEffect(() => {
    if (wagerProfile) {
      refetchRaces();
    }
    setTvgTracks([]);
  }, [isLogged]);

  useEffect(() => {
    const unsubscribeRefetch = () => {
      if (wagerProfile) {
        refetchRaces();
      }
    };
    const newFavorites = () => {
      if (wagerProfile) {
        refetchRaces();
      }
    };
    mediatorClassic.subscribe("NEW_FAVORITE_TRACKS", newFavorites);
    mediator.base.subscribe("URP:MTP_UPDATE_CLOSED_RACES", unsubscribeRefetch);
    return () => {
      mediator.base.unsubscribe(
        "URP:MTP_UPDATE_CLOSED_RACES",
        unsubscribeRefetch
      );
      mediatorClassic.unsubscribe("NEW_FAVORITE_TRACKS", newFavorites);
    };
  }, [refetchRaces, wagerProfile]);

  useEffect(() => {
    if (data) {
      const formattedAndSortedScheduledRaces = sortByStatus(
        filterClosedRaces(getScheduledRaces(data), minutesPastPost)
      );
      // @ts-ignore
      const wageredRaces: Array<RaceProgram> =
        Object.keys(racesActiveBets || {})
          .map((tvgRaceId) =>
            formattedAndSortedScheduledRaces.find(
              (race) => `${race.tvgRaceId}` === `${tvgRaceId}`
            )
          )
          .filter(Boolean) || [];

      setFeaturedRaces(
        formattedAndSortedScheduledRaces.filter(
          (race) =>
            race.isInFeaturedTrack ||
            race.isFavorite ||
            hasWagerToday(wageredRaces, race) ||
            isRaceWagered(wageredRaces, race) ||
            activeBetsTrackCode.includes(race.trackAbbr)
        )
      );
      setScheduledRaces(LhnRacesFilter(formattedAndSortedScheduledRaces));
    }
  }, [data, racesActiveBets]);

  const onClickShowMore = useCallback(() => {
    setShowMore((prevState) => !prevState);
  }, []);

  const featuredTransitions = useLHNTransitionsInOut(
    featuredRaces,
    true,
    showMore
  );

  const renderFeaturedRaces = useMemo(
    () =>
      featuredTransitions &&
      featuredTransitions((style, race: RaceProgram) => {
        const promo: Promo | null = getOptedInPromos(
          get(race, "promos", []),
          optedInPromos,
          isLogged
        );

        return (
          <AnimatedListItem
            data-qa-label="lhn-animated-list-item"
            style={style}
          >
            <RaceEntry
              style={race.style}
              title={showDescription(race.description, notDescriptionLhn)}
              raceNumber={race.raceNumber}
              trackName={race.trackName}
              trackAbbr={race.trackAbbr}
              mtp={{
                mtp: race.mtp,
                postTime: race.postTime,
                status: race.raceStatus.code as RaceStatusEnum
              }}
              details={{
                hasWatchTvg: race.onTvg,
                hasWatchTvg2: race.onTvg2,
                pickNumber: getPickNumberFromString(
                  race.description,
                  parsePick
                ),
                hasPromo: !!promo,
                isOptedIn: !!promo?.isOptedIn,
                showBetSuccess: !!racesActiveBets[race.tvgRaceId]
              }}
              isNavCollapsed={isNavCollapsed}
              isFeatureRace
              onClickRaceEntry={onClickRaceEntry}
              isInsideHamburgerMenu={isInsideHamburgerMenu}
            />
          </AnimatedListItem>
        );
      }),
    [
      featuredRaces,
      racesActiveBets,
      showMore,
      isNavCollapsed,
      featuredTransitions
    ]
  );

  const scheduledTransitions = useLHNTransitionsInOut(
    removeSimilarTracks(scheduledRaces || [], featuredRaces)
  );

  const renderScheduledRaces = useMemo(
    () =>
      scheduledTransitions &&
      scheduledTransitions((style, race: RaceProgram) => {
        const promo: Promo | null = getOptedInPromos(
          get(race, "promos", []),
          optedInPromos,
          isLogged
        );

        return (
          <AnimatedListItem
            data-qa-label="lhn-animated-list-item"
            style={style}
          >
            <RaceEntry
              style={race.style}
              title={showDescription(race.description, notDescriptionLhn)}
              raceNumber={race.raceNumber}
              trackName={race.trackName}
              trackAbbr={race.trackAbbr}
              mtp={{
                mtp: race.mtp,
                postTime: race.postTime,
                status: race.raceStatus.code as RaceStatusEnum
              }}
              details={{
                hasWatchTvg: race.onTvg,
                hasWatchTvg2: race.onTvg2,
                pickNumber: getPickNumberFromString(
                  race.description,
                  parsePick
                ),
                hasPromo: !!promo,
                isOptedIn: !!promo?.isOptedIn
              }}
              isNavCollapsed={isNavCollapsed}
              onClickRaceEntry={onClickRaceEntry}
              isInsideHamburgerMenu={isInsideHamburgerMenu}
            />
          </AnimatedListItem>
        );
      }),
    [racesActiveBets, scheduledTransitions, isNavCollapsed]
  );

  const [springs, api] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500
  }));

  useEffect(() => {
    if (!isNavCollapsed) {
      api.start({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: {
          duration: 500,
          easing: easings.easeInQuint
        }
      });
    }
  }, [isNavCollapsed]);

  const renderEmptyCase = useMemo(
    () => (
      <>
        {!isNavCollapsed ? (
          <AnimatedEmptyText style={springs}>
            <Paragraph
              qaLabel="lhn-empty-races-text"
              color="blue.200"
              fontSize="s"
            >
              {emptyMessage}
            </Paragraph>
          </AnimatedEmptyText>
        ) : (
          <Paragraph
            qaLabel="lhn-colapsed-empty-paragraph"
            color="blue.200"
            fontSize="s"
            mt="space-4"
          >
            {defaultEmptyMessage}
          </Paragraph>
        )}
      </>
    ),
    [scheduledRaces, featuredRaces, isNavCollapsed]
  );

  const getMask = useCallback(
    () => (isNavCollapsed ? <LHNCollapsedMask /> : <LHNMask />),
    [isNavCollapsed]
  );

  const scheduledRacesCounter = get(scheduledRaces, "length", 0);

  const showScheduledRaces =
    !!scheduledRacesCounter ||
    (!featuredRaces.length && !scheduledRacesCounter);

  return (
    <Container>
      {!scheduledRaces ||
      (loading && !scheduledRaces.length && !featuredRaces.length) ? (
        getMask()
      ) : (
        <>
          {!!featuredRaces.length && (
            <FeaturedContainer data-qa-label="lhn-featured-container">
              <Header
                isNavCollapsed={isNavCollapsed}
                title={featuredRacesTitle}
                iconName="featured"
              />
              <FeaturedRaces data-qa-label="lhn-featured-list">
                {renderFeaturedRaces}
                {uniqBy(featuredRaces, "trackAbbr").length > 8 && (
                  <MoreButton
                    showMore={showMore}
                    isNavCollapsed={isNavCollapsed}
                    onClick={onClickShowMore}
                  />
                )}
              </FeaturedRaces>
            </FeaturedContainer>
          )}
          {showScheduledRaces && (
            <ScheduledContainer data-qa-label="lhn-scheduled-container">
              <Header
                isNavCollapsed={isNavCollapsed}
                title={scheduledRacesTitle}
                iconName="scheduledRaces"
              />
              <ScheduledRaces data-qa-label="lhn-scheduled-list">
                {scheduledRaces.length ? renderScheduledRaces : renderEmptyCase}
              </ScheduledRaces>
            </ScheduledContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default Races;
