// Default Button
import styled from "styled-components";

export default styled.button`
  outline: none;
  height: 100%;
  background-color: transparent;
  opacity: ${(props) => (props.isDisabled ? 0.3 : 1)};
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;
