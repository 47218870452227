import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const RaceEntryContainer = styled.button<{ isNavCollapsed: boolean }>`
  align-items: center;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  color: ${buildColor("blue", "000")};
  cursor: pointer;
  display: ${({ isNavCollapsed }) => (isNavCollapsed ? "inline-flex" : "flex")};
  flex-grow: ${({ isNavCollapsed }) => (isNavCollapsed ? 0 : 1)};
  min-height: 64px;
  padding: 12px 0;
  position: relative;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colorTokens.lhnHeader.selectedcell.background};
    border: 1px solid
      ${({ theme }) => theme.colorTokens.lhnHeader.selectedcell.border};
  }

  &:hover hr {
    background-color: ${({ theme }) =>
      theme.colorTokens.lhnHeader.selectedcell.border};
  }
`;

export const HR = styled.hr`
  border: none;
  position: absolute;
  height: 1px;
  left: 46px;
  right: 0;
  bottom: -1px;
  background-color: ${({ theme }) =>
    theme.colorTokens.lhnHeader.border.default};
  margin: 0;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8px;
  flex: 1;
  width: 0;
`;

export const MTPContainer = styled.div`
  display: flex;
`;
