import { useState, useRef, useCallback, useEffect, KeyboardEvent } from "react";
import { debounce } from "lodash";
import { Item, UseSearchBarProps, UseSearchBarReturn } from "./types";

export const useSearchBar = ({
  items,
  searchTerm,
  setSearchTerm,
  minSearchTermLength,
  isLoading,
  debounceTime,
  warningMessages,
  handleOnClick
}: UseSearchBarProps): UseSearchBarReturn => {
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [focused, setFocused] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [warningPosition, setWarningPosition] = useState({ top: 0, left: 0 });
  const [activeIndex, setActiveIndex] = useState(-1);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const updateWarningPosition = useCallback(() => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      setWarningPosition({
        top: rect.top + 40,
        left: rect.left
      });
    }
  }, []);

  useEffect(() => {
    updateWarningPosition();

    const handleScroll = debounce(updateWarningPosition, 50);
    const handleResize = debounce(updateWarningPosition, 50);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [updateWarningPosition]);

  const filterItems = useCallback(
    (term: string, loading: boolean) => {
      if (loading) {
        setHasWarning(false);
        return;
      }

      let warning = false;
      if (focused && term.length > 0 && term.length < minSearchTermLength) {
        warning = true;
        setIsOpen(false);
      } else if (focused && term.length >= minSearchTermLength) {
        const newItems = items.filter((item) =>
          item.label.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredItems(newItems);
        warning = newItems.length === 0;
        setIsOpen(newItems.length > 0);
      } else {
        setFilteredItems([]);
        setIsOpen(false);
      }

      setHasWarning(warning);
    },
    [items, minSearchTermLength, focused]
  );

  const debouncedFilterItems = useCallback(
    debounce(filterItems, debounceTime),
    [filterItems, debounceTime]
  );

  useEffect(() => {
    debouncedFilterItems(searchTerm, isLoading);
    return () => {
      debouncedFilterItems.cancel();
    };
  }, [searchTerm, debouncedFilterItems, isLoading]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleOnFocus = () => {
    setFocused(true);
    setIsOpen(filteredItems.length > 0);
  };

  const handleOnBlur = () => {
    setFocused(false);
  };

  const scrollIntoView = useCallback((index: number) => {
    if (listRef.current) {
      const element = listRef.current.children[index] as HTMLElement;
      if (element) {
        const listRect = listRef.current.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        if (elementRect.bottom > listRect.bottom) {
          listRef.current.scrollTop += elementRect.bottom - listRect.bottom;
        } else if (elementRect.top < listRect.top) {
          listRef.current.scrollTop -= listRect.top - elementRect.top;
        }
      }
    }
  }, []);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!focused) {
      setFocused(true);
    }

    if (!isOpen) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setActiveIndex((prevIndex) => {
          const newIndex =
            prevIndex < filteredItems.length - 1 ? prevIndex + 1 : prevIndex;
          scrollIntoView(newIndex);
          return newIndex;
        });
        break;
      case "ArrowUp":
        e.preventDefault();
        setActiveIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : -1;
          if (newIndex >= 0) scrollIntoView(newIndex);
          return newIndex;
        });
        break;
      case "Enter":
        e.preventDefault();
        if (activeIndex >= 0 && activeIndex < filteredItems.length) {
          handleItemClick(filteredItems[activeIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        setIsOpen(false);
        setActiveIndex(-1);
        break;
      default: // no default
    }
  };

  const handleItemClick = (item: Item) => {
    if (handleOnClick) {
      handleOnClick(item.value);
    }
    setIsOpen(false);
    setFocused(false);
    setActiveIndex(-1);
  };

  const renderWarningMessage = () =>
    searchTerm?.length > 0 && searchTerm.length <= minSearchTermLength
      ? warningMessages.minLength
      : warningMessages.notFound;

  return {
    filteredItems,
    focused,
    hasWarning,
    isOpen,
    wrapperRef,
    listRef,
    warningPosition,
    handleOnChange,
    handleOnFocus,
    handleOnBlur,
    handleItemClick,
    renderWarningMessage,
    activeIndex,
    handleKeyDown
  };
};
