export const QA_LABELS = {
  bannerCard: {
    container: "banner-card",
    pin: "pinner-banner-icon",
    dismiss: "dismiss-card"
  },
  classicCard: {
    wrapper: "classic-card",
    container: "classic-card-container",
    pin: "pinner-banner-icon",
    title: "classic-card-title",
    description: "classic-card-description",
    linkText: "classic-card-linkText",
    dismiss: "dismiss-card"
  },
  emptyMessage: {
    description: "braze-empty-message-description",
    title: "braze-empty-message-title",
    icon: "braze-empty-message-icon",
    container: "braze-empty-message-container"
  },
  inbox: {
    badge: "inbox-badge",
    button: "inbox-button"
  },
  modal: {
    container: "braze-inbox-modal"
  }
};

export const EXTERNAL_LINK_REGEX =
  /^(?:(?:https?|ftp):\/\/|www\.)[^\s/$.?#]+\.[^\s]*$/;
