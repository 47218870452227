import React from "react";
import { get } from "lodash";
// @ts-ignore
import WvRgLogo from "@fdr/static-ui/images/wv-rg-logo.png";
// @ts-ignore
import IlRgLogo from "@fdr/static-ui/images/il-rg-logo.png";
// @ts-ignore
import PalRgLogo from "@fdr/static-ui/images/pa-rg-logo.png";
// @ts-ignore
import FdrRgLogo from "@fdr/static-ui/images/fdr-rg-logo.png";

import { openExternalLink } from "@tvg/sh-utils/mobileUtils";

import {
  MessageStateWrapper,
  MessageContainer,
  FinalMessageContainer
} from "./styled-components";

interface Props {
  firstMessage: string;
  secondMessage: string;
  logo: string;
  link?: string;
  qaLabel?: string;
}

const mappedLogos = {
  wvRgLogo: WvRgLogo,
  ilRgLogo: IlRgLogo,
  palRgLogo: PalRgLogo,
  fdrRgLogo: FdrRgLogo
};

export const RGMessageState = ({
  firstMessage,
  secondMessage,
  link,
  logo,
  qaLabel = "rg-message-state"
}: Props) => {
  const logoImage = get(mappedLogos, logo, FdrRgLogo);

  return (
    <MessageStateWrapper data-qa-label={qaLabel}>
      <img
        height={40}
        width={logoImage === WvRgLogo ? 52 : 40}
        src={logoImage}
        alt="Racing Responsible Gaming Logo"
      />
      <MessageContainer>
        {firstMessage}
        <FinalMessageContainer>{secondMessage}</FinalMessageContainer>
        {link && (
          <FinalMessageContainer
            rel="racing-rg-link"
            onClick={() => openExternalLink(`https://${link}`)}
          >
            {link}
          </FinalMessageContainer>
        )}
      </MessageContainer>
    </MessageStateWrapper>
  );
};

export default RGMessageState;
