import React, { memo } from "react";
import { Container } from "./styled-components";

const TrackGroupHeader = () => (
  <Container>
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_39045_266999)">
        <rect
          x="0.75"
          width="12"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
          className="pulse"
        />
      </g>
      <defs>
        <clipPath id="clip0_39045_266999">
          <rect x="0.75" width="12" height="12" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Container>
);

export default memo(TrackGroupHeader);
