import { Colors, ColorVariation, Theme } from "@tvg/design-system";
import styled from "styled-components";

const getBackgroundColor = ({
  theme,
  backgroundColor
}: {
  theme: Theme;
  backgroundColor: string;
}) => {
  const [color = "red", variation = "600"]: string[] =
    backgroundColor.split(".");
  // @ts-ignore
  return theme.colors[color as keyof Colors][variation as keyof ColorVariation];
};

export const Dismiss = styled.button<{
  width: number;
  backgroundColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${getBackgroundColor};
  width: ${({ width }) => width}px;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
`;
