import { BrazeInboxEvent, BrazeInboxAction, BrazeInboxState } from "./types";

export const initialState: BrazeInboxState = {
  isModalOpen: false
};

export const reducers = (
  state: BrazeInboxState = initialState,
  action: BrazeInboxAction
) => {
  switch (action.type) {
    case BrazeInboxEvent.OPEN: {
      return { ...state, isModalOpen: true };
    }
    case BrazeInboxEvent.CLOSE: {
      return { ...state, isModalOpen: false };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
