import React, { FC, memo } from "react";
import { Paragraph } from "../../../typography";
import { Container } from "../../styled-components";
import { useColorTokens } from "../../../../theming";
import { MtpCellProps } from "../../types";
import { useQaLabel } from "../../../../hooks";

export const Default: FC<
  Pick<MtpCellProps, "withBackground" | "align" | "qaLabel">
> = memo(({ withBackground, align, qaLabel = "mtp-cell", ...rest }) => {
  const qaLabelProps = useQaLabel(qaLabel);
  const colorTokens = useColorTokens();

  return (
    <Container
      withBackground={withBackground}
      align={align}
      bgColor={colorTokens.background.accent}
      lineColor={colorTokens.border.default}
      {...qaLabelProps}
      {...rest}
    >
      <Paragraph
        fontFamily="bold"
        textAlign={align}
        qaLabel={`${qaLabel}-available-bet-types`}
        fontSize="16px"
        lineHeight="20px"
        color={colorTokens.content.default}
      >
        Available bet types
      </Paragraph>
    </Container>
  );
});
