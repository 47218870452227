import React from "react";
import { useDispatch } from "react-redux";
import { Icon } from "@tvg/design-system/web";
import Collapsible from "../../Collapsible";
import { Container, StyledSummary } from "./styled-components";
import { CollapsibleByYearProps } from "./types";
import { useRaceScroll } from "../../../hooks/useRaceScroll";
import RaceResultCard from "../../RaceResultCard";
import { ScrollButtonFloating } from "../../CollapsibleWrapperResults/styled-components";

const CollapsibleByYear: React.FC<CollapsibleByYearProps> = ({
  qaLabel = "CollapsibleByYear",
  races,
  year,
  runnerNameHighlight,
  isOpen,
  handleOnClick
}) => {
  const dispatcher = useDispatch();
  const isCollapsibleYearOpen = isOpen[year];
  const initialScrollBehavior = "first";
  const {
    isNextEnabled,
    isPrevEnabled,
    containerRef: racesRef,
    handleScroll
  } = useRaceScroll(races, isCollapsibleYearOpen, initialScrollBehavior);

  return (
    <Collapsible
      key={year}
      summary={<StyledSummary tag="h3">{year}</StyledSummary>}
      isOpen={isCollapsibleYearOpen}
      onClick={() => handleOnClick(year)}
    >
      <ScrollButtonFloating
        data-qa-label={`${qaLabel}-prev-button`}
        onClick={() => handleScroll("prev")}
        disabled={!isPrevEnabled}
        isOpen={isCollapsibleYearOpen}
        position="start"
      >
        <Icon
          name="arrowLeft"
          size="s"
          lineColor="--fd-colors-component-button-tertiary-content-base"
        />
      </ScrollButtonFloating>
      <Container ref={racesRef}>
        {races.map((race) => (
          <RaceResultCard
            key={race.track.code + race.date}
            race={race}
            runnerNameHighlight={runnerNameHighlight}
            dispatch={dispatcher}
            isGrouped
            isTrackNameShown
            showFullNameOnReplayVideo
          />
        ))}
      </Container>
      <ScrollButtonFloating
        data-qa-label={`${qaLabel}-next-button`}
        onClick={() => handleScroll("next")}
        disabled={!isNextEnabled}
        isOpen={isCollapsibleYearOpen}
        position="end"
      >
        <Icon
          name="arrowRight"
          size="s"
          lineColor="--fd-colors-component-button-tertiary-content-base"
        />
      </ScrollButtonFloating>
    </Collapsible>
  );
};

export default CollapsibleByYear;
