import { get } from "lodash";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { emptyMessage } from "./defaultValues";
import { BrazeMessage } from "./types";

export const getBrazeContentCards = (store: unknown) =>
  get(store, "brazeData.brazeContentCards.cards", []);

export const getBrazeMessages = (store: unknown): BrazeMessage =>
  parseCAPIMessage(store, "capi.messages.brazeMessages", emptyMessage);

export const isBrazeModalOpen = (store: unknown) =>
  get(store, "brazeInbox.isModalOpen", false);

export const isBrazeInboxEnabled = (store: unknown) =>
  get(store, "capi.featureToggles.isBrazeInboxEnabled", false);
