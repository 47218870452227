import React, { FC } from "react";
import { Countries, Icon } from "@tvg/design-system";
import { Flag, Paragraph } from "@tvg/design-system/web";
import { FlagContainer, HeaderContainer } from "./styled-components";
import { HeaderProps } from "../../types";
import { getFlagLabel } from "../../utils";

const HeaderDesktop: FC<HeaderProps> = ({ favoriteElem, onBack, track }) => (
  <HeaderContainer>
    <FlagContainer data-qa-label="track-details-back" onClick={onBack}>
      <Icon name="arrowLeft" size="m" qaLabel="" />
    </FlagContainer>
    <Flag
      country={track.location.country as Countries}
      size="xl"
      ml={8}
      qaLabel=""
      paddingRight="4px"
    />
    <Paragraph qaLabel="track-detail-country" fontFamily="condensedRegular">
      {getFlagLabel(track)}
    </Paragraph>
    <Paragraph
      qaLabel="track-detail-title"
      color="content.subtle"
      fontFamily="bold"
      fontSize="3xl"
      ml={16}
    >
      {track.name}
    </Paragraph>
    {favoriteElem()}
  </HeaderContainer>
);

export default HeaderDesktop;
