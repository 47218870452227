export const setWithdrawals = (withdrawals) => ({
  type: "WITHDRAWALS_SET_WITHDRAWS",
  payload: { withdrawals }
});

export const openCancelWithdrawal = (cancelInformation) => ({
  type: "WITHDRAWALS_OPEN_CANCEL",
  payload: { cancelInformation }
});

export const closeCancelWithdrawal = (successfulCancel) => ({
  type: "WITHDRAWALS_CLOSE_CANCEL",
  payload: { successfulCancel }
});

export const errorCancellingWithdrawal = (message, code) => ({
  type: "WITHDRAWALS_CANCEL_ERROR",
  payload: { message, code }
});

export const cancelWithdrawalLoading = (isLoading) => ({
  type: "WITHDRAWALS_CANCEL_LOADING",
  payload: { isLoading }
});

export const resetMessages = () => ({
  type: "WITHDRAWALS_RESET_MESSAGES"
});

export const clearErrorCancellingWithdrawal = () => ({
  type: "WITHDRAWALS_CLEAR_ERROR"
});

export const setPendingLoading = (isPendingLoading) => ({
  type: "WITHDRAWALS_FETCH_LOADING",
  payload: { isPendingLoading }
});
