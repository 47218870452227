import React, { FC, memo } from "react";
import * as Icons from "./components/IconPaths";
import { StyledSvg } from "./styled-components";
import { useColorTokens } from "../../hooks";
import { FdIconProps, FdIconNames } from "./types";

export const FdIcon: FC<FdIconProps> = memo(
  ({ color = "content.strong", name, qaLabel, ...rest }) => {
    const IconContent = Icons[name as FdIconNames];
    const iconColor = useColorTokens(color);

    return (
      <StyledSvg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-qa-label={qaLabel || name}
        {...rest}
      >
        <IconContent color={iconColor} />
      </StyledSvg>
    );
  }
);
