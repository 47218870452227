import { SeoArea } from "@tvg/design-system/web";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  getSeoTILFooterTitle,
  getSeoTILFooterContent
} from "../../redux/selectors";

const SeoFooter = () => {
  const title = useSelector(getSeoTILFooterTitle);
  const content = useSelector(getSeoTILFooterContent);

  return <SeoArea title={title} message={content} />;
};

export default memo(SeoFooter);
