import React from "react";
import {
  ColorTokens,
  Icon,
  IconButton,
  useColorTokens
} from "@tvg/design-system";
import {
  CardContainer,
  Image,
  CardWrapper,
  IconWrapper,
  DismissContainer,
  TrashIconContainer
} from "./styled-components";
import { Props } from "./types";
import { QA_LABELS } from "../../utils/constants";
import { getLinkProps } from "../../utils";

const BannerCard = React.forwardRef<HTMLDivElement, Props>(
  (
    { url, imageUrl, title, pinned, dismissible, onDismissCard, onClickCard },
    ref
  ) => {
    const { content } = useColorTokens() as ColorTokens;
    return (
      <CardContainer ref={ref} data-qa-label={QA_LABELS.bannerCard.container}>
        {/* @ts-ignore */}
        <CardWrapper
          {...getLinkProps(url)}
          onClick={(e) => {
            if (url) {
              e.stopPropagation();
              onClickCard();
            }
          }}
        >
          <Image src={imageUrl || ""} alt={title} />
          {pinned && (
            <IconWrapper>
              <Icon
                qaLabel={QA_LABELS.bannerCard.pin}
                name="announcements"
                size="s"
                lineColor={content.link}
              />
            </IconWrapper>
          )}
        </CardWrapper>
        {dismissible && (
          <DismissContainer className="delete-button">
            <TrashIconContainer>
              <IconButton
                variant="tertiary"
                tertiaryColor="red.600"
                iconName="trash"
                qaLabel={QA_LABELS.bannerCard.dismiss}
                onPress={onDismissCard}
                size="m"
              />
            </TrashIconContainer>
          </DismissContainer>
        )}
      </CardContainer>
    );
  }
);

export default BannerCard;
