import React, { Component } from "react";

import { noop, bindAll } from "lodash";

import { Switch, Slider } from "./styled-components";

export default class Toggle extends Component {
  static defaultProps = {
    on: false,
    onClick: noop,
    qaLabel: ""
  };

  constructor(props) {
    super(props);
    bindAll(this, ["clickToggle"]);
  }

  clickToggle(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.props.onClick();
  }

  render() {
    return (
      <Switch
        isOn={this.props.on}
        onClick={this.clickToggle}
        {...(this.props.qaLabel !== ""
          ? {
              "data-qa-label": this.props.qaLabel
            }
          : {})}
      >
        <input type="checkbox" />
        <Slider isOn={this.props.on} />
      </Switch>
    );
  }
}
