import { Dispatch } from "redux";
import capi from "@tvg/api/capi";
import { batch } from "react-redux";
import { get, attempt } from "lodash";
import {
  addMessagesNamespaces,
  messageUpdate,
  failMessagesRequest,
  successMessagesRequest
} from "../redux/actions";

// request messages by namespaces
export const getNamespace = async (namespace: string, dispatch: Dispatch) => {
  if (!namespace) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return (
    capi
      .fetchMessagesByNamespaces(namespace)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: Record<string, any>) => {
        const messages: Record<string, object> = get(response, "data.response");
        let combinedMessages: Record<string, string> = {};
        const namespaces: Record<string, boolean> = {};

        // get namespaces and messages from the request
        Object.keys(messages).forEach((name: string) => {
          combinedMessages = { ...combinedMessages, ...messages[name] };
          namespaces[name] = true;
        });

        // dispatch to store
        batch(() => {
          dispatch(messageUpdate(combinedMessages));
          dispatch(addMessagesNamespaces(namespaces));
        });
      })
      .catch((err: Error) => dispatch(failMessagesRequest(err)))
  );
};

// Load store state
export const loadBoosterMessagesToStore = (
  messagesContent: string,
  dispatch: Dispatch
) => {
  const namespaces: Record<string, boolean> = {};
  let messagesCombined = {};

  if (messagesContent) {
    const messages = attempt(() =>
      get(JSON.parse(decodeURIComponent(messagesContent)), "response", [])
    );

    if (messages) {
      // get Namespaces and combine messages from server side
      Object.keys(messages).forEach((namespace: string) => {
        if (Object.keys(messages[namespace]).length) {
          messagesCombined = { ...messagesCombined, ...messages[namespace] };
          namespaces[namespace] = true;
        }
      });

      // dispatch to store
      batch(() => {
        dispatch(successMessagesRequest(messagesCombined));
        dispatch(addMessagesNamespaces(namespaces));
      });
    }
  }
};
