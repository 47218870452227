import React, { useMemo } from "react";
import { range, get } from "lodash";
import { IconButton } from "@tvg/design-system/src/components/iconButton";
import { Button } from "@tvg/design-system/src/components/button";
import { isSameYear, isSameMonth } from "date-fns";
import { Icon } from "../../../../Icon";
import {
  YearLabel,
  OpenButtonWrapper,
  SelectorWrapper,
  SelectorContainer,
  SelectorHeader,
  CloseButtonWrapper,
  SelectorTitle,
  YearWrapper,
  PickerWrapper,
  PickerButtonWrapper
} from "./styled-components";
import { MonthSelectorProps } from "../types";

export const MonthSelector = ({
  date,
  localeUtils,
  isMonthSelector,
  setIsMonthSelector,
  selectedMonth,
  setSelectedMonth,
  initialYearRange = 21
}: MonthSelectorProps) => {
  const currentYear = new Date().getFullYear();
  const selectedYear = date.getFullYear();
  const initialYear = currentYear - initialYearRange;
  const months = localeUtils.getMonths();
  const currentMonth = months[date.getMonth()];
  const monthLabel = `${currentMonth} ${selectedYear}`;

  const renderMonths = useMemo(() => {
    const yearRange = range(currentYear, initialYear);
    const monthRange = range(0, 12);

    return yearRange.map((year) => (
      <YearWrapper key={year}>
        <YearLabel>{year}</YearLabel>
        <PickerWrapper>
          {monthRange.map((month) => {
            const dateToRender = new Date(year, month);
            const isSelectedMonth =
              isSameMonth(selectedMonth as Date, dateToRender) &&
              isSameYear(selectedMonth as Date, dateToRender);

            return (
              <PickerButtonWrapper
                key={month}
                isSelectedMonth={isSelectedMonth}
              >
                <Button
                  qaLabel="month-selector-button"
                  variant={isSelectedMonth ? "primary" : "tertiary"}
                  onPress={() => {
                    setSelectedMonth(dateToRender);
                    setIsMonthSelector(false);
                  }}
                >
                  {get(months, month, "")}
                </Button>
              </PickerButtonWrapper>
            );
          })}
        </PickerWrapper>
      </YearWrapper>
    ));
  }, []);

  const renderMonthSelector = () => (
    <SelectorWrapper>
      <SelectorContainer>
        <SelectorHeader>
          <CloseButtonWrapper>
            <IconButton
              qaLabel="month-selector-close-button"
              variant="tertiary"
              iconName="chevronLeft"
              onPress={() => setIsMonthSelector(false)}
            />
          </CloseButtonWrapper>
          <SelectorTitle>Select Month</SelectorTitle>
        </SelectorHeader>
        {renderMonths}
      </SelectorContainer>
    </SelectorWrapper>
  );

  const renderMonthCaption = () => (
    <div className="DayPicker-Caption">
      <OpenButtonWrapper>
        <Button
          qaLabel="month-selector-open-button"
          variant="tertiary"
          onPress={() => setIsMonthSelector(true)}
          iconPosition="end"
          icon={
            <Icon
              name="chevronRight"
              size="s"
              lineColor="component.button.tertiary.content.base"
            />
          }
        >
          {monthLabel}
        </Button>
      </OpenButtonWrapper>
    </div>
  );

  return isMonthSelector ? renderMonthSelector() : renderMonthCaption();
};

export default MonthSelector;
