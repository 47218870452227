import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontCondensed } from "@fdr/static-ui/Typography";

export const LabelStyled = styled.span`
  font-family: ${fontCondensed};
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${buildColor("grey", "D3")};
`;
