import styled, { css } from "styled-components";

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space["space-3"]};
`;

export const FilterLabel = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTokens.content.default};
    font-family: ${theme.fonts.condensedRegular};
    font-size: ${theme.fontSizes.xs};
  `}
`;
