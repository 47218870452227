import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "@fdr/static-ui/ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  width: 294px;
  height: 329px;
`;

export default class TopRacesMask extends PureComponent {
  render() {
    return (
      <SVG>
        <g opacity="0.94" fill="none">
          <rect
            x="0.5"
            y="0.5"
            width="293"
            height="328"
            rx="4.5"
            fill="white"
          />
          <g filter="url(#filter0_i_1_2635)">
            <path
              d="M1 5C1 2.79086 2.79086 1 5 1H289C291.209 1 293 2.79086 293 5V93H1V5Z"
              fill="white"
            />
            <path d="M13 13H113V25H13V13Z" fill="#E4E8EE" />
            <rect x="13" y="37" width="45" height="12" fill="#E4E8EE" />
            <circle cx="23" cy="71" r="10" fill="#E4E8EE" />
            <rect x="37" y="65" width="21" height="12" fill="#E4E8EE" />
            <rect x="264" y="62.5" width="17" height="17" fill="#E4E8EE" />
            <rect x="222" y="62.5" width="17" height="17" fill="#E4E8EE" />
            <rect x="243" y="62.5" width="17" height="17" fill="#E4E8EE" />
          </g>
          <rect
            width="66"
            height="67"
            transform="translate(1 93)"
            fill="white"
          />
          <rect x="13" y="105" width="42" height="42" fill="#E4E8EE" />
          <g filter="url(#filter1_i_1_2635)">
            <rect
              width="226"
              height="67"
              transform="translate(67 93)"
              fill="white"
            />
            <rect x="67" y="108.5" width="120" height="12" fill="#E4E8EE" />
            <rect x="67" y="132.5" width="160" height="12" fill="#E4E8EE" />
            <rect x="260" y="108.5" width="21" height="12" fill="#E4E8EE" />
          </g>
          <rect
            width="66"
            height="67"
            transform="translate(1 160)"
            fill="white"
          />
          <rect x="13" y="172" width="42" height="42" fill="#E4E8EE" />
          <g filter="url(#filter2_i_1_2635)">
            <rect
              width="226"
              height="67"
              transform="translate(67 160)"
              fill="white"
            />
            <rect x="67" y="175.5" width="120" height="12" fill="#E4E8EE" />
            <rect x="67" y="199.5" width="160" height="12" fill="#E4E8EE" />
            <rect x="260" y="175.5" width="21" height="12" fill="#E4E8EE" />
          </g>
          <rect
            width="66"
            height="67"
            transform="translate(1 227)"
            fill="white"
          />
          <rect x="13" y="239" width="42" height="42" fill="#E4E8EE" />
          <rect
            width="226"
            height="67"
            transform="translate(67 227)"
            fill="white"
          />
          <rect x="67" y="242.5" width="120" height="12" fill="#E4E8EE" />
          <rect x="67" y="266.5" width="160" height="12" fill="#E4E8EE" />
          <rect x="260" y="242.5" width="21" height="12" fill="#E4E8EE" />
          <g filter="url(#filter3_i_1_2635)">
            <path
              d="M1 294H293V324C293 326.209 291.209 328 289 328H5C2.79086 328 1 326.209 1 324V294Z"
              fill="#F5F8FC"
            />
            <rect x="13" y="305" width="160" height="12" fill="#E4E8EE" />
            <path
              d="M274.146 306.647C273.951 306.842 273.951 307.159 274.146 307.354L277.792 311L274.146 314.646C273.951 314.841 273.951 315.158 274.146 315.353C274.341 315.548 274.658 315.548 274.853 315.353L278.853 311.353C278.951 311.255 278.999 311.127 278.999 310.999C278.999 310.871 278.95 310.743 278.853 310.645L274.853 306.645C274.658 306.452 274.342 306.452 274.146 306.647Z"
              fill="#E4E8EE"
            />
          </g>
          <rect
            x="0.5"
            y="0.5"
            width="293"
            height="328"
            rx="4.5"
            stroke="#CFD6DB"
          />
        </g>
        <defs>
          <filter
            id="filter0_i_1_2635"
            x="1"
            y="1"
            width="292"
            height="92"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_1_2635"
            />
          </filter>
          <filter
            id="filter1_i_1_2635"
            x="67"
            y="93"
            width="226"
            height="67"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_1_2635"
            />
          </filter>
          <filter
            id="filter2_i_1_2635"
            x="67"
            y="160"
            width="226"
            height="67"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_1_2635"
            />
          </filter>
          <filter
            id="filter3_i_1_2635"
            x="1"
            y="294"
            width="292"
            height="34"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_1_2635"
            />
          </filter>
        </defs>
      </SVG>
    );
  }
}
