import React from "react";
import { Paragraph, Icon } from "@tvg/design-system";
import { ContentCardEmptyContainer, IconContainer } from "./styled-components";
import { Props } from "./types";
import { QA_LABELS } from "../../utils/constants";

const EmptyMessage = ({ title, description }: Props) => (
  <ContentCardEmptyContainer data-qa-label={QA_LABELS.emptyMessage.container}>
    <IconContainer>
      <Icon size="m" name="email" qaLabel={QA_LABELS.emptyMessage.icon} />
    </IconContainer>
    <Paragraph
      qaLabel={QA_LABELS.emptyMessage.title}
      fontFamily="bold"
      fontSize="s"
      color="grey.900"
      mt="space-6"
    >
      {title}
    </Paragraph>
    <Paragraph
      qaLabel={QA_LABELS.emptyMessage.description}
      fontSize="s"
      color="grey.800"
      mt="space-1"
      px="space-6"
      textAlign="center"
    >
      {description}
    </Paragraph>
  </ContentCardEmptyContainer>
);

export default EmptyMessage;
