import React from "react";
import { IconWithBackground, IconNames, useTheme } from "@tvg/design-system";
import { Props } from "./types";

const InfoIllustrationIcon: React.FC<Props> = ({ iconName }) => {
  const { colorTokens } = useTheme();

  return (
    <IconWithBackground
      mainCircleBackground={`${colorTokens.background.infoSubtle}`}
      mainIcon={{
        name: iconName,
        lineColor: `${colorTokens.content.info}`,
        backgroundColor: `${colorTokens.background.infoSubtle}`
      }}
      badgeIcon={{
        name: "info" as IconNames,
        lineColor: `${colorTokens.content.info}`,
        backgroundColor: `${colorTokens.background.infoSubtle}`
      }}
    />
  );
};

export default InfoIllustrationIcon;
