import { RefObject, useEffect, useState } from "react";
import { noop } from "lodash";

const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  {
    threshold = 0.5,
    root = null,
    rootMargin = "0%",
    freezeOnceVisible = false
  },
  onUnmounted = noop
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    // eslint-disable-next-line consistent-return
    return () => {
      onUnmounted();
      observer.disconnect();
    };
  }, [elementRef.current, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
};

export default useIntersectionObserver;
