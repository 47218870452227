import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 12px 0;

  > div {
    height: 46px;
    width: 46px;
    padding: 3px;
  }
`;
