import React from "react";
import { FdIconContent } from "../../types";

export const Logout = ({ color }: FdIconContent) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.5 16.5H8.5C8.776 16.5 9 16.724 9 17C9 17.276 8.776 17.5 8.5 17.5H1.5C0.673 17.5 0 16.827 0 16V2C0 1.173 0.673 0.5 1.5 0.5H8.5C8.776 0.5 9 0.724 9 1C9 1.276 8.776 1.5 8.5 1.5H1.5C1.224 1.5 1 1.724 1 2V16C1 16.276 1.224 16.5 1.5 16.5ZM16.962 8.809C17.013 8.931 17.013 9.069 16.962 9.191C16.936 9.252 16.899 9.308 16.854 9.354L13.855 12.353C13.757 12.45 13.629 12.499 13.501 12.499C13.373 12.499 13.245 12.451 13.147 12.353C12.952 12.158 12.952 11.841 13.147 11.646L15.293 9.5H4.5C4.224 9.5 4 9.276 4 9C4 8.724 4.224 8.5 4.5 8.5H15.293L13.147 6.354C12.952 6.159 12.952 5.842 13.147 5.647C13.342 5.452 13.659 5.452 13.854 5.647L16.853 8.646C16.9 8.692 16.937 8.747 16.962 8.809Z"
    fill={color}
  />
);
