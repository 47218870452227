import styled from "styled-components";
import Box from "../../containers/Box";

export const Container = styled(Box)`
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
`;

export const RacesContainer = styled.div`
  padding-bottom: 16px;
  display: flex;
  overflow: auto;
  width: 100%;
  gap: 8px;
`;
