import { BrazeContentCard } from "@tvg/braze/src/types";
import { brazeClickContentCard } from "@tvg/braze";
import { sendEventBrazeClickCard } from "../../utils/amplitude";

export const getViewedCards = (listOfBrazeCards: BrazeContentCard[]) =>
  listOfBrazeCards.filter((card) => card.viewed);

export const getSortedCards = (listOfBrazeCards: BrazeContentCard[]) =>
  listOfBrazeCards.sort(
    (a: BrazeContentCard, b: BrazeContentCard) =>
      Number(b.pinned) - Number(a.pinned) ||
      +new Date(b.updated!) - +new Date(a.updated!)
  );

export const onClickContentCard = (card: BrazeContentCard, index: number) => {
  brazeClickContentCard(card, true);
  sendEventBrazeClickCard(card, index);
};
