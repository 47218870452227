import useExperiments from "../../useExperiments";
import { DEPLOYMENT_KEY, EXPERIMENT_KEY } from "./constants";
import { VariantEnum } from "../../types";

export const useNewWatchLiveButton = () => {
  const { getVariant } = useExperiments(DEPLOYMENT_KEY);
  const variant = getVariant<VariantEnum, boolean>(EXPERIMENT_KEY);

  return variant?.value === VariantEnum.TREATMENT;
};
