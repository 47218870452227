import React, { memo } from "react";
import { AccountMenuLinkProps } from "../../types";
import AccountMenuLink from "../AccountMenuLink";
import { LinksGroupWrapper, Header } from "./styled-components";

interface Props {
  headerText: string;
  links: AccountMenuLinkProps[];
  device: string;
  qaLabel?: string;
}

export const LinksGroup = memo(
  ({
    device = "mobile",
    headerText = "Header",
    links = [],
    qaLabel = "links-group"
  }: Props) => (
    <LinksGroupWrapper device={device} data-qa-label={qaLabel}>
      <Header data-qa-label={`${qaLabel}-header`}>{headerText}</Header>
      {links.map((menuItem) => (
        <li key={`key-${menuItem.qaLabel}`}>
          <AccountMenuLink
            onTouchStart={menuItem.onTouchStart}
            qaLabel={menuItem.qaLabel}
            to={menuItem.to}
            onClick={menuItem.onClick}
            linkText={menuItem.linkText}
            highlightedText={
              menuItem.highlightedText && menuItem.highlightedText
            }
            hideLeftIcon={!menuItem.leftIcon}
          />
        </li>
      ))}
    </LinksGroupWrapper>
  )
);

export default LinksGroup;
