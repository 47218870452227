import { attempt } from "lodash";

(() => {
  const detectPrivateMode = (cb: Function) => {
    let db;
    const on = cb.bind(null, true);
    const off = cb.bind(null, false);

    const tryls = () => {
      try {
        // @ts-ignore
        window.openDatabase(null, null, null, null);
        off();
      } catch (e) {
        on();
      }
    };

    // @ts-ignore
    if (window.webkitRequestFileSystem) {
      // @ts-ignore
      window.webkitRequestFileSystem(0, 0, off, on);
    } else if ("MozAppearance" in document.documentElement.style) {
      db = indexedDB.open("test");
      db.onerror = on;
      db.onsuccess = off;
      // @ts-ignore
    } else if (/constructor/i.test(window.HTMLElement) || window.safari) {
      tryls();
    } else if (
      !window.indexedDB &&
      // @ts-ignore
      (window.PointerEvent || window.MSPointerEvent)
    ) {
      on();
    } else {
      off();
    }
  };

  detectPrivateMode((isPrivateMode: boolean) => {
    if (isPrivateMode) {
      attempt(() => localStorage.setItem("privateMode", "true"));
    }
  });
})();
