// import tvgCong from "@tvg/conf";
import { get } from "lodash";
import { AW_ENV_MAPPING } from "@tvg/conf/src/environment";
import { AWFlowsConfig } from "../sdkWebTypes";
import { Environment } from "../types";
import { CLIENT_AUTH_TOKEN } from "./web";

const product = "racing";

const getConfig = (env: Environment): AWFlowsConfig => ({
  // TODO: define in tvg conf
  clientAuthToken: CLIENT_AUTH_TOKEN,
  environment: {
    type: get(AW_ENV_MAPPING, env, "prod")
  },
  appDomain: {
    product
  }
});

export default getConfig;
