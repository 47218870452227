import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  ColorTokens,
  Icon,
  Paragraph,
  useColorTokens
} from "@tvg/design-system";
import { TABS, useRaceTracksFilter } from "@urp/lib-racetracks";
import { TrackFilterValueEnum } from "@urp/lib-racetracks/src/types";
import {
  getDistancesFilter,
  getRaceTypesFilter,
  getRacesFilter,
  getRegionsFilter,
  getTrackTypesFilter
} from "@urp/lib-racetracks/src/redux/selectors";
import {
  Container,
  IconContainer,
  Tag,
  TagsContainer
} from "./styled-components";
import {
  DISTANCE_OPTIONS,
  BETTING_FEATURE_OPTIONS,
  RACE_TYPE_OPTIONS,
  REGION_OPTIONS,
  TRACKS_OPTIONS
} from "../TracksFilter/constants";
import { processFilter } from "./utils";

interface FilterTagsProps {
  currentTab: TABS;
}

const FilterTags: FC<FilterTagsProps> = ({ currentTab }) => {
  const colorTokens = useColorTokens() as ColorTokens;
  const raceTypeFilter = useSelector(getRaceTypesFilter);
  const regionsFilter = useSelector(getRegionsFilter);
  const tracksType = useSelector(getTrackTypesFilter);
  const racesFilter = useSelector(getRacesFilter);
  const distancesFilter = useSelector(getDistancesFilter);

  const {
    handleRaceTypeChange,
    handleRegionChange,
    handleTrackChange,
    handleRaceFilter,
    handleDistanceFilter,
    handleClearFilters
  } = useRaceTracksFilter();

  const raceTypes = useMemo(
    () =>
      processFilter(
        RACE_TYPE_OPTIONS,
        raceTypeFilter,
        handleRaceTypeChange,
        currentTab
      ),
    [raceTypeFilter, currentTab]
  );

  const regions = useMemo(
    () =>
      processFilter(
        REGION_OPTIONS,
        regionsFilter,
        handleRegionChange,
        currentTab
      ),
    [regionsFilter, currentTab]
  );

  const tracks = useMemo(
    () =>
      processFilter(TRACKS_OPTIONS, tracksType, handleTrackChange, currentTab),
    [tracksType, currentTab]
  );

  const races = useMemo(
    () =>
      processFilter(
        BETTING_FEATURE_OPTIONS,
        racesFilter,
        handleRaceFilter,
        currentTab
      ),
    [racesFilter, currentTab]
  );

  const distances = useMemo(
    () =>
      processFilter(
        DISTANCE_OPTIONS,
        distancesFilter,
        handleDistanceFilter,
        currentTab
      ),
    [distancesFilter, currentTab]
  );

  const filterTags =
    currentTab === TABS.TODAY
      ? [...raceTypes, ...regions, ...tracks, ...races, ...distances]
      : [
          ...regions,
          ...(tracks.filter(
            // TODO: Remove this filter when featured it's implemented on BE
            (track) => track.value !== TrackFilterValueEnum.FEATURED
          ) || [])
        ];

  return (
    <Container hasTags={!!filterTags.length}>
      <TagsContainer>
        {filterTags.map((tag) => (
          <Tag>
            <Paragraph
              qaLabel={tag.label}
              color={colorTokens.content.subtle}
              fontFamily="regular"
              fontSize={12}
            >
              {tag.label}
            </Paragraph>
            <IconContainer data-qa-label={`icon-${tag.value}`} onClick={tag.cb}>
              <Icon name="close" size="s" pl={3} pr={3} />
            </IconContainer>
          </Tag>
        ))}
      </TagsContainer>
      {filterTags?.length > 1 && (
        <Button
          qaLabel="clear-filter"
          size="s"
          variant="tertiary"
          ml={16}
          onPress={handleClearFilters}
        >
          Clear all
        </Button>
      )}
    </Container>
  );
};

export default FilterTags;
