import { PastRaceResults } from "@tvg/ts-types/Race";
import { RacesGroupedByYear } from "./types";

export const getRacesGroupedByYear = (
  races: PastRaceResults[]
): RacesGroupedByYear =>
  races.reduce((group: RacesGroupedByYear, race) => {
    const parsedDate = new Date(race.date);
    const year = parsedDate.getFullYear().toString();
    group[year] = group[year] || [];
    group[year].push(race);

    return group;
  }, {});
