import { Dispatch } from "redux";
import { Location, NavigateFunction } from "react-router-dom";
import { modalHashMap } from "../routes/hashes";

export const hashInterceptor = (
  previousLocation: Location | undefined,
  currentLocation: Location,
  dispatch: Dispatch,
  navigate: NavigateFunction,
  isLogged: boolean,
  isLoginModalOpen: boolean,
  isAccountCompliant: boolean,
  enableAWFlows: boolean
) => {
  if (currentLocation.hash) {
    const url = `${currentLocation.pathname}${currentLocation.search}`;

    const previousHash =
      previousLocation && previousLocation.hash
        ? previousLocation.hash
        : undefined;

    const hash = modalHashMap({
      dispatch,
      navigate,
      location: url,
      isLogged,
      previousHash,
      isLoginModalOpen,
      isAccountCompliant,
      enableAWFlows
    }).find((h) => h.hash === currentLocation.hash);

    if (hash) {
      hash.call();
    }
  }
};

export default hashInterceptor;
