import React from "react";
import { noop } from "lodash";

import Icon from "@fdr/static-ui/Icons";

import colorBuilder from "@fdr/static-ui/ColorPalette";
import { arrowRight } from "@fdr/static-ui/Icons/icons";

import {
  AccountLinkGroup,
  LinkText,
  AccountExternalLink,
  AccountHighlightedText
} from "./styled-components";

const AccountMenuLink = ({
  qaLabel,
  to,
  onClick,
  linkText,
  highlightedText,
  leftIcon,
  rightIcon,
  isExternalLink,
  hideLeftIcon,
  hideRightIcon,
  disabled
}) => {
  const AccountLinkComp = isExternalLink
    ? AccountExternalLink
    : AccountLinkGroup;

  return (
    <AccountLinkComp
      onTouchStart={noop}
      data-qa-label={qaLabel}
      to={to}
      onClick={onClick}
      disabled={disabled}
    >
      {!hideLeftIcon && leftIcon && (
        <Icon
          icon={leftIcon.icon}
          color={leftIcon.color}
          size={leftIcon.size}
          qaLabel={`${qaLabel}-icon`}
        />
      )}
      <LinkText qaLabel={`${qaLabel}-text`}>
        {linkText}
        {highlightedText && (
          <AccountHighlightedText fontSize={12} name={highlightedText} />
        )}
      </LinkText>
      {!hideRightIcon && rightIcon && (
        <Icon
          icon={rightIcon.icon}
          color={rightIcon.color}
          size={rightIcon.size}
          qaLabel={`${qaLabel}-arrow`}
        />
      )}
    </AccountLinkComp>
  );
};

AccountMenuLink.defaultProps = {
  qaLabel: "account-nav-default",
  to: "/",
  onClick: noop,
  linkText: "Default",
  highlightedText: "",
  leftIcon: {
    icon: arrowRight,
    color: colorBuilder("navy", "900"),
    size: 17
  },
  rightIcon: {
    icon: arrowRight,
    color: colorBuilder("blue"),
    size: 17
  },
  isExternalLink: false,
  hideLeftIcon: false,
  hideRightIcon: false,
  disabled: false
};

export default AccountMenuLink;
