import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, noop } from "lodash";
import TVGConf from "@tvg/conf";
import { breakpoints, useMediaQuery, useTheme } from "@tvg/design-system";
import { AlertBanner } from "@tvg/design-system/web/components";
import { parseJSONCapiMessage } from "@fdr/utils/parseJSONCapiMessage";
import { getGeolocationStatus } from "@tvg/sh-geolocation/src/redux/selectors";

import LocationRevalidator from "@tvg/revalidate-location";
import { getResidenceState } from "@urp/store-selectors";
import useActiveBets from "@tvg/sh-lib-my-bets/hooks/useActiveBets";

import { bannerProps } from "@tvg/sh-utils/fixedBanner";
import { REVALIDATE_LOCATION_MESSAGES } from "./components/Header/constants";
import StandardHeader from "./components/Header";
import HamburgerMenu from "./components/HamburgerMenu";
import { GlobalStyle, Header, AlertContainer } from "./styled-components";
import { setIsHamburgerMenuOpen as setIsHamburgerMenuOpenAction } from "./store/actions";
import { getIsHamburgerMenuOpen } from "./store/selectors";
import { handleHamburgerMenuGtm } from "./gtm";

const HDR = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktopMinXXl = useMediaQuery(breakpoints.desktop.min.xxl);
  const isDesktopMaxXl = useMediaQuery(breakpoints.desktop.max.xxl);
  const wasMenuOpen = useSelector(getIsHamburgerMenuOpen);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const userHomeState = useSelector(getResidenceState);
  const { totalActiveBets: activeBets } = useActiveBets();
  const geolocationStatus = useSelector(getGeolocationStatus);
  const geolocationBannerMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.geoLocationBannerMsg")
  );
  const accountCompliantBannerMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.accountCompliantBannerMsg")
  );
  const isVerified = useSelector((store) => get(store, "userData.isVerified"));
  const isLogged = useSelector((store) => get(store, "userData.logged"));
  const isTermsAccepted = useSelector((store) =>
    get(store, "userData.jurisdiction.accepted")
  );

  const logoBrand = useMemo(
    () =>
      TVGConf().brand === "fdr" && userHomeState === "IA"
        ? "fdr-iowa"
        : TVGConf().brand,
    [userHomeState]
  );

  const onOpenHamburgerMenu = useCallback(() => {
    dispatch(setIsHamburgerMenuOpenAction(true));
    setIsHamburgerMenuOpen(true);
    handleHamburgerMenuGtm(true);
  }, []);

  const onCloseHamburgerMenu = useCallback(() => {
    dispatch(setIsHamburgerMenuOpenAction(false));
    setIsHamburgerMenuOpen(false);
    handleHamburgerMenuGtm(false);
  }, []);

  // close menu if user resizes browser
  useEffect(() => {
    if ((isDesktopMinXXl && isHamburgerMenuOpen) || !wasMenuOpen) {
      setIsHamburgerMenuOpen(false);
    }
  }, [isDesktopMinXXl, isHamburgerMenuOpen, wasMenuOpen]);

  // open menu if user resizes browser again and was open before
  useEffect(() => {
    if (isDesktopMaxXl && wasMenuOpen) {
      setIsHamburgerMenuOpen(true);
    }
  }, [isDesktopMaxXl, wasMenuOpen]);

  const bannerPropsValues = bannerProps(
    dispatch,
    {
      isVerified,
      isLogged,
      isTermsAccepted
    },
    accountCompliantBannerMsg,
    geolocationBannerMsg,
    geolocationStatus
  );

  return (
    <>
      <GlobalStyle />
      <Header theme={theme} data-qa-label="headerDesktop">
        <StandardHeader
          onClickHamburger={onOpenHamburgerMenu}
          activeBets={activeBets}
          logoBrand={logoBrand}
        />
        <HamburgerMenu
          isOpen={isHamburgerMenuOpen}
          logoBrand={logoBrand}
          onClose={onCloseHamburgerMenu}
        />
        <LocationRevalidator
          device="desktop"
          messages={REVALIDATE_LOCATION_MESSAGES}
        />
      </Header>
      {bannerPropsValues && (
        <AlertContainer>
          <AlertBanner
            qaLabel="alertBanner"
            icon={{
              name: "exclamation",
              size: "s",
              lineColor: theme.colorTokens.content.onDark
            }}
            title={bannerPropsValues.text}
            link={{
              onClick: bannerPropsValues.link?.onClick || noop,
              text: bannerPropsValues.link?.text || ""
            }}
          />
        </AlertContainer>
      )}
    </>
  );
};

export default HDR;
