import tvgConf from "@tvg/conf";
import getProtocol from "../protocolSetter";
import requester from "../requester";
import { serviceResponse } from "../types";

const seoService: string = "service.seo";
const getSeoServiceUrl = (): string => `${tvgConf().config(seoService)}`;

const getSeoData = (
  path: string
): Promise<
  serviceResponse<{
    title: string;
    description: string;
  }>
> =>
  requester()({
    url: `${getProtocol()}${getSeoServiceUrl()}/metainfo?basepath=${path}`,
    method: "get",
    headers: { "x-tvg-context": tvgConf().context() }
  });

const SEO_SERVICE = { getSeoData };

export default SEO_SERVICE;
