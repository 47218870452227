import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--fd-colors-core-white);
`;

export const HeaderContainer = styled.div`
  position: relative;
`;

export const ToggleContainer = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  display: flex;
  align-items: flex-start;
  padding: var(--fd-space-space-3);
`;
