import React, { PureComponent } from "react";
import { noop } from "lodash";
import DefaultButton from "./styled-components";

export default class ButtonDefault extends PureComponent {
  static defaultProps = {
    children: null,
    onClick: noop,
    className: "",
    isDisabled: false,
    qaLabel: "",
    renderTag: false
  };

  render() {
    const componentProps = {
      ...(this.props.qaLabel !== ""
        ? {
            "data-qa-label": this.props.qaLabel
          }
        : {}),
      onClick: !this.props.isDisabled ? this.props.onClick : noop,
      className: this.props.className,
      isDisabled: this.props.isDisabled
    };

    return (
      <DefaultButton {...componentProps} disabled={this.props.isDisabled}>
        {this.props.children}
      </DefaultButton>
    );
  }
}
