import React from "react";
import { Icon, Paragraph, IconNames, useColorTokens } from "@tvg/design-system";
import { animated, useSpring, useTransition } from "@react-spring/web";
import { HeaderContainer, IconContainer, HR } from "./styled-components";

interface HeaderProps {
  title: string;
  iconName: IconNames;
  isNavCollapsed: boolean;
}

const AnimatedHR = animated(HR);

const Header = ({ isNavCollapsed = false, title, iconName }: HeaderProps) => {
  const colorToken = useColorTokens("lhnHeader.content.default");
  const titleTransition = useTransition(isNavCollapsed, {
    config: { duration: 150 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const hrTransition = useSpring({
    config: { duration: 150 },
    width: isNavCollapsed ? "48px" : "100%"
  });

  return (
    <HeaderContainer>
      <IconContainer>
        <Icon
          name={iconName}
          qaLabel="races-header-icon"
          size="m"
          lineColor={colorToken}
        />
      </IconContainer>
      {titleTransition(
        (styles, isCollapsed) =>
          !isCollapsed && (
            <animated.div style={styles}>
              <Paragraph
                fontFamily="condensedRegular"
                fontSize="s"
                qaLabel="races-paragraph"
                color={colorToken}
                pl="space-3"
              >
                {title.toUpperCase()}
              </Paragraph>
            </animated.div>
          )
      )}
      <AnimatedHR style={hrTransition} />
    </HeaderContainer>
  );
};

export default Header;
