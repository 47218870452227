import React from "react";
import {
  LabelWrapper,
  IconWrapper,
  StyledLabel,
  StyledOptionalTag
} from "./styled-components";
import { InputLabelProps } from "./types";
import { Icon } from "../Icon";
import { useColorTokens } from "../../hooks";

export const InputLabel = ({
  label,
  isOptional = false,
  isChecked = false,
  qaLabel = "label",
  ...rest
}: InputLabelProps) => {
  const colorTokens = useColorTokens();
  return (
    <LabelWrapper>
      <StyledLabel {...rest} data-qa-label={qaLabel}>
        {label}
      </StyledLabel>
      {isOptional && <StyledOptionalTag>(optional)</StyledOptionalTag>}
      {isChecked && (
        <IconWrapper>
          <Icon
            name="success"
            size="s"
            backgroundColor={colorTokens.component.input.surface}
          />
        </IconWrapper>
      )}
    </LabelWrapper>
  );
};
