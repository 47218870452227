import React from "react";
import { Path } from "react-native-svg";

export const SaudiArabia: React.FC = () => (
  <>
    <Path d="M32 0H0V20H32V0Z" fill="#329A47" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7764 8.41994C14.7764 8.33336 14.8687 8.26842 14.9611 
      8.29007C15.2612 8.33336 15.3305 8.33336 15.5844 8.355C15.9538 8.39829 
      15.7461 8.48487 15.5844 8.5931C15.4921 8.65803 15.4459 8.67968 15.3997 
      8.76626C15.3766 8.80955 15.4228 9.00435 15.3997 9.026C15.1688 9.26409 
      14.7764 8.61474 14.7764 8.41994ZM16.3464 7.09959C16.3464 7.03466 16.4618 
      7.0563 16.508 7.07795C16.8081 7.20782 16.3464 7.31604 16.3464 
      7.09959ZM13.899 6.8615C13.899 6.90479 13.9914 7.12124 14.0144 
      7.16453C14.153 7.53249 14.4531 8.11691 14.2453 8.48487C14.0606 8.83119 
      13.6451 9.32903 13.2295 9.39396C13.0679 9.41561 12.7677 9.39396 12.8139 
      9.17751C12.837 9.11258 13.114 8.98271 13.1602 8.93942C13.3911 8.78791 
      13.5988 8.57145 13.7143 8.33336C13.8528 8.05197 13.8759 7.83552 13.7836 
      7.53249C13.7605 7.44591 13.7374 7.38098 13.6912 7.31604C13.6681 7.27275 
      13.6451 7.2944 13.6451 7.25111C13.7836 7.25111 13.9452 7.40262 13.7836 
      7.07795C13.6912 6.90479 13.5065 6.58011 13.2525 6.75327C13.2294 6.77492 
      13.2064 6.79656 13.1833 6.81821C12.9755 7.07795 13.2064 7.31604 13.368 
      7.4892C13.4834 7.61907 13.5758 7.83552 13.4834 7.98704C13.4372 8.09526 
      13.3218 8.18184 13.2295 8.24678C13.1371 8.33336 12.8601 8.57145 12.7215 
      8.63639C12.7215 8.54981 12.7215 8.50652 12.7446 8.44158C12.7446 8.37665 
      12.7677 8.31171 12.7677 8.24678C12.6061 8.29007 12.4906 8.65803 12.4676 
      8.7879C12.4445 8.89613 12.3752 8.89613 12.2367 8.98271C11.8211 9.24245 
      11.5902 9.32903 11.1054 9.67535C11.0361 9.71864 10.9668 9.76193 10.9437 
      9.84851C11.1284 9.93509 11.4979 9.74029 11.6826 9.697L12.1905 
      9.50219C12.2367 9.48054 12.3059 9.43725 12.3521 9.43725C12.3983 9.50219 
      12.3983 9.56713 12.4444 9.63206C12.4906 9.697 12.5368 9.74028 12.583 
      9.78357C13.0448 10.1732 13.7374 9.78357 14.0606 9.39396C14.1068 9.35067 
      14.153 9.28574 14.1992 9.24245C14.2222 9.2208 14.2453 9.19916 14.2684 
      9.17751C14.3146 9.11258 14.407 8.96106 14.4993 8.98271C14.5224 9.00435 
      14.6148 9.13422 14.684 9.2208L14.8456 9.37232C15.0303 9.54548 15.3074 
      9.63206 15.5613 9.697C15.7229 9.74029 16.0231 9.82686 16.1385 
      9.71864C16.254 9.63206 16.2078 9.4589 16.0693 9.39396C15.8153 9.28574 
      15.469 9.09093 15.8384 8.87448C15.9077 8.83119 16.0693 8.83119 16.1616 
      8.80955C16.4849 8.76626 16.8081 8.72297 17.1083 8.63639C17.1544 8.61474 
      17.2006 8.61474 17.2699 8.5931C17.3391 8.57145 17.3391 8.57145 17.3622 
      8.48487C17.3853 8.355 17.4315 8.20349 17.5238 8.09526C17.5469 8.05197 
      17.5931 8.03033 17.6162 7.98704C17.4777 7.90046 17.3853 7.9221 17.2237 
      7.9221H16.0693C15.9539 7.9221 15.9308 7.94375 15.8846 7.87881C16.0462 
      7.79223 16.7157 7.83552 16.9466 7.38098C17.1313 7.0563 17.0159 6.66669 
      16.6465 6.55847C16.4618 6.49353 16.3464 6.53682 16.1847 6.64505C15.977 
      6.79656 15.9769 7.03466 15.9769 7.25111C15.9769 7.33769 15.9769 7.38098 
      15.9076 7.38098C15.6768 7.42427 15.746 7.38098 15.3997 7.4892C15.1688 
      7.57578 14.7071 7.79223 14.684 8.00868C14.6379 8.00868 14.6148 7.98704 
      14.6148 7.96539C14.5917 7.9221 14.5686 7.66236 14.5686 7.59743C14.5455 
      7.42427 14.4993 7.31604 14.4531 7.16453C14.407 7.01301 14.2223 6.51518 
      14.0376 6.47189C13.9683 6.58011 13.899 6.70998 13.899 6.8615Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8485 14.8701C20.6407 14.8701 20.5945 14.8701 20.3867 
      14.8485C20.2943 14.8485 20.3174 14.7402 20.3405 14.6537C20.456 14.6537 
      20.5483 14.6753 20.6868 14.6753H21.2409C21.3102 14.6753 21.3564 14.697 
      21.4257 14.697C21.4949 14.697 21.5411 14.6753 21.5641 14.7402C21.5872 
      14.7835 21.5642 14.8268 21.5411 14.8701H21.1717C21.1486 14.7619 20.987 
      14.7835 20.9177 14.8052C20.8715 14.8268 20.8485 14.8268 20.8485 
      14.8701ZM21.8181 15.2381H21.772C21.7027 15.2381 21.6565 15.1948 21.5641 
      15.0866C21.4718 15.1299 20.8946 15.1082 20.733 15.1082C20.5714 15.1082 
      20.5252 15.1082 20.3405 15.0866C20.3174 15.1082 20.3174 15.1515 20.2713 
      15.1731C20.0635 15.3463 19.9018 14.8701 20.0173 14.6537C18.2164 14.6537 
      16.4155 14.6537 14.5916 14.6537C13.7835 14.6537 12.9524 14.6537 12.1443 
      14.6753C11.4978 14.6753 10.8051 14.7402 10.251 14.329C10.1818 14.2857 
      10.1356 14.2208 10.0894 14.1775C10.0663 14.1558 10.0432 14.1125 10.0201 
      14.0909C10.1356 14.0909 10.2049 14.1125 10.3434 14.1125L10.8052 
      14.1342C11.0822 14.1558 11.29 14.1558 11.5671 14.1558C11.6363 14.1558 
      11.6594 14.1558 11.7056 14.1775C11.7518 14.1775 11.821 14.1775 11.8672 
      14.1775C14.5916 14.1775 17.316 14.2208 20.0404 14.2208C19.9249 14.0692 
      19.8788 13.8528 20.1558 13.7879C20.1789 13.8312 20.202 13.8744 20.202 
      13.9177C20.4098 13.9177 20.3636 14.1125 20.2943 14.2208L20.8023 
      14.2424C21.1486 14.2424 21.8874 14.1125 22.0721 14.4372C22.1645 14.6104 
      22.2799 15.1731 21.9567 15.2597C21.9105 15.2381 21.8643 15.2381 21.8181 
      15.2381Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6566 5.41125C21.6104 5.38961 21.5873 5.36796 21.5642 
      5.32467C21.5411 5.28138 21.495 5.25974 21.495 5.21645C21.5411 5.1948 
      21.5642 5.21645 21.6104 5.17316C21.6104 5.08658 21.4718 4.97835 21.4026 
      4.91342C21.3102 4.82684 21.2872 4.78355 21.1487 4.69697L21.0101 
      4.58874C20.9639 4.5671 21.0101 4.5671 20.9408 4.5671C20.8946 4.65368 
      20.8716 5.02164 20.8947 5.12987C20.9408 5.32467 21.2872 5.67099 21.4257 
      5.8658L21.7027 6.21212C21.7951 6.32034 21.772 6.27706 21.772 
      6.45022L21.8182 7.1645C21.8182 7.25108 21.8643 7.8355 21.8643 
      7.90043C21.8643 7.98701 21.8875 8.09524 21.8875 8.20346C21.8875 8.37662 
      21.8412 8.57143 21.7951 8.72294C21.7258 8.91775 21.5642 8.8961 21.4257 
      8.76623C21.3564 8.67965 21.2179 8.4632 21.1487 8.41991C20.7792 8.18182 
      20.4791 9.22078 20.3406 9.41558C20.2944 9.48052 20.1789 9.48052 20.0866 
      9.43723C19.9481 9.35065 19.9019 9.17749 19.8557 9.02597C19.8095 8.87446 
      19.8095 8.61472 19.8326 8.4632C19.7402 8.52814 19.671 8.67965 19.6479 
      8.78788C19.6248 8.91775 19.6248 9.11255 19.6479 9.24242C19.671 9.39394 
      19.7172 9.48052 19.7864 9.61039C19.9019 9.78355 20.202 10.0649 20.4329 
      9.91342C20.7561 9.71861 20.6407 9.26407 20.8254 9.04762C20.8716 8.98268 
      20.9639 8.98268 21.0332 9.02597C21.0794 9.04762 21.1717 9.17749 21.3103 
      9.26407C21.495 9.37229 21.6566 9.48052 21.8875 9.329C22.0722 9.22078 
      22.1876 9.11255 22.2338 8.8961C22.3261 8.52814 22.2568 7.33766 22.2568 
      6.94805C22.3492 7.05627 22.5339 7.31602 22.6032 7.42424C22.6493 7.48918 
      22.6494 7.77056 22.6494 7.85714C22.6494 7.94372 22.6724 8.07359 22.6724 
      8.16017C22.6724 8.20346 22.6724 8.2684 22.6724 8.31169C22.6724 8.37662 
      22.6955 8.39827 22.6955 8.4632C22.6955 8.67965 22.6955 8.8961 22.6955 
      9.11255C22.811 9.09091 22.9726 8.85281 23.0187 8.74459C23.088 8.61472 
      23.0649 8.61472 23.0649 8.4632C23.0649 8.35498 23.0649 8.24675 23.0649 
      8.11688C23.1111 8.16017 23.1804 8.2684 23.2035 8.33333L23.5498 
      8.8961C23.5729 8.93939 23.5729 8.98268 23.6191 9.00433C23.6883 8.87446 
      23.6883 8.65801 23.6652 8.50649C23.6421 8.31169 23.5959 8.24675 23.4805 
      8.09524C23.3651 7.92208 23.2497 7.74892 23.1111 7.5974C22.9495 7.40259 
      22.9726 7.44588 22.9495 7.1645C22.9495 7.07792 22.9264 6.99134 22.9264 
      6.90476C22.8802 6.47186 22.7648 6.08225 22.7186 5.67099C22.6955 5.51948 
      22.7417 5.60606 22.8803 5.51948C22.8803 5.28138 22.6494 4.80519 22.5339 
      4.61039C22.4416 4.48052 22.28 4.65368 22.2338 4.74026C22.026 5.06493 
      22.3723 5.88745 22.4415 6.23377C22.4646 6.38528 22.5108 6.53679 22.5108 
      6.68831C22.4646 6.64502 22.28 6.38528 22.2338 6.2987C22.0952 6.10389 
      22.0491 6.08225 22.0491 5.82251C22.0491 5.77922 22.0491 5.75757 22.0491 
      5.73593L22.026 5.4329C22.0952 5.4329 22.0721 5.47619 22.1645 
      5.47619C22.303 5.30303 21.8413 4.69697 21.7259 4.5671C21.6335 4.5671 
      21.6104 4.61039 21.5642 4.67532C21.518 4.74026 21.518 4.78355 21.518 
      4.89177C21.6335 5.06493 21.6566 5.23809 21.6566 5.41125Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3203 7.53243C10.3203 7.12118 10.8051 7.61901 10.4819 
      7.59737C10.3896 7.57572 10.3203 7.59737 10.3203 7.53243ZM10.8052 
      4.91338C10.8052 5.04325 11.0822 6.58005 11.1053 6.75321C11.1284 6.88308 
      11.1977 7.12118 11.1515 7.18611C11.0361 7.40256 10.8744 7.09953 10.759 
      7.0346C10.7359 7.01295 10.6897 6.99131 10.6436 6.96966C10.1818 6.7965 
      9.69694 7.61901 10.0433 7.92204C10.2049 8.05191 10.4357 8.05191 10.5973 
      7.94369C10.6435 7.92204 10.7128 7.85711 10.759 7.85711C10.8744 7.85711 
      10.6436 8.22507 10.6436 8.24672C10.5281 8.37659 10.3896 8.50646 10.2049 
      8.59304C10.0202 8.67962 9.72002 8.78784 9.51222 8.70126C9.41987 8.65797 
      9.32754 8.57139 9.30445 8.48481C9.25828 8.29001 9.35061 7.92204 9.1659 
      7.79217C9.11973 7.81382 9.09666 7.81382 9.07357 7.85711C9.05049 7.9004 
      9.0505 7.94369 9.02741 7.96533C8.61183 9.17746 10.1125 9.69694 10.7821 
      8.65797C10.8052 8.61468 10.8283 8.59304 10.8513 8.54975C10.9437 8.39823 
      10.9899 8.22507 11.0361 8.03027C11.0591 7.9004 11.2207 7.6623 11.1977 
      7.94369C11.1746 8.13849 11.1977 8.20343 11.1053 8.44152C10.9899 8.78784 
      10.7359 9.13417 10.3896 9.30733C10.0202 9.48049 9.23518 9.632 9.0043 
      9.15581C8.93503 9.0043 8.95811 8.24672 8.70414 8.46317C8.51944 8.63633 
      8.58874 9.26403 8.68109 9.45884C9.09667 10.3679 10.5281 9.84845 11.0591 
      9.35061C11.1053 9.30732 11.1284 9.28568 11.1745 9.24239C11.3823 9.02594 
      11.4747 8.74455 11.5209 8.46317C11.5209 8.39823 11.5209 7.70559 11.5209 
      7.70559C11.544 7.72724 11.5902 7.74888 11.6133 7.77053C11.798 7.87875 
      12.0289 7.79217 12.1443 7.68395C12.329 7.51079 12.5599 7.81382 12.8138 
      7.61901C12.8831 7.55408 12.9062 7.51079 12.9293 7.40256C12.9523 7.2294 
      12.9985 7.0346 12.9985 6.83979C12.6522 6.92637 12.7445 6.94802 12.6753 
      7.18611C12.4906 7.27269 12.3982 6.70992 12.1673 7.14282C12.0519 7.33763 
      11.9827 7.33763 11.8441 7.20776C11.7287 7.09953 11.8903 6.90473 11.544 
      7.05624C11.544 6.96966 11.4285 6.1255 11.4054 5.97399C11.4054 5.9307 
      11.3593 5.62767 11.3593 5.60602C11.5901 5.62767 11.5209 5.62767 11.6133 
      5.56274C11.6133 5.51945 11.5671 5.43286 11.5671 5.38957C11.5209 5.23806 
      11.3823 4.8701 11.29 4.76187C11.2438 4.71858 11.2207 4.67529 11.1284 
      4.69693C10.9437 4.69693 10.8052 4.76187 10.8052 4.91338Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.11968 4.78354C9.11968 5.08658 9.16583 5.34632 9.21201 5.6277C9.2351 
      5.71428 9.23514 5.80086 9.25823 5.88744C9.25823 5.95238 9.32749 6.2987 
      9.25823 6.36363C9.05044 6.53679 9.09657 5.73593 9.09657 5.69264C9.09657 
      5.41125 8.91186 4.97835 8.63481 5.41125C8.38084 5.80086 8.49628 6.2987 
      8.28849 6.34199C8.21922 6.2987 8.19614 6.23376 8.17305 6.14718C8.14996 
      6.03896 8.0807 5.38961 7.94217 5.38961C7.8729 5.38961 7.896 5.51948 7.896 
      5.56277C7.87291 5.84415 7.87293 6.2987 7.96528 6.55844C7.98837 6.62337 
      8.03454 6.68831 8.08072 6.75324C8.26542 6.9264 8.56558 6.90476 8.61175 
      6.40692C8.63484 6.14718 8.58864 6.01731 8.70408 5.84415C8.75026 5.77922 
      8.79647 5.82251 8.79647 5.88744C8.79647 6.03896 8.79647 6.42857 8.79647 
      6.60173C8.79647 6.79653 8.86573 6.90476 9.02735 6.99134C9.14279 7.03463 
      9.28129 6.96969 9.35056 7.09957C9.39673 7.14285 9.37367 7.20779 9.37367 
      7.27272L9.39672 7.66233C9.39672 7.92207 9.44289 8.18182 9.44289 
      8.44156C9.74303 8.44156 9.76612 7.96536 9.78921 7.77056C9.78921 7.70562 
      9.8123 7.61905 9.78921 7.53247C9.78921 7.46753 9.76615 7.3593 9.76615 
      7.29437C9.74307 7.05627 9.60451 6.60173 9.97392 6.68831C10.0894 6.70995 
      10.1356 6.81818 10.2741 6.83982C10.4126 6.86147 10.5973 6.77489 10.7127 
      6.70995C10.8051 6.64502 10.9206 6.53679 10.9206 6.38528C10.9206 6.14718 
      10.8513 5.95238 10.782 5.73593C10.7589 5.69264 10.7589 5.67099 10.7589 
      5.6277C10.7359 5.58441 10.7358 5.56277 10.7127 5.51948C10.7358 5.54112 
      10.7589 5.56277 10.8051 5.56277C11.036 5.58441 10.6666 5.12987 10.6204 
      5.08658C10.5973 5.06493 10.5973 5.06493 10.5742 5.06493C10.3895 4.93506 
      10.0432 5.17316 10.4126 5.77922C10.505 5.93073 10.5742 5.99567 10.5973 
      6.21212C10.5973 6.25541 10.5973 6.25541 10.5973 6.2987C10.4588 6.32034 
      10.4588 6.34199 10.251 6.16883C10.1124 6.03896 10.0663 6.0606 10.0894 
      5.93073C10.1125 5.75757 10.1356 5.64935 10.0663 5.49783C9.97396 5.25974 
      10.0663 5.41125 9.90465 5.17316C9.69686 4.87013 9.60455 5.28138 9.60455 
      5.4329C9.60455 5.69264 9.67379 5.93073 9.81232 6.14718C9.78923 6.16883 
      9.74303 6.27705 9.67377 6.16883C9.65068 6.14718 9.65069 6.12554 9.6276 
      6.08225C9.60451 6.01731 9.58144 5.95238 9.58144 5.8658C9.53526 5.58441 
      9.55836 5.02164 9.41983 4.78354C9.25822 4.54545 9.11968 4.71861 9.11968 
      4.78354Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4084 5.51947C17.4315 5.54111 17.4084 5.54111 17.4084 
      5.51947V5.51947ZM17.3622 5.23808C17.3622 5.32466 17.4084 5.41124 17.4084 
      5.51947C17.3853 5.49782 17.293 5.3896 17.2699 5.36795C17.2006 5.28137 
      17.2006 5.25973 17.3622 5.23808ZM16.4849 4.74025C16.4849 5.04328 16.6003
       5.10821 16.785 5.32466L17.039 5.64934C17.0621 5.69263 17.5931 6.38527 
       17.6162 6.42856C17.6393 6.49349 17.7085 7.22942 17.7085 7.33765C17.7085 
       7.40258 17.7316 7.40259 17.7316 7.46752L17.7547 7.90042C17.7778 7.987 
       17.7547 8.76622 17.7547 8.87445L17.7316 9.32899C17.7316 9.35064 17.7085 
       9.37228 17.7547 9.37228C18.3781 9.30735 18.1241 7.90042 18.101 
       7.59739C18.101 7.53245 18.078 7.51081 18.078 7.44587L18.0549 
       7.14285C18.0779 7.16449 18.1472 7.29436 18.1934 7.33765L18.7244 
       8.2251C18.7937 8.33332 18.9784 8.658 19.0015 8.74458C19.0708 8.72293 
       19.0708 8.67964 19.0708 8.61471C19.1169 8.4199 19.0938 8.11687 19.0015 
       7.96536C18.886 7.74891 18.7706 7.53246 18.6321 7.31601C18.5859 7.25107 
       18.5628 7.20778 18.5397 7.16449C18.4936 7.09955 18.4704 7.05626 18.4473 
       7.01297C18.3781 6.90475 18.3088 6.79653 18.2396 6.70995C18.1703 6.60172 
       18.1011 6.51514 18.0318 6.40691C17.9625 6.32033 17.9625 6.10388 17.9625 
       5.97401C17.9625 5.86579 17.9395 5.75756 17.9164 5.64934C17.9164 5.5844 
       17.9164 5.60605 17.9164 5.54111C17.9856 5.54111 17.9625 5.5844 18.0318 
       5.5844C18.0318 5.25973 17.7316 4.91341 17.5469 4.67531C17.5008 4.63202 
       17.5008 4.61038 17.4546 4.56709C17.293 4.67531 17.2929 4.84847 17.2929 
       5.02163C17.2699 4.99999 17.2468 4.97834 17.2237 4.93505C17.1775 4.87012 
       16.9697 4.69696 16.9005 4.65367C16.8543 4.63202 16.8543 4.61038 16.8081 
       4.58873C16.6003 4.45886 16.4849 4.48051 16.4849 4.74025Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0765 4.87011C15.0765 5.21643 15.3766 5.56275 15.1458 
      5.90907C15.0072 6.10388 14.7301 6.19046 14.5916 5.95236C14.43 5.69262 
      14.6147 5.30301 14.5223 4.99998C14.4762 4.84847 14.3146 4.80518 14.153 
      4.99998C13.9452 5.23808 13.8297 5.54111 13.7374 5.84414C13.7143 5.95236 
      13.6912 5.97401 13.6219 6.03894C13.5296 5.99565 13.4834 5.93072 13.4603 
      5.84414C13.4141 5.71427 13.4141 5.06492 13.2294 5.02163C12.9062 5.30301 
      13.2063 6.12552 13.391 6.36362C13.4603 6.4502 13.5296 6.47184 13.6219 
      6.47184C13.7143 6.47184 13.7605 6.49349 13.8528 6.36362C13.9452 6.23375 
      14.0144 6.0173 14.0375 5.86578C14.0375 5.82249 14.0606 5.75756 14.0837 
      5.73591C14.1068 5.69262 14.1298 5.67098 14.176 5.64933C14.2222 5.69262 
      14.1991 5.71427 14.1991 5.80085C14.1991 5.97401 14.1991 6.12552 14.2453 
      6.29868C14.2684 6.36362 14.3377 6.47184 14.3838 6.51513C14.5455 6.645 
      14.7532 6.66665 14.9379 6.55843C15.0534 6.49349 15.1919 6.29868 15.3074 
      6.23375C15.469 6.14717 15.5613 6.32033 15.5613 6.36362C15.6767 6.55842 
      15.9307 6.58007 16.1385 6.49349C16.3694 6.40691 16.3001 6.03894 16.6003 
      6.10388C16.6003 6.14717 16.5079 6.25539 16.6003 6.32033C16.7157 6.38526 
      16.8542 6.23375 16.9004 6.14717C16.9466 6.08223 17.062 5.93072 17.039 
      5.86578C16.9697 5.69262 16.9466 5.84414 16.8542 5.84414C16.6926 5.84414 
      16.6234 5.95236 16.4618 5.82249C16.3694 5.73591 16.3694 5.49782 16.3232 
      5.38959C16.2078 5.04327 16.3232 5.25972 16.4618 5.10821C16.5541 4.99998 
      16.3463 4.7186 16.2309 4.61037C16.0924 4.4805 15.8845 4.54544 15.8153 
      4.67531C15.7229 4.82682 15.746 5.06492 15.8384 5.19479C15.8846 5.25972 
      15.9076 5.30301 15.9307 5.36795C16 5.51946 16.1616 5.82249 16.0231 
      5.95236C15.6306 6.29868 15.5382 5.5844 15.5382 5.36795C15.5382 5.30301 
      15.5382 5.30301 15.5151 5.23808C15.5151 4.97834 15.5151 4.76189 15.3997 
      4.54544C15.3535 4.4805 15.3535 4.41557 15.2842 4.37228C15.215 4.50215 
      15.0765 4.76189 15.0765 4.87011Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4805 5.02165C23.4805 5.25975 23.5498 5.51949 23.5728 
      5.75758L23.7113 6.79654C23.7344 6.90477 23.7344 7.03464 23.7575 
      7.16451C23.7806 7.33767 23.8499 8.26841 23.8499 8.41992C23.8499 8.48486 
      23.8499 8.54979 23.8499 8.61473C23.873 8.7446 23.8499 8.85282 23.8268 
      8.96105C23.7806 9.11256 23.7806 9.09092 23.6652 9.22079C23.3189 9.63204 
      23.2727 9.50217 22.9494 9.71862C22.6724 9.89178 22.7186 10.0649 22.9956 
      10.0217C23.1111 10 23.2034 10 23.3189 9.97836C24.404 9.78356 24.2424 
      8.61473 24.2424 7.83551C24.2424 7.72728 24.2424 7.6407 24.2424 
      7.61906L24.2193 7.29438C24.1731 6.64503 24.1731 6.40693 24.0346 
      5.77923C23.9423 5.34633 23.9884 5.45455 24.1731 5.41126C24.2424 5.12988 
      23.7576 4.84849 23.6883 4.58875C23.619 4.58875 23.619 4.6104 23.5959 
      4.65369C23.5728 4.69698 23.5497 4.71862 23.5497 4.76191C23.5036 4.82685 
      23.4805 4.93507 23.4805 5.02165Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8629 5.1948C18.8629 5.34631 18.9553 5.86579 18.9784 6.0606L19.0938 
      7.1212C19.0938 7.20778 19.0938 7.27272 19.1169 7.33765C19.1169 7.38094 
      19.14 7.48917 19.14 7.53246C19.14 7.70562 19.1862 8.24675 19.2093 
      8.39826C19.2554 8.74458 19.2323 8.80952 19.0245 8.98268C18.9784 9.02597 
      18.9322 9.06925 18.8629 9.11254C18.8398 9.13419 18.8167 9.15584 18.7937 
      9.17748C18.5859 9.329 18.2165 9.61038 17.9625 9.67532C17.9625 9.78354 
      18.0779 9.87012 18.1703 9.91341C18.2857 9.97835 18.4012 9.9567 18.5397 
      9.91341C18.8168 9.82683 19.0246 9.65367 19.1862 9.43722C19.3247 9.24241 
      19.5094 8.96103 19.5556 8.72294L19.5787 8.29004C19.5787 7.51082 19.5094 
      6.70995 19.3709 5.95237C19.3478 5.86579 19.2785 5.64934 19.3247 
      5.54112C19.3478 5.47618 19.394 5.51947 19.4632 5.47618C19.5556 5.41125 
      19.4401 5.23809 19.394 5.10822C19.3478 5.02163 19.3016 4.93506 19.2554 
      4.84848C19.2092 4.78354 19.14 4.63203 19.0938 4.61038C19.0476 4.65367 
      18.9784 4.78354 18.9553 4.84848C18.8861 4.9567 18.8629 5.08657 18.8629 
      5.1948Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0634 8.78786V9.17747C20.1789 9.17747 20.2713 8.98266 20.3174 
      8.89608C20.5021 8.44153 20.3867 7.90041 20.3174 7.42422C20.2482 6.8831 
      20.1789 6.36361 20.0866 5.82249C20.0635 5.67097 20.0865 5.62768 20.1327 
      5.5411C20.202 5.43288 20.3867 5.62768 20.1558 5.28136C20.0635 5.12985 
      19.8095 4.76188 19.7633 4.63201C19.4863 4.63201 19.5555 5.45452 19.5786 
      5.60604C19.7172 6.23374 19.8788 7.0779 19.9711 7.68396C20.0404 8.00864 
      20.0634 8.39825 20.0634 8.78786Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6522 4.93509C12.6522 4.71864 12.7907 4.95674 12.8138 4.95674C12.86 
      4.97838 12.6522 5.17319 12.6522 4.93509ZM12.4444 4.8918C12.4444 5.10825 
      12.606 5.04332 12.6522 5.19483C12.606 5.28141 12.4906 5.34635 12.4213 
      5.41128L12.1673 5.60609C12.0288 5.71431 11.8441 5.82254 11.7056 
      5.95241C11.6825 5.97405 11.6594 5.97405 11.6594 6.01734C11.821 6.01734 
      12.075 6.08228 12.2366 5.97405L12.3521 5.86583C12.4213 5.80089 12.5137 
      5.71431 12.5829 5.64938L12.6984 5.54115C12.7446 5.49786 12.9985 5.23812 
      13.0216 5.19483C12.9754 5.17319 12.9293 5.1299 12.8831 5.08661C12.9062 
      5.02167 12.9293 5.02167 12.9754 4.97838C13.2294 4.71864 12.7446 4.43725 
      12.5368 4.67535C12.4906 4.74028 12.4444 4.84851 12.4444 4.8918Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0101 6.12555C21.0101 6.23377 21.0563 6.19048 21.0332 
      6.29871C20.8024 6.32035 20.9409 6.14719 20.7792 6.14719C20.5715 6.14719 
      20.5253 6.38529 20.6407 6.5368C20.71 6.62338 20.8023 6.70996 20.8947 
      6.7749L21.0563 6.88312C21.0794 6.90477 21.1948 6.9697 21.241
      6.94806C21.2641 6.92641 21.2641 6.68832 21.2641 6.62338C21.2641 6.55845 
      21.2641 6.55845 21.2872 6.51516C21.3103 6.27706 21.3334 6.342 21.495 
      6.342C21.4257 5.77923 21.0101 5.9091 21.0101 6.12555Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.96533 8.00867C8.12695 7.92209 8.31164 7.85716 8.47326 
      7.77058C8.54252 7.74893 8.58869 7.70564 8.63486 7.684C8.7503 7.64071 
      8.84265 7.57577 8.98118 7.53248C9.09662 7.48919 9.21206 7.42425 9.3275 
      7.38096C9.05045 6.86148 7.96533 7.55413 7.96533 8.00867Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.78064 9.35064C7.78064 9.39393 7.78064 9.43722 7.78064 
      9.45887C7.80373 10.2164 8.61184 9.71861 8.33479 9.35064L8.31168 
      9.32899C8.15006 9.19912 8.19625 9.61038 8.03463 9.35064C7.96537 9.24242 
      8.03463 8.93939 8.03463 8.80952C8.03463 8.74458 8.05768 8.658 8.05768 
      8.57142H8.01152C8.01152 8.61471 7.98844 8.61471 7.96535 8.658C7.873 
      8.83116 7.78064 9.13419 7.78064 9.35064Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7561 7.66236C20.7561 7.74894 20.7561 7.77059 20.8023 
      7.83552C20.9178 7.81388 21.264 7.59743 21.3333 7.53249C21.518 7.40262 
      21.8874 7.20781 21.6796 6.94807C21.6334 6.94807 21.2179 7.2511 21.1948 
      7.27275L20.9408 7.44591C20.8946 7.46756 20.8716 7.4892 20.8254 
      7.53249C20.7792 7.55414 20.7561 7.57578 20.7561 7.66236Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5541 9.30733C16.5541 9.35062 16.5541 9.37226 16.5772 
      9.39391L16.6002 9.41555C16.6464 9.41555 16.7619 9.32897 16.785 
      9.4372C16.8773 9.71858 16.8773 9.69694 17.2006 9.56707C17.316 9.52378 
      17.4776 9.48049 17.5238 9.48049C17.5699 9.26404 17.316 9.30733 17.1774 
      9.35062C16.8542 9.45884 16.9697 9.35062 16.9697 9.09088C16.8542 9.06923 
      16.5541 9.13417 16.5541 9.30733Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.326 9.37232C22.326 9.52384 22.4877 9.4589 22.4415 9.65371C22.4415 
      9.697 22.0721 9.97838 22.4184 9.91345C22.4877 9.8918 22.5569 9.87016 
      22.6031 9.84851C22.7878 9.76193 22.8571 9.71864 22.834 9.58877C22.834 
      9.56713 22.6954 9.61042 22.6724 9.58877C22.6262 9.50219 22.7185 9.4589 
      22.7185 9.37232C22.7416 9.24245 22.326 9.22081 22.326 9.37232Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5411 8.18184C21.5411 8.50651 21.6796 8.57145 21.7489 8.22513C21.772 
      8.07361 21.772 7.72729 21.6566 7.61907C21.2179 7.2511 21.5411 8.07361 
      21.5411 8.18184Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5152 6.8182V6.88314C15.4459 6.88314 15.3536 6.64504 15.3074 
      6.79656C15.2843 6.86149 15.3305 6.86149 15.2613 6.86149C15.2613 6.86149 
      15.2612 6.88314 15.2382 6.86149C15.2151 6.83985 15.215 6.83985 15.215 
      6.8182C15.1689 6.6234 14.938 6.92643 15.1227 7.09959C15.2382 7.20782 
      15.5845 7.20781 15.6768 7.0563C15.8153 6.88314 15.5152 6.60175 15.5152 
      6.8182Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6089 5.95235V6.01728C18.5397 6.01728 18.5396 6.01728 18.5396 
      5.95235C18.5166 5.82248 18.4011 5.86577 18.4011 5.99564C18.2856 5.99564 
      18.3088 5.80083 18.1933 5.90906C18.101 5.99564 18.1702 6.1688 18.2395 
      6.25538C18.378 6.3636 18.6551 6.34196 18.7474 6.19044C18.909 5.97399 
      18.6089 5.75754 18.6089 5.95235Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.74316 4.76194C9.74316 5.368 10.6436 4.9351 10.3665 4.63207C10.3204 
      4.58878 10.2511 4.58877 10.228 4.67535C10.2049 4.71864 10.228 4.71864 
      10.2049 4.78358C10.1357 4.78358 10.1356 4.78358 10.1356 4.71865C10.1126 
      4.61042 10.0202 4.58878 9.99715 4.76194C9.83554 4.76194 9.97404 4.65371 
      9.8586 4.63207C9.78934 4.61042 9.74316 4.697 9.74316 4.76194Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4242 5.28145C18.4242 5.47625 18.378 5.4979 18.4242 5.56283C18.4704 
      5.56283 18.4935 5.54119 18.5166 5.4979C18.5396 5.47625 18.5627 5.43296 
      18.5627 5.38967C18.632 5.21651 18.6551 5.00006 18.5397 4.84855C18.4704 
      4.76197 18.1933 4.54551 18.1702 4.78361C18.1241 4.93512 18.4242 5.10829 
      18.4242 5.28145Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9957 5.21646C22.9957 5.34634 23.0188 5.34634 23.0881 
      5.45456C23.2497 5.7143 23.2266 5.62772 23.2266 5.86582C23.2266 5.93075 
      23.2035 5.93075 23.2035 5.99569C23.2035 6.10391 23.2497 6.08227 23.2728 
      6.08227C23.2959 6.03898 23.3189 6.01733 23.3651 5.88746C23.4344 5.67101 
      23.4344 5.56279 23.342 5.34634C23.319 5.30305 23.2959 5.25975 23.2728 
      5.21646C23.1573 5.02166 22.9957 5.12988 22.9957 5.21646Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3521 6.23377C12.3521 6.5368 12.6753 6.55845 12.8138 6.40693C12.8369 
      6.38529 12.86 6.342 12.8831 6.29871C12.9293 6.25542 12.9292 6.21213 
      12.9061 6.16884C12.6522 6.12555 12.7676 6.40693 12.6291 6.12555C12.5598 
      6.01732 12.7677 5.97403 12.606 5.9091C12.4675 5.86581 12.3521 6.14719 
      12.3521 6.23377Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.45027 5.32472C8.54262 5.32472 8.54258 5.25978 8.58876 5.1732C8.63494 
      5.02169 8.65802 4.87018 8.58876 4.71866C8.54258 4.61044 8.3579 4.52385 
      8.26555 4.63208C8.1732 4.7403 8.45027 4.93511 8.45027 5.04334C8.45027 
      5.15156 8.42718 5.23814 8.45027 5.32472Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6378 5.10826C14.6378 5.25977 14.7995 5.21648 14.8456 
      5.08661C15.1227 4.41562 14.7764 4.35068 14.684 4.67535C14.661 4.80522 
      14.6378 4.95674 14.6378 5.10826Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2366 8.37665C12.2366 8.54981 12.1905 8.57146 12.2598 
      8.63639C12.4445 8.61475 12.4213 8.29007 12.3983 8.18185C12.2828 7.81388 
      11.9365 7.98704 12.075 8.13856C12.1443 8.20349 12.2366 8.26843 12.2366 
      8.37665Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4834 4.87012C13.4603 5.02163 13.622 5.12986 13.7605 5.02163C13.8067 
      4.97834 13.8297 4.93505 13.8297 4.87012C13.7836 4.84847 13.7374 4.84847 
      13.6912 4.87012C13.5989 4.67531 13.8067 4.61037 13.8297 4.52379C13.8528 
      4.41557 13.7143 4.39392 13.5758 4.58873C13.5527 4.67531 13.4834 4.76189 
      13.4834 4.87012Z"
      fill="#F8F8F9"
    />
  </>
);

export default SaudiArabia;
