import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Action, Store, Dispatch } from "redux";
import { format, parseJSON } from "date-fns";
import {
  AWPath,
  openAWPage
} from "@tvg/sh-lib-account-wallet/src/utils/AWPages";
import PreferenceService from "@tvg/api/uam";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import { GlobalWalletMediatorEventType } from "@tvg/ts-types/Amplitude";
import mediator from "@tvg/mediator";
import PlayableBalanceModal from "@urp/playable-balance/src/components/PlayableBalance";
import { isPlayableBalanceModalOpen } from "@urp/playable-balance/src/redux/selectors";
import {
  closePlayableBalanceModal,
  openPlayableBalanceModal
} from "@urp/playable-balance/src/redux/actions";
import { AccountXSellProps } from "./types";
import { hasNewLink, supportOpenGtm } from "./utils";
import { useGwMenuRoutes, usePreferences } from "./hooks";
import AccountMenuMain from "./components/AccountMenuMain";
import { globalWalletMenuDataDefault, playableBalance } from "./defaultValues";

export const GWAccountMenu = ({
  accountNumber,
  userName,
  sessionStartAt,
  isBalanceShown,
  balance,
  bonus,
  sharedDeposits,
  sharedWinnings,
  setBalanceShown,
  derbyGuide,
  isTutorialsLinkNew,
  doNotSell,
  isPreferencesShown,
  device,
  isBetConfirmationEnabled,
  dispatch,
  onLogout,
  termsAndConditionsURL,
  rgMessagePerState,
  stateSelectoAbbr,
  xSellMigrationDate,
  talentPicks,
  watchFDTV,
  enableNoLoginRequiredForAccountPage,
  isLogged,
  enablePreferencesCenter,
  playableBalanceContent,
  onTransactionHistoryClick,
  globalWalletMenuData,
  deskAccountLinks
}: AccountXSellProps) => {
  const reduxDispatch = useDispatch();
  const isPlayableBalanceModalOpened = useSelector(isPlayableBalanceModalOpen);

  useEffect(() => {
    if (isPlayableBalanceModalOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isPlayableBalanceModalOpened]);

  // talentPicks, watchFDTV, derbyGuide are just wrappers to hold toggles
  const { accountLinks, accountOverviewLinks, otherLinks } = useGwMenuRoutes({
    derbyGuide,
    isTutorialsLinkNew,
    doNotSell,
    termsConditions: {
      url: termsAndConditionsURL || "",
      label: "Terms and Conditions"
    },
    xSellMigrationDate,
    accountNumber,
    talentPicks,
    watchFDTV,
    enableNoLoginRequiredForAccountPage,
    enablePreferencesCenter,
    onTransactionHistoryClick,
    globalWalletMenuData
  });

  const onToggleShownBalance = () => {
    if (!accountNumber) {
      onOpenComplianceModal();
      return;
    }

    const newShownBalance = !isBalanceShown;
    PreferenceService.setUserPref(
      accountNumber,
      "balance_is_shown",
      Number(newShownBalance).toString()
    );

    setBalanceShown(newShownBalance);
  };

  const { toggleBetConfirmationPreference } = usePreferences({
    dispatch,
    accountNumber
  });

  const deposits = {
    depositHandler: () => openAWPage(AWPath.Deposit),
    withdrawHandler: () => openAWPage(AWPath.Withdrawals)
  };

  const noLoginRequiredForAccountPage =
    enableNoLoginRequiredForAccountPage && !isXSell();

  const handleOnOpenPlayableBalanceModal = () => {
    reduxDispatch(openPlayableBalanceModal());
    mediator.base.dispatch({
      type: GlobalWalletMediatorEventType.PLAYABLE_BALANCE_MODAL,
      payload: {
        open: true
      }
    });
  };

  const handleOnClose = () => {
    reduxDispatch(closePlayableBalanceModal());
    mediator.base.dispatch({
      type: GlobalWalletMediatorEventType.PLAYABLE_BALANCE_MODAL,
      payload: {
        open: false
      }
    });
  };

  return (
    <>
      <AccountMenuMain
        balance={balance}
        isBalanceShown={isBalanceShown}
        onToggleShownBalance={onToggleShownBalance}
        userName={userName || accountNumber}
        balancesSummary={[
          {
            label: "Playable balance",
            value: balance
          }
        ]}
        balances={[
          {
            label: "Shared deposits",
            value: sharedDeposits
          },
          {
            label: "Bonuses",
            value: bonus
          },
          {
            label: "Shared winnings",
            value: sharedWinnings
          }
        ]}
        accountLinks={accountLinks}
        accountOverviewLinks={accountOverviewLinks}
        otherLinks={otherLinks}
        device={device}
        isPreferencesShown={isPreferencesShown && !enablePreferencesCenter}
        isBetConfirmationEnabled={isBetConfirmationEnabled === "1"}
        onBetConfirmationToggle={() => {
          if (accountNumber) {
            toggleBetConfirmationPreference(isBetConfirmationEnabled);
          } else {
            onOpenComplianceModal();
          }
        }}
        sessionStartAt={sessionStartAt}
        deposits={deposits}
        onLogout={onLogout}
        onSupport={supportOpenGtm}
        supportUrl={globalWalletMenuData?.support?.url || ""}
        rgMessagePerState={
          (stateSelectoAbbr &&
            rgMessagePerState &&
            // eslint-disable-next-line security/detect-object-injection
            rgMessagePerState[stateSelectoAbbr]) as
            | Record<string, string>
            | undefined
        }
        noLoginRequiredForAccountPage={noLoginRequiredForAccountPage}
        isLogged={isLogged}
        onViewBalanceDetails={() => {
          openAWPage(AWPath.Account);
          mediator.base.dispatch({
            type: GlobalWalletMediatorEventType.VIEW_BALANCE_DETAILS
          });
        }}
        onOpenPlayableBalanceModal={handleOnOpenPlayableBalanceModal}
        deskAccountLinks={deskAccountLinks}
      />
      {playableBalanceContent && (
        <PlayableBalanceModal
          isOpen={isPlayableBalanceModalOpened}
          onClose={handleOnClose}
          modalContent={playableBalanceContent}
        />
      )}
    </>
  );
};

const mapStateToProps = (
  store: Store
): Omit<
  AccountXSellProps,
  | "setBalanceShown"
  | "isExternalDeposit"
  | "device"
  | "dispatch"
  | "onLogout"
  | "termsConditions"
  | "onTransactionHistoryClick"
  | "deskAccountLinks"
> => ({
  firstName: get(store, "userData.user.firstName", ""),
  lastName: get(store, "userData.user.lastName", ""),
  accountNumber: get(store, "userData.user.accountNumber"),
  userName: get(store, "userData.user.userName"),
  balance: get(store, "userData.balance", 0),
  bonus: get(store, "userData.bonus", 0),
  sharedDeposits: get(store, "userData.sharedDeposits", 0),
  sharedWinnings: get(store, "userData.sharedWinnings", 0),
  sessionStartAt: get(store, "userData.sessionStartAt", new Date()),
  isBalanceShown:
    get(store, "userData.preferences.balance_is_shown", "0") !== "0",
  derbyGuide: {
    toggle: get(store, "capi.featureToggles.enableDerbyGuide", false)
  },
  globalWalletMenuData: parseJSONCapiMessage(
    store,
    "capi.messages.globalWalletMenu",
    globalWalletMenuDataDefault
  ),
  isTutorialsLinkNew:
    get(store, "mobileAccount.tutorialsList.length", 0) &&
    hasNewLink(get(store, "mobileAccount.tutorialsList", [])),
  doNotSell: {
    toggle: get(store, "capi.featureToggles.doNotSell", false)
  },
  isPreferencesShown: get(
    store,
    "capi.featureToggles.userBetPreferences",
    false
  ),
  isBetConfirmationEnabled: get(
    store,
    "userData.preferences.show_bet_confirm",
    "0"
  ),
  isTouchIdEnabled: get(store, "ios.touchId.touchIdEnabled", false),
  termsAndConditionsURL: get(
    store,
    "userData.jurisdiction.terms_and_conditions.url",
    ""
  ),
  rgMessagePerState: parseJSONCapiMessage(
    store,
    "capi.messages.rgMessagePerState",
    {}
  ),
  stateSelectoAbbr: get(store, "app.selectedState", ""),
  xSellMigrationDate: format(
    parseJSON(
      get(store, "capi.messages.xSellMigrationDate", "2022-12-22T02:59:59.999Z")
    ),
    "LLL dd', 'u"
  ),
  talentPicks: {
    toggle: get(store, "capi.featureToggles.enableTalentPicksPage", false)
  },
  watchFDTV: {
    toggle: get(store, "capi.featureToggles.enableURPWatchFDTV", false)
  },
  enableNoLoginRequiredForAccountPage: get(
    store,
    "capi.featureToggles.enableNoLoginRequiredForAccountPage",
    false
  ),
  isLogged: get(store, "userData.logged", false),
  enablePreferencesCenter: get(
    store,
    "capi.featureToggles.enablePreferencesCenter",
    false
  ),
  playableBalanceContent: parseJSONCapiMessage(
    store,
    "capi.messages.playableBalance",
    playableBalance
  )
});

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setBalanceShown: (isBalanceShown: boolean) => {
    dispatch({
      type: "USER_PREFS_BALANCE_SHOWN",
      payload: isBalanceShown ? "1" : "0"
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GWAccountMenu);
