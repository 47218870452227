import styled from "styled-components";

// hardcoded color - doesn't exist in ds
export const Container = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 41px;
  background-color: #f7fbff;
  position: relative;
  overflow: hidden;
`;

export const Svg = styled.svg`
  position: absolute;
  top: 6px;
  left: 2px;
  height: 26px;
`;
