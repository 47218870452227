import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { InputCheckboxSelect } from "@tvg/design-system/web";
import { TABS, TABS_NAMES, useRaceTracksFilter } from "@urp/lib-racetracks";
import { getIsLogged } from "@urp/store-selectors";
import { getRaceTracksIsSearchActive } from "@urp/lib-racetracks/src/redux/selectors";
import { buildQaLabel } from "@tvg/test-utils/utils";

import { FiltersContainer, InputsWrapper, Section } from "./styled-component";
import RaceTracksSearch from "../RaceTracksSearch";
import { COMP_WIDTH_PLUS_ERROR_MARGIN } from "./constants";
import { getMappedProps } from "./utils";
import FilterTags from "../FilterTags";
import type { MappedFilterProps, Props } from "./types";
import {
  getFiltersConfiguration,
  getIsAllFiltersFeatureEnabled
} from "../../redux/selectors";
import { CONTAINER, CONTENT, FILTER, SECTION } from "../../utils/constants";
import DrawerWrapper from "./DrawerWrapper";

const TracksFilter: FC<Props> = ({ currentTab, qaLabel = "", loading }) => {
  const filterContainerRef = useRef<HTMLDivElement | null>(null);
  const isTracksSearchActive = useSelector(getRaceTracksIsSearchActive);
  const [numVisibleFilters, setNumVisibleFilters] = useState(0);
  const isLogged = useSelector(getIsLogged);
  const filtersConfig = useSelector(getFiltersConfiguration);
  const isAllFiltersEnabled = useSelector(getIsAllFiltersFeatureEnabled);

  const {
    handleRaceTypeChange,
    handleRegionChange,
    handleTrackChange,
    handleDistanceFilter,
    handleRaceFilter,
    raceTypesFilter,
    regionsFilter,
    trackTypesFilter,
    racesFilter,
    distancesFilter
  } = useRaceTracksFilter();

  /**
   * Will set the amount of visible filters depending on the width of its container
   */
  useEffect(() => {
    if (isAllFiltersEnabled) {
      const element = filterContainerRef.current;
      if (!element) return () => {};

      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const { width } = entry.contentRect;
          const roundedValue = Math.floor(width / COMP_WIDTH_PLUS_ERROR_MARGIN);
          setNumVisibleFilters(roundedValue);
        });
      });

      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [filterContainerRef.current?.clientWidth, isAllFiltersEnabled]);

  if (loading) {
    return null;
  }

  const tracksFilters = filtersConfig
    .filter(({ visible }) => visible)
    .reduce<MappedFilterProps[]>((acc, { id, label }) => {
      const filterProps = getMappedProps(id, {
        currentTab,
        handleDistanceFilter,
        handleRaceFilter,
        handleRaceTypeChange,
        handleRegionChange,
        handleTrackChange,
        isLogged,
        distancesFilter,
        racesFilter,
        raceTypesFilter,
        regionsFilter,
        trackTypesFilter,
        qaLabel
      });

      if (filterProps)
        acc.push({
          ...filterProps,
          label
        });

      return acc;
    }, []);

  const inputCheckboxes = tracksFilters.map(
    (
      { onChange, options, qaLabel: mapQaLabel, selectedValue, label },
      index
    ) => (
      <InputCheckboxSelect
        qaLabel={mapQaLabel}
        key={`${label}-${index.toString()}`}
        selectList={options}
        onSelectCheckbox={onChange}
        selectedValues={selectedValue}
        label={label}
      />
    )
  );

  // Cuts down the array of all filters with the state stored
  const renderInputCheckboxFilters = isAllFiltersEnabled
    ? inputCheckboxes.splice(0, numVisibleFilters)
    : inputCheckboxes;

  // If we dont have filters (this stops the button from rendering when the page is loading)
  // or our array of buildInputCheckboxFilters (result of splicing) has something, we should stop the button
  // from rendering
  const areCardsHidden = numVisibleFilters !== 0 && inputCheckboxes.length > 0;

  return (
    <Section data-qa-label={buildQaLabel([qaLabel, FILTER, SECTION])}>
      <FiltersContainer
        data-qa-label={buildQaLabel([qaLabel, FILTER, CONTAINER])}
      >
        <RaceTracksSearch
          analyticsEnrichment={{ activeTab: TABS_NAMES[TABS.TODAY] }}
          qaLabel={buildQaLabel([qaLabel, FILTER])}
        />
        <InputsWrapper
          hidden={isTracksSearchActive}
          ref={filterContainerRef}
          data-qa-label={buildQaLabel([qaLabel, FILTER, CONTAINER, CONTENT])}
          isAllFiltersEnabled={isAllFiltersEnabled}
        >
          {renderInputCheckboxFilters}
        </InputsWrapper>
        {!isTracksSearchActive && areCardsHidden && isAllFiltersEnabled && (
          <DrawerWrapper
            qaLabel={qaLabel}
            title="Filter By"
            currentTab={currentTab}
          />
        )}
      </FiltersContainer>
      <FilterTags currentTab={currentTab} />
    </Section>
  );
};

export default TracksFilter;
