import styled, { css } from "styled-components";
import { Theme } from "@tvg/design-system";

interface LinksGroupBaseStylesProps {
  device: string;
  theme: Theme;
}

export const LinksGroupBaseStyles = ({
  device,
  theme
}: LinksGroupBaseStylesProps) => `
  display: flex;
  flex-direction: column;
  background-color: ${
    theme.colorTokens.component.button.secondaryDark.content.base
  };
  ${() =>
    device === "tablet"
      ? css`
          border: 1px solid ${theme.colorTokens.component.input.base};
          border-radius: 2px;
          overflow: hidden;
        `
      : css`
          border-top: 1px solid ${theme.colorTokens.component.input.base};
          border-bottom: 1px solid ${theme.colorTokens.component.input.base};
        `};

  & > li,
  & > button {
    height: 44px;
    list-style-type: none;
    align-items: center;
    position: relative;
    padding: 0 16px;
    background-color: ${
      theme.colorTokens.component.button.secondaryDark.content.base
    };

    &:active {
      background-color: ${theme.colors.grey["100"]};
    }

    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: calc(100% - 16px);
        bottom: 0;
        left: 16px;
        background: ${theme.colorTokens.component.input.base};
      }
    }
  }
`;

export const LinksGroupWrapper = styled.ul<{ device: string }>`
  ${({ device, theme }) => LinksGroupBaseStyles({ device, theme })}
`;

export const HeaderBaseStyles = css`
  height: 20px;
  margin: 12px 16px;
  color: ${({ theme }) => theme.colorTokens.background.secondary};
  font-size: 16px;
  font-weight: bold;
  line-height: 125%;
`;

export const Header = styled.div`
  ${HeaderBaseStyles};
`;
