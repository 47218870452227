import React from "react";

export const FdrIowa = () => (
  <>
    <path
      d="M21.3321 43.9153C21.4445 43.8007 21.443 43.6667 21.3646 43.4738C20.0727 39.9905 19.1044 36.3143 18.5471 32.6713C18.4806 32.2283 18.3358 31.9556 18.0904 31.695C15.9529 29.5164 14.0164 26.9175 12.4126 24.0805C14.1938 23.2518 15.808 22.729 17.4666 22.4006C17.7667 22.3433 17.9115 22.1625 17.9337 21.8899C18.0342 19.4958 18.2899 17.1244 18.6684 14.7861C18.7024 14.5254 18.5678 14.2979 18.2456 14.3431C15.2626 14.7062 12.2234 15.5349 9.33936 16.8969C8.7392 14.8765 8.28243 12.7778 8.01488 10.6323C11.7119 9.06693 15.7415 8.11324 19.8835 7.89779C20.1732 7.88573 20.3402 7.75014 20.4186 7.47744C21.1192 5.1738 22.0106 2.87017 23.0794 0.611732C23.2124 0.328486 23.0897 0.0226404 22.7453 0.0105874C14.9965 -0.159662 7.28168 1.72362 0.422769 5.63935C0.122692 5.8096 0 6.0823 0 6.4454C0.133039 21.5252 7.92766 35.903 20.8428 43.9153C21.0202 44.0282 21.2094 44.0282 21.3321 43.9153Z"
      fill="white"
    />
    <path
      d="M24.494 42.6437C36.3064 34.3828 43.3205 20.7207 43.4211 6.4454C43.4329 6.0823 43.2984 5.8096 42.9983 5.63935C38.0773 2.83702 32.5562 0.964283 26.7217 0.29534C26.4319 0.262194 26.2206 0.363139 26.0979 0.613239C25.0395 2.72403 24.1052 4.99301 23.2922 7.39909C23.2139 7.65974 23.3927 7.89779 23.6485 7.92039C27.8348 8.14789 31.7757 9.10008 35.4165 10.6323C34.2147 20.3802 29.2036 28.9469 22.0564 34.6887C21.8229 34.8695 21.7564 35.109 21.8229 35.4149C22.324 37.7185 23.0247 40.102 23.882 42.4508C23.9825 42.7115 24.2708 42.8019 24.494 42.6437Z"
      fill="white"
    />
    <path
      d="M66.9125 7.5971C66.9125 4.10324 64.6642 1.71089 60.7127 1.71089H53.1741V20.2989H56.1293V13.3604H58.9372L64.1528 20.2989H67.7761L62.329 13.2104C65.2239 12.6523 66.9125 10.6582 66.9125 7.5971ZM56.1293 10.7074V4.57285H60.3967C62.7922 4.57285 63.9357 5.82435 63.9357 7.63398C63.9357 9.44361 62.8163 10.7074 60.3967 10.7074H56.1293ZM91.0168 11.0074C91.0168 14.8848 93.6921 17.7492 97.4241 17.7492C100 17.7492 102.056 16.3354 103.016 14.1668H106.176C105.021 18.0074 101.665 20.6112 97.4458 20.6112C92.0228 20.6112 88.0352 16.4977 88.0352 11.0074C88.0352 5.51701 92.0228 1.39126 97.4458 1.39126C101.665 1.39126 105.021 3.99259 106.176 7.84789H103.016C102.056 5.66699 100 4.25322 97.4241 4.25322C93.6921 4.25322 91.0168 7.12748 91.0168 11.0074ZM76.4968 1.71335L69.4334 20.3014H72.5575L74.4053 15.3077H82.3323L84.1802 20.3014H87.3042L80.2529 1.71335H76.4968ZM75.4764 12.4703L78.3712 4.68841L81.2661 12.4703H75.4788H75.4764ZM109.701 1.71335H112.644V20.3014H109.701V1.71335ZM129.398 1.71335H132.292V20.3014H128.816L119.917 6.29888V20.3014H117.022V1.71335H120.498L129.398 15.7158V1.71335ZM144.381 10.4173H154.226V20.2915H151.673V16.5739C150.814 18.9638 148.339 20.6087 145.083 20.6087C139.925 20.6087 135.94 16.5739 135.94 11.0246C135.94 5.47521 139.952 1.38879 145.343 1.38879C149.589 1.38879 152.923 3.9385 154.069 7.70774H150.918C149.929 5.58339 147.898 4.25568 145.319 4.25568C141.594 4.25568 138.912 7.09552 138.912 11.0787C138.912 14.8479 141.36 17.8205 145.293 17.8205C148.339 17.8205 150.761 15.9888 151.232 12.9621H144.381V10.4123V10.4173Z"
      fill="white"
    />
    <path
      d="M205.509 35.7214C208.328 36.3477 210 36.8894 210 39.124C210 41.5447 207.906 42.6112 205.678 42.6112C202.537 42.6112 201.035 40.9522 200.663 38.87H202.081C202.47 40.4444 203.584 41.2908 205.644 41.2908C207.467 41.2908 208.582 40.529 208.582 39.1748C208.582 37.8713 207.484 37.6004 205.188 37.0926C202.689 36.5339 200.984 35.9245 200.984 33.8254C200.984 31.6078 202.942 30.389 205.222 30.389C207.991 30.389 209.409 31.7602 209.831 33.5376H208.413C207.974 32.4034 206.961 31.7094 205.239 31.7094C203.5 31.7094 202.385 32.505 202.385 33.8254C202.385 34.8073 203.128 35.1797 205.509 35.7214Z"
      fill="#EEEEEE"
    />
    <path
      d="M198.124 30.5753H199.576L196.283 42.4251H194.51L193.312 38.1422L191.86 32.2681H191.606L190.154 38.1422L188.972 42.4251H187.183L183.89 30.5753H185.342L186.913 36.2462L188.095 40.9862L189.26 36.2462L190.83 30.5753H192.636L194.207 36.2462L195.388 41.0031L196.57 36.2462L198.124 30.5753Z"
      fill="#EEEEEE"
    />
    <path
      d="M176.772 30.389C180.199 30.389 182.85 33.0129 182.85 36.5001C182.85 39.9873 180.199 42.6112 176.755 42.6112C173.327 42.6112 170.66 39.9873 170.66 36.5001C170.66 33.0129 173.327 30.389 176.772 30.389ZM176.755 31.7263C174.121 31.7263 172.078 33.7746 172.078 36.5001C172.078 39.2255 174.121 41.2739 176.755 41.2739C179.389 41.2739 181.432 39.2425 181.432 36.5001C181.432 33.7577 179.389 31.7263 176.755 31.7263Z"
      fill="#EEEEEE"
    />
    <path
      d="M162.653 30.5753C166.097 30.5753 168.714 33.0468 168.714 36.5002C168.714 39.9366 166.08 42.4251 162.653 42.4251H158.651V30.5753H162.653ZM162.67 41.0877C165.304 41.0877 167.296 39.1918 167.313 36.5002C167.313 33.8086 165.304 31.9126 162.67 31.9126H160.053V41.0877H162.67Z"
      fill="#EEEEEE"
    />
    <path
      d="M155.1 42.4251L153.698 38.8701H148.211L146.81 42.4251H145.324L150.051 30.5753H151.858L156.585 42.4251H155.1ZM148.751 37.5328H153.175L150.963 31.9126L148.751 37.5328Z"
      fill="#EEEEEE"
    />
    <path
      d="M144.004 31.9126H137.909V35.6707H143.599V37.008H137.909V41.0877H144.004V42.4251H136.507V30.5753H144.004V31.9126Z"
      fill="#EEEEEE"
    />
    <path
      d="M131.807 30.5753H133.529V42.4251H132.128V32.3866L128.126 38.4469H127.991L123.99 32.3866V42.4251H122.588V30.5753H124.311L128.059 36.2293L131.807 30.5753Z"
      fill="#EEEEEE"
    />
    <path
      d="M115.379 31.9126H109.284V35.6707H114.974V37.008H109.284V41.0877H115.379V42.4251H107.883V30.5753H115.379V31.9126Z"
      fill="#EEEEEE"
    />
    <path
      d="M103.513 42.4251V30.5753H104.915V42.4251H103.513Z"
      fill="#EEEEEE"
    />
    <path
      d="M99.8449 42.4251L96.1473 37.8375H93.851V42.4251H92.4496V30.5753H97.1266C99.7436 30.5753 101.128 32.0988 101.128 34.2318C101.128 36.1954 99.9462 37.5497 97.8019 37.8036L101.584 42.4251H99.8449ZM93.851 31.9126V36.5848H96.9915C98.8656 36.5848 99.7098 35.5691 99.7098 34.2318C99.7098 32.9114 98.8487 31.9126 96.9915 31.9126H93.851Z"
      fill="#EEEEEE"
    />
    <path
      d="M88.0804 42.4251V30.5753H89.4818V42.4251H88.0804Z"
      fill="#EEEEEE"
    />
    <path
      d="M84.5287 42.4251L83.1273 38.8701H77.6399L76.2386 42.4251H74.7527L79.4803 30.5753H81.2869L86.0145 42.4251H84.5287ZM78.1802 37.5328H82.6039L80.3921 31.9126L78.1802 37.5328Z"
      fill="#EEEEEE"
    />
    <path
      d="M71.864 42.4251L68.1663 37.8375H65.8701V42.4251H64.4687V30.5753H69.1456C71.7627 30.5753 73.1472 32.0988 73.1472 34.2318C73.1472 36.1954 71.9653 37.5497 69.821 37.8036L73.603 42.4251H71.864ZM65.8701 31.9126V36.5848H69.0105C70.8847 36.5848 71.7289 35.5691 71.7289 34.2318C71.7289 32.9114 70.8678 31.9126 69.0105 31.9126H65.8701Z"
      fill="#EEEEEE"
    />
    <path
      d="M57.9186 30.5753C60.5356 30.5753 62.0045 32.1834 62.0045 34.3672C62.0045 36.5509 60.5356 38.2268 57.9186 38.2268H54.5755V42.4251H53.1741V30.5753H57.9186ZM57.8679 36.8895C59.7421 36.8895 60.5863 35.7384 60.5863 34.3841C60.5863 33.0299 59.7421 31.9126 57.8679 31.9126H54.5755V36.8895H57.8679Z"
      fill="#EEEEEE"
    />
  </>
);
