import React from "react";
import { FdIconContent } from "../../types";

export const Withdraw = ({ color }: FdIconContent) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 0.00305176H17C17.276 0.00305176 17.5 0.227052 17.5 0.503052C17.5 0.779052 17.276 1.00305 17 1.00305H13.5V7.50305C13.5 8.33005 12.827 9.00305 12 9.00305H6C5.173 9.00305 4.5 8.33005 4.5 7.50305V1.00305H1C0.724 1.00305 0.5 0.779052 0.5 0.503052C0.5 0.227052 0.724 0.00305176 1 0.00305176H5H13ZM9 2.00305C9.651 2.00305 10.201 1.58405 10.408 1.00305H7.592C7.799 1.58405 8.349 2.00305 9 2.00305ZM12.5 7.50305C12.5 7.77905 12.276 8.00305 12 8.00305H6C5.724 8.00305 5.5 7.77905 5.5 7.50305V1.00305H6.551C6.783 2.14305 7.793 3.00305 9 3.00305C10.207 3.00305 11.217 2.14305 11.449 1.00305H12.5V7.50305ZM9.5 15.2961L11.646 13.1491C11.842 12.9541 12.158 12.9551 12.353 13.1501C12.548 13.3451 12.548 13.6621 12.353 13.8571L9.354 16.8561C9.308 16.9031 9.253 16.9401 9.191 16.9651C9.13 16.9901 9.065 17.0031 9 17.0031C8.935 17.0031 8.87 16.9901 8.809 16.9651C8.748 16.9391 8.692 16.9021 8.646 16.8561L5.647 13.8571C5.452 13.6621 5.452 13.3451 5.647 13.1501C5.842 12.9551 6.159 12.9551 6.354 13.1501L8.5 15.2961V10.5031C8.5 10.2271 8.724 10.0031 9 10.0031C9.276 10.0031 9.5 10.2271 9.5 10.5031V15.2961Z"
    fill={color}
  />
);
