import styled, { css } from "styled-components";

export const Block = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};

  :empty {
    display: none;
  }
`;

export const Section = styled(Block)`
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colorTokens.border.default}`};
  padding: ${({ theme }) => theme.space["space-5"]};
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space["space-5"]};
`;

export const InputsWrapper = styled.div<{ hidden: boolean }>`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.space["space-5"]};
  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}
`;
