import styled, { css } from "styled-components";

export const RaceListContainer = styled.div`
  width: 100vw;
  display: flex;
  transform: translateZ(0);
  overflow: scroll hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  padding: 0 var(--fd-space-space-2, 8px);
  scroll-padding-left: var(--fd-space-space-2, 8px);
  gap: var(--fd-space-space-2, 8px);

  &::-webkit-scrollbar {
    display: none;
  }

  & > a {
    scroll-snap-align: start;
  }
`;

export const ScrollButtonFloating = styled.button<{
  disabled: boolean;
  position: "start" | "end";
  isOpen: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  position: absolute;
  height: 32px;
  width: 32px;
  top: 50%;
  z-index: 1;
  padding: var(--fd-space-space-2, 8px);
  border: 1px solid
    var(--fd-colors-component-button-tertiary-border-base, #0070eb);
  border-radius: var(--fd-radii-border-radius-020);
  box-shadow: var(--fd-shadows-elevations-bottom-low);
  background-color: var(
    --fd-colors-component-button-transparent-content-base,
    #ffffff
  );
  transition:
    visibility 0.3s ease-out 0.5s,
    opacity 0.3s ease-in-out 0.5s;
  ${({ disabled, position }) => css`
    ${position === "start"
      ? "left: var(--fd-space-space-5, 16px);"
      : "right: var(--fd-space-space-5, 16px);"}
    visibility: ${disabled ? "hidden" : "visible"};
    opacity: ${disabled ? 0 : 1};
  `}

  &:hover {
    background-color: var(
      --fd-colors-component-button-tertiary-background-hover
    );
  }
`;
