import styled from "styled-components";

export const LoadingContainer = styled.div`
  min-height: 768px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  background: ${({ theme }) => theme.colorTokens.background.secondary};
`;
