import React from "react";

export const FourNjBets = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M185.248 28.058L186.773 21.4686H190.516C190.931 23.6651 192.387 24.7279 194.951 24.7279C197.377 24.7279 198.624 24.0193 198.624 22.6731C198.624 22.1772 198.416 21.8229 198.07 21.4686C197.723 21.1143 196.406 20.6892 194.258 20.1933C192.11 19.6973 190.585 18.9179 189.823 17.8551C189.06 16.7923 188.644 15.7295 188.644 14.7375C188.644 13.0371 189.337 11.5491 190.793 10.4155C192.248 9.28182 194.119 8.715 196.337 8.715C198.07 8.715 199.525 9.14012 200.703 9.91951L200.911 8.92756H205L203.753 14.525H199.872C199.456 13.0371 198.278 12.2577 196.406 12.2577C195.505 12.2577 194.812 12.3994 194.258 12.7536C193.704 13.1079 193.426 13.533 193.426 14.0999C193.426 14.4541 193.565 14.8084 193.911 15.0918C194.258 15.3752 195.436 15.7295 197.515 16.2255C199.594 16.7214 201.05 17.43 201.881 18.4928C202.713 19.4847 203.129 20.6184 203.129 21.9646C203.129 23.8068 202.366 25.2947 200.772 26.4992C199.248 27.7037 197.238 28.2705 194.743 28.2705C192.595 28.2705 190.793 27.8454 189.476 26.9952L189.199 28.058H185.248Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.961 23.0982L181.922 27.4912C180.259 28.2705 178.665 28.6957 177.071 28.6957C175.823 28.6957 174.853 28.3414 174.091 27.6329C173.328 26.9243 172.982 25.9324 172.982 24.7987C172.982 23.9485 173.12 23.0274 173.328 22.1063L175.407 13.0371H173.19L174.091 8.92755H176.308L176.932 6.16427L183.793 1.27538L182.06 8.92755H184.625L183.724 13.0371H181.16L179.15 21.8937C179.011 22.3189 179.011 22.6731 179.011 22.9565C179.011 23.5942 179.427 23.9485 180.189 23.9485C180.952 24.0193 181.853 23.6651 182.961 23.0982Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.527 16.5797H165.012C165.081 16.2963 165.081 16.0129 165.081 15.7295C165.081 14.6667 164.804 13.8873 164.18 13.2496C163.556 12.612 162.863 12.3285 161.962 12.3285C159.883 12.2577 158.428 13.7456 157.527 16.5797ZM164.042 22.3897L168.338 24.3736C166.467 27.2786 163.626 28.6957 159.814 28.6957C157.18 28.6957 155.101 27.9163 153.507 26.4284C151.983 24.9405 151.151 22.8857 151.151 20.4058C151.151 17.1466 152.191 14.3833 154.27 12.0451C156.349 9.70696 158.913 8.50246 162.032 8.50246C164.388 8.50246 166.398 9.28185 167.992 10.8406C169.586 12.3994 170.417 14.4541 170.417 17.0757C170.417 17.926 170.348 18.8471 170.14 19.839H156.765C156.695 20.2641 156.626 20.6893 156.626 21.1144C156.626 22.3189 156.973 23.3108 157.596 23.9485C158.22 24.657 159.121 25.0113 160.161 25.0113C161.824 24.8696 163.071 24.0194 164.042 22.3897Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.449 24.0193H137.706C139.162 24.0193 140.27 23.5233 141.033 22.5314C141.795 21.5394 142.211 20.3349 142.211 18.9887C142.211 17.9968 141.934 17.2174 141.31 16.6506C140.686 16.0129 139.855 15.7295 138.746 15.7295H136.459L134.449 24.0193ZM137.221 11.7617H139.716C142.627 11.7617 144.082 10.3446 144.082 7.43961C144.082 5.66827 143.112 4.74718 141.171 4.74718H138.746L137.221 11.7617ZM124.885 23.8068H127.588L131.885 4.88888H129.182L130.152 0.566823H142.419C145.122 0.566823 147.201 1.2045 148.656 2.40901C150.042 3.61352 150.805 5.24315 150.805 7.2979C150.805 10.8406 148.726 13.037 144.637 13.8873C147.409 15.5169 148.795 17.6425 148.795 20.1932C148.795 22.6022 147.825 24.5153 145.953 25.9324C144.082 27.3494 141.934 28.058 139.577 28.058H123.915L124.885 23.8068Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.1349 28.058L78.1051 23.8068H80.6694L84.9662 4.88888H82.4019L83.3722 0.566823H93.4905L99.6585 19.6264L102.985 4.88888H100.421L101.391 0.566823H111.163L110.193 4.88888H107.628L102.361 28.058H96.332L89.3323 6.16425L85.382 23.8068H88.2234L87.1839 28.058H77.1349Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.7495 28.058L62.5811 24.3736H65.1454L66.0463 20.5475H58.0071L57.6606 16.0129L70.8283 0.141693H76.026L72.2143 17.0048H76.3032L75.4716 20.5475H71.3827L70.4817 24.3736H73.046L72.2143 28.058H61.7495ZM66.8087 17.0048L68.9571 6.94362L60.8485 17.0048H66.8087Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.212 0.566867L113.173 4.88893H116.707L114.282 15.8712C113.589 18.9888 112.618 20.9018 111.787 21.9646C110.747 23.3108 109.569 23.7359 107.975 23.7359C107.421 23.7359 106.797 23.6651 106.104 23.5234L104.787 28.058C106.242 28.4831 107.628 28.6957 109.015 28.6957C111.787 28.6957 114.143 27.8454 116.153 26.2158C117.539 25.0113 118.717 23.24 119.618 20.831C119.965 19.7682 120.311 18.6345 120.588 17.3591L121.212 14.9501L123.499 4.74722H126.479L127.449 0.42516H114.212V0.566867Z"
      fill="white"
    />
    <path
      d="M195.575 44L198.832 31.6006H3.25727L0 44H195.575Z"
      fill="#38AB4F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6457 0L4.18616 28.0642L49.8112 28.0667L57.272 0.00372507L11.6457 0Z"
      fill="#38AB4F"
    />
    <path
      d="M34.2359 41.9452V33.8679H36.9388C38.2555 33.8679 39.1565 34.0805 39.7802 34.4348C40.3346 34.789 40.6812 35.4267 40.6812 36.3478C40.6812 37.198 40.4039 37.7649 39.8495 38.19C39.2951 38.6151 38.5327 38.8277 37.4932 38.8277H36.1071V41.9452H34.2359ZM36.1071 37.4815H37.0081C37.6318 37.4815 38.0476 37.4106 38.3248 37.198C38.602 37.0563 38.7407 36.7021 38.7407 36.3478C38.7407 35.9227 38.602 35.6393 38.3248 35.4976C38.0476 35.3559 37.6318 35.2141 37.0081 35.2141H36.1071V37.4815Z"
      fill="white"
    />
    <path
      d="M43.1068 37.8357C43.1068 36.5604 43.5226 35.5684 44.285 34.789C45.0473 34.0805 46.1561 33.6554 47.4729 33.6554C48.7897 33.6554 49.8985 34.0097 50.6609 34.789C51.4232 35.4976 51.839 36.5604 51.839 37.8357C51.839 39.182 51.4232 40.1739 50.6609 40.9533C49.8985 41.7327 48.859 42.0869 47.4729 42.0869C46.0868 42.0869 45.0473 41.7327 44.285 40.9533C43.5226 40.1739 43.1068 39.182 43.1068 37.8357ZM45.1859 37.8357C45.1859 38.8277 45.3938 39.5362 45.7403 40.103C46.1562 40.599 46.7106 40.8824 47.4729 40.8824C48.2353 40.8824 48.7897 40.599 49.2055 40.103C49.6213 39.6071 49.7599 38.8277 49.7599 37.8357C49.7599 36.8438 49.552 36.1353 49.1362 35.6393C48.7204 35.1433 48.166 34.8599 47.4036 34.8599C46.6413 34.8599 46.0868 35.1433 45.671 35.6393C45.3245 36.1353 45.1859 36.9146 45.1859 37.8357Z"
      fill="white"
    />
    <path
      d="M53.9875 33.8679H56.1359L57.4526 40.1739L59.2545 33.8679H61.4722L63.0662 40.1739L64.5216 33.8679H66.3928L64.1751 41.9452H61.9574L60.2941 35.7101L58.4922 41.9452H56.3438L53.9875 33.8679Z"
      fill="white"
    />
    <path
      d="M68.8877 33.8679H74.9171V35.2141H70.8282V37.1272H74.6399V38.4734H70.8282V40.6699H75.0557V42.0161H68.8877V33.8679Z"
      fill="white"
    />
    <path
      d="M77.9665 33.8679H81.7782C82.8177 33.8679 83.6494 34.0096 84.1345 34.3639C84.6196 34.7182 84.8968 35.2141 84.8968 35.9227C84.8968 36.4895 84.7582 36.9146 84.481 37.2689C84.2038 37.5523 83.7187 37.8357 83.1643 37.9066C83.6494 37.9774 83.9959 38.1191 84.2731 38.3317C84.481 38.5443 84.6196 38.8985 84.7582 39.4654L84.8968 40.599C84.9661 40.9533 85.0355 41.1658 85.1048 41.4493C85.1741 41.6618 85.2434 41.8744 85.382 42.0161H83.2336C83.1643 41.8744 83.095 41.8035 83.095 41.6618C83.0256 41.5201 83.0256 41.3075 83.0256 41.095L82.887 39.8905C82.8177 39.3945 82.7484 39.1111 82.6098 38.9694C82.4712 38.8985 82.194 38.8277 81.7089 38.8277H80.0456V42.0869H77.9665V33.8679ZM79.907 37.3397H81.0851C81.7782 37.3397 82.3326 37.2689 82.5405 37.1272C82.8177 36.9855 82.9563 36.7021 82.9563 36.2769C82.9563 35.8518 82.8177 35.5684 82.6098 35.3558C82.3326 35.2141 81.8475 35.0724 81.0851 35.0724H79.907V37.3397Z"
      fill="white"
    />
    <path
      d="M87.8769 33.8679H93.9063V35.2141H89.8174V37.1272H93.6291V38.4734H89.8174V40.6699H94.045V42.0161H87.8769V33.8679Z"
      fill="white"
    />
    <path
      d="M96.9557 41.9452V33.8679H99.7971C100.559 33.8679 101.183 33.9388 101.599 34.0096C102.084 34.0805 102.5 34.293 102.846 34.5056C103.401 34.8599 103.817 35.285 104.094 35.8518C104.371 36.4187 104.51 37.1272 104.51 37.8357C104.51 39.1819 104.094 40.1739 103.262 40.8116C102.431 41.5201 101.252 41.8035 99.6585 41.8035H96.9557V41.9452ZM98.8962 40.599H99.8664C100.837 40.599 101.53 40.3864 101.946 39.9613C102.361 39.5362 102.569 38.8277 102.569 37.9066C102.569 36.9855 102.361 36.2769 101.946 35.8518C101.53 35.4267 100.837 35.2141 99.8664 35.2141H98.8962V40.599Z"
      fill="white"
    />
    <path
      d="M112.618 41.9452V33.8679H115.737C116.915 33.8679 117.816 34.0096 118.371 34.3639C118.925 34.7182 119.202 35.2141 119.202 35.9227C119.202 36.3478 119.064 36.7729 118.856 37.0563C118.578 37.3397 118.232 37.5523 117.747 37.694C118.371 37.8357 118.856 38.0483 119.133 38.4025C119.479 38.7568 119.618 39.1819 119.618 39.6779C119.618 40.0322 119.549 40.3156 119.479 40.5282C119.341 40.8116 119.202 41.0241 118.994 41.2367C118.717 41.5201 118.371 41.6618 117.885 41.8035C117.47 41.8744 116.777 41.9452 115.806 41.9452H112.618ZM114.49 37.1272H115.737C116.361 37.1272 116.777 37.0563 116.984 36.9146C117.192 36.7729 117.331 36.4895 117.331 36.1352C117.331 35.781 117.192 35.4976 116.984 35.285C116.777 35.1433 116.361 35.0724 115.737 35.0724H114.49V37.1272ZM114.49 40.6699H115.737C116.499 40.6699 117.054 40.599 117.331 40.4573C117.608 40.3156 117.677 39.9613 117.677 39.5362C117.677 39.1111 117.539 38.8277 117.262 38.6151C116.984 38.4734 116.499 38.3317 115.737 38.3317H114.49V40.6699Z"
      fill="white"
    />
    <path
      d="M124.885 41.9452V38.9694L121.836 33.8679H123.915L125.994 37.3397L127.865 33.8679H129.875L126.826 38.9694V41.9452H124.885Z"
      fill="white"
    />
    <path
      d="M138.954 41.9453V35.1433H136.251V33.7971H143.597V35.1433H140.894V41.9453H138.954Z"
      fill="white"
    />
    <path
      d="M150.874 41.9452H148.725L145.399 33.8679H147.547L149.973 40.1739L152.329 33.8679H154.339L150.874 41.9452Z"
      fill="white"
    />
    <path
      d="M164.735 37.6222V41.3774C164.249 41.59 163.695 41.7317 163.141 41.8734C162.586 42.0151 161.893 42.0151 161.2 42.0151C159.675 42.0151 158.497 41.6608 157.596 40.9523C156.765 40.2438 156.349 39.2518 156.349 37.9765C156.349 36.6302 156.765 35.5674 157.596 34.8589C158.428 34.0795 159.606 33.7253 161.131 33.7253C162.24 33.7253 163.141 33.9378 163.764 34.4338C164.388 34.8589 164.735 35.4966 164.735 36.2051V36.3468H162.933C162.933 35.8509 162.725 35.4966 162.448 35.2132C162.101 34.9298 161.685 34.8589 161.061 34.8589C160.23 34.8589 159.606 35.1423 159.121 35.7092C158.636 36.276 158.428 37.0554 158.428 37.9765C158.428 38.8976 158.705 39.6769 159.19 40.1729C159.675 40.6689 160.438 40.8815 161.408 40.8815C161.685 40.8815 161.893 40.8815 162.17 40.8106C162.448 40.8106 162.655 40.7398 162.933 40.6689V38.8976H161.2V37.6222H164.735Z"
      fill="white"
    />
    <path
      d="M41.8025 3.10693L42.1527 4.78195L41.9746 4.82168C23.319 9.02101 12.6903 24.6412 12.5866 24.7989L12.478 24.9603L11.0503 23.8701L11.154 23.7224C23.4373 6.2943 41.4499 3.16529 41.6305 3.13549L41.8025 3.10693Z"
      fill="white"
    />
    <path
      d="M38.1479 16.5622C38.8093 17.3035 39.7855 17.6561 41.0314 17.6288L41.2157 17.6239L41.2462 19.125L41.079 19.1424C41.0571 19.1449 40.9228 19.1586 40.7093 19.1586C39.9991 19.1586 38.4164 19.0108 37.2046 17.8151C36.0503 16.6789 35.5512 14.9058 35.722 12.5467L35.7366 12.3567L37.168 12.4945L37.1448 12.6845C37.1362 12.7654 36.882 15.1453 38.1479 16.5622Z"
      fill="white"
    />
    <path
      d="M40.3061 6.49875L39.2481 7.6324L48.1684 17.6514L46.0146 20.7444L47.197 21.7142L50.133 17.5223L40.3061 6.49875Z"
      fill="white"
    />
    <path
      d="M7.70828 15.0138H15.3558V16.6044H7.28606L7.70828 15.0138Z"
      fill="white"
    />
    <path
      d="M25.7337 6.61141H9.94698L9.52598 8.17715H25.7337V6.61141Z"
      fill="white"
    />
    <path
      d="M8.82213 10.8253H19.9804V12.391H8.40602L8.82213 10.8253Z"
      fill="white"
    />
  </>
);
