import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Block = styled.section`
  width: 100%;
  background-color: var(--fd-colors-background-surface);

  :empty {
    display: none;
  }
`;

export const ContainerHeader = styled(Block)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--fd-colors-border-default);
  padding: var(--fd-space-space-5);
  min-height: 87px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: 65% 33%;
  gap: var(--fd-space-space-5);
  background-color: var(--fd-colors-background-base);
  padding: var(--fd-space-space-5);
`;

export const SectionContainer = styled.section`
  border-radius: ${({ theme }) => theme.radii.s};
  overflow: hidden;
`;

export const SearchOptionsContainer = styled.div`
  display: flex;
  white-space: nowrap;
`;

export const CheckboxContainer = styled.div`
  margin: 16px;
  align-content: center;
`;
