export const initialState = {
  show: false,
  method: undefined,
  error: false,
  src: {
    sd: null,
    hd: null,
    replay: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "VIDEO_REQUEST_SUCCESS": {
      return {
        ...state,
        src: {
          sd:
            action.payload.src.sd !== null
              ? action.payload.src.sd
              : state.src.sd,
          hd:
            action.payload.src.hd !== null
              ? action.payload.src.hd
              : state.src.hd,
          replay: action.payload.src.replay
        },
        show: action.payload.show
      };
    }

    case "VIDEO_REQUEST_FETCHING": {
      return {
        ...state,
        show: action.payload.show
      };
    }

    case "VIDEO_REQUEST_ERROR": {
      return {
        ...state,
        error: true,
        show: action.payload.show,
        src: {
          sd: null,
          hd: null,
          replay: null
        }
      };
    }

    case "VIDEO_CLOSE": {
      return initialState;
    }

    case "VIDEO_OPEN": {
      return {
        ...state,
        show: true,
        method: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
