import styled, { css } from "styled-components";

export const Container = styled.div<{ hasTags: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  ${({ hasTags }) =>
    hasTags &&
    css`
      margin-top: ${({ theme }) => theme.space["space-5"]};
    `}

  button div[role="span"] {
    color: ${({ theme }) =>
      theme.colorTokens.component.button.buttonLink.content.base};
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  width: 100%;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colorTokens.background.infoSubtle};
  border-radius: ${({ theme }) => theme.radii.l};
  padding: 3px 6px;
  height: 16px;
  & p {
    white-space: nowrap;
  }
`;

export const IconContainer = styled.div`
  svg {
    width: 13px;
    height: 13px;
  }
  svg > path {
    stroke-width: 3.5;
  }
`;
