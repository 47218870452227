import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Tabs, TabList, Tab, TabPanel } from "@tvg/design-system/web";
import { TABS, TabConfig } from "@urp/lib-racetracks";
import { TabContentDesk } from "@urp/tracks-builder";
import {
  getTabsTracksPage,
  getCurrentTabTracksPage
} from "@urp/lib-racetracks/src/redux/selectors";
import { setCurrentTab } from "@urp/lib-racetracks/src/redux/actions/tabs";
import { sendTracksTabOpenedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import { TabsWrapper } from "./styled-components";

const NavigationTabs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabsConfig = useSelector(getTabsTracksPage);
  const currentTab = useSelector(getCurrentTabTracksPage);

  useEffect(() => {
    const pathItems = pathname.split("/");

    switch (pathItems[pathItems.length - 1]) {
      case TABS.RESULTS:
        dispatch(setCurrentTab(TABS.RESULTS));
        break;
      case TABS.ALL_TRACKS:
        dispatch(setCurrentTab(TABS.ALL_TRACKS));
        break;
      default:
        if (pathname.includes(TABS.ALL_TRACKS)) {
          dispatch(setCurrentTab(TABS.ALL_TRACKS));
        } else {
          dispatch(setCurrentTab(TABS.TODAY));
        }
    }
    return () => {
      dispatch(setCurrentTab(TABS.TODAY));
    };
  }, [pathname]);

  const handlerOnChange = (tab: string) => {
    const tabConfig = tabsConfig?.tabsDesktop?.find(({ id }) => id === tab);
    if (tabConfig) {
      navigate(tabConfig.url);
      sendTracksTabOpenedAnalyticEvt({
        tabName: tabConfig.id,
        url: tabConfig.url
      });
    }
  };

  return (
    <TabsWrapper>
      <Tabs
        qaLabel="tabs-tracks"
        selectedValue={currentTab}
        isStretched
        isFullHeight
        onChange={handlerOnChange}
        headerWidth="520px"
      >
        <TabList isStretched qaLabel="tabList">
          {tabsConfig.tabsDesktop
            .filter((tab) => tab.enabled)
            .map((tab: TabConfig) => (
              <Tab key={tab.id} value={tab.id} title={tab.title} />
            ))}
        </TabList>
        <TabPanel value={TABS.TODAY}>
          <TabContentDesk currentTab={TABS.TODAY} />
        </TabPanel>
        <TabPanel value={TABS.RESULTS}>
          <TabContentDesk currentTab={TABS.RESULTS} />
        </TabPanel>
        <TabPanel value={TABS.ALL_TRACKS}>
          <TabContentDesk currentTab={TABS.ALL_TRACKS} />
        </TabPanel>
      </Tabs>
    </TabsWrapper>
  );
};

export default NavigationTabs;
