import React, { memo } from "react";
import { Button, Paragraph } from "@tvg/design-system";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { useSelector } from "react-redux";
import { getIsLogged } from "@urp/store-selectors";
import { Container, Section } from "./styled-components";
import { Props } from "./types";
import { onLogin, onSignUp } from "../../utils";
import { BAR, BUTTON, CONTAINER, JOIN, TEXT } from "../../constants";
import { getJoinBarConfiguration } from "../../redux/selectors";
import { defaultJoinBarConfiguration } from "../../redux/defaultValues";

const JoinBar = ({ qaLabel = "", isVisible = true }: Props) => {
  // SELECTORS
  const isLogged = useSelector(getIsLogged);
  const {
    title = defaultJoinBarConfiguration.title || "",
    message = defaultJoinBarConfiguration.message || "",
    loginCTAText = defaultJoinBarConfiguration.loginCTAText || "",
    signupCTAText = defaultJoinBarConfiguration.signupCTAText || ""
  } = useSelector(getJoinBarConfiguration);

  const colorTokens = useColorTokens();

  const shouldRenderJoinbar = !isLogged && isVisible;

  if (!shouldRenderJoinbar) return null;

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, JOIN, BAR, CONTAINER])}>
      <Section>
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, JOIN, BAR, TEXT, "title"])}
          fontFamily="regular"
          color={colorTokens.content.default}
        >
          {title}
        </Paragraph>
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, JOIN, BAR, TEXT, "subtitle"])}
          fontFamily="regular"
          color={colorTokens.content.subtle}
          fontSize={12}
        >
          {message}
        </Paragraph>
      </Section>
      <Button
        qaLabel={buildQaLabel([qaLabel, JOIN, BAR, BUTTON, loginCTAText])}
        onPress={() => onLogin()}
        variant="primary"
        size="m"
        margin="auto"
      >
        {loginCTAText}
      </Button>
      <Button
        qaLabel={buildQaLabel([qaLabel, JOIN, BAR, BUTTON, signupCTAText])}
        onPress={() => onSignUp()}
        variant="betting"
        size="m"
        margin="auto"
        paddingX="space-4"
      >
        {signupCTAText}
      </Button>
    </Container>
  );
};

export default memo(JoinBar);
