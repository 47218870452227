export const FOOTER = "footer";
export const CONTAINER = "container";
export const TEXT = "text";
export const BALANCE = "balance";
export const BAR = "bar";
export const ITEM = "item";
export const ICON = "icon";
export const BADGE = "badge";
export const JOIN = "join";
export const BUTTON = "button";
