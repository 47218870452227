export enum BrazeInboxEvent {
  OPEN = "OPEN_CONTENT_CARDS_MODAL",
  CLOSE = "CLOSE_CONTENT_CARDS_MODAL"
}

export interface BrazeInboxState {
  isModalOpen: boolean;
}

export interface BrazeInboxAction {
  type: BrazeInboxEvent;
  payload: boolean;
}

export interface BrazeMessage {
  modalTitle: string;
  emptyTitle: string;
  emptyDescription: string;
}
