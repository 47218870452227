import {
  MapFeatureToggles,
  FeatureToggles,
  Messages
} from "@tvg/ts-types/capi";

type FeaturesRequestSuccessAction = {
  type: "FEATURES_GET_SUCCESS";
  payload: {
    featureToggles: MapFeatureToggles | {};
  };
};

type FeaturesChange = {
  type: "FEATURES_CHANGE";
  payload: {
    toggleName: string;
    newValue: boolean;
  };
};

type FeaturesResponseAction = {
  type: "FEATURES_RESPONSE";
  payload: {
    features: FeatureToggles | [];
  };
};

type MessagesRequestSuccessAction = {
  type: "MESSAGES_GET_SUCCESS";
  payload: {
    messages: Messages | false;
  };
};

type MessagesAddNamespacesAction = {
  type: "MESSAGES_ADD_NAMESPACES";
  payload: {
    namespaces: Record<string, boolean>;
  };
};

type MessagesUpdateAction = {
  type: "MESSAGES_UPDATE";
  payload: {
    messages: Messages | false;
  };
};

type SeoMessagesRequestSuccessAction = {
  type: "SEO_MESSAGES_GET_SUCCESS";
  payload: {
    name: string;
    content: string;
  };
};

type MessagesRequestFailAction = {
  type: "MESSAGES_GET_FAIL";
  payload: Error;
  error: true;
};

type SeoMessagesRequestFailAction = {
  type: "SEO_MESSAGES_GET_FAIL";
  payload: Error;
  error: true;
};

type FeaturesRequestFailAction = {
  type: "FEATURES_GET_FAIL";
  payload: Error;
  error: true;
};

type SeoMessagesRequestAction = {
  type: "SEO_MESSAGES_SUCCESS";
  payload: {
    seoMessages: Messages | false;
  };
};

export type CapiActions =
  | MessagesRequestFailAction
  | MessagesRequestSuccessAction
  | MessagesUpdateAction
  | MessagesAddNamespacesAction
  | FeaturesRequestSuccessAction
  | FeaturesResponseAction
  | FeaturesChange
  | SeoMessagesRequestSuccessAction
  | SeoMessagesRequestFailAction
  | FeaturesRequestFailAction
  | SeoMessagesRequestAction;

export const successFeaturesRequest = (
  featureToggles: MapFeatureToggles | {}
): FeaturesRequestSuccessAction => ({
  type: "FEATURES_GET_SUCCESS",
  payload: {
    featureToggles
  }
});

export const featuresResponse = (
  features: FeatureToggles
): FeaturesResponseAction => ({
  type: "FEATURES_RESPONSE",
  payload: {
    features
  }
});

export const successMessagesRequest = (
  messagesData: Messages | false
): MessagesRequestSuccessAction => ({
  type: "MESSAGES_GET_SUCCESS",
  payload: {
    messages: messagesData
  }
});

export const addMessagesNamespaces = (
  namespaces: Record<string, boolean>
): MessagesAddNamespacesAction => ({
  type: "MESSAGES_ADD_NAMESPACES",
  payload: {
    namespaces
  }
});

export const messageUpdate = (
  messagesData: Messages | false
): MessagesUpdateAction => ({
  type: "MESSAGES_UPDATE",
  payload: {
    messages: messagesData
  }
});

export const successSeoMessagesRequest = (
  name: string,
  content: string
): SeoMessagesRequestSuccessAction => ({
  type: "SEO_MESSAGES_GET_SUCCESS",
  payload: {
    name,
    content
  }
});

export const failMessagesRequest = (err: Error): MessagesRequestFailAction => ({
  type: "MESSAGES_GET_FAIL",
  payload: err,
  error: true
});

export const failFeaturesRequest = (err: Error): FeaturesRequestFailAction => ({
  type: "FEATURES_GET_FAIL",
  payload: err,
  error: true
});

export const failSeoMessagesRequest = (
  err: Error
): SeoMessagesRequestFailAction => ({
  type: "SEO_MESSAGES_GET_FAIL",
  payload: err,
  error: true
});

export const successSeoMessages = (
  messagesData: Messages | false
): SeoMessagesRequestAction => ({
  type: "SEO_MESSAGES_SUCCESS",
  payload: {
    seoMessages: messagesData
  }
});
