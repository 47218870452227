import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const Container = styled.div`
  position: relative;
`;

export const MainContainer = styled.div`
  cursor: auto;
  z-index: 20;
  position: absolute;
  top: 50px;
  background: ${buildColor("white", "900")};
  border-radius: 4px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  width: 375px;
  transform: translate(calc(-50% + 22px), 0);
  overflow: scroll;
  max-height: 85vh;
  left: -calc(375px / 2);
`;

export const ContentContainer = styled.div`
  position: absolute;
  padding-top: 8px;
  top: 0;
`;
