import React, { useEffect, useState } from "react";
import mediator from "@tvg/mediator";
import { Loading } from "@tvg/design-system";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { useSelector } from "react-redux";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import { getAccountNumber } from "@urp/store-selectors";
import { LoadingRouteContainer } from "./styled-components";

interface Props {
  protectedRoute: boolean;
  isLogged: boolean;
  loginRequest: boolean;
  intendedPath: string;
  children: JSX.Element;
}

// TODO Refactor this with the new protocol, this is an hack to close the socket and open it again without losing the subscriptions
// @ts-ignore
const clearWebSocketOnLogin = (wsClient) => {
  try {
    // @ts-ignore
    if (wsClient?.client?.onclose) {
      // @ts-ignore
      wsClient.clearInactivityTimeout();
      // @ts-ignore
      wsClient.client.onopen = null;
      // @ts-ignore
      wsClient.client.onclose = null;
      // @ts-ignore
      wsClient.client.onerror = null;
      // @ts-ignore
      wsClient.client.onmessage = null;
      // @ts-ignore
      wsClient.client.close();
      // @ts-ignore
      wsClient.client = null;
      // @ts-ignore
      wsClient.eventEmitter.emit("disconnected");
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Fail to clear subscription", e);
  }
};

const RequireAuth = ({
  protectedRoute,
  isLogged,
  loginRequest,
  intendedPath,
  children
}: Props) => {
  const [finishedLogIn, setFinishedLogIn] = useState(false);
  const navigate = useNavigate();
  // navigate back while the user is logged in;
  const navigateBack =
    protectedRoute && loginRequest && !isLogged && !finishedLogIn;
  const client = useApolloClient();
  const accountId = useSelector(getAccountNumber);
  const isAccountCompliant = useSelector(isAccountCompliantSelector);

  useEffect(() => {
    if (protectedRoute && loginRequest && !isLogged) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
        payload: {
          callback: () => {
            setFinishedLogIn(true);
            navigate(intendedPath);
          }
        }
      });
    }

    if (isLogged && protectedRoute && !isAccountCompliant) {
      onOpenComplianceModal();
    }
  }, [protectedRoute, loginRequest, isLogged, isAccountCompliant]);

  useEffect(() => {
    if (navigateBack) {
      navigate(-1);
    }
  }, [navigateBack]);

  useEffect(() => {
    if (!isLogged) {
      setFinishedLogIn(false);
    }
  }, [isLogged]);

  useEffect(
    () => () => {
      // @ts-ignore
      if (client?.subscriptionCosmoClient?.terminate) {
        // @ts-ignore
        client?.subscriptionCosmoClient?.terminate();
      } else {
        // @ts-ignore
        clearWebSocketOnLogin(client?.subscriptionClient);
      }
    },
    [accountId]
  );

  return !protectedRoute || isLogged ? (
    children
  ) : (
    <LoadingRouteContainer>
      <Loading size="xl" />
    </LoadingRouteContainer>
  );
};

export default RequireAuth;
