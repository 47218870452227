import React, { PropsWithChildren } from "react";
import { breakpoints, SectionHeader, useMediaQuery } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Props } from "./types";
import { CONTAINER, SECTION } from "../../utils/constants";
import {
  Container,
  HeaderContainer,
  ToggleContainer
} from "./styled-components";
import SpoilerToggle from "../SpoilerToggle";

const TracksSection = ({
  title,
  subTitle,
  children,
  qaLabel = "",
  isResults = false
}: PropsWithChildren<Props>) => {
  const isDesktop = useMediaQuery(breakpoints.desktop.min.md);

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, SECTION, CONTAINER])}>
      <HeaderContainer>
        <SectionHeader
          title={title}
          subTitle={subTitle}
          qaLabel={buildQaLabel([qaLabel, SECTION])}
        />
        {isDesktop && isResults && (
          <ToggleContainer>
            <SpoilerToggle qaLabel={qaLabel} />
          </ToggleContainer>
        )}
      </HeaderContainer>
      {children}
    </Container>
  );
};

export default TracksSection;
