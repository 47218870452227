import { Badge } from "@tvg/design-system/web";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)`
  appearance: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  gap: 3px;
  flex: 1;
`;

export const BadgeWrapper = styled(Badge)`
  margin: 0;
  margin-bottom: -4px; /* workaround for unknown height given to badge children */
`;
