import React from "react";
import { FilterButtonContainer } from "./styled-components";
import { FilterButtonProps } from "../../types";

const FilterButton = ({
  qaLabel,
  value,
  children,
  isActive = false,
  isDisabled = false,
  onClick
}: FilterButtonProps) => (
  <FilterButtonContainer
    aria-selected={isActive}
    key={value}
    data-qa-label={qaLabel}
    disabled={isDisabled}
    isActive={isActive}
    onClick={onClick}
  >
    {children}
  </FilterButtonContainer>
);

export default FilterButton;
