import React from "react";
import { Path } from "react-native-svg";

export const Malaysia: React.FC = () => (
  <>
    <Path d="M0 0H32V20H0V0Z" fill="#CC0001" />
    <Path d="M0 18.5714H32V20H0V18.5714Z" fill="white" />
    <Path d="M0 15.7143H32V17.1429H0V15.7143Z" fill="white" />
    <Path d="M0 12.8571H32V14.2857H0V12.8571Z" fill="white" />
    <Path d="M0 10H32V11.4286H0V10Z" fill="white" />
    <Path d="M0 7.14286H32V8.57143H0V7.14286Z" fill="white" />
    <Path d="M0 4.28571H32V5.71429H0V4.28571Z" fill="white" />
    <Path d="M0 1.42857H32V2.85714H0V1.42857Z" fill="white" />
    <Path d="M0 0H15V11.4286H0V0Z" fill="#010066" />
    <Path
      d="M5.72857 1.42857C3.355 1.42857 1.42857 3.34857 1.42857 5.71429C1.42857
      8.08 3.355 10 5.72857 10C6.58614 10 7.385 9.74914 8.05614 9.31743C7.66529
      9.45229 7.24614 9.526 6.80943 9.526C4.70471 9.526 2.99657 7.82357 2.99657
      5.726C2.99657 3.62814 4.70471 1.92571 6.80943 1.92571C7.27443 1.92571 7.72
      2.00857 8.13229 2.161C7.446 1.69857 6.61857 1.42857 5.72857 1.42857Z"
      fill="#FFCC00"
    />
    <Path
      d="M12.8596 7.94529L11.0481 7.00029L11.5839 8.93457L10.3724
      7.31657L9.99429 9.28571L9.62257 7.31543L8.406 8.92971L8.94771
      6.99714L7.13343 7.93671L8.48114 6.42486L6.42857 6.50343L8.31543
      5.71171L6.43114 4.91429L8.48343 4.99914L7.14057 3.48343L8.95171
      4.42829L8.416 2.494L9.62771 4.112L10.0057 2.14286L10.3774 4.11314L11.594
      2.49886L11.0523 4.43143L12.8666 3.49186L11.5189 5.00371L13.5714
      4.92514L11.6846 5.71686L13.5689 6.51429L11.5166 6.42943L12.8596 7.94529Z"
      fill="#FFCC00"
    />
  </>
);

export default Malaysia;
