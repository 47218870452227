import React, { memo } from "react";
import SectionHeader from "../SectionHeader";
import TrackGroupHeader from "../TrackGroupHeader";
import TrackRowTitle from "../TrackRowTitle";
import TrackRowContent from "../TrackRowContent";
import { Container } from "./styled-components";
import { Props } from "./types";

const TracksAZLoading = ({ numGroups = 3, numRowsPerGroup = 5 }: Props) => (
  <Container data-qa-label="tracksAZ-loadingMask">
    <SectionHeader />
    <TrackGroupHeader />
    <TrackRowTitle />
    <TrackRowContent />
    {[...Array(numGroups)].map((numGroup, index) => (
      <div key={`${numGroup}-${index.toString()}`}>
        <TrackGroupHeader />
        {[...Array(numRowsPerGroup)].map((val, idx) => (
          <TrackRowTitle key={`${val}-${idx.toString()}`} />
        ))}
      </div>
    ))}
  </Container>
);

export default memo(TracksAZLoading);
