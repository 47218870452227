import React from "react";

export const Iowa = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66989 0L0 23.8353L40.7951 23.8374L47.4661 0.00316375L6.66989 0Z"
      fill="#38AB4F"
    />
    <path
      d="M33.6343 2.63876L33.9474 4.06137L33.7881 4.09512C17.1074 7.66166 7.60393 20.9281 7.51119 21.062L7.41408 21.1991L6.1375 20.2732L6.23024 20.1477C17.2132 5.34583 33.3189 2.68833 33.4804 2.66302L33.6343 2.63876Z"
      fill="white"
    />
    <path
      d="M30.3665 14.0665C30.9579 14.6961 31.8308 14.9956 32.9448 14.9724L33.1096 14.9682L33.1368 16.2432L32.9874 16.2579C32.9677 16.26 32.8477 16.2716 32.6568 16.2716C32.0217 16.2716 30.6066 16.1461 29.5231 15.1306C28.4909 14.1657 28.0447 12.6597 28.1974 10.6561L28.2105 10.4947L29.4904 10.6118L29.4697 10.7731C29.4619 10.8419 29.2347 12.8632 30.3665 14.0665Z"
      fill="white"
    />
    <path
      d="M32.2963 5.51945L31.3503 6.48227L39.3262 14.9916L37.4004 17.6185L38.4577 18.4421L41.0829 14.8819L32.2963 5.51945Z"
      fill="white"
    />
    <path
      d="M3.1493 12.7514H9.98721V14.1023H2.77178L3.1493 12.7514Z"
      fill="white"
    />
    <path
      d="M19.2665 5.61517H5.151L4.77457 6.94497H19.2665V5.61517Z"
      fill="white"
    />
    <path
      d="M4.14523 9.19406H14.1222V10.5239H3.77317L4.14523 9.19406Z"
      fill="white"
    />
    <path
      d="M0.268229 31.4295H5.66978C6.73756 31.4295 7.59832 31.7371 8.25206 32.3523C8.9058 32.962 9.23267 33.8211 9.23267 34.9296C9.23267 35.8829 8.94121 36.7143 8.35829 37.4238C7.77537 38.1277 6.8792 38.4796 5.66978 38.4796H1.89441V43.6425H0.268229V31.4295ZM7.59014 34.9379C7.59014 34.04 7.26327 33.4304 6.60953 33.1089C6.24997 32.9371 5.75694 32.8512 5.13044 32.8512H1.89441V37.0829H5.13044C5.86045 37.0829 6.45154 36.9249 6.90372 36.609C7.36134 36.2931 7.59014 35.7361 7.59014 34.9379Z"
      fill="white"
    />
    <path
      d="M16.1875 37.0247C16.9502 37.0247 17.5522 36.8695 17.9935 36.5591C18.4402 36.2487 18.6636 35.6889 18.6636 34.8797C18.6636 34.0095 18.353 33.4165 17.732 33.1006C17.3997 32.9343 16.9557 32.8512 16.4 32.8512H12.4285V37.0247H16.1875ZM10.8023 31.4295H16.3591C17.2744 31.4295 18.0289 31.5653 18.6227 31.8369C19.7504 32.3579 20.3143 33.3195 20.3143 34.7218C20.3143 35.4534 20.1644 36.052 19.8648 36.5176C19.5706 36.9831 19.1566 37.3572 18.6227 37.6399C19.0912 37.8339 19.4426 38.0889 19.6769 38.4048C19.9166 38.7207 20.05 39.2334 20.0773 39.9429L20.1345 41.5807C20.1508 42.0463 20.189 42.3927 20.2489 42.6199C20.3469 43.0079 20.5213 43.2573 20.7719 43.3682V43.6425H18.778C18.7235 43.5372 18.6799 43.4014 18.6472 43.2351C18.6145 43.0689 18.5873 42.7474 18.5655 42.2707L18.4674 40.2338C18.4293 39.4357 18.1378 38.9008 17.5931 38.6293C17.2825 38.4796 16.7949 38.4048 16.1303 38.4048H12.4285V43.6425H10.8023V31.4295Z"
      fill="white"
    />
    <path
      d="M28.1925 38.6376L26.3702 33.2419L24.4335 38.6376H28.1925ZM25.5203 31.4295H27.359L31.7146 43.6425H29.9331L28.7155 39.9844H23.9677L22.6684 43.6425H21.0013L25.5203 31.4295Z"
      fill="white"
    />
    <path d="M32.8919 31.4295H34.5345V43.6425H32.8919V31.4295Z" fill="white" />
    <path
      d="M42.094 37.0247C42.8567 37.0247 43.4587 36.8695 43.9 36.5591C44.3467 36.2487 44.5701 35.6889 44.5701 34.8797C44.5701 34.0095 44.2595 33.4165 43.6385 33.1006C43.3062 32.9343 42.8622 32.8512 42.3065 32.8512H38.335V37.0247H42.094ZM36.7088 31.4295H42.2656C43.1809 31.4295 43.9354 31.5653 44.5292 31.8369C45.6569 32.3579 46.2208 33.3195 46.2208 34.7218C46.2208 35.4534 46.071 36.052 45.7713 36.5176C45.4771 36.9831 45.0631 37.3572 44.5292 37.6399C44.9977 37.8339 45.3491 38.0889 45.5834 38.4048C45.8231 38.7207 45.9565 39.2334 45.9838 39.9429L46.041 41.5807C46.0573 42.0463 46.0955 42.3927 46.1554 42.6199C46.2535 43.0079 46.4278 43.2573 46.6784 43.3682V43.6425H44.6845C44.63 43.5372 44.5864 43.4014 44.5537 43.2351C44.521 43.0689 44.4938 42.7474 44.472 42.2707L44.3739 40.2338C44.3358 39.4357 44.0443 38.9008 43.4996 38.6293C43.189 38.4796 42.7015 38.4048 42.0368 38.4048H38.335V43.6425H36.7088V31.4295Z"
      fill="white"
    />
    <path d="M48.3052 31.4295H49.9478V43.6425H48.3052V31.4295Z" fill="white" />
    <path
      d="M52.0812 31.4295H60.8332V32.926H53.6666V36.6339H60.2939V38.0473H53.6666V42.1876H60.9558V43.6425H52.0812V31.4295Z"
      fill="white"
    />
    <path
      d="M68.6817 31.4295H71.0107L74.4592 41.7553L77.8831 31.4295H80.1876V43.6425H78.6431V36.4344C78.6431 36.185 78.6486 35.7721 78.6595 35.1957C78.6704 34.6192 78.6758 34.0012 78.6758 33.3417L75.2518 43.6425H73.642L70.1935 33.3417V33.7158C70.1935 34.0151 70.199 34.4724 70.2099 35.0876C70.2262 35.6973 70.2344 36.1462 70.2344 36.4344V43.6425H68.6817V31.4295Z"
      fill="white"
    />
    <path
      d="M82.1495 31.4295H90.9015V32.926H83.7348V36.6339H90.3621V38.0473H83.7348V42.1876H91.024V43.6425H82.1495V31.4295Z"
      fill="white"
    />
    <path
      d="M98.6489 38.6376L96.8266 33.2419L94.8899 38.6376H98.6489ZM95.9768 31.4295H97.8154L102.171 43.6425H100.39L99.1719 39.9844H94.4241L93.1248 43.6425H91.4578L95.9768 31.4295Z"
      fill="white"
    />
    <path
      d="M107.59 42.2292C108.14 42.2292 108.592 42.171 108.946 42.0546C109.578 41.8384 110.096 41.4227 110.499 40.8075C110.82 40.3142 111.052 39.6823 111.193 38.9119C111.275 38.4519 111.316 38.0251 111.316 37.6316C111.316 36.1185 111.019 34.9435 110.425 34.1065C109.837 33.2696 108.886 32.8512 107.573 32.8512H104.689V42.2292H107.59ZM103.054 31.4295H107.916C109.567 31.4295 110.847 32.0253 111.757 33.217C112.569 34.2922 112.975 35.6695 112.975 37.3489C112.975 38.6459 112.735 39.8181 112.256 40.8657C111.411 42.7169 109.959 43.6425 107.9 43.6425H103.054V31.4295Z"
      fill="white"
    />
    <path
      d="M119.586 31.0969C121.706 31.0969 123.275 31.7898 124.293 33.1754C125.089 34.2562 125.486 35.6391 125.486 37.324C125.486 39.1475 125.031 40.6634 124.122 41.8717C123.054 43.2906 121.531 44 119.554 44C117.707 44 116.255 43.3792 115.198 42.1377C114.256 40.9405 113.784 39.4274 113.784 37.5984C113.784 35.9467 114.188 34.5333 114.994 33.3583C116.029 31.8507 117.56 31.0969 119.586 31.0969ZM119.75 42.5201C121.183 42.5201 122.218 41.9991 122.855 40.9571C123.498 39.9096 123.819 38.7069 123.819 37.3489C123.819 35.9134 123.449 34.7578 122.708 33.8821C121.973 33.0063 120.965 32.5685 119.684 32.5685C118.442 32.5685 117.429 33.0036 116.645 33.8738C115.86 34.7384 115.468 36.0159 115.468 37.7064C115.468 39.0588 115.803 40.2006 116.473 41.1317C117.148 42.0573 118.241 42.5201 119.75 42.5201Z"
      fill="white"
    />
    <path
      d="M127.555 31.4295L129.818 41.3645L132.531 31.4295H134.296L137.009 41.3645L139.273 31.4295H141.054L137.9 43.6425H136.192L133.422 33.5163L130.635 43.6425H128.927L125.789 31.4295H127.555Z"
      fill="white"
    />
    <path
      d="M142.959 39.7018C142.997 40.3946 143.158 40.9571 143.441 41.3895C143.98 42.1987 144.931 42.6033 146.293 42.6033C146.903 42.6033 147.459 42.5146 147.96 42.3372C148.93 41.9936 149.415 41.3784 149.415 40.4916C149.415 39.8265 149.21 39.3526 148.802 39.0699C148.388 38.7928 147.739 38.5517 146.857 38.3466L145.231 37.9725C144.168 37.7286 143.417 37.4598 142.975 37.166C142.213 36.6561 141.831 35.894 141.831 34.8797C141.831 33.7823 142.204 32.8816 142.951 32.1777C143.697 31.4738 144.754 31.1219 146.121 31.1219C147.38 31.1219 148.448 31.4323 149.325 32.053C150.207 32.6683 150.649 33.6548 150.649 35.0128H149.12C149.039 34.3587 148.864 33.8571 148.598 33.5079C148.102 32.8706 147.26 32.5519 146.072 32.5519C145.114 32.5519 144.424 32.7569 144.005 33.1671C143.585 33.5772 143.376 34.0539 143.376 34.5971C143.376 35.1957 143.621 35.6335 144.111 35.9106C144.433 36.088 145.16 36.3097 146.293 36.5757L147.976 36.9665C148.788 37.1549 149.415 37.4127 149.856 37.7397C150.619 38.3106 151 39.1392 151 40.2255C151 41.5779 150.515 42.5451 149.545 43.127C148.581 43.709 147.459 44 146.179 44C144.686 44 143.517 43.612 142.673 42.8361C141.829 42.0656 141.415 41.0209 141.431 39.7018H142.959Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.995 5.63646C134.444 5.63646 135.667 5.76063 136.619 6.16498C137.538 6.55765 138.171 7.27508 138.178 8.43081H150.675C151.34 2.69353 146.202 0 133.87 0C131.329 0 128.609 0.205888 125.978 0.752448L107.843 0.75457L97.8543 15.0776H97.7757L95.4266 0.75457L54.8448 0.756693L53.3424 6.39315H64.1747L59.7242 23.0839H70.8041L75.2558 6.39315H84.9173L89.8643 23.0839H101.495L114.426 7.50113C113.527 8.7874 112.815 10.2774 112.354 12.0052C109.606 22.3039 119.785 23.8374 127.551 23.8374C133.91 23.8374 137.737 23.0563 141.149 21.0197L141.854 23.0839H146.61L150.007 10.3496H134.246L132.898 15.4077H137.456C136.223 17.0845 134.116 18.202 130.506 18.202C125.016 18.202 122.851 16.0296 123.949 11.9107C125.152 7.40455 128.484 5.63646 132.995 5.63646Z"
      fill="white"
    />
  </>
);
