import React, { FC } from "react";

import {
  ColorTokens,
  IconNames,
  IconWithBackground,
  Paragraph,
  breakpoints,
  useColorTokens,
  useMediaQuery
} from "@tvg/design-system";
import {
  HintContainer,
  IconContainer,
  NoUpcomingRacesContainer
} from "./styled-components";
import { NoUpcomingRacesProps } from "./types";

const NoUpcomingRaces: FC<NoUpcomingRacesProps> = ({ title, hint }) => {
  const colorTokens = useColorTokens() as ColorTokens;
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);

  return (
    <NoUpcomingRacesContainer>
      <Paragraph
        qaLabel="no-upcomingRaces-title"
        fontFamily="bold"
        fontSize={isDesktop ? 18 : 14}
      >
        {title}
      </Paragraph>
      {isDesktop ? (
        <HintContainer>
          <IconContainer>
            <IconWithBackground
              mainCircleBackground={`${colorTokens.background.infoSubtle}`}
              mainIcon={{
                name: "filters",
                lineColor: `${colorTokens.content.info}`,
                backgroundColor: `${colorTokens.background.infoSubtle}`,
                size: "s"
              }}
              badgeIcon={{
                name: "info" as IconNames,
                lineColor: `${colorTokens.content.info}`,
                backgroundColor: `${colorTokens.background.infoSubtle}`
              }}
            />
          </IconContainer>
          <Paragraph qaLabel="no-upcomingRaces-hint" fontFamily="bold">
            {hint}
          </Paragraph>
        </HintContainer>
      ) : (
        <Paragraph qaLabel="no-upcomingRaces-hint">{hint}</Paragraph>
      )}
    </NoUpcomingRacesContainer>
  );
};

export default NoUpcomingRaces;
