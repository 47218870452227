import { gql } from "@apollo/client";

const RaceDetailsFragments = {
  entry: gql`
    fragment RaceDetails on Race {
      number
      description
      purse
      type {
        name
        code
        shortName
      }
    }
  `
};

export default RaceDetailsFragments;
