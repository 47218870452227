import { useRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  getRaceTracksIsSearchActive,
  getTracksSearchMsg,
  getRaceTracksSearchQuery
} from "../redux/selectors";
import {
  setRaceTracksSearch,
  setRaceTracksIsSearchActive,
  clearRaceTracksSearch
} from "../redux/actions/raceTracksSearch";
import { sendTracksSearchAnalyticEvt } from "../utils/analytics";

export const useRaceTracksSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const [interactionTracked, setInteractionTracked] = useState(false);
  const isSearchActive = useSelector(getRaceTracksIsSearchActive);
  const tracksSearchMsg = useSelector(getTracksSearchMsg);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchQueryGlobal = useSelector(getRaceTracksSearchQuery);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile) {
      dispatch(clearRaceTracksSearch());
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setSearchQuery(searchQueryGlobal);
    }
  }, [searchQueryGlobal]);

  const setSearchQueryDebounce = useCallback(
    debounce((newSearchQuery: string) => {
      dispatch(setRaceTracksSearch(newSearchQuery));
    }, 300),
    []
  );

  const onSearchQueryChange = (
    newSearchQuery: string,
    analyticsEnrichment?: Record<string, unknown>
  ) => {
    setSearchQuery(newSearchQuery);
    setSearchQueryDebounce(newSearchQuery);
    if (newSearchQuery.length === 1 && !interactionTracked) {
      sendTracksSearchAnalyticEvt({
        ...analyticsEnrichment
      });
      setInteractionTracked(true);
    }
  };

  const onCancelSearch = () => {
    setSearchQuery("");
    dispatch(clearRaceTracksSearch());
    setInteractionTracked(false);
  };

  const onClearSearch = () => {
    setSearchQuery("");
    dispatch(setRaceTracksSearch(""));
    searchInputRef.current?.focus();
  };

  const onFocusSearch = () => {
    dispatch(setRaceTracksIsSearchActive(true));
  };

  return {
    searchInputRef,
    isSearchActive,
    tracksSearchMsg,
    searchQuery,
    onSearchQueryChange,
    onCancelSearch,
    onClearSearch,
    onFocusSearch
  };
};

export default useRaceTracksSearch;
