import { AnyAction, Dispatch, Middleware } from "redux";
import isEqual from "lodash/isEqual";
import { BroadcastChannel } from "broadcast-channel";
import { ApplicationState } from "./types";

const allowedActions = [
  "USER_WALLET_BALANCES_SUCCESS",
  "USER_BALANCE_SUCCESS",
  "USER_PREFS_BALANCE_SHOWN",
  "REMOVE_FAVORITE_TRACK",
  "SET_FAVORITE_TRACK",
  "USER_PROMOS_SUCCESS",
  "USER_PROMOS_FAIL",
  "USER_PROMOS_CLEAR",
  "USER_DATA_UPDATE",
  "USER_LOGOUT",
  "CLOSE_LOGIN_MODAL",
  "SET_IS_VERIFIED",
  "SET_USER_JURISDICTION",
  "USER_PREFS_BET_CONFIRMATION",
  "SET_DEFAULT_BET_PREFERENCES",
  "USER_PREFS_SET",
  "GEOLOCATION_STATUS",
  "GEOLOCATION_ERROR",
  "GEOLOCATION_STATUS_CLEAR",
  "GEOLOCATION_MESSAGE_SHOWN",
  "TRY_GEOLOCATION_RETRY",
  "GEOLOCATION_RETRY_CLEAR",
  "SET_GEOLOCATION_RETRY_STATUS"
];

const shouldForward = (action: AnyAction): boolean =>
  action.type &&
  allowedActions.includes(action.type) &&
  (!action.meta || !action.meta.local);

const broadcastActions = (
  channelName: string
): Middleware<{}, ApplicationState, Dispatch<AnyAction>> => {
  const channel = new BroadcastChannel<AnyAction>(channelName);

  return ({ dispatch, getState }) => {
    channel.onmessage = (data) =>
      dispatch({
        ...data,
        meta: {
          ...data.meta,
          local: true
        }
      });
    return (next) => (action) => {
      if (shouldForward(action)) {
        if (action.type === "USER_PREFS_SET") {
          const currentPreferences = getState().userData?.preferences;
          const newPreferences = action.payload.preferences;

          // Broadcast only if preferences changed
          if (!isEqual(currentPreferences, newPreferences)) {
            channel.postMessage(action);
          }
        } else {
          channel.postMessage(action);
        }
      }

      return next(action);
    };
  };
};

export default broadcastActions;
