import React from "react";
import { Path } from "react-native-svg";

export const UnitedStates: React.FC = () => (
  <>
    <Path d="M32 0H0V20H32V0Z" fill="#F8F8F9" />
    <Path
      d="M0 18.4632H32V20H0V18.4632ZM0 0H32V1.53679H0V0ZM0 
      3.07359H32V4.61039H0V3.07359ZM0 6.14719H32V7.68398H0V6.14719ZM0 
      9.24242H32V10.7792H0V9.24242ZM0 12.316H32V13.8528H0V12.316ZM0 
      15.3896H32V16.9264H0V15.3896Z"
      fill="#DE3131"
    />
    <Path d="M16.2078 0H0V10.7792H16.2078V0Z" fill="#2F67BD" />
    <Path
      d="M14.9841 3.03032L14.8456 2.59742L14.7071 3.03032H14.2222L14.6147 
      3.29006L14.4762 3.72296L14.8687 3.46322L15.2612 3.72296L15.1227 
      3.29006L15.5151 3.03032H14.9841ZM1.63925 1.1472L2.03173 
      0.88746H1.54689L1.40837 0.454559L1.26984 0.88746H0.784973L1.17749 
      1.1472L1.03896 1.5801L1.43145 1.32036L1.82393 1.5801L1.63925 
      1.1472ZM4.31745 1.1472L4.70994 0.88746H4.22509L4.08657 0.454559L3.94805 
      0.88746H3.46321L3.85569 1.1472L3.71717 1.5801L4.10965 1.32036L4.50217 
      1.5801L4.31745 1.1472ZM7.01874 1.1472L7.41125 0.88746H6.92641L6.78786 
      0.454559L6.64934 0.88746H6.16449L6.55698 1.1472L6.41846 1.5801L6.81097 
      1.32036L7.20346 1.5801L7.01874 1.1472ZM9.69697 1.1472L10.0895 
      0.88746H9.60462L9.46609 0.454559L9.32754 0.88746H8.8427L9.23521 
      1.1472L9.09666 1.5801L9.48918 1.32036L9.88166 1.5801L9.69697 
      1.1472ZM3.04761 8.65802L3.4401 8.39829H2.95525L2.81673 7.96538L2.67821 
      8.39829H2.19337L2.58585 8.65802L2.44733 9.09093L2.83981 8.83119L3.23233 
      9.09093L3.04761 8.65802ZM3.00145 6.55846L3.39393 6.29872H2.90909L2.77057 
      5.86582L2.63202 6.29872H2.14717L2.53969 6.55846L2.40114 6.99136L2.79365 
      6.73162L3.18613 6.99136L3.00145 6.55846ZM3.00145 4.45889L3.39393 
      4.19915H2.90909L2.77057 3.76625L2.63202 4.19915H2.14717L2.53969 
      4.45889L2.40114 4.89179L2.79365 4.63205L3.18613 4.89179L3.00145 
      4.45889ZM12.3752 1.1472L12.7677 0.88746H12.2828L12.1443 0.454559L12.0058 
      0.88746H11.5209L11.9134 1.1472L11.7749 1.5801L12.1674 1.32036L12.5599 
      1.5801L12.3752 1.1472ZM15.0765 1.1472L15.469 0.88746H14.9841L14.8456 
      0.454559L14.7071 0.88746H14.2222L14.6147 1.1472L14.4762 1.5801L14.8687 
      1.32036L15.2612 1.5801L15.0765 1.1472ZM5.70273 4.45889L6.09522 
      4.19915H5.61038L5.47185 3.76625L5.33333 4.19915H4.84849L5.24097 
      4.45889L5.10245 4.89179L5.49494 4.63205L5.88745 4.89179L5.70273 
      4.45889ZM5.67965 6.55846L6.07214 6.29872H5.58729L5.44877 5.86582L5.31025 
      6.29872H4.82538L5.21789 6.55846L5.07937 6.99136L5.47185 6.73162L5.86434 
      6.99136L5.67965 6.55846ZM5.72582 8.65802L6.11833 8.39829H5.63346L5.49494 
      7.96538L5.35641 8.39829H4.87157L5.26406 8.65802L5.12553 9.09093L5.51802 
      8.83119L5.91053 9.09093L5.72582 8.65802ZM8.42713 8.65802L8.81962 
      8.39829H8.33477L8.19625 7.96538L8.0577 8.39829H7.57286L7.96537 
      8.65802L7.82682 9.09093L8.21934 8.83119L8.61182 9.09093L8.42713 
      8.65802ZM8.38094 6.55846L8.77345 6.29872H8.28858L8.15006 5.86582L8.01154 
      6.29872H7.52669L7.91918 6.55846L7.78066 6.99136L8.17314 6.73162L8.56566 
      6.99136L8.38094 6.55846ZM8.38094 4.45889L8.77345 4.19915H8.28858L8.15006 
      3.76625L8.01154 4.19915H7.52669L7.91918 4.45889L7.78066 4.89179L8.17314 
      4.63205L8.56566 4.89179L8.38094 4.45889ZM2.97834 2.20781L3.37085 
      1.94807H2.88601L2.74746 1.51517L2.60893 1.94807H2.12409L2.51658 
      2.20781L2.37805 2.64071L2.77057 2.38097L3.16305 2.64071L2.97834 
      2.20781ZM5.65657 2.20781L6.04905 1.94807H5.56421L5.42569 1.51517L5.28714 
      1.94807H4.8023L5.19481 2.20781L5.05626 2.64071L5.44877 2.38097L5.84126 
      2.64071L5.65657 2.20781ZM11.0823 4.45889L11.4747 4.19915H10.9899L10.8514 
      3.76625L10.7128 4.19915H10.228L10.6205 4.45889L10.4819 4.89179L10.8745 
      4.63205L11.2669 4.89179L11.0823 4.45889ZM11.0591 6.55846L11.4517 
      6.29872H10.9668L10.8283 5.86582L10.6897 6.29872H10.2049L10.5974 
      6.55846L10.4589 6.99136L10.8514 6.73162L11.2439 6.99136L11.0591 
      6.55846ZM11.1053 8.65802L11.4978 8.39829H11.013L10.8745 7.96538L10.7359 
      8.39829H10.2511L10.6436 8.65802L10.5051 9.09093L10.8975 8.83119L11.29 
      9.09093L11.1053 8.65802ZM13.8066 8.65802L14.1991 8.39829H13.7143L13.5757 
      7.96538L13.4372 8.39829H12.9524L13.3449 8.65802L13.2063 9.09093L13.5988 
      8.83119L13.9913 9.09093L13.8066 8.65802ZM13.7374 6.55846L14.1299 
      6.29872H13.645L13.5065 5.86582L13.3679 6.29872H12.8831L13.2756 
      6.55846L13.1371 6.99136L13.5296 6.73162L13.9221 6.99136L13.7374 
      6.55846ZM13.7605 4.45889L14.1529 4.19915H13.6681L13.5296 3.76625L13.3911 
      4.19915H12.9062L13.2987 4.45889L13.1602 4.89179L13.5527 4.63205L13.9451 
      4.89179L13.7605 4.45889ZM8.35786 2.20781L8.75034 1.94807H8.2655L8.12698 
      1.51517L7.98845 1.94807H7.50361L7.8961 2.20781L7.75757 2.64071L8.15006 
      2.38097L8.54257 2.64071L8.35786 2.20781ZM11.0361 2.20781L11.4286 
      1.94807H10.9437L10.8052 1.51517L10.6667 1.94807H10.1818L10.5743 
      2.20781L10.4358 2.64071L10.8283 2.38097L11.2208 2.64071L11.0361 
      2.20781ZM13.7374 2.20781L14.1299 1.94807H13.645L13.5065 1.51517L13.3679 
      1.94807H12.8831L13.2756 2.20781L13.1371 2.64071L13.5296 2.38097L13.9221 
      2.64071L13.7374 2.20781ZM1.70849 9.71863L2.10101 9.45889H1.61616L1.47761 
      9.02599L1.33909 9.45889H0.854249L1.24673 9.71863L1.10821 10.1515L1.50072 
      9.89179L1.89321 10.1515L1.70849 9.71863ZM1.63925 7.61906L2.03173 
      7.35932H1.54689L1.40837 6.92642L1.26984 7.35932H0.784973L1.17749 
      7.61906L1.03896 8.05196L1.43145 7.79222L1.82393 8.05196L1.63925 
      7.61906ZM1.66233 5.54114L2.05481 5.2814H1.56997L1.43145 4.8485L1.29293 
      5.2814H0.808084L1.20057 5.54114L1.06205 5.97404L1.45453 5.7143L1.84704 
      5.97404L1.66233 5.54114ZM4.36362 5.54114L4.75613 5.2814H4.27129L4.13274 
      4.8485L3.99421 5.2814H3.50937L3.90186 5.54114L3.76333 5.97404L4.15585 
      5.7143L4.54833 5.97404L4.36362 5.54114ZM4.34053 7.61906L4.73305 
      7.35932H4.24818L4.10965 6.92642L3.97113 7.35932H3.48629L3.87877 
      7.61906L3.74025 8.05196L4.13274 7.79222L4.52525 8.05196L4.34053 
      7.61906ZM4.38673 9.71863L4.77921 9.45889H4.29437L4.15585 9.02599L4.0173 
      9.45889H3.53245L3.92497 9.71863L3.78642 10.1515L4.17893 9.89179L4.57142 
      10.1515L4.38673 9.71863ZM7.08802 9.71863L7.4805 9.45889H6.99566L6.85714 
      9.02599L6.71861 9.45889H6.23377L6.62626 9.71863L6.48773 10.1515L6.88022 
      9.89179L7.27273 10.1515L7.08802 9.71863ZM7.01874 7.61906L7.41125 
      7.35932H6.92641L6.78786 6.92642L6.64934 7.35932H6.16449L6.55698 
      7.61906L6.41846 8.05196L6.81097 7.79222L7.20346 8.05196L7.01874 
      7.61906ZM7.04185 5.54114L7.43434 5.2814H6.94949L6.81097 4.8485L6.67242 
      5.2814H6.18758L6.58009 5.54114L6.44154 5.97404L6.83405 5.7143L7.22654 
      5.97404L7.04185 5.54114ZM1.63925 3.29006L2.03173 3.03032H1.54689L1.40837 
      2.59742L1.26984 3.03032H0.784973L1.17749 3.29006L1.03896 3.72296L1.43145 
      3.46322L1.82393 3.72296L1.63925 3.29006ZM4.31745 3.29006L4.70994 
      3.03032H4.22509L4.08657 2.59742L3.94805 3.03032H3.46321L3.85569 
      3.29006L3.71717 3.72296L4.10965 3.46322L4.50217 3.72296L4.31745 
      3.29006ZM9.72006 5.54114L10.1125 5.2814H9.6277L9.48918 4.8485L9.35065 
      5.2814H8.86578L9.2583 5.54114L9.11977 5.97404L9.51226 5.7143L9.90474 
      5.97404L9.72006 5.54114ZM9.72006 7.61906L10.1125 7.35932H9.6277L9.48918 
      6.92642L9.35065 7.35932H8.86578L9.2583 7.61906L9.11977 8.05196L9.51226 
      7.79222L9.90474 8.05196L9.72006 7.61906ZM9.76622 9.71863L10.1587 
      9.45889H9.67386L9.53534 9.02599L9.39682 9.45889H8.91198L9.30446 
      9.71863L9.16594 10.1515L9.55842 9.89179L9.95094 10.1515L9.76622 
      9.71863ZM12.4675 9.71863L12.86 9.45889H12.3752L12.2367 9.02599L12.0981 
      9.45889H11.6133L12.0058 9.71863L11.8672 10.1515L12.2597 9.89179L12.6522 
      10.1515L12.4675 9.71863ZM15.1457 9.71863L15.5382 9.45889H15.0534L14.9149 
      9.02599L14.7763 9.45889H14.2915L14.684 9.71863L14.5455 10.1515L14.9379 
      9.89179L15.3304 10.1515L15.1457 9.71863ZM12.3983 7.61906L12.7907 
      7.35932H12.3059L12.1674 6.92642L12.0289 7.35932H11.544L11.9365 
      7.61906L11.798 8.05196L12.1905 7.79222L12.583 8.05196L12.3983 
      7.61906ZM15.0996 7.61906L15.4921 7.35932H15.0072L14.8687 6.92642L14.7301 
      7.35932H14.2453L14.6378 7.61906L14.4993 8.05196L14.8918 7.79222L15.2843 
      8.05196L15.0996 7.61906ZM12.4213 5.54114L12.8139 5.2814H12.329L12.1905 
      4.8485L12.0519 5.2814H11.5671L11.9596 5.54114L11.8211 5.97404L12.2135 
      5.7143L12.6061 5.97404L12.4213 5.54114ZM15.0996 5.54114L15.4921 
      5.2814H15.0072L14.8687 4.8485L14.7301 5.2814H14.2453L14.6378 
      5.54114L14.4993 5.97404L14.8918 5.7143L15.2843 5.97404L15.0996 
      5.54114ZM7.01874 3.29006L7.41125 3.03032H6.92641L6.78786 2.59742L6.64934 
      3.03032H6.16449L6.55698 3.29006L6.41846 3.72296L6.81097 3.46322L7.20346 
      3.72296L7.01874 3.29006ZM9.69697 3.29006L10.0895 3.03032H9.60462L9.46609 
      2.59742L9.32754 3.03032H8.8427L9.23521 3.29006L9.09666 3.72296L9.48918 
      3.46322L9.88166 3.72296L9.69697 3.29006ZM12.3752 3.29006L12.7677 
      3.03032H12.2828L12.1443 2.59742L12.0058 3.03032H11.5209L11.9134 
      3.29006L11.7749 3.72296L12.1674 3.46322L12.5599 3.72296L12.3752 3.29006Z"
      fill="#F8F8F9"
    />
  </>
);

export default UnitedStates;
