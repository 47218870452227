import React from "react";
import { useQaLabel } from "@tvg/design-system/src/hooks";
import { WagerType } from "@tvg/ts-types/Wager";
import { BetTypeButton } from "./components/betTypeButton";
import { BetTypeExpandableButton } from "./components/betTypeExpandableButton";

import { groupByWagerType } from "./utils";
import { IconButton } from "../iconButton";
import useBetSelectorScroll from "../../hooks/useBetSelectorScroll";
import {
  BetTypesContainer,
  BetTypeSelectorContainer,
  InfoContainer,
  InfoText,
  Navigation,
  FadeLayer
} from "./styled-components";
import { BetTypeSelectorProps } from "./types";

const BetTypeSelector: React.FC<BetTypeSelectorProps> = ({
  betTypesList,
  selectedBetType,
  setSelectedBetType,
  qaLabel = "bet-type-selector"
}) => {
  const filteredList: Array<WagerType[]> = groupByWagerType(betTypesList);

  const {
    scrollAmount,
    isOverFlowing,
    handleScrollTo,
    betTypesContainerRef,
    getWidthToScroll
  } = useBetSelectorScroll(filteredList);

  return (
    <BetTypeSelectorContainer {...useQaLabel(qaLabel)}>
      <InfoContainer {...useQaLabel("available-bet-types-container")}>
        <InfoText {...useQaLabel("available-bet-types")}>
          Available bet types
        </InfoText>
      </InfoContainer>
      <BetTypesContainer
        ref={betTypesContainerRef}
        {...useQaLabel("bets-types-container")}
        isOverFlowing={isOverFlowing}
      >
        {/* TODO: added MOCK only for test */}
        {filteredList[0][0] !== undefined &&
          filteredList.map((betType: Array<WagerType>) =>
            betType.length === 1 ? (
              <BetTypeButton
                betType={betType[0]}
                isSelected={betType[0].id === selectedBetType}
                key={betType[0].id}
                onPress={setSelectedBetType}
              />
            ) : (
              <BetTypeExpandableButton
                betTypes={betType}
                selectedValue={selectedBetType}
                isSelected={
                  !!betType.find((type) => type.id === selectedBetType)
                }
                key={betType[0].id}
                onPress={setSelectedBetType}
              />
            )
          )}
      </BetTypesContainer>
      {isOverFlowing && (
        <FadeLayer
          {...useQaLabel("fade")}
          orientation={scrollAmount <= 0 ? "left" : "right"}
          width={
            betTypesContainerRef.current?.getBoundingClientRect().width || 0
          }
        />
      )}
      {isOverFlowing && (
        <Navigation>
          <IconButton
            variant="secondary"
            size="m"
            iconName={scrollAmount <= 0 ? "arrowRight" : "arrowLeft"}
            onPress={() =>
              scrollAmount <= 0
                ? handleScrollTo(getWidthToScroll())
                : handleScrollTo(-getWidthToScroll())
            }
            qaLabel="bet-types-scroll"
          />
        </Navigation>
      )}
    </BetTypeSelectorContainer>
  );
};

export default BetTypeSelector;
export { BetTypeSelectorProps, BetTypeSelector, groupByWagerType };
