import React from "react";
import { CheckboxGroup } from "@tvg/design-system/src/components/checkbox/checkboxGroup";
import { Checkbox } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import type { Props } from "./types";
import { CHECKBOX, CONTAINER } from "../../../utils/constants";

const FilterGroup = ({
  options,
  title,
  selectedValues,
  onChange,
  qaLabel = ""
}: Props) => (
  <CheckboxGroup
    qaLabel={buildQaLabel([qaLabel, CHECKBOX, CONTAINER])}
    selectedValues={selectedValues}
    label={title}
    onChange={onChange}
  >
    {options.map(({ label, value }, index) => (
      <Checkbox
        qaLabel={buildQaLabel([qaLabel, CHECKBOX, value])}
        label={label}
        value={value}
        key={`${label}-${index.toString()}`}
      />
    ))}
  </CheckboxGroup>
);

export default FilterGroup;
