import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

type Transition = {
  function: string;
  value: number;
  time: "ms" | "s";
};

const easeInOut: Transition = {
  function: "ease-in-out",
  value: 200,
  time: "ms"
};

const generateTransition = (
  transition: Transition,
  cssProp: string = "all",
  delay?: string
): string =>
  `${cssProp} ${transition.value}${transition.time} ${transition.function}${
    delay ? ` ${delay}` : ""
  }`;

export const SearchHeaderContainer = styled.div<{ isSearchActive: boolean }>`
  display: flex;
  align-items: center;
  ${({ theme, isSearchActive }) => css`
    background-color: ${theme.colorTokens.background.surface};
    @media screen and ${breakpoints.desktop.max.sm} {
      padding: ${theme.space["space-5"]};
      ${isSearchActive &&
      css`
        border-bottom: 1px solid ${theme.colorTokens.border.default};
      `}
    }
  `}
`;

// TODO: this input will be removed and moved to Design System after the desktop release

export const CustomSearchContainer = styled.div<{ isSearchActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme, isSearchActive }) => css`
    border: 1px solid
      ${isSearchActive
        ? theme.colorTokens.lhnHeader.selectedcell.border
        : theme.colors.grey["500"]};
    border-radius: ${theme.radii.xs};
    ${!isSearchActive && "box-shadow: 0px 1px 3px 0px rgba(17, 43, 68, 0.12);"}
  `}

  ${({ theme, isSearchActive }) => css`
    @media screen and ${breakpoints.desktop.min.sm} {
      border-color: ${isSearchActive
        ? theme.colorTokens.component.input.active
        : theme.colorTokens.border.default};
      width: 269px;
    }
  `}
`;

export const CustomSearchIcon = styled.span`
  display: flex;
  align-items: center;
  border-right: none;
  ${({ theme }) => css`
    padding-left: ${theme.space["space-4"]};
    border-radius: ${theme.radii.xs} 0 0 ${theme.radii.xs};
  `}
`;

export const CustomSearchInput = styled.input`
  width: 100%;
  border-left: none;
  ${({ theme }) => css`
    padding: ${theme.space["space-4"]};
    border-radius: 0 ${theme.radii.xs} ${theme.radii.xs} 0;
    font-family: ${theme.fonts.regular};
    font-size: ${theme.fontSizes.s};
    line-height: ${theme.lineHeights.s};
    color: ${theme.colorTokens.content.default};
  `}

  &::placeholder {
    color: ${({ theme }) => theme.colorTokens.content.subtle};
  }

  &:focus {
    outline: none;
  }
`;

export const CancelSearchContainer = styled.div<{
  isVisible: boolean;
}>`
  transition: ${generateTransition(easeInOut, "opacity")};
  ${({ isVisible }) =>
    !isVisible
      ? css`
          width: 0;
          height: 0;
          overflow: hidden;
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
`;

export const CustomClearButton = styled.button<{
  isVisible: boolean;
}>`
  background-color: transparent;
  padding: 0 8px;
  cursor: pointer;
  ${({ isVisible }) => !isVisible && "display: none;"}
`;
