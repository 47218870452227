import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  padding: ${({ theme }) => `${theme.space["space-5"]} 0`};
  flex: 1;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  svg {
    min-width: fit-content;
  }
`;

export const RacesContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.space["space-5"]};
`;
