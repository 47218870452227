import React from "react";
import { LoadingMaskAnimation } from "@tvg/design-system/web";

const LHNCollapsedMask = () => (
  <LoadingMaskAnimation>
    <svg
      width="48"
      height="948"
      viewBox="0 0 48 948"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_393_78634)">
        <rect width="48" height="48" fill="transparent" />
        <rect y="47" width="48" height="1" fill="white" fillOpacity="0.2" />
        <rect
          x="8"
          y="18"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="74"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="90"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="154"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="170"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="234"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="250"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="314"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="330"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="394"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="410"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="474"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="490"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="554"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="570"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="634"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="650"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          width="48"
          height="48"
          transform="translate(0 720)"
          fill="transparent"
        />
        <rect y="767" width="48" height="1" fill="white" fillOpacity="0.2" />
        <rect
          x="8"
          y="738"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="794"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="810"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="874"
          width="32"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          x="14"
          y="890"
          width="20"
          height="12"
          rx="1"
          fill="white"
          fillOpacity="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_393_78634">
          <rect width="48" height="1660" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </LoadingMaskAnimation>
);

export default LHNCollapsedMask;
