import mediator from "@tvg/mediator";
import PreferenceService from "@tvg/api/uam";
import messenger from "@tvg/messenger";
import { Dispatch } from "redux";
import {
  iOSTouchIdToggleChange,
  setBetConfirmationPreference
} from "../redux/actions";

interface UsePreferencesProps {
  dispatch: Dispatch;
  accountNumber: string;
}

export function usePreferences({
  dispatch,
  accountNumber
}: UsePreferencesProps) {
  const toggleBetConfirmationPreference = (currentValue: "0" | "1") => {
    const nextValue = currentValue === "0" ? "1" : "0";
    dispatch(setBetConfirmationPreference(nextValue));

    mediator.base.dispatch({
      type: "MORE_SWITCH_TOGGLE",
      payload: {
        action: nextValue === "1" ? "Toggle On" : "Toggle Off",
        field: "betConfirmation"
      }
    });

    return PreferenceService.setUserPref(
      accountNumber,
      "show_bet_confirm",
      nextValue
    ).catch(() => {
      dispatch(setBetConfirmationPreference(currentValue));
      messenger.create({
        type: "error",
        position: "bottom",
        hasIcon: true,
        message: "Error while trying to change bet confirmation setting"
      });
    });
  };

  const toggleTouchId = (newValue: boolean) => {
    dispatch(iOSTouchIdToggleChange(newValue));

    mediator.ios.dispatch({
      type: "PREFERENCE_TOUCH_ID",
      payload: {
        touchIdEnabled: newValue
      }
    });

    mediator.base.dispatch({
      type: "MORE_SWITCH_TOGGLE",
      payload: {
        action: newValue ? "Toggle On" : "Toggle Off",
        field: "touchId"
      }
    });
  };

  return {
    toggleBetConfirmationPreference,
    toggleTouchId
  };
}

export default usePreferences;
