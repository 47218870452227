import React, { PropsWithChildren, memo } from "react";
import { Header } from "@tvg/design-system/web";
import { Container } from "./styled-components";
import { Props } from "./types";

const SectionTitle = ({ qaLabel, children }: PropsWithChildren<Props>) => (
  <Container>
    <Header tag="h2" fontSize={12} fontWeight={400} qaLabel={qaLabel}>
      <> {children}</>
    </Header>
  </Container>
);

export default memo(SectionTitle);
