import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import color from "../ColorPalette";

const SVGContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  z-index: 30;
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 720px;
  background: ${color("white", "100")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${color("white", "100")};
    stroke-width: 1;
  }
`;

export default class TracksMask extends PureComponent {
  render() {
    return (
      <SVGContainer data-qa-label="tracksMask">
        <SVG>
          <g fill="none" fillRule="evenodd" transform="translate(0 -48)">
            <rect width="518" height="1048" fill={color("grey", "100")} />
            <rect
              width="518"
              height="480"
              y="568"
              fill={color("white", "100")}
            />
            <rect
              width="12"
              height="8"
              x="12"
              y="626"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="86"
              height="8"
              x="12"
              y="592"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="120"
              height="8"
              x="12"
              y="666"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="120"
              height="8"
              x="12"
              y="714"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="136"
              height="8"
              x="12"
              y="762"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="12"
              height="8"
              x="12"
              y="802"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="82"
              height="8"
              x="12"
              y="842"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="130"
              height="8"
              x="12"
              y="890"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="12"
              height="8"
              x="12"
              y="930"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="116"
              height="8"
              x="12"
              y="970"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="132"
              height="8"
              x="12"
              y="1018"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect width="518" height="1" y="645" fill={color("grey", "200")} />
            <rect width="518" height="1" y="693" fill={color("grey", "200")} />
            <rect width="518" height="1" y="741" fill={color("grey", "200")} />
            <rect width="518" height="1" y="789" fill={color("grey", "200")} />
            <rect width="518" height="1" y="821" fill={color("grey", "200")} />
            <rect width="518" height="1" y="869" fill={color("grey", "200")} />
            <rect width="518" height="1" y="917" fill={color("grey", "200")} />
            <rect width="518" height="1" y="949" fill={color("grey", "200")} />
            <rect width="518" height="1" y="997" fill={color("grey", "200")} />
            <rect
              width="518"
              height="154"
              y="298"
              fill={color("white", "100")}
            />
            <rect
              width="106"
              height="8"
              x="12"
              y="365"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect width="518" height="1" y="451" fill={color("grey", "100")} />
            <rect
              width="79"
              height="8"
              x="12"
              y="322"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="91"
              height="47"
              x="12.5"
              y="392.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="91"
              height="47"
              x="112.5"
              y="392.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="91"
              height="47"
              x="212.5"
              y="392.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="91"
              height="47"
              x="312.5"
              y="392.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="20"
              height="8"
              x="20"
              y="403"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="28"
              height="8"
              x="120"
              y="403"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="20"
              y="421"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="120"
              y="421"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="28"
              height="8"
              x="220"
              y="403"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="220"
              y="421"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="36"
              height="8"
              x="320"
              y="403"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="320"
              y="421"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="91"
              height="47"
              x="412.5"
              y="392.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="36"
              height="8"
              x="420"
              y="403"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="420"
              y="421"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="518"
              height="108"
              y="452"
              fill={color("white", "100")}
            />
            <rect
              width="122"
              height="8"
              x="12"
              y="473"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="91"
              height="47"
              x="12.5"
              y="500.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="91"
              height="47"
              x="112.5"
              y="500.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="91"
              height="47"
              x="212.5"
              y="500.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="91"
              height="47"
              x="312.5"
              y="500.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="20"
              height="8"
              x="20"
              y="511"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="28"
              height="8"
              x="120"
              y="511"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="20"
              y="529"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="120"
              y="529"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="28"
              height="8"
              x="220"
              y="511"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="220"
              y="529"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="36"
              height="8"
              x="320"
              y="511"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="320"
              y="529"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="91"
              height="47"
              x="412.5"
              y="500.5"
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="36"
              height="8"
              x="420"
              y="511"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="44"
              height="8"
              x="420"
              y="529"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="518"
              height="175"
              y="115"
              fill={color("white", "100")}
            />
            <rect
              width="141"
              height="116"
              x="12.5"
              y="161.5"
              fill={color("white", "100")}
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="141"
              height="116"
              x="162.5"
              y="161.5"
              fill={color("white", "100")}
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="36"
              height="16"
              x="24"
              y="173"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="36"
              height="16"
              x="174"
              y="173"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="90"
              height="8"
              x="24"
              y="202"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="98"
              height="8"
              x="174"
              y="202"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="42"
              height="8"
              x="24"
              y="217"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="42"
              height="8"
              x="174"
              y="217"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="141"
              height="116"
              x="312.5"
              y="161.5"
              fill={color("white", "100")}
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="52"
              height="16"
              x="324"
              y="173"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="74"
              height="8"
              x="324"
              y="202"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="42"
              height="8"
              x="324"
              y="217"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="141"
              height="116"
              x="462.5"
              y="161.5"
              fill={color("white", "100")}
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="52"
              height="16"
              x="474"
              y="173"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="90"
              height="8"
              x="474"
              y="202"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="42"
              height="8"
              x="474"
              y="217"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="150"
              height="8"
              x="12"
              y="139"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect width="518" height="67" y="48" fill={color("navy", "900")} />
          </g>
        </SVG>
      </SVGContainer>
    );
  }
}
