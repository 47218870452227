import React, { memo, useState, useRef } from "react";
import { InputMessage } from "../inputMessage";
import { Checkbox } from "../../../../src/components/checkbox";
import { Icon } from "../../Icon";
import { useOnClickOutside } from "../../../../src/hooks";
import { useColorTokens } from "../../../hooks";
import { CheckboxSelectProps } from "../types";
import {
  InputWrapper,
  InputBox,
  SelectIconWrapper
} from "../styled-components";
import {
  CustomDropdown,
  Label,
  SelectOptions,
  SelectOption
} from "./styled-components";

export const InputCheckboxSelect = ({
  label = "",
  messageAbove = "",
  messageBellow = "",
  isInvalid = false,
  isDisabled = false,
  warningMessage = "",
  qaLabel,
  selectList,
  selectedValues = [],
  onSelectCheckbox,
  ...rest
}: CheckboxSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const colorTokens = useColorTokens();

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <InputWrapper {...rest} data-qa-label={qaLabel}>
      {!!messageAbove && (
        <InputMessage message={messageAbove} marginTop="space-3" />
      )}
      <CustomDropdown ref={dropdownRef}>
        <InputBox
          data-qa-label="input-box"
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          isFocused={isOpen}
          hasBorder
          hasBackground
          {...(!isDisabled && { onClick: handleToggle })}
        >
          <Label isDisabled={isDisabled}>{label}</Label>
          <SelectIconWrapper isDisabled={isDisabled}>
            <Icon
              size="s"
              qaLabel={isOpen ? "chevron-up-icon" : "chevron-down-icon"}
              lineColor={
                isDisabled
                  ? colorTokens.content.disabled
                  : colorTokens.component.input.active
              }
              name={isOpen ? "chevronUp" : "chevronDown"}
            />
          </SelectIconWrapper>
        </InputBox>
        {isOpen && (
          <SelectOptions>
            <Checkbox.Group
              selectedValues={selectedValues}
              onChange={onSelectCheckbox}
              qaLabel="checkbox-group"
            >
              {selectList.map((option) => (
                <SelectOption key={`option-${option.label}`}>
                  <Checkbox value={option.value} label={option.label} />
                </SelectOption>
              ))}
            </Checkbox.Group>
          </SelectOptions>
        )}
      </CustomDropdown>
      {isInvalid && warningMessage && (
        <InputMessage
          message={warningMessage}
          isWarning={isInvalid}
          mx={0}
          mt={2}
          mb="space-3"
        />
      )}
      {!!messageBellow && <InputMessage message={messageBellow} />}
    </InputWrapper>
  );
};

export default memo(InputCheckboxSelect);
