import React, { FC } from "react";
import { Countries, PageHeader } from "@tvg/design-system";
import { Flag, Paragraph } from "@tvg/design-system/web";
import { getTrackSubtitle } from "../../utils";
import { HeaderProps } from "../../types";

const HeaderMobile: FC<HeaderProps> = ({ favoriteElem, onBack, track }) => (
  <>
    {favoriteElem()}
    <PageHeader
      title={track.name}
      onBack={onBack}
      titlePosition="center"
      subtitleType="element"
      subtitle={
        <>
          <Flag
            country={track.location.country as Countries}
            size="s"
            qaLabel=""
            paddingRight="4px"
          />
          <Paragraph qaLabel="" color="content.subtle" fontFamily="regular">
            {getTrackSubtitle(track)}
          </Paragraph>
        </>
      }
    />
  </>
);

export default HeaderMobile;
