import styled from "styled-components";

export const Item = styled.li`
  display: flex;
  position: relative;
  transition: all 500ms ease;
`;

export const CardWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  z-index: 1;
  transition: transform 0.125s ease-out;
  position: relative;
`;
