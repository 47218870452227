import React from "react";

import { FilterButtonBarProps } from "../../types";
import { ButtonBarContainer } from "./styled-components";

const FilterButtonBar = ({
  qaLabel,
  isDisabled = false,
  activeButtons,
  onButtonClick,
  children,
  ...rest
}: FilterButtonBarProps) => (
  <ButtonBarContainer data-qa-label={qaLabel} {...rest}>
    {React.Children.map(children, (child: React.ReactElement) =>
      React.cloneElement(child, {
        onClick: () => onButtonClick(child.props.value),
        isActive: !isDisabled && activeButtons.includes(child.props.value),
        isDisabled
      })
    )}
  </ButtonBarContainer>
);

export default FilterButtonBar;
