import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const ContentCardEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  flex: 1;
  justify-content: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: ${buildColor("grey", "100")};
`;
