import styled, { css } from "styled-components";
import { buildColor, Theme } from "@tvg/design-system";

export const TrackInfo = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
`;

export const ResultedTag = styled.span`
  color: ${buildColor("grey", "800")};
  font-size: 14px;
  letter-spacing: 0;
`;

export const IconButtonContainer = styled.div<{
  theme: Theme;
  isFavorite: boolean;
}>`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  margin-left: ${({ theme }) => theme.space["space-4"]};

  button {
    width: 20px;
    height: 20px;
  }

  svg {
    path {
      transition:
        fill 0.2s ease-in-out,
        stroke 0.2s ease-in-out;
      ${({ isFavorite }) =>
        isFavorite &&
        css`
          fill: ${buildColor("yellow", "400")};
          stroke: ${buildColor("yellow", "400")};
        `}
    }
  }
`;

export const LinkCardContainer = styled.div`
  width: 100%;
  position: relative;

  & > button {
    display: flex;
    align-items: center;
    background-color: ${buildColor("white", "900")};
    border-width: 1px 0;
    border-radius: 0;
  }
`;

export const ContentContainer = styled.div<{ theme: Theme }>`
  display: flex;
  margin-left: ${({ theme }) => theme.space["space-6"]};
`;
