import {
  trackBetaFeatureFeedback,
  events as AlchemerEvents
} from "@urp/alchemer";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import {
  AWPathsMap,
  openAWPage
} from "@tvg/sh-lib-account-wallet/src/utils/AWPages";
import { openTransactionHistoryModal } from "@urp/transaction-history/src/redux/actions";
import { MenuLinkProps } from "../types";

interface UseGWMenuRoutesReturn {
  deskAccountLinks: MenuLinkProps[];
}

const goTo = (
  route: string,
  target: string = "",
  description: string = "",
  updateMenuOpenStatus: CallableFunction,
  isAccountCompliant: boolean,
  navigate: CallableFunction
) => {
  updateMenuOpenStatus();
  if (target === "_blank" && typeof window !== "undefined") {
    window.open(route, "_blank");
  } else if (route.startsWith("/")) {
    if (isAccountCompliant) {
      navigate(route);
    } else {
      onOpenComplianceModal();
    }
  } else if (typeof window !== "undefined") {
    if (description === "Send Feedback") {
      AlchemerEvents.showSendFeedbackSurvey();
      trackBetaFeatureFeedback();
    } else {
      window.location.href = route;
    }
  }
};

const onClickLinkItem = (
  href: string,
  target: string,
  description: string = "",
  qaLabel: string,
  updateMenuOpenStatus: CallableFunction,
  isAccountCompliant: boolean,
  navigate: CallableFunction,
  dispatch: CallableFunction
) => {
  // temporary edge case until component is moved to DS
  if (qaLabel === "my-account-summary-transactions-before") {
    if (isAccountCompliant) {
      dispatch(openTransactionHistoryModal());
    } else {
      onOpenComplianceModal();
    }
  } else if (Object.keys(AWPathsMap).includes(qaLabel)) {
    openAWPage(AWPathsMap[qaLabel], { forceWeb: true });
  } else {
    goTo(
      href,
      target,
      description,
      updateMenuOpenStatus,
      isAccountCompliant,
      navigate
    );
  }
};

export const useGwDeskMenuRoutes = (
  deskMenuConfiguration: Record<string, string>[],
  updateMenuOpenStatus: CallableFunction,
  navigate: CallableFunction,
  isAccountCompliant: boolean,
  dispatch: CallableFunction
): UseGWMenuRoutesReturn => {
  const returnObject: MenuLinkProps[] = [] as MenuLinkProps[];

  deskMenuConfiguration.forEach((linkItem) => {
    if (linkItem.qaLabel !== "talent-picks") {
      const item: MenuLinkProps = {
        qaLabel: linkItem.qaLabel,
        to: linkItem.href,
        linkText: linkItem.description,
        onClick: () => {
          onClickLinkItem(
            linkItem.href,
            linkItem.target,
            linkItem.description,
            linkItem.qaLabel,
            updateMenuOpenStatus,
            isAccountCompliant,
            navigate,
            dispatch
          );
        },
        isExternalLink: true
      };
      returnObject.push(item);
    }
  });

  return { deskAccountLinks: returnObject };
};
