import React from "react";
import { Path } from "react-native-svg";

export const Denmark: React.FC = () => (
  <>
    <Path
      d="M32 0V7.8355H14.7302V0H32ZM10.0433 0H0V7.8355H10.0433V0ZM0 
      12.1645V20H10.0433V12.1645H0ZM14.7302 20H32V12.1645H14.7302V20Z"
      fill="#DE3131"
    />
    <Path
      d="M14.7302 0H10.0433V7.8355H0V12.1645H10.0433V20H14.7302V12.1645H32V7.8355H14.7302V0Z"
      fill="#F8F8F9"
    />
  </>
);

export default Denmark;
