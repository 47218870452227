import styled, { css } from "styled-components";
import FavoriteIcon from "../FavoriteIcon";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${({ theme }) => theme.colorTokens.background.layer};
  width: 100%;
  position: relative;
`;

export const Favorite = styled(FavoriteIcon)<{ isMobile: boolean }>`
  position: absolute;
  z-index: 1;
  top: 14px;
  right: 6px;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      position: inherit;
      margin-left: var(--fd-space-space-3);
    `}
`;

export const ContentContainer = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      padding: var(--fd-space-space-4, 16px);
    `}
  background-color: var(--fd-colors-background-base);
`;
