import styled from "styled-components";

export const Container = styled.div`
  padding: ${({ theme }) =>
    `0 ${theme.space["space-5"]} ${theme.space["space-4"]} ${theme.space["space-5"]}`};
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  display: flex;
`;

export const RacesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space["space-3"]};
  overflow: hidden;
  padding: ${({ theme }) => `0 ${theme.space["space-3"]}`};
`;
