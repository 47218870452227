import styled from "styled-components";
import styledNative from "styled-components/native";
import { Header, Tag } from "@tvg/design-system";

export const LinksContainer = styled.div`
  max-height: calc(85vh - 290px - 68px);
`;

export const SectionTitle = styledNative(Header)`
    display: flex;
`;

export const NewTag = styledNative(Tag)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto 0;
`;
