import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{ mediaQuery: string }>`
  ${flexColumn};

  @media ${({ mediaQuery }) => mediaQuery} {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
`;

export const Menu = styled.div`
  height: 0;
  position: relative;
  ${flexColumn};
  align-items: center;
`;

export const ContentContainer = styled.div`
  position: absolute;
  padding-top: 8px;
  top: 0;
`;

export const ContentWrapper = styled.ul`
  list-style-type: none;
  width: 375px;
  background-color: ${buildColor("white", "900")};
  box-shadow:
    0 4px 4px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  overflow: hidden;
`;
