import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useRaceTracks,
  RESULTED_RACES_STATUS,
  useUserPromos
} from "@urp/lib-racetracks";
import type { Track } from "@tvg/ts-types/Track";
import type { Race } from "@tvg/ts-types/Race";
import {
  getCurrentTabTracksPage,
  getOpenTracks
} from "@urp/lib-racetracks/src/redux/selectors";
import { buildQaLabel } from "@tvg/test-utils/utils";
import {
  FilterOptions,
  TrackFilterValueEnum
} from "@urp/lib-racetracks/src/types";
import { hasFiltersApplied } from "@urp/lib-racetracks/src/utils/races";
import { PromotionTypeEnum } from "@tvg/ts-types/Promos";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  RaceWithBets,
  useGetRacesWithBetsByTrack
} from "@urp/lib-racetracks/src/hooks/useGetRacesWithBetsByTrack";
import { isEmpty } from "lodash";
import { UnaryFn, TernaryFn } from "@tvg/ts-types/Functional";
import { Module } from "@urp/amplitude/src/modules/raceCell/types";
import { getIsLogged } from "@urp/store-selectors";
import { getTracksAZInfo } from "../../redux/selectors";
import TracksAZList from "../TracksAZList";
import { tracksAzInfo } from "../../redux/defaultValues";
import { BASE_QA_LABEL, LIST } from "../../utils/constants";
import { getRaceTracksByLetter } from "../../utils";
import EmptyMessage from "../EmptyMessage";
import FavoritesNotFound from "../../emptyCases/FavoritesNotFound";
import TracksSection from "../TracksSection";
import TracksAZLoading from "../../loadingMasks/TracksAZLoading";
import { MyBetsClickProps } from "../PastResults/types";
import { EmptyContainer, EmptyMessageContainer } from "./styled-components";

interface TracksAZProps {
  isResults?: boolean;
  isOnlyFavorites?: boolean;
  filterOptions?: FilterOptions;
  subTitle?: string;
  onMyBetsClick?: UnaryFn<MyBetsClickProps, void>;
  onRaceCellClick?: TernaryFn<Race, Track, Module, void>;
  dateFilter?: string;
}

const TracksAZ = ({
  isResults = false,
  isOnlyFavorites = false,
  filterOptions,
  subTitle = "",
  onMyBetsClick,
  onRaceCellClick,
  dateFilter
}: TracksAZProps) => {
  const {
    title = tracksAzInfo.title,
    resultedTitle = tracksAzInfo.resultedTitle
  } = useSelector(getTracksAZInfo);
  const currentTab = useSelector(getCurrentTabTracksPage);
  const isLogged = useSelector(getIsLogged);
  const openTracks = useSelector(getOpenTracks);
  const { tracksRaces, onTrackClickHandler, loading } = useRaceTracks({
    ...filterOptions,
    raceStatusFilter: isResults
      ? RESULTED_RACES_STATUS
      : filterOptions?.raceStatusFilter,
    trackTypesFilter: isOnlyFavorites
      ? [TrackFilterValueEnum.FAVORITE]
      : filterOptions?.trackTypesFilter
  });
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const { checkRacePromos } = useUserPromos(PromotionTypeEnum.RACE);
  const { fetchRacesWithBets, races: racesWithBets } =
    useGetRacesWithBetsByTrack();

  const [trackCode, setTrackCode] = useState("");
  const [tracksWithBets, setTracksWithBets] = useState<
    Record<string, RaceWithBets>
  >({});

  useEffect(() => {
    if (isLogged && trackCode && dateFilter && isResults) {
      fetchRacesWithBets({
        trackCode,
        startDate: dateFilter,
        endDate: dateFilter
      }).then(() => setTrackCode(""));
    }
  }, [trackCode, dateFilter, isLogged, isResults]);

  useEffect(() => {
    if (isLogged && dateFilter && isResults && tracksRaces.length > 0) {
      const trackCodesOnTab = tracksRaces.map((track) => track.code);
      openTracks
        .filter((track) => trackCodesOnTab.includes(track))
        .forEach((track) =>
          fetchRacesWithBets({
            trackCode: track,
            startDate: dateFilter,
            endDate: dateFilter
          }).then((races) => {
            setTracksWithBets((prevState) => ({
              ...prevState,
              [races.trackCode as string]: races
            }));
          })
        );
    }
  }, [isLogged, dateFilter, isResults, tracksRaces]);

  useEffect(() => {
    if (!isEmpty(racesWithBets) && racesWithBets?.trackCode) {
      setTracksWithBets((prevState) => ({
        ...prevState,
        [racesWithBets.trackCode as string]: racesWithBets
      }));
    }
  }, [racesWithBets]);

  if (loading) {
    return <TracksAZLoading />;
  }

  if (!tracksRaces?.length && hasFiltersApplied(filterOptions)) {
    return (
      <TracksSection
        title={isResults ? resultedTitle : title}
        qaLabel={buildQaLabel([BASE_QA_LABEL, LIST])}
      >
        <EmptyMessageContainer>
          <EmptyMessage />
        </EmptyMessageContainer>
      </TracksSection>
    );
  }

  if (!tracksRaces?.length && isOnlyFavorites) {
    return <FavoritesNotFound />;
  }

  if (isDesktop && !tracksRaces?.length && isResults) {
    return (
      <EmptyContainer>
        <EmptyMessage isGlobal isTracksSearchActive currentTab={currentTab} />
      </EmptyContainer>
    );
  }

  const handleTrackClick = (track: string) => {
    onTrackClickHandler(track, isResults);
    setTrackCode(track);
  };

  return (
    <TracksAZList
      title={isResults ? resultedTitle : title}
      subTitle={subTitle}
      isResults={isResults}
      groupByLetter={getRaceTracksByLetter(tracksRaces)}
      qaLabel={BASE_QA_LABEL}
      onTrackClickHandler={handleTrackClick}
      checkRacePromos={checkRacePromos}
      tracksWithBets={tracksWithBets}
      onMyBetsClick={onMyBetsClick}
      onRaceCellClick={onRaceCellClick}
    />
  );
};

export default memo(TracksAZ);
