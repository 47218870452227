import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "@fdr/static-ui/ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

export default class RacingTipMask extends PureComponent {
  render() {
    return (
      <Container>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="351"
          height="64"
          viewBox="0 0 351 64"
        >
          <g fill="none" fillRule="evenodd">
            <rect width="351" height="64" fill="#1F375B" rx="4" />
            <rect
              className="pulse"
              width="276"
              height="8"
              x="12"
              y="40"
              fill="#294B75"
              rx="2"
            />
            <rect
              className="pulse"
              width="132"
              height="8"
              x="12"
              y="16"
              fill="#294B75"
              rx="2"
            />
          </g>
        </SVG>
      </Container>
    );
  }
}
