import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const flexColumn = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  ${flexColumn};
  flex: 1;
  z-index: 1;
  transition: transform 0.125s ease-out;
  background-color: #12273c;
  position: relative;
  width: 100%;

  &:hover {
    .delete-button {
      opacity: 1;
    }
  }
`;

export const CardWrapper = styled(Link)`
  ${flexColumn};
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    height: 30px;
    z-index: 2;
  }

  &::before {
    top: 0;
    background-image: linear-gradient(#12273c, rgba(255, 255, 255, 0));
  }

  &::after {
    bottom: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0), #12273c);
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;
`;

export const DismissContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  height: 100%;
  width: auto;
  opacity: 0;
  transition: 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export const TrashIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
