import React from "react";
import { Path, G, Defs, Rect, ClipPath } from "react-native-svg";

export const TrinidadAndTobago: React.FC = () => (
  <>
    <G clipPath="url(#clip0_18753_2501)">
      <Path d="M32.3333 0H-1V20H32.3333V0Z" fill="#DE3131" />
      <Path d="M-1 0L22.1386 20H32.3333L9.19474 0H-1Z" fill="white" />
      <Path
        d="M0.699097 0L23.8377 20H30.6342L7.49559 0H0.699097Z"
        fill="black"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_18753_2501">
        <Rect width="32" height="20" rx="2" fill="white" />
      </ClipPath>
    </Defs>
  </>
);

export default TrinidadAndTobago;
