import styled from "styled-components";

const Box = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white["900"]};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colorTokens.border.subtle}`};
`;

export default Box;
