import { buildColor } from "@tvg/design-system";
import React from "react";
import { SVG, SVGContainer } from "./styled-components";

const TracksLoadingMask = () => (
  <SVGContainer data-qa-label="tracksMask">
    <SVG>
      <rect
        className="filter-section"
        fill={buildColor("blue", "700")}
        height="56"
        width="100%"
        transform="translate(0)"
      />
      <g transform="translate(0 60)">
        <rect fill={buildColor("blue", "000")} height="100%" width="100%" />
        <rect fill={buildColor("white", "100")} height="40" width="100%" />
        <rect
          className="pulse"
          width="158"
          height="8"
          x="8"
          y="16"
          rx="1"
          ry="1"
          fill={buildColor("blue", "000")}
        />
        <rect
          fill={buildColor("white", "100")}
          height="1"
          width="100%"
          y="39"
        />
        <rect
          fill={buildColor("white", "100")}
          height="72"
          width="100%"
          y="40"
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="8"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="108"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="208"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="308"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="408"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="508"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="608"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="708"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="808"
          y="56"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          fill={buildColor("white", "100")}
          height="1"
          width="100%"
          y="111"
        />
        <rect
          fill={buildColor("white", "100")}
          height="1"
          width="100%"
          y="120"
        />
        <rect
          fill={buildColor("white", "100")}
          height="40"
          width="100%"
          y="120"
        />
        <rect
          className="pulse"
          width="94"
          height="8"
          x="8"
          y="136"
          rx="1"
          ry="1"
          fill={buildColor("blue", "000")}
        />
        <rect
          fill={buildColor("white", "100")}
          height="1"
          width="100%"
          y="159"
        />
        <rect
          fill={buildColor("white", "100")}
          height="96"
          width="100%"
          y="160"
        />
        <rect
          className="pulse"
          width="158"
          height="8"
          x="8"
          y="176"
          rx="1"
          ry="1"
          fill={buildColor("blue", "000")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="8"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="108"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="208"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="308"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="408"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="508"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="608"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="708"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          className="stroke"
          width="92"
          height="40"
          x="808"
          y="200"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          fill={buildColor("white", "100")}
          height="1"
          width="100%"
          y="255"
        />
        <rect
          fill={buildColor("white", "100")}
          height="40"
          width="100%"
          y="265"
        />
        <rect
          className="pulse"
          width="118"
          height="8"
          x="8"
          y="281"
          rx="1"
          ry="1"
          fill={buildColor("white", "100")}
        />
        <rect
          fill={buildColor("white", "100")}
          height="1"
          width="100%"
          y="265"
        />
        <rect
          fill={buildColor("white", "100")}
          height="1"
          width="100%"
          y="304"
        />

        <g transform="translate(0 305)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="94"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 346)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="173"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 387)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="94"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 428)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="173"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 469)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="94"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 510)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="134"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 551)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="94"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 592)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="134"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 633)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="94"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>

        <g transform="translate(0 674)">
          <rect fill={buildColor("white", "100")} height="40" width="100%" />
          <rect
            className="pulse"
            width="173"
            height="8"
            x="8"
            y="16"
            rx="1"
            ry="1"
            fill={buildColor("white", "100")}
          />
          <rect
            fill={buildColor("blue", "000")}
            height="1"
            width="100%"
            y="40"
          />
        </g>
      </g>
    </SVG>
  </SVGContainer>
);

export default TracksLoadingMask;
