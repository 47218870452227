import React from "react";
import ToastManager from "@tvg/toast-manager";
import { Container } from "./styled-components";

const WagerpadLayout: React.FC = ({ children }) => (
  <Container>
    {children}
    <ToastManager isLhnVisible={false} />
  </Container>
);

export default WagerpadLayout;
