import React, { useMemo, useState, useCallback } from "react";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import conf from "@tvg/conf";
import { useSelector } from "react-redux";
import { get } from "lodash";
import mediator from "@tvg/mediator";
import {
  Button,
  Icon,
  LinkItem,
  IconNames,
  useIsValidIcon,
  breakpoints
} from "@tvg/design-system";
import { getWagerProfile } from "@urp/store-selectors";
import { ButtonIcon } from "@tvg/design-system/src/components/button/types";
import {
  Container,
  ButtonContainer,
  ContentWrapper
} from "./styled-components";
import AnimatedMenu from "./animatedMenu";
import { SubMenus } from "../types";

export interface DropdownMenuProps {
  title: string;
  route: string;
  target?: string;
  qaLabel: string;
  subMenus: Array<SubMenus>;
  disableClick?: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  title,
  route,
  target,
  qaLabel,
  subMenus,
  disableClick = false
}) => {
  const isValidIcon = useIsValidIcon();
  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const mouseEvents = {
    onMouseEnter: () => setIsMenuVisible(true),
    onMouseLeave: () => setIsMenuVisible(false)
  };
  const userProfile = useSelector(getWagerProfile);
  const buttonIcon = subMenus.length
    ? { icon: isMenuVisible ? "chevronUp" : "chevronDown" }
    : {};

  const handleNavigation = useCallback(
    (targetBlank, url, linkTitle, isParent = false) => {
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "Global Navigation Link Clicked",
          link: linkTitle,
          destinationUrl: url,
          menu: title,
          module: "Global Header"
        }
      });
      if (disableClick && isParent) {
        return false;
      }
      return targetBlank ? window.open(url, "_blank") : navigate(url);
    },
    []
  );

  const menuItems = useMemo(
    () =>
      subMenus &&
      subMenus
        .filter(
          (subMenu) =>
            !get(subMenu, "options.brandsException", []).includes(conf.brand) &&
            !get(subMenu, "options.profileTypeException", []).includes(
              userProfile
            )
        )
        .map((item: SubMenus, index: number) => {
          const newWindow = item.options && item.options.target === "newWindow";
          const iconName = isValidIcon(item.icon) ? item.icon : "info";
          return (
            <li key={`menu-item-${index.toString()}`}>
              <LinkItem
                title={{ render: item.name }}
                description={item.description}
                onPress={() =>
                  handleNavigation(newWindow, item.route, item.name)
                }
                start={
                  <Icon
                    name={iconName as IconNames}
                    lineColor="blue_accent.500"
                    backgroundColor="transparent"
                    size="s"
                    mr="space-4"
                  />
                }
                hasArrowRight={false}
                qaLabel={item.subQaLabel}
              />
            </li>
          );
        }),
    [subMenus]
  );

  return (
    <Container mediaQuery={breakpoints.desktop.max.xl}>
      <ButtonContainer
        data-qa-label="dropdown-button-container"
        {...mouseEvents}
      >
        <Button
          variant={isMenuVisible ? "secondary_dark" : "tertiary_dark"}
          onPress={() =>
            handleNavigation(target === "newWindow", route, title, true)
          }
          qaLabel={qaLabel}
          iconPosition="end"
          {...(buttonIcon as { icon: ButtonIcon })}
        >
          {title}
        </Button>
      </ButtonContainer>
      <AnimatedMenu
        isMenuVisible={isMenuVisible}
        mouseEvents={mouseEvents}
        hasContent={!!subMenus.length}
      >
        <ContentWrapper data-qa-label={`${title}-SubMenuItem`}>
          {menuItems}
        </ContentWrapper>
      </AnimatedMenu>
    </Container>
  );
};

export default DropdownMenu;
