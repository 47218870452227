import React from "react";
import { DialogMessage } from "@tvg/design-system";
import { useSelector } from "react-redux";
import InfoIllustrationIcon from "../../components/InfoIllustrationIcon";
import { Props } from "./types";
import { Container } from "./styled-components";
import { getHorseNotFoundInfo } from "../../redux/selectors";

const HorseNotFound: React.FC<Props> = ({ name }) => {
  const { dialogMessage } = useSelector(getHorseNotFoundInfo);

  return (
    <Container>
      <DialogMessage
        illustration={<InfoIllustrationIcon iconName="horse" />}
        title={dialogMessage.title + ` "${name}"`}
        description={dialogMessage.description}
      />
    </Container>
  );
};

export default HorseNotFound;
