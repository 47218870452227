import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 676px;
  justify-content: center;
  align-items: center;
  background-color: var(--fd-colors-core-white);
`;
