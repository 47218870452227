import styled, { css } from "styled-components";

export const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    background-color: ${theme.colorTokens.background.layer};
    gap: ${theme.space["space-3"]};
  `};
`;
