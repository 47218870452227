import React from "react";
import color from "@fdr/static-ui/ColorPalette";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const RaceTrackDetailsMask = () => (
  <Container>
    <SVG
      width="375"
      height="81"
      viewBox="0 0 375 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_31988_162472)">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H371C373.209 0 375 1.79086 375 4V81H0V4Z"
          fill="white"
        />
        <path
          d="M22 17.75L15.75 24L22 30.25M30.125 24H15.8135"
          stroke="#131314"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <rect
          x="122"
          y="16"
          width="132"
          height="16"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <circle cx="156.5" cy="47" r="8" fill="#0A0A0A" fillOpacity="0.05" />
        <rect
          x="168.5"
          y="41"
          width="58"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <circle cx="353" cy="24" r="8" fill="#0A0A0A" fillOpacity="0.05" />
      </g>
    </SVG>
    <SVG
      width="375"
      height="190"
      viewBox="0 0 375 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="375" height="190" fill="white" />
      <rect
        x="35"
        y="153"
        width="305"
        height="12"
        rx="1"
        fill="#0A0A0A"
        fillOpacity="0.05"
      />
      <rect
        x="103"
        y="27"
        width="172"
        height="96"
        rx="1"
        fill="#0A0A0A"
        fillOpacity="0.05"
      />
    </SVG>
    <SVG
      width="375"
      height="120"
      viewBox="0 0 375 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31985_161028)">
        <rect width="375" height="48" fill="white" />
        <rect
          x="16"
          y="16.5"
          width="166"
          height="15"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          width="375"
          height="72"
          transform="translate(0 48)"
          fill="white"
        />
        <rect x="15.5" y="47.5" width="80" height="65" rx="4.5" fill="white" />
        <rect
          x="15.5"
          y="47.5"
          width="80"
          height="65"
          rx="4.5"
          stroke="#CFD6DB"
        />
        <rect
          x="28"
          y="60"
          width="57"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="28"
          y="76"
          width="21"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect x="102.5" y="47.5" width="80" height="65" rx="4.5" fill="white" />
        <rect
          x="102.5"
          y="47.5"
          width="80"
          height="65"
          rx="4.5"
          stroke="#CFD6DB"
        />
        <rect
          x="115"
          y="60"
          width="57"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="115"
          y="76"
          width="21"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect x="189.5" y="47.5" width="80" height="65" rx="4.5" fill="white" />
        <rect
          x="189.5"
          y="47.5"
          width="80"
          height="65"
          rx="4.5"
          stroke="#CFD6DB"
        />
        <rect
          x="202"
          y="60"
          width="57"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="202"
          y="76"
          width="21"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect x="276.5" y="47.5" width="80" height="65" rx="4.5" fill="white" />
        <rect
          x="276.5"
          y="47.5"
          width="80"
          height="65"
          rx="4.5"
          stroke="#CFD6DB"
        />
        <rect
          x="289"
          y="60"
          width="57"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect
          x="289"
          y="76"
          width="21"
          height="12"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
        />
        <rect x="363.5" y="47.5" width="80" height="65" rx="4.5" fill="white" />
        <rect
          x="363.5"
          y="47.5"
          width="80"
          height="65"
          rx="4.5"
          stroke="#CFD6DB"
        />
      </g>
      <path
        d="M375 119H0V121H375V119Z"
        fill="#EAF0F6"
        mask="url(#path-1-inside-1_31985_161028)"
      />
    </SVG>
    <SVG
      width="375"
      height="187"
      viewBox="0 0 375 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="375" height="187" fill="white" />
      <rect
        className="pulse"
        x="16"
        y="16"
        width="143"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="47"
        width="319"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="63"
        width="343"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="79"
        width="323"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="95"
        width="338"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="111"
        width="312"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="127"
        width="332"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="143"
        width="319"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="159"
        width="343"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
    </SVG>
    <SVG
      width="375"
      height="187"
      viewBox="0 0 375 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="375" height="187" fill="white" />
      <rect
        className="pulse"
        x="16"
        y="16"
        width="143"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="47"
        width="319"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="63"
        width="343"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="79"
        width="323"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="95"
        width="338"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="111"
        width="312"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="127"
        width="332"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="143"
        width="319"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="159"
        width="343"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
    </SVG>
    <SVG
      width="375"
      height="187"
      viewBox="0 0 375 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="375" height="187" fill="white" />
      <rect
        className="pulse"
        x="16"
        y="16"
        width="143"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="47"
        width="319"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="63"
        width="343"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="79"
        width="323"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="95"
        width="338"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="111"
        width="312"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="127"
        width="332"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="143"
        width="319"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        y="159"
        width="343"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
    </SVG>
  </Container>
);

export default RaceTrackDetailsMask;
