import React, { useRef, MutableRefObject, RefObject } from "react";
import useOnClickOutside from "@tvg/design-system/src/hooks/useOnClickOutside";
import { Header } from "@tvg/design-system";
import { config, useTransition, animated } from "@react-spring/web";
import {
  Menu,
  HeaderContainer,
  CardsContainer,
  MenuContainer
} from "./styled-components";
import { Props } from "./types";

const AnimatedMenuContainer = animated(MenuContainer);

const ViewDesktop = ({ isModalOpen, renderContent, onClose, title }: Props) => {
  const ref: MutableRefObject<HTMLDivElement | undefined> = useRef();

  useOnClickOutside(ref as RefObject<HTMLElement>, () => {
    onClose();
  });

  const menuTransition = useTransition(isModalOpen, {
    config: isModalOpen ? { ...config.stiff } : { duration: 150 },
    from: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` },
    enter: { opacity: 1, transform: `translate3d(0px, 0px, 0px)` },
    leave: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` }
  });

  return menuTransition(
    (style, isOpen) =>
      isOpen && (
        <AnimatedMenuContainer
          style={style}
          ref={ref as MutableRefObject<HTMLDivElement>}
          data-qa-label="inbox-cards-menu"
        >
          <Menu>
            <HeaderContainer>
              <Header
                tag="h2"
                qaLabel="braze-card-title"
                fontFamily="bold"
                color="grey.900"
              >
                {title}
              </Header>
            </HeaderContainer>
            <CardsContainer>{renderContent}</CardsContainer>
          </Menu>
        </AnimatedMenuContainer>
      )
  );
};

export default ViewDesktop;
