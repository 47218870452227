import styled, { css } from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontBold, fontNormal } from "@fdr/static-ui/Typography";

export const ListItemContainer = styled.li`
  display: flex;
  padding: 12px 0;
`;

export const IconContainer = styled.div`
  margin-right: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:nth-child(2) {
    flex-direction: column;
    margin: 8px 0 16px;
  }
`;
export const Type = styled.div`
  color: ${buildColor("grey", "900")};
  font-size: 14px;
  font-family: ${fontBold};
  line-height: 17px;
`;
export const Value = styled.div`
  font-size: 14px;
  line-height: 17px;
  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          color: ${buildColor("green", "600")};
          font-family: ${fontBold};
        `
      : css`
          color: ${buildColor("grey", "900")};
          font-family: ${fontNormal};
        `}
`;

export const Description = styled.div`
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: 14px;
`;

export const Details = styled.div`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  ${({ scratched }) =>
    scratched &&
    css`
      text-decoration: line-through;
    `};
`;

export const Date = styled.div`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 12px;
`;
