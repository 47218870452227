import React, { memo } from "react";
import { Container } from "./styled-components";

const SectionHeaderMask = () => (
  <Container>
    <svg
      width="87"
      height="16"
      viewBox="0 0 87 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="pulse"
        x="0.75"
        width="85.5684"
        height="16"
        rx="1"
        fill="#0A0A0A"
        fillOpacity="0.05"
      />
    </svg>
  </Container>
);

export default memo(SectionHeaderMask);
