import { get } from "lodash";
import { HdrStore } from "./types";

export const getIsHamburgerMenuOpen = (store: HdrStore) =>
  get(store, "hdr.isHamburgerMenuOpen");

export const enableURPWatchFDTV = (store: HdrStore) =>
  get(store, "capi.featureToggles.enableURPWatchFDTV");

export const enableURPWagerPad = (store: HdrStore) =>
  get(store, "capi.featureToggles.enableURPWagerPad");
