import React, { MouseEventHandler } from "react";

import { Icon, useTheme } from "@tvg/design-system";
import { SwipeButtonProps } from "./types";
import { BUTTON_WIDTH_DEFAULT } from "./constants";
import { Dismiss } from "./styled-components";

const SwipeButton = ({
  dismissBtnWidth = BUTTON_WIDTH_DEFAULT,
  dismissIconSize = "m",
  dismissIconColor = "white.900",
  dismissBackgroundColor = "red.600",
  onBtnClick,
  iconBtn = "trash"
}: SwipeButtonProps) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    onBtnClick(event);
  };

  const theme = useTheme();

  return (
    <Dismiss
      onClick={handleClick}
      width={dismissBtnWidth}
      theme={theme}
      backgroundColor={dismissBackgroundColor}
    >
      <Icon
        name={iconBtn}
        size={dismissIconSize}
        lineColor={dismissIconColor}
        backgroundColor={dismissBackgroundColor}
      />
    </Dismiss>
  );
};
// $FlowFixMe
export default React.memo(SwipeButton);
export { BUTTON_WIDTH_DEFAULT };
