export const fdrDefaultAccountMenuValues = {
  mainLinks: [
    {
      description: "My Bets",
      iconName: "bets",
      href: "/my-bets",
      qaLabel: "my-bets",
      needTalentRole: false,
      target: ""
    }
  ],
  userAccountLinks: [
    {
      description: "Account Overview",
      iconName: "wallet",
      href: "/account",
      qaLabel: "account-balance",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Transactions before Dec 22, 2022",
      iconName: "accountHistory",
      href: "/accounts",
      qaLabel: "my-account-summary-transactions-before",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Transactions after Dec 22, 2022",
      iconName: "accountHistory",
      href: "/accounts",
      qaLabel: "my-account-summary",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Personal Details",
      iconName: "edit",
      href: "/account/settings",
      qaLabel: "my-profile",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Tax Center",
      iconName: "file",
      href: "/account/tax-center",
      qaLabel: "tax-center",
      needTalentRole: false,
      target: "_blank"
    },
    {
      description: "Activity Statement",
      iconName: "calendar",
      href: "/account/activity-statement",
      qaLabel: "activity-statement",
      needTalentRole: false,
      target: "_blank"
    },
    {
      description: "Responsible Gaming",
      iconName: "lock",
      href: "/responsible-play",
      qaLabel: "responsible-gaming",
      needTalentRole: false,
      target: "_blank"
    },
    {
      description: "Preferences",
      iconName: "settings",
      href: "/preferences",
      qaLabel: "my-preferences",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Send Feedback",
      iconName: "customerSupport",
      href: "mailto:support@tvg.com",
      qaLabel: "support-email",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Talent Picks Tool",
      iconName: "tvgPicks",
      href: "/talent-picks/",
      qaLabel: "talent-picks",
      needTalentRole: true,
      target: "_blank"
    }
  ]
};

export const tvgDefaultAccountMenuValues = {
  mainLinks: [
    {
      description: "My Bets",
      iconName: "bets",
      href: "/my-bets",
      qaLabel: "my-bets",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Favorite Tracks",
      iconName: "star",
      href: "/my-favorite-tracks",
      qaLabel: "my-favorite-track",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Stable",
      iconName: "stable",
      href: "/my-stable",
      qaLabel: "my-stable",
      needTalentRole: false,
      target: ""
    }
  ],
  userAccountLinks: [
    {
      description: "Account Summary",
      iconName: "accountHistory",
      href: "/my-account-summary",
      qaLabel: "my-account-summary",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Personal Details",
      iconName: "edit",
      href: "/my-profile",
      qaLabel: "my-profile",
      needTalentRole: false,
      target: ""
    },
    {
      description: "W2-G Reports",
      iconName: "file",
      href: "/w2-g-reports",
      qaLabel: "w2-g-reports",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Year End Reports",
      iconName: "calendar",
      href: "/year-end-reports",
      qaLabel: "year-end-reports",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Responsible Gaming",
      iconName: "lock",
      href: "/responsible-gaming",
      qaLabel: "responsible-gaming",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Preferences",
      iconName: "settings",
      href: "/preferences",
      qaLabel: "my-preferences",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Send Feedback",
      iconName: "customerSupport",
      href: "mailto:support@tvg.com",
      qaLabel: "support-email",
      needTalentRole: false,
      target: ""
    },
    {
      description: "Talent Picks Tool",
      iconName: "tvgPicks",
      href: "/talent-picks/",
      qaLabel: "talent-picks",
      needTalentRole: true,
      target: "_blank"
    }
  ]
};
