import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { breakpoints, useMediaQuery, WebScrollbar } from "@tvg/design-system";
import { IconButton, secondaryDarkOverride } from "@tvg/design-system/web";
import { useSpring, animated } from "@react-spring/web";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import { getIsHamburgerMenuOpen } from "@tvg/hdr/src/store/selectors";
import { setIsHamburgerMenuOpen as setIsHamburgerMenuOpenAction } from "@tvg/hdr/src/store/actions";
import { getGeolocationStatus } from "@tvg/sh-geolocation/src/redux/selectors";
import { setBetSlipIsExpanded } from "@tvg/pp/src/store/actions";
import { bannerProps } from "@tvg/sh-utils/fixedBanner";
import Races from "./components/Races";
import { LHNProps } from "./types";
import { Nav, GlobalStyle, ArrowButtonContainer } from "./styled-components";

interface EventProps {
  deltaY: number;
  preventDefault: () => void;
}

const AnimatedWrapper = animated(WebScrollbar);
const AnimatedArrow = animated(ArrowButtonContainer);

export const LHN = ({
  isInsideHamburgerMenu = true,
  changeCollapsedMediaQuery = true,
  isHeaderVisible = true,
  isPositionFixed = true,
  showCollapseButton = false,
  onClickRaceEntry,
  setIsLargeContent
}: LHNProps) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const wasHamburgerMenuOpen = useSelector(getIsHamburgerMenuOpen);
  const isDesktopMinXl = useMediaQuery(breakpoints.desktop.min.xl);
  const isDesktopMinXxl = useMediaQuery(breakpoints.desktop.min.xxl);
  const isIntermediumMediaQuery = isDesktopMinXl && !isDesktopMinXxl;
  const geolocationStatus = useSelector(getGeolocationStatus);
  const geolocationBannerMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.geoLocationBannerMsg")
  );
  const accountCompliantBannerMsg = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.accountCompliantBannerMsg")
  );
  const isVerified = useSelector((store) => get(store, "userData.isVerified"));
  const isLogged = useSelector((store) => get(store, "userData.logged"));
  const isTermsAccepted = useSelector((store) =>
    get(store, "userData.jurisdiction.accepted")
  );
  const lhnRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const wrapperTransition = useSpring({
    config: { duration: 200 },
    width: isCollapsed ? "63px" : "292px",
    marginLeft: "7px",
    paddingRight: isCollapsed ? "0px" : "8px"
  });

  const arrowTransition = useSpring({
    config: { duration: 200 },
    rotateY: isCollapsed ? "180deg" : "0"
  });

  const onCollapse = useCallback(() => {
    setIsCollapsed((prevState) => {
      if (!wasHamburgerMenuOpen) dispatch(setBetSlipIsExpanded(!prevState));
      return !prevState;
    });
  }, []);

  const handleHamburgerMenu = useCallback(() => {
    dispatch(setIsHamburgerMenuOpenAction(!wasHamburgerMenuOpen));
  }, [wasHamburgerMenuOpen]);

  useEffect(() => {
    setIsCollapsed(isInsideHamburgerMenu ? false : changeCollapsedMediaQuery);
  }, [changeCollapsedMediaQuery]);

  useEffect(() => {
    dispatch(setBetSlipIsExpanded(isCollapsed || wasHamburgerMenuOpen));
    setIsLargeContent?.(isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    const handleNativeScroll = (nativeScrollEvent: EventProps) => {
      const scroll = scrollRef.current;
      if (!scroll) return;

      const atTop = scroll.scrollTop === 0;
      const atBottom =
        Math.ceil(scroll.scrollTop) + 1 >=
        scroll.scrollHeight - scroll.clientHeight;

      if (
        (nativeScrollEvent.deltaY < 0 && atTop) ||
        (nativeScrollEvent.deltaY > 0 && atBottom)
      ) {
        nativeScrollEvent.preventDefault();
      }
    };

    const LHNnav = lhnRef.current;
    if (LHNnav) {
      LHNnav.addEventListener("wheel", handleNativeScroll, { passive: false });
    }

    return () => {
      if (LHNnav) {
        LHNnav.removeEventListener("wheel", handleNativeScroll);
      }
    };
  }, []);

  const bannerPropsValues = bannerProps(
    dispatch,
    {
      isVerified,
      isLogged,
      isTermsAccepted
    },
    accountCompliantBannerMsg,
    geolocationBannerMsg,
    geolocationStatus
  );

  return (
    <>
      <GlobalStyle />
      <Nav
        mediaQuery={breakpoints.desktop.max.xl}
        isInsideHamburguerMenu={isInsideHamburgerMenu}
        isHeaderVisible={isHeaderVisible}
        isPositionFixed={isPositionFixed}
        data-qa-label="lhn-scroll-container"
        hasFixedBanner={!!bannerPropsValues}
        ref={lhnRef}
      >
        <AnimatedWrapper
          isDark
          scrollOrientation="vertical"
          style={wrapperTransition}
          ref={scrollRef}
        >
          <Races
            isNavCollapsed={isCollapsed}
            onClickRaceEntry={onClickRaceEntry}
            isInsideHamburgerMenu={showCollapseButton}
          />
        </AnimatedWrapper>
        {showCollapseButton && !isInsideHamburgerMenu && (
          <AnimatedArrow isCollapsed={isCollapsed} style={arrowTransition}>
            <IconButton
              overrideColors={secondaryDarkOverride}
              iconName="arrowCollapseLeft"
              qaLabel="collapse-button"
              onClick={
                isIntermediumMediaQuery ? handleHamburgerMenu : onCollapse
              }
            />
          </AnimatedArrow>
        )}
      </Nav>
      {showCollapseButton && isInsideHamburgerMenu && (
        <AnimatedArrow
          isHaburguerMenu={isInsideHamburgerMenu}
          isCollapsed={isCollapsed}
          style={arrowTransition}
        >
          <IconButton
            overrideColors={secondaryDarkOverride}
            iconName="arrowCollapseLeft"
            qaLabel="collapse-button"
            onClick={isIntermediumMediaQuery ? handleHamburgerMenu : onCollapse}
          />
        </AnimatedArrow>
      )}
    </>
  );
};

export default LHN;
