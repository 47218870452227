type PreferenceMetadataEnum = "0" | "1";

interface SetBetConfirmationPreference {
  type: "USER_PREFS_BET_CONFIRMATION";
  payload: PreferenceMetadataEnum;
}

interface iOSTouchIdToggleChangeAction {
  type: "IOS_TOUCH_ID_CHANGE";
  payload: {
    isChanging: boolean;
  };
}

export const setBetConfirmationPreference = (
  isBetConfirmationEnabled: PreferenceMetadataEnum
): SetBetConfirmationPreference => ({
  type: "USER_PREFS_BET_CONFIRMATION",
  payload: isBetConfirmationEnabled
});

export const iOSTouchIdToggleChange = (
  isChanging: boolean
): iOSTouchIdToggleChangeAction => ({
  type: "IOS_TOUCH_ID_CHANGE",
  payload: { isChanging }
});
