import React from "react";
import { useSelector } from "react-redux";
import { Paragraph } from "@tvg/design-system";
import { get } from "lodash";
import IconDescriptive from "./IconDescriptive";
import { Container, IconContainer } from "./styled-components";
import { getPickLabel } from "../../../store/selectors";

export interface DetailsProps {
  showBetSuccess?: boolean;
  isOptedIn?: boolean;
  hasPromo?: boolean;
  pickNumber?: string;
  hasWatchTvg?: boolean;
  hasWatchTvg2?: boolean;
}

const Details = (props: DetailsProps) => {
  const pickLabel = useSelector(getPickLabel);

  const {
    showBetSuccess = false,
    isOptedIn = false,
    hasPromo = false,
    pickNumber = "",
    hasWatchTvg = false,
    hasWatchTvg2 = false
  } = props;

  const detailsLength = Object.keys(props).filter(
    (item) =>
      get(props, `${[item]}`) !== false && get(props, `${[item]}`) !== ""
  ).length;

  if (!detailsLength) {
    return null;
  }

  return (
    <Container>
      {showBetSuccess && (
        <IconDescriptive
          iconName="betsSuccess"
          text={detailsLength >= 4 ? "" : "bets"}
          qaLabel="bet-icon"
        />
      )}
      {isOptedIn && (
        <IconDescriptive
          iconName="optedIn"
          text={detailsLength >= 5 ? "" : "opted in"}
          qaLabel="opted-in-icon"
        />
      )}
      {hasPromo && !isOptedIn && (
        <IconDescriptive
          iconName="promo"
          iconColor="yellow.200"
          text={detailsLength >= 5 ? "" : "promo"}
          qaLabel="promo-icon"
        />
      )}
      {!!pickNumber && (
        <IconContainer data-qa-label="pick-icon-container">
          <Paragraph
            fontFamily="bold"
            fontSize="s"
            qaLabel="pick-icon"
            color="blue.200"
            textTransform="uppercase"
          >
            {`p${pickNumber}`}
          </Paragraph>
          {detailsLength <= 5 && (
            <Paragraph
              fontFamily="condensedRegular"
              fontSize="xs"
              qaLabel="pick-icon-text"
              color="blue.200"
              textTransform="uppercase"
              ml="space-1"
            >
              {`${pickLabel}`}
            </Paragraph>
          )}
        </IconContainer>
      )}
      {(hasWatchTvg || hasWatchTvg2) && (
        <IconDescriptive
          iconName="liveVideo"
          iconColor="blue.200"
          text="FDTV"
          qaLabel="tvg-live-video-icon"
        />
      )}
    </Container>
  );
};

export default Details;
