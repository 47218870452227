import React from "react";
import { MessageWrapper } from "./styled-components";
import { InputMessageProps } from "./types";
import { Paragraph } from "../Typography";
import { Icon } from "../Icon";
import { useColorTokens } from "../../hooks";

export const InputMessage = ({
  message,
  isWarning = false,
  qaLabel = "message",
  ...rest
}: InputMessageProps) => {
  const colorTokens = useColorTokens();
  return isWarning ? (
    <MessageWrapper {...rest} data-qa-label={qaLabel}>
      <Icon
        name="warning"
        size="s"
        lineColor={colorTokens.content.warning}
        backgroundColor={colorTokens.background.warning}
      />
      <Paragraph
        qaLabel="warning-message"
        color={colorTokens.content.warning}
        ml="space-2"
      >
        {message}
      </Paragraph>
    </MessageWrapper>
  ) : (
    <Paragraph qaLabel={qaLabel} color={colorTokens.content.subtle} {...rest}>
      {message}
    </Paragraph>
  );
};
