import React, { memo } from "react";
import Icon from "@fdr/static-ui/Icons";
import { arrowUp } from "@fdr/static-ui/Icons/icons";

import { ToggleButton, IconAnimated } from "./styled-components";

export const ToggleHideShow = memo(
  ({
    labelHide,
    labelShow,
    isOpen,
    onToggle,
    qaLabel = "toggle-hide-show"
  }) => {
    return (
      <ToggleButton onClick={onToggle} data-qa-label={qaLabel}>
        <span>{isOpen ? labelHide : labelShow}</span>
        <IconAnimated isOpen={isOpen}>
          <Icon icon={arrowUp} />
        </IconAnimated>
      </ToggleButton>
    );
  }
);

export default ToggleHideShow;
