import React, { memo } from "react";
import { buildQaLabel } from "@tvg/test-utils/utils";
import TrackLetterBlock from "../TrackLetterBlock";
import { Props } from "./types";
import TracksSection from "../TracksSection";
import { LIST } from "../../utils/constants";

const TracksAZList = ({
  title,
  subTitle,
  groupByLetter,
  onTrackClickHandler,
  onRaceCellClick,
  qaLabel = "",
  isResults = false,
  checkRacePromos,
  tracksWithBets,
  onMyBetsClick
}: Props) => (
  <TracksSection
    title={title}
    subTitle={subTitle}
    qaLabel={buildQaLabel([qaLabel, LIST])}
    isResults={isResults}
  >
    {Object.keys(groupByLetter)
      .sort()
      .map((key) => (
        <TrackLetterBlock
          key={key}
          letter={key}
          isResults={isResults}
          groupedRacesByTrack={groupByLetter[`${key}`]}
          onTrackClickHandler={onTrackClickHandler}
          qaLabel={buildQaLabel([qaLabel, LIST])}
          checkRacePromos={checkRacePromos}
          tracksWithBets={tracksWithBets}
          onMyBetsClick={onMyBetsClick}
          onRaceCellClick={onRaceCellClick}
        />
      ))}
  </TracksSection>
);

export default memo(TracksAZList);
