export type SetIsHamburgerMenuOpen = {
  type: "HAMBURGER_MENU_STATE";
  payload: boolean;
};

export type hdrActions = SetIsHamburgerMenuOpen;

export const setIsHamburgerMenuOpen = (
  isOpen: boolean
): SetIsHamburgerMenuOpen => ({
  type: "HAMBURGER_MENU_STATE",
  payload: isOpen
});
