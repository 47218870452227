import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { SectionHeader, useMediaQuery, breakpoints } from "@tvg/design-system";
import { Carousel } from "@tvg/design-system/web";
import { useUpcomingRaces } from "@urp/lib-racetracks";
import { EmptyMessage } from "@urp/tracks-builder";
import { isFilterApplied } from "@urp/tracks-builder/src/utils";
import { getUpcomingRacesInfo } from "@urp/store-selectors";
import {
  Container,
  ListContainer,
  EmptyMessageContainer
} from "./styled-components";
import { UpcomingRacesProps } from "./types";
import NoUpcomingRaces from "./NoUpcomingRaces";
import renderRacesList from "../RacesList";
import LoadingMask from "../LoadingMask";

const UpcomingRaces = ({
  qaLabel = "upcoming-races",
  filterOptions,
  title,
  noUpcomingRacesMessages
}: UpcomingRacesProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { maxResults, title: carouselTitle } =
    useSelector(getUpcomingRacesInfo);
  const { upcomingRaces, loading } = useUpcomingRaces({
    filterOptions,
    maxResults
  });
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const raceCards = renderRacesList(upcomingRaces, qaLabel);
  const initialVisibleCards = 5;

  useEffect(() => {
    /* This ref change depending if mobile or desktop,
    so when desktop carrusel set the ref when scrolling, so later we can reset to left
    when mobile the another list that is already on this component is assigned to ref */
    const element = ref.current;
    setTimeout(() =>
      element?.scroll({
        left: 0,
        behavior: "smooth"
      })
    );
  }, [JSON.stringify(filterOptions)]);

  const handleOnMountCarousel = (elem: HTMLDivElement) => {
    ref.current = elem;
  };

  if (loading) {
    return <LoadingMask />;
  }

  if (!upcomingRaces?.length) {
    if (!noUpcomingRacesMessages) return null;

    if (isFilterApplied(filterOptions)) {
      return (
        <EmptyMessageContainer>
          <EmptyMessage />
        </EmptyMessageContainer>
      );
    }
    return (
      <NoUpcomingRaces
        title={noUpcomingRacesMessages.title}
        hint={noUpcomingRacesMessages.hint}
      />
    );
  }

  return (
    <Container data-qa-label={qaLabel}>
      {title && <SectionHeader title={title} />}
      {isDesktop ? (
        <Carousel
          items={raceCards}
          numberVisibleCards={initialVisibleCards}
          qaLabel={qaLabel}
          title={carouselTitle}
          onMount={handleOnMountCarousel}
        />
      ) : (
        <ListContainer ref={ref} data-qa-label={`${qaLabel}-list`}>
          {raceCards}
        </ListContainer>
      )}
    </Container>
  );
};

export default UpcomingRaces;
