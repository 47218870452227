import React from "react";
import { get } from "lodash";
import Icon from "@fdr/static-ui/Icons";
import {
  transactionAdjust,
  transactionDeposit,
  transactionBets,
  transactionWithdraw,
  transactionPromo
} from "@fdr/static-ui/Icons/icons";
import buildColor from "@fdr/static-ui/ColorPalette";

import {
  ListItemContainer,
  IconContainer,
  Content,
  ContentRow,
  Type,
  Value,
  Description,
  Details,
  Date
} from "./styled-components";

const TYPE_WAGER_REBATE = "wager rebate";
const TYPE_WITHDRAWAL_CANCELLED = "withdrawal cancelled";
const typesToIgnoreNegativeValues = [TYPE_WITHDRAWAL_CANCELLED];

const scratchedTypes = ["bet refunded", "bet cancelled"];

const typesToHighlight = [
  "bet won",
  "deposit",
  "promotion",
  "positive adjustment",
  TYPE_WITHDRAWAL_CANCELLED,
  TYPE_WAGER_REBATE
];
const positiveValues = [
  ...typesToHighlight,
  "bet refunded",
  "bet cancelled",
  TYPE_WAGER_REBATE
];

const iconFactory = (type) => {
  let formatedType = type.toLowerCase();

  if (formatedType.indexOf("bet") !== -1) {
    formatedType = "bet";
  } else if (formatedType.indexOf("adjustment") !== -1) {
    formatedType = "adjustment";
  }

  const iconTypes = {
    bet: transactionBets,
    deposit: transactionDeposit,
    withdrawal: transactionWithdraw,
    promotion: transactionPromo,
    adjustment: transactionAdjust
  };

  return iconTypes[formatedType] ? iconTypes[formatedType] : transactionBets;
};

const TransactionHistoryListItem = ({ transaction }) => {
  let transactionAmount = get(transaction, "amount", "0").toFixed(2).toString();

  // some types have negative values but actually represent money adding to balance (ex: withdraw cancelled)
  const shouldIgnoreNegative = typesToIgnoreNegativeValues.includes(
    transaction.type.toLowerCase()
  );
  const isNegative = !shouldIgnoreNegative && transactionAmount.includes("-");
  transactionAmount = transactionAmount.replace("-", "");

  return (
    <ListItemContainer>
      <IconContainer>
        <Icon
          icon={iconFactory(transaction.type)}
          size={16}
          color={buildColor("blue", "500")}
        />
      </IconContainer>
      <Content>
        <ContentRow>
          <Type>{transaction.type}</Type>
          <Value
            isHighlighted={
              typesToHighlight.includes(transaction.type.toLowerCase()) &&
              !isNegative
            }
          >
            {positiveValues.includes(transaction.type.toLowerCase()) &&
            !isNegative
              ? `+$`
              : `-$`}
            {transactionAmount}
          </Value>
        </ContentRow>
        <ContentRow>
          <Description>{transaction.description}</Description>
          <Details
            scratched={scratchedTypes.includes(transaction.type.toLowerCase())}
          >
            {transaction.details}
          </Details>
        </ContentRow>
        <ContentRow>
          <Date>{transaction.date}</Date>
        </ContentRow>
      </Content>
    </ListItemContainer>
  );
};

TransactionHistoryListItem.defaultProps = {
  transaction: {
    date: "",
    amount: 0,
    type: "",
    description: "",
    details: ""
  }
};

export default TransactionHistoryListItem;
