import { get } from "lodash";

export const userDataSelector = (store) => get(store, "userData", {});

export const userFirstName = (store) =>
  get(store, "userData.user.firstName", "");

export const userLastName = (store) => get(store, "userData.user.lastName", "");

export const userRoles = (store) =>
  get(store, "userData.user.accountRoles", []);
