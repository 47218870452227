export type SetPageTitleAction = {
  type: "SET_PAGE_TITLE";
  payload: string;
};

export type SetReloadAppAction = {
  type: "SET_RELOAD_APP";
  payload: boolean;
};

export const setPageTitle = (title: string): SetPageTitleAction => ({
  type: "SET_PAGE_TITLE",
  payload: title
});

export const setReloadApp = (reload: boolean): SetReloadAppAction => ({
  type: "SET_RELOAD_APP",
  payload: reload
});
