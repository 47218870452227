import { useEffect, useRef } from "react";
import { isEqual } from "lodash";
import type { NullaryFn } from "@tvg/ts-types/Functional";

export default function useDeepEffect(
  effectFunc: NullaryFn<void>,
  deps: Array<unknown>
): void {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) =>
      isEqual(obj, deps[index])
    );

    if (isFirst.current || !isSame) {
      effectFunc();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, [deps]);
}
