import React, { memo } from "react";
import { Container, RacesContainer } from "./styled-components";

const TrackGroupContent = () => (
  <Container>
    <svg
      width="37"
      height="75"
      viewBox="0 0 37 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_39045_259823)">
        <rect
          x="0.5"
          y="0.796997"
          width="36"
          height="74"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
          className="pulse"
        />
      </g>
      <defs>
        <clipPath id="clip0_39045_259823">
          <rect
            x="0.5"
            y="0.796997"
            width="36"
            height="74"
            rx="1"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>

    <RacesContainer>
      {[...Array(7)].map((val, index) => (
        <svg
          width="86"
          height="75"
          viewBox="0 0 86 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={`${val}-${index.toString()}`}
        >
          <rect
            x="0.25"
            y="0.796997"
            width="85"
            height="74"
            rx="4"
            fill="white"
            className="pulse"
          />
          <rect
            x="0.75"
            y="1.297"
            width="84"
            height="73"
            rx="3.5"
            stroke="#0A0A0A"
            strokeOpacity="0.05"
            className="pulse"
          />
          <rect
            x="12.25"
            y="12.797"
            width="28"
            height="12"
            rx="1"
            fill="#0A0A0A"
            fillOpacity="0.05"
            className="pulse"
          />
          <rect
            x="12.25"
            y="28.797"
            width="60.686"
            height="16"
            rx="1"
            fill="#0A0A0A"
            fillOpacity="0.05"
            className="pulse"
          />
        </svg>
      ))}
    </RacesContainer>

    <svg
      width="37"
      height="75"
      viewBox="0 0 37 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_39045_259824)">
        <rect
          x="0.5"
          y="0.796997"
          width="36"
          height="74"
          rx="1"
          fill="#0A0A0A"
          fillOpacity="0.05"
          className="pulse"
        />
      </g>
      <defs>
        <clipPath id="clip0_39045_259824">
          <rect
            x="0.5"
            y="0.796997"
            width="36"
            height="74"
            rx="1"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </Container>
);

export default memo(TrackGroupContent);
