import React from "react";
import { Modal } from "@tvg/design-system";
import { Props } from "./types";
import { QA_LABELS } from "../../utils/constants";

const ViewMobile = ({ isModalOpen, title, onClose, renderContent }: Props) => (
  <Modal
    isOpen={isModalOpen}
    title={title}
    type="fullscreen"
    onClose={onClose}
    qaLabel={QA_LABELS.modal.container}
    padding={0}
  >
    {renderContent}
  </Modal>
);

export default ViewMobile;
