import { gql } from "@apollo/client";

const VideoFragments = {
  entry: gql`
    fragment Video on Race {
      video {
        hasReplay
        replayFileName
        isStreamHighDefinition
      }
    }
  `
};

export default VideoFragments;
