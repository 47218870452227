import { Dispatch } from "redux";
import { get } from "lodash";
import { NavigateFunction } from "react-router";
import { clearUserFavoriteTracks } from "@tvg/tracks/src/v2/redux/actions";
import {
  updateUserInfo,
  setUserLoggedState,
  resetUserInfo
} from "../store/actions/user";
import { UserData } from "../store/actions/types";

export const updateRouter =
  (navigate: NavigateFunction, todaysTracks: string[]) => () => {
    setTimeout(() => {
      const pathWithParameters = window.location.href.replace(
        window.location.origin,
        ""
      );

      let pathToReplace = pathWithParameters;

      if (
        pathWithParameters.match(/^\/racetracks\/.*\/.*\?race=[0-9]*/) &&
        todaysTracks.length
      ) {
        const pathSplit = pathWithParameters.split("/");
        const searchTrackAbbr = pathSplit[2];
        const searchTrackName = pathSplit[3].substring(
          0,
          pathSplit[3].lastIndexOf("?")
        );
        const searchRaceNumber = get(
          pathWithParameters.match(/race=([^&#]*)/),
          "[1]",
          0
        );
        const trackExistsToday = todaysTracks.find(
          (race) => race === `${searchTrackAbbr}-${searchRaceNumber}`
        );

        if (!trackExistsToday) {
          pathToReplace = `/racetracks/${searchTrackAbbr}/${searchTrackName}`;
        }
      }

      navigate(pathToReplace, { replace: true });
    }, 0);
  };

export const fetchUserData = (dispatch: Dispatch) => (data: UserData) => {
  if (data.logged && data.user) {
    dispatch(updateUserInfo(data.user));
    dispatch(setUserLoggedState(true));
  } else {
    dispatch(setUserLoggedState(false));
    dispatch(resetUserInfo());
    dispatch(clearUserFavoriteTracks());
  }
};
