import { useMemo } from "react";
import { matchRoutes, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import getRouteMessages from "@tvg/sh-lib-capi-messages";
import { useIsomorphicLayoutEffect } from "@tvg/custom-hooks";
import {
  getNamespaces,
  getFeatureToggles
} from "@tvg/sh-lib-capi-messages/redux/selectors";
import { layoutRoutes } from "../routes";
import { KeyRoutes } from "../types";
import { getReloadApp } from "../store/selectors/general";
import { setReloadApp } from "../store/actions/page";

const sendAngularRouteUpdate = (route: string) => {
  if (get(window, "mediator")) {
    setTimeout(() => {
      get(window, "mediator").dispatch("TVG4_NAVIGATION", {
        route
      });
    }, 100);
  }
};

const useRouteInterceptor = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const namespacesStore = useSelector(getNamespaces);
  const togglesStore = useSelector(getFeatureToggles);
  const shouldReloadApp = useSelector(getReloadApp);

  const flatRoutes = useMemo(() => {
    const routes: Record<string, string[]> = {};
    const keyPathArray: KeyRoutes[] = [];

    layoutRoutes.forEach((layout) => {
      layout.routes.forEach((route) => {
        const isRouteAvailable = route.props?.toggle
          ? togglesStore[route.props.toggle]
          : true;
        if (isRouteAvailable) {
          routes[route.key.toLowerCase()] = route.namespaces;
          keyPathArray.push({ path: route.path, key: route.key });
        }
      });
    });

    return { routes, keyPathArray };
  }, []);

  const routeMatched = matchRoutes(flatRoutes.keyPathArray, location);

  useIsomorphicLayoutEffect(() => {
    // Update angular route on each route change
    sendAngularRouteUpdate(`${location.pathname}${location.search || ""}`);

    const routeKey = routeMatched ? routeMatched[0].route.key : "home";
    // Get message for route
    getRouteMessages(
      // @ts-ignore
      routeKey,
      namespacesStore,
      flatRoutes.routes,
      dispatch,
      ["Global", "InformationalPages"]
    );

    if (shouldReloadApp && routeKey === "home") {
      dispatch(setReloadApp(false));
      window.location.reload();
    }
  }, [location.pathname]);
};

export default useRouteInterceptor;
