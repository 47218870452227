import React from "react";
import { Button } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Container } from "./styled-components";
import { Props } from "./types";
import { ACTION, FOOTER } from "../../../utils/constants";

const FiltersDrawerFooter = ({
  onClose,
  onApply,
  onClear,
  qaLabel = "",
  hasFiltersApplied
}: Props) => (
  <Container>
    <Button
      qaLabel={buildQaLabel([qaLabel, FOOTER, ACTION, "apply"])}
      variant="primary"
      onPress={hasFiltersApplied ? onApply : onClose}
      borderRadius={4}
    >
      {hasFiltersApplied ? "Apply" : "Close"}
    </Button>
    <Button
      qaLabel={buildQaLabel([qaLabel, FOOTER, ACTION, "clear"])}
      variant="tertiary"
      onPress={onClear}
      borderRadius={4}
    >
      Clear all filters
    </Button>
  </Container>
);

export default FiltersDrawerFooter;
