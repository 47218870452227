import React from "react";
import { FdIconContent } from "../../types";

export const Deposit = ({ color }: FdIconContent) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.309 6.962C8.248 6.936 8.192 6.899 8.146 6.853L5.147 3.854C4.952 3.659 4.952 3.342 5.147 3.147C5.342 2.952 5.659 2.952 5.854 3.147L8 5.293V0.5C8 0.224 8.224 0 8.5 0C8.776 0 9 0.224 9 0.5V5.293L11.146 3.147C11.341 2.952 11.658 2.952 11.853 3.147C12.048 3.342 12.048 3.659 11.853 3.854L8.854 6.853C8.808 6.9 8.753 6.937 8.691 6.962C8.63 6.987 8.565 7 8.5 7C8.435 7 8.37 6.987 8.309 6.962ZM13.38 16H16.5C16.776 16 17 16.224 17 16.5C17 16.776 16.776 17 16.5 17H0.5C0.224 17 0 16.776 0 16.5C0 16.224 0.224 16 0.5 16H3.62C3.214 15.224 3 14.375 3 13.5C3 10.467 5.467 8 8.5 8C11.533 8 14 10.467 14 13.5C14 14.375 13.785 15.224 13.38 16ZM8.5 9C6.019 9 4 11.019 4 13.5C4 14.391 4.281 15.247 4.794 16H6.5H10.5H12.206C12.719 15.247 13 14.391 13 13.5C13 11.019 10.981 9 8.5 9ZM10.5 16C10.2239 16 10 15.7761 10 15.5C10 15.2239 10.2239 15 10.5 15C10.7761 15 11 15.2239 11 15.5C11 15.7761 10.7761 16 10.5 16ZM6.5 16C6.22386 16 6 15.7761 6 15.5C6 15.2239 6.22386 15 6.5 15C6.77614 15 7 15.2239 7 15.5C7 15.7761 6.77614 16 6.5 16ZM8.5 11C8.77614 11 9 10.7761 9 10.5C9 10.2239 8.77614 10 8.5 10C8.22386 10 8 10.2239 8 10.5C8 10.7761 8.22386 11 8.5 11ZM11 11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5C10 11.2239 10.2239 11 10.5 11C10.7761 11 11 11.2239 11 11.5ZM11.5 14C11.7761 14 12 13.7761 12 13.5C12 13.2239 11.7761 13 11.5 13C11.2239 13 11 13.2239 11 13.5C11 13.7761 11.2239 14 11.5 14ZM7 11.5C7 11.7761 6.77614 12 6.5 12C6.22386 12 6 11.7761 6 11.5C6 11.2239 6.22386 11 6.5 11C6.77614 11 7 11.2239 7 11.5ZM5.5 14C5.77614 14 6 13.7761 6 13.5C6 13.2239 5.77614 13 5.5 13C5.22386 13 5 13.2239 5 13.5C5 13.7761 5.22386 14 5.5 14Z"
    fill={color}
  />
);
