import React from "react";
import { Icon } from "../Icon";
import { LoadingSpinner } from "../LoadingSpinner";
import { SearchBarProps } from "./types";
import { DEFAULT_WARNING_MESSAGES } from "./constants";
import { useSearchBar } from "./useSearchBar";
import WarningPortal from "./components/WarningPortal";
import WarningMessage from "./components/WarningMessage";
import ListItems from "./components/ListItems";
import {
  SearchBarWrapper,
  SearchBarContainer,
  TextInput,
  ListItemsContainer
} from "./styled-components";

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  items,
  searchTerm,
  setSearchTerm,
  minSearchTermLength = 3,
  isLoading = false,
  debounceTime = 300,
  warningMessages = {
    minLength: DEFAULT_WARNING_MESSAGES.minLength,
    notFound: DEFAULT_WARNING_MESSAGES.notFound
  },
  qaLabel,
  handleOnClick
}) => {
  const {
    filteredItems,
    focused,
    hasWarning,
    isOpen,
    wrapperRef,
    listRef,
    warningPosition,
    handleOnChange,
    handleOnFocus,
    handleOnBlur,
    handleItemClick,
    renderWarningMessage,
    activeIndex,
    handleKeyDown
  } = useSearchBar({
    items,
    searchTerm,
    setSearchTerm,
    minSearchTermLength,
    isLoading,
    debounceTime,
    warningMessages,
    handleOnClick
  });

  return (
    <>
      {hasWarning && !isLoading && (
        <WarningPortal position={warningPosition}>
          <WarningMessage
            message={renderWarningMessage()}
            qaLabel={`${qaLabel}-warning`}
          />
        </WarningPortal>
      )}
      <SearchBarWrapper ref={wrapperRef}>
        <SearchBarContainer
          data-qa-label={qaLabel}
          isFocused={focused}
          hasWarning={hasWarning}
        >
          <Icon name="search" size="s" mr="space-4" />
          <TextInput
            type="text"
            data-qa-label="text-input"
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onKeyDown={handleKeyDown}
            role="combobox"
            aria-expanded={isOpen}
            aria-autocomplete="list"
            aria-controls="search-listbox"
            aria-activedescendant={
              activeIndex >= 0 ? `item-${activeIndex}` : undefined
            }
          />
          {isLoading && <LoadingSpinner size="s" ml="space-4" />}
        </SearchBarContainer>
        <ListItemsContainer id="search-listbox" role="listbox">
          {isOpen && !hasWarning && (
            <ListItems
              items={filteredItems}
              searchTerm={searchTerm}
              handleOnClick={handleItemClick}
              activeIndex={activeIndex}
              listRef={listRef}
            />
          )}
        </ListItemsContainer>
      </SearchBarWrapper>
    </>
  );
};
