import React from "react";
import { LabelStyled } from "./styled-components";

type Props = {
  qaLabel?: string;
  children: React.ReactElement;
};

export const Label = ({ qaLabel = "label", children }: Props) => (
  <LabelStyled data-qa-label={qaLabel}>{children}</LabelStyled>
);

export default Label;
