import React, { memo, FC } from "react";
import { useIconSize, useQaLabel } from "../../hooks";
import { StyledSvg } from "./styled-components";
import FlagContent from "./flagContent";
import * as FlagPaths from "./flags";
import { FlagProps, Countries } from "./types";

export const Flag: FC<FlagProps> = ({ size, country, qaLabel, ...props }) => {
  const { iconSize } = useIconSize(size);
  const viewTestProps = useQaLabel(qaLabel || country);
  const Flags = Object.keys(FlagPaths);
  const height = (Number(iconSize) * 20) / 32;

  return Flags.includes(country) ? (
    <StyledSvg
      width={iconSize}
      height={height}
      viewBox="0 0 32 20"
      fill="none"
      // @ts-ignore
      tabIndex="0"
      {...viewTestProps}
      {...props}
    >
      <FlagContent country={country} />
    </StyledSvg>
  ) : null;
};

export { Countries, FlagProps };
export default memo(Flag);
