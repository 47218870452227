import React, { useState } from "react";
import { format } from "date-fns";
import { UnmountClosed } from "react-collapse";

import AccountDetails from "../../_molecules/AccountDetails";
import AccountBalances from "../../_molecules/AccountBalances";

import AccountBalancesSummary from "../../_molecules/AccountBalancesSummary";
import ToggleHideShow from "../../_molecules/ToggleHideShow";

import { Container, LastLogin, ToggleWrapper } from "./styled-components";

export const AccountBalanceDetails = ({
  fullName,
  username,
  balancesSummary,
  balances,
  sessionStartAt,
  isBalanceVisible,
  onVisibilityToggle,
  qaLabel = "account-balance-details"
}) => {
  const [isCollapsableOpen, setCollapsable] = useState(false);

  return (
    <Container isBalanceVisible={isBalanceVisible} data-qa-label={qaLabel}>
      <LastLogin data-qa-label={`${qaLabel}-lastLogin`}>
        Last login:{" "}
        {sessionStartAt && format(sessionStartAt, "MM/dd/yyyy, h:mm:ss a")}
      </LastLogin>
      <AccountDetails
        fullName={fullName}
        username={username}
        sessionStartAt={sessionStartAt}
      />
      <AccountBalancesSummary
        isVisible={isBalanceVisible}
        onToggle={onVisibilityToggle}
        balances={balancesSummary}
      />
      {isBalanceVisible && (
        <div data-qa-label={`${qaLabel}-visible`}>
          <UnmountClosed
            isOpened={isCollapsableOpen}
            initialStyle={{ height: "141px", overflow: "initial" }}
            data-qa-label={`${qaLabel}-collapse`}
          >
            <AccountBalances balances={balances} />
          </UnmountClosed>
          <ToggleWrapper isCollapsed={isCollapsableOpen}>
            <ToggleHideShow
              labelShow="Show details"
              labelHide="Hide details"
              isOpen={isCollapsableOpen}
              onToggle={() => setCollapsable(!isCollapsableOpen)}
            />
          </ToggleWrapper>
        </div>
      )}
    </Container>
  );
};

export default AccountBalanceDetails;
