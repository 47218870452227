import styled, { css } from "styled-components";

const flexCenter = css`
  display: flex;
  align-items: center;
`;

export const Container = styled.ul`
  ${flexCenter};
  margin-top: 4px;
`;

export const IconContainer = styled.li`
  ${flexCenter};

  &:not(:first-child) {
    margin-left: 8px;
  }
`;
