import React, { FC, memo } from "react";
import moment from "moment/moment";
import { Paragraph } from "../../../typography";
import { RaceInfo } from "../raceInfo";
import { Container } from "../../styled-components";
import { MtpCellProps } from "../../types";
import { useQaLabel } from "../../../../hooks";
import { useColorTokens } from "../../../../theming";

export const Mtp: FC<
  Pick<
    MtpCellProps,
    | "align"
    | "qaLabel"
    | "withBackground"
    | "trackCode"
    | "raceNumber"
    | "mtp"
    | "postTime"
  >
> = memo(
  ({
    align,
    qaLabel = "mtp-cell",
    withBackground,
    trackCode,
    raceNumber,
    mtp,
    postTime,
    ...rest
  }) => {
    const RACE_CLOSE_TO_START = 5;
    const qaLabelProps = useQaLabel(qaLabel);
    const raceLabel = `${trackCode} - R${raceNumber}`;
    const label = mtp > 59 ? moment(postTime).format("hh:mm A") : `${mtp}m`;
    const colorTokens = useColorTokens();

    return (
      <Container
        align={align}
        withBackground={withBackground}
        bgColor={
          mtp <= RACE_CLOSE_TO_START
            ? colorTokens.background.negativeSubtle
            : colorTokens.background.infoSubtle
        }
        lineColor={colorTokens.border.default}
        {...qaLabelProps}
        {...rest}
      >
        <Paragraph
          fontFamily={mtp <= RACE_CLOSE_TO_START ? "bold" : "medium"}
          textAlign={align}
          qaLabel={`${qaLabel}-race-status`}
          fontSize="18px"
          lineHeight="22px"
          color={
            mtp <= RACE_CLOSE_TO_START
              ? colorTokens.background.negative
              : colorTokens.content.mtp
          }
        >
          {label}
        </Paragraph>
        <RaceInfo align={align} label={raceLabel} qaLabel={qaLabel} />
      </Container>
    );
  }
);
