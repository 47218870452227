type RaceTracksSearchType = {
  type: "SET_RACE_TRACKS_SEARCH";
  payload: string;
};
type RaceTracksIsSearchActiveType = {
  type: "SET_RACE_TRACKS_IS_SEARCH_ACTIVE";
  payload: boolean;
};
type ClearRaceTracksSearchType = { type: "CLEAR_RACE_TRACKS_SEARCH" };

export type RaceTracksSearchActionTypes =
  | RaceTracksSearchType
  | RaceTracksIsSearchActiveType
  | ClearRaceTracksSearchType;

export const setRaceTracksSearch = (
  searchQuery: string
): RaceTracksSearchType => ({
  type: "SET_RACE_TRACKS_SEARCH",
  payload: searchQuery
});

export const setRaceTracksIsSearchActive = (
  isSearchActive: boolean
): RaceTracksIsSearchActiveType => ({
  type: "SET_RACE_TRACKS_IS_SEARCH_ACTIVE",
  payload: isSearchActive
});

export const clearRaceTracksSearch = (): ClearRaceTracksSearchType => ({
  type: "CLEAR_RACE_TRACKS_SEARCH"
});
