import { ToastActions } from "./actions";
import { ToastMessageStore } from "../types";

export const initialState: ToastMessageStore[] = [];

const toastReducer = (
  state: ToastMessageStore[] = initialState,
  action: ToastActions
) => {
  switch (action.type) {
    case "ADD_TOAST": {
      if (
        state.find(
          (entry) =>
            entry.message === action.payload.toastProps.message &&
            entry.title === action.payload.toastProps.title
        )
      ) {
        return state;
      }
      return [...state, { ...action.payload.toastProps }];
    }
    case "REMOVE_TOAST": {
      return [...state.filter((toast) => toast.id !== action.payload.toastId)];
    }
    case "CLEAR_TOASTS": {
      return [];
    }
    default: {
      return state;
    }
  }
};
export default toastReducer;
