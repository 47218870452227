import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paragraph, Switch } from "@tvg/design-system/web";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { getHideResults } from "@urp/lib-racetracks/src/redux/selectors";
import { toggleHideResults } from "@urp/lib-racetracks/src/redux/actions/races";
import { ToggleHideResultsEnum } from "@urp/lib-racetracks/src/types";
import { SPOILER_TOGGLE } from "../../utils/constants";
import { SpoilerToggleContainer } from "./styled-component";

interface SpoilerToggleProps {
  qaLabel: string;
}

const SpoilerToggle: FC<SpoilerToggleProps> = ({ qaLabel }) => {
  const dispatch = useDispatch();
  const hideResults = useSelector(getHideResults);

  useEffect(() => {
    const hideResultsStatus = localStorage.getItem("hideResults");
    if (hideResultsStatus === ToggleHideResultsEnum.ON) {
      dispatch(toggleHideResults(hideResultsStatus as ToggleHideResultsEnum));
    }
  }, []);

  const handleToggleResults = async () => {
    const hideResultsStatus =
      hideResults === ToggleHideResultsEnum.OFF
        ? ToggleHideResultsEnum.ON
        : ToggleHideResultsEnum.OFF;

    localStorage.setItem("hideResults", hideResultsStatus);
    dispatch(toggleHideResults(hideResultsStatus));
  };

  return (
    <SpoilerToggleContainer>
      <Paragraph
        qaLabel={buildQaLabel([qaLabel, SPOILER_TOGGLE])}
        mr={8}
        whiteSpace="nowrap"
      >
        Hide Races Results (spoiler)
      </Paragraph>
      <Switch
        isOn={hideResults === ToggleHideResultsEnum.ON}
        onPress={handleToggleResults}
        qaLabel={buildQaLabel([qaLabel, "switch"])}
      />
    </SpoilerToggleContainer>
  );
};

export default SpoilerToggle;
