import styled, { css } from "styled-components";
import { space } from "styled-system";
import { breakpoints } from "@tvg/design-system";

export const FilterContainer = styled.section`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  ${({ theme }) => css`
    background-color: ${theme.colorTokens.background.surface};
    padding: ${theme.space["space-5"]};
    gap: ${theme.space["space-2"]};
    border-bottom: 1px solid ${theme.colorTokens.border.default};
  `}
  ${space}

  @media screen and ${breakpoints.tablet.min.sm} {
    justify-content: flex-end;
    gap: ${({ theme }) => theme.space["space-5"]};
  }
`;

export const FilterLabel = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTokens.content.default};
    font-family: ${theme.fonts.condensed};
    font-size: ${theme.fontSizes.xs};
  `}
`;
