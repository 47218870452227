import { useSelector } from "react-redux";
import { getFdUserId } from "@urp/store-selectors";
import useExperiments from "../../useExperiments";
import { DEPLOYMENT_KEY, EXPERIMENT_KEY } from "./constants";
import { VariantEnum, AutoPlayConfig } from "./types";
import { parseResponse } from "./utils";

export const useAutoPlayPPVideos = () => {
  const accountNumber = useSelector(getFdUserId);
  const { setUserExperiment, getVariant } = useExperiments(DEPLOYMENT_KEY, {
    automaticFetchOnAmplitudeIdentityChange: false
  });

  setUserExperiment({ user_id: accountNumber });

  const variant = getVariant<VariantEnum, number>(EXPERIMENT_KEY);

  return parseResponse(variant);
};

export { AutoPlayConfig };
