import React, { useRef, useEffect, useState } from "react";
import useIntersectionObserver from "@tvg/custom-hooks/hooks/useIntersect";
import BannerCard from "../BannerCard";
import ClassicCard from "../ClassicCard";
import SwipeButton, { BUTTON_WIDTH_DEFAULT } from "../SwipeButton";
import { UseSwipeButton } from "../../hooks/useSwipeButton";
import { CARD_SC } from "../../types";
import { Props } from "./types";
import { MS_UNTIL_READ } from "./constants";
import { Item, CardWrapper } from "./styled-components";

const ListItem = ({
  card,
  onViewCard,
  dismissCardClick,
  onClickContentCard,
  showDismiss,
  toggleShowDismiss,
  isDesktop
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const [cardViewed] = useState(card.viewed);
  useEffect(() => {
    let timeout: number | undefined;
    if (entry?.isIntersecting && !card.viewed) {
      timeout = setTimeout(() => {
        onViewCard(card.id);
      }, MS_UNTIL_READ);
    } else {
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [entry?.isIntersecting]);
  const useDisableSwipeBtn = card.dismissible && !isDesktop;
  const handleEvents = useDisableSwipeBtn
    ? UseSwipeButton(ref, showDismiss, toggleShowDismiss, BUTTON_WIDTH_DEFAULT)
    : {};

  return (
    <Item {...handleEvents}>
      {(card.sc === CARD_SC.BANNER && (
        <BannerCard
          ref={ref}
          url={card.url}
          title={card.title}
          imageUrl={card.imageUrl}
          pinned={card.pinned}
          onDismissCard={dismissCardClick}
          dismissible={card.dismissible && !useDisableSwipeBtn}
          onClickCard={onClickContentCard}
        />
      )) ||
        (card.sc === CARD_SC.CAPTIONED_IMG && (
          <CardWrapper ref={ref}>
            <BannerCard
              url={card.url}
              title={card.title}
              imageUrl={card.imageUrl}
              pinned={card.pinned}
              onDismissCard={dismissCardClick}
              dismissible={false}
              onClickCard={onClickContentCard}
            />
            <ClassicCard
              url={card.url}
              description={card.description}
              title={card.title}
              onDismissCard={dismissCardClick}
              dismissible={card.dismissible && !useDisableSwipeBtn}
              imageUrl={card.imageUrl}
              linkText={card.linkText}
              viewed={cardViewed}
              isCaptionedImage
              pinned={card.pinned}
              onClickContentCard={onClickContentCard}
            />
          </CardWrapper>
        )) || (
          <ClassicCard
            ref={ref}
            url={card.url}
            description={card.description}
            title={card.title}
            onDismissCard={dismissCardClick}
            dismissible={card.dismissible && !useDisableSwipeBtn}
            imageUrl={card.imageUrl}
            linkText={card.linkText}
            viewed={cardViewed}
            pinned={card.pinned}
            onClickContentCard={onClickContentCard}
          />
        )}
      {useDisableSwipeBtn && <SwipeButton onBtnClick={dismissCardClick} />}
    </Item>
  );
};

export default ListItem;
