import React, { forwardRef, useRef } from "react";
import type { InputFieldProps } from "./types";
import {
  InputBox,
  StyledInput,
  StyledPressable,
  EndWrapper
} from "./styled-components";

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      type = "text",
      isInvalid = false,
      isDisabled = false,
      isFocused = false,
      maxLength = 250,
      start,
      end,
      qaLabel,
      ...rest
    }: InputFieldProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    return (
      <InputBox isDisabled={isDisabled} {...rest} data-qa-label={qaLabel}>
        {start}
        <StyledPressable
          onFocus={() =>
            (ref && typeof ref !== "function" && ref.current?.focus()) ||
            inputRef?.current?.focus()
          }
        >
          <StyledInput
            ref={ref || inputRef}
            type={type}
            isInvalid={isInvalid}
            disabled={isDisabled}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isNested
            maxLength={maxLength}
            {...rest}
          />
          <EndWrapper>{end}</EndWrapper>
        </StyledPressable>
      </InputBox>
    );
  }
);
