import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { getWagerProfile } from "@urp/store-selectors";
import { PastRaceResults } from "@tvg/ts-types/Race";
import { GET_RESULTED_RACES } from "../graphql/queries/ResultedRacesQuery";
import { TRACKS_FILTER_OPTIONS, TRACKS_POLLER_INTERVAL } from "../constants";

interface TrackRacesVariables {
  trackAbbr: string;
  isEnabled?: boolean;
  sortPostTime?: "ASC" | "DESC";
}

export function useResultedRaces({
  trackAbbr,
  isEnabled = true,
  sortPostTime = "ASC"
}: TrackRacesVariables) {
  const wagerProfile = useSelector(getWagerProfile);

  const { data, loading, error } = useQuery<
    {
      races: Array<PastRaceResults>;
    },
    {
      wagerProfile: string;
      trackAbbr: string;
      sortBy: { byPostTime: "ASC" | "DESC" };
    }
  >(GET_RESULTED_RACES, {
    variables: {
      wagerProfile,
      trackAbbr,
      sortBy: { byPostTime: sortPostTime }
    },
    skip: !isEnabled || trackAbbr === TRACKS_FILTER_OPTIONS.ALL_TRACKS.value,
    fetchPolicy: "cache-and-network",
    pollInterval: TRACKS_POLLER_INTERVAL
  });

  return {
    races: isEnabled ? data?.races || [] : [],
    loading: isEnabled ? loading : false,
    error: isEnabled ? error : undefined
  };
}
