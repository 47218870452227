import styled, { css } from "styled-components";

export const AccountDetailsWrapper = styled.div`
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const textStyles = css`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
`;

export const LabelWrapper = styled.span`
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 125%;
  font-family: "Proxima nova", sans-serif;
  font-stretch: condensed;
`;

export const Spacer = styled.span`
  margin-top: 4px;
`;
