import { TABS } from "../../types";

type SetCurrentTab = {
  type: "SET_CURRENT_TAB";
  payload: {
    currentTab: TABS;
  };
};

export type Actions = SetCurrentTab;

export const setCurrentTab = (currentTab: TABS | TABS): SetCurrentTab => ({
  type: "SET_CURRENT_TAB",
  payload: { currentTab }
});
