import { BrazeInboxAction, BrazeInboxEvent } from "./types";

export const openInboxModal = (): BrazeInboxAction => ({
  type: BrazeInboxEvent.OPEN,
  payload: true
});

export const closeInboxModal = (): BrazeInboxAction => ({
  type: BrazeInboxEvent.CLOSE,
  payload: false
});
