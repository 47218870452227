import React from "react";
import TracksSection from "../TracksSection";
import { Props } from "./types";
import TracksInformationLocation from "../TracksInformationLocation";

const TracksInformationSection = ({
  dictionary,
  title,
  onTrackClick,
  isFavorite,
  toggleFavorite
}: Props) => {
  const vec = Object.keys(dictionary);

  if (!vec.length) return null;

  return (
    <TracksSection title={title}>
      {vec.sort().map((subtitle) => (
        <TracksInformationLocation
          key={subtitle}
          dictionary={dictionary}
          title={subtitle}
          onTrackClick={onTrackClick}
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
        />
      ))}
    </TracksSection>
  );
};

export default TracksInformationSection;
