import { BrazeContentCard } from "@tvg/braze/src/types";
import mediator from "@tvg/mediator";

export const sendEventBrazeCardImpression = (
  viewedCard: BrazeContentCard,
  position: number
) =>
  mediator.base.dispatch({
    type: "BRAZE:CARD_IMPRESSION",
    payload: {
      id: viewedCard.id,
      position: position + 1,
      pinned: viewedCard.pinned
    }
  });

export const sendEventBrazeClickCard = (
  card: BrazeContentCard,
  index: number
) =>
  mediator.base.dispatch({
    type: "BRAZE:CLICK_CARD",
    payload: {
      id: card.id,
      position: index + 1,
      pinned: card.pinned
    }
  });

export const sendEventBrazeDismissCard = (
  card: BrazeContentCard,
  index: number
) =>
  mediator.base.dispatch({
    type: "BRAZE:DISMISS_CARD",
    payload: {
      id: card.id,
      position: index + 1,
      pinned: card.pinned
    }
  });

export const sendEventMenuClick = () =>
  mediator.base.dispatch({
    type: "NAVIGATION:MENU_CLICK",
    payload: {
      action: "Inbox Opened",
      module: "Global Header"
    }
  });
