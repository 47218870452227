import styled, { css } from "styled-components";

export const AccountMenuHeader = styled.div`
  background: linear-gradient(125deg, #005fc8 0%, #003d81 100%);
  margin-bottom: 16px;
`;

// grey 100 is not in ds
export const AccountSection = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.base};
`;

export const ActionsWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:first-of-type {
    margin-right: 24px;
  }
`;

// Colors are manual until moved to DS
// #0070EB - component/button/secondary
// #128000 - component/button/primary
export const IconWrapper = styled.div<{ fdBackground: boolean }>`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: ${({ fdBackground }) => (fdBackground ? "#0070EB" : "#128000")};
`;

export const IconButtonDescription = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 8px;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  font-family: "Proxima nova", sans-serif;
  font-stretch: condensed;
`;

export const LinksSection = styled.div<{ withoutMargin?: boolean }>`
  margin-bottom: 16px;
  ${({ withoutMargin }) => withoutMargin && "margin-bottom: 0;"}
`;

export const AccountLinkGroup = styled.ul<{ device: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.secondaryDark.content.base};
  ${({ theme, device }) =>
    device === "tablet"
      ? css`
          border: 1px solid ${theme.colorTokens.component.input.base};
          border-radius: 2px;
          overflow: hidden;
        `
      : css`
          border-top: 1px solid ${theme.colorTokens.component.input.base};
          border-bottom: 1px solid ${theme.colorTokens.component.input.base};
        `};

  & > li,
  & > button {
    height: 44px;
    list-style-type: none;
    align-items: center;
    position: relative;
    padding: 0 16px;
    background-color: ${({ theme }) =>
      theme.colorTokens.component.button.secondaryDark.content.base};

    &:active {
      background-color: ${({ theme }) => theme.colors.grey["100"]};
    }

    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: calc(100% - 16px);
        bottom: 0;
        left: 16px;
        background: ${({ theme }) => theme.colorTokens.component.input.base};
      }
    }
  }
`;

export const HeaderText = styled.div`
  height: 20px;
  margin: 12px 16px;
  color: ${({ theme }) => theme.colorTokens.background.secondary};
  font-size: 16px;
  font-weight: bold;
  line-height: 125%;
`;

export const LinkText = styled.span`
  font-size: 14px;
  color: blue;
  line-height: 1;
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

export const AccountLinkRow = styled.div<{ isDanger?: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;

  > svg {
    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      margin-left: auto;
      margin-right: 5px;
    }
  }

  cursor: pointer;
  justify-content: space-between;
  height: 48px;
  color: ${({ isDanger, theme }) =>
    isDanger ? theme.colorTokens.background.negative : "inherit"};

  ${LinkText} {
    color: ${({ isDanger, theme }) =>
      isDanger
        ? theme.colorTokens.background.negative
        : theme.colorTokens.component.button.buttonLink.content.base};
  }
`;

export const Footer = styled.div`
  padding: 16px 0 32px;
`;
