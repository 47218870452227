import React from "react";
import { Path } from "react-native-svg";

export const Germany: React.FC = () => (
  <>
    <Path d="M32 0H0V6.62337H32V0Z" fill="black" />
    <Path d="M32 6.62338H0V13.3117H32V6.62338Z" fill="#DE3131" />
    <Path d="M32 13.3117H0V20H32V13.3117Z" fill="#FFC642" />
  </>
);

export default Germany;
