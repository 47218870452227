import styled from "styled-components";
import { Header, Paragraph } from "@tvg/design-system/web/";

export const Block = styled.section`
  width: 100%;
  position: relative;
  background-color: var(--fd-colors-background-surface);

  :empty {
    display: none;
  }
`;

export const BodyHeader = styled(Block)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--fd-colors-border-subtle);
  padding: var(--fd-space-space-5);
`;

export const RaceResultsContainer = styled.div`
  background-color: var(--fd-colors-background-surface);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: var(--fd-space-space-4);
  padding: var(--fd-space-space-4);
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--fd-space-space-1);
`;

export const StyledHeader = styled(Header)`
  color: var(--fd-colors-content-strong);
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: var(--fd-space-space05, 2px);
  color: var(--fd-colors-content-subtle);
`;

export const StyledSummary = styled(Header)`
  color: var(--fd-colors-content-strong);
  font-size: var(--fd-font-sizes-font-size-040);
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--fd-colors-background-surface);
`;
