import styled from "styled-components";
// @ts-ignore
import { fontNormal, fontBold, defaultSize } from "@fdr/static-ui/Typography";
// @ts-ignore
import buildColor from "@fdr/static-ui/ColorPalette";

export const MessageStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${buildColor("grey", "D4")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  line-height: 20px;
  text-align: center;
  padding-bottom: 26px;
`;

export const MessageContainer = styled.div`
  flex-direction: row;
  padding: 8px 8px 0 8px;
`;

export const FinalMessageContainer = styled.a`
  font-family: ${fontBold};
  color: ${buildColor("grey", "D4")};
  vertical-align: baseline;
`;
