export const OPEN_PLAYABLE_BALANCE_MODAL = "OPEN_PLAYABLE_BALANCE_MODAL";
export const CLOSE_PLAYABLE_BALANCE_MODAL = "CLOSE_PLAYABLE_BALANCE_MODAL";

export interface PlayableBalanceOpenModalAction {
  type: "OPEN_PLAYABLE_BALANCE_MODAL";
}

export interface PlayableBalanceCloseModalAction {
  type: "CLOSE_PLAYABLE_BALANCE_MODAL";
}

export type PlayableBalanceModalActions =
  | PlayableBalanceCloseModalAction
  | PlayableBalanceOpenModalAction;
