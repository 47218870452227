import { get } from "lodash";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { Store } from "redux";
import { JoinBarConfigurationMessage } from "./types";
import { defaultJoinBarConfiguration } from "./defaultValues";

export const getIsPromosEnabled = (store: Store) =>
  get(store, "capi.featureToggles.isPromosEnabled", false);

export const getNoLoginRequired = (store: Store) =>
  get(store, "capi.featureToggles.enableNoLoginRequiredForAccountPage", false);

export const getBalanceShown = (store: Store) =>
  get(store, "userData.preferences.balance_is_shown", "0");

export const getJoinBarConfiguration = (
  store: Store
): JoinBarConfigurationMessage =>
  parseCAPIMessage(
    store,
    "capi.messages.joinBarConfiguration",
    defaultJoinBarConfiguration
  );
