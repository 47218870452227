import axios from "axios";
import tvgConf from "@tvg/conf";
import DomMutator from "./dom-mutator";

const { NODE_ENV = "production" } = process.env;
const isDev = NODE_ENV === "development";

const conf = tvgConf();

const { gcs } = conf.config("google");
const { environment } = conf;

interface EntriesType {
  html: string;
  js: string[];
  css: string[];
  baseFlag: string;
}

const max_retries = 5;
const retry_time = 500;

const instance = axios.create({
  headers: {
    "x-clientapp": "tvg4-rde"
  },
  withCredentials: true
});

class App {
  name: string;

  url: string;

  entries: EntriesType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domMutator: any;

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
    this.entries = {
      html: "",
      js: [],
      css: [],
      baseFlag: ""
    };
    this.domMutator = new DomMutator();
  }

  downloadChunk = async (chunk: string) => {
    let content = "";
    if (chunk) content = await instance.get(chunk);
    return content;
  };

  getAssetUrl = (el: string) =>
    (environment === "staging" || environment === "production") && !isDev
      ? `${gcs.url}/${gcs.tvgStatic}/static/${el
          .slice(1, el.length)
          .replace("tvg4", "tvg4-rde")}`
      : `https://${tvgConf().config("/")?.domain?.desktop}/${el.slice(
          1,
          el.length
        )}`;

  fetchAppEntries = async (
    setIsTvg4Ready: (arg: boolean) => void,
    retry = 0
  ) => {
    try {
      const { data } = await instance.get(this.url);
      this.entries.html = data.html;
      this.entries.js = [...data.js].map((el) => this.getAssetUrl(el));
      this.entries.css = [...data.css].map((el) => this.getAssetUrl(el));
      this.entries.baseFlag = `window[hasLoaded${this.name.toUpperCase()}] = false;`;

      setIsTvg4Ready(true);
    } catch (e) {
      if (retry < max_retries) {
        setTimeout(() => {
          this.fetchAppEntries(setIsTvg4Ready, retry + 1);
        }, retry_time * retry);
      }
      console.log("Error on lazy load of tvg4", e);
      setIsTvg4Ready(false);
    }
  };

  toString = () => `
    App:
      -name: ${this.name}
      -url: ${this.url}
      -entries: ${JSON.stringify(this.entries, null, 2)}
    `;
}

export default App;
