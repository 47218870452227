import React, { memo } from "react";
import { Container } from "./styled-components";

const TrackRowTitle = () => (
  <Container>
    <svg
      width="111"
      height="20"
      viewBox="0 0 111 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_39045_259783)">
        <rect
          x="0.5"
          width="20"
          height="20"
          rx="10"
          fill="#0A0A0A"
          fillOpacity="0.05"
          className="pulse"
        />
      </g>
      <rect
        x="28.5"
        y="4"
        width="81.8125"
        height="12"
        rx="1"
        fill="#0A0A0A"
        fillOpacity="0.05"
        className="pulse"
      />
      <defs>
        <clipPath id="clip0_39045_259783">
          <rect x="0.5" width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>

    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.808594"
        y="0.583252"
        width="17.9414"
        height="16.8336"
        rx="1"
        fill="#0A0A0A"
        fillOpacity="0.05"
        className="pulse"
      />
    </svg>
  </Container>
);

export default memo(TrackRowTitle);
