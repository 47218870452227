import { gql } from "@apollo/client";

export const WAGERS_HISTORY_BY_RACE = gql`
  query GetGroupWagersMyBets(
    $accountId: Int!
    $startDate: String
    $endDate: String
    $statusList: [Status!]
    $trackCode: String!
  ) {
    wagerHistory(
      accountId: $accountId
      beginDate: $startDate
      endDate: $endDate
      statusList: $statusList
      trackCode: $trackCode
    ) {
      __typename
      wagers {
        raceNumber
        trackCode
      }
    }
  }
`;
