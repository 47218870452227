import { get } from "lodash";
import { Store } from "@reduxjs/toolkit";

export const getHasRequestedLogin = (store: Store) =>
  get(store, "userData.hasRequested", false);

export const getUsePromosWidgetDSToggle = (store: Store) =>
  get(store, "capi.featureToggles.usePromosWidgetDS", false);

export const getPromosWidgetTitle = (store: Store) =>
  get(store, "capi.messages.promosWidgetTitle", "Promotions Calendar");
