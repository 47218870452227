import { usePrevious } from "@tvg/custom-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql, useQuery } from "@apollo/client";
import { getIsAWLoginModalOpen } from "@tvg/sh-lib-account-actions/src/selectors/modal";
import mtpStatusAction from "@tvg/mtp-update/src/actions";
import { isFdr } from "@tvg/pp/src/utils/general";
import { getIsLogged } from "@urp/store-selectors";
import { getDateRace, getMtpStatus } from "../store/selectors/general";

const useRefreshApp = () => {
  const raceDate = useSelector(getDateRace);
  const mtpStatus = useSelector(getMtpStatus);
  const previous = usePrevious(raceDate);
  const isLogged = useSelector(getIsLogged);
  const isAWLoginModalOpen = useSelector(getIsAWLoginModalOpen);
  const isFDR = isFdr();
  const dispatch = useDispatch();

  const { data } = useQuery(
    gql`
      query getRaceDate {
        raceDate
      }
    `,
    {
      pollInterval: 300000,
      fetchPolicy: "network-only",
      ssr: false
    }
  );

  // For now the only case is when the user logs in via wagerpad
  // and we need the modal to close via refresh
  useEffect(() => {
    if (isLogged && isAWLoginModalOpen && isFDR) {
      window.location.reload();
    }
  }, [isLogged, isAWLoginModalOpen, isFDR]);

  useEffect(() => {
    if (data?.raceDate && raceDate !== data.raceDate) {
      dispatch(mtpStatusAction(mtpStatus, data.raceDate));
    }
  }, [data?.raceDate]);

  useEffect(() => {
    if (raceDate && previous && previous !== raceDate) {
      window.location.reload();
    }
  }, [raceDate, previous]);
};

export default useRefreshApp;
