import React from "react";
import { BRAND_WIDTH, LOGO_PATH } from "./types";
import type { LogoProps } from "./types";
import { StyledSVG } from "./styled-components";
import * as LogoPaths from "./components/LogoPaths";

export const Logo = ({
  accessibilityLabel = "Home page",
  qaLabel = "logo-label",
  brand = "tvg",
  height = 44,
  ...props
}) => {
  const dynamicWidth =
    (height * BRAND_WIDTH[brand as keyof typeof BRAND_WIDTH]) / 44;
  const LogoPath = LogoPaths[LOGO_PATH[brand as keyof typeof LOGO_PATH]];

  return (
    <StyledSVG
      {...props}
      data-qa-label={qaLabel}
      width={dynamicWidth}
      height={height}
      viewBox={`0 0 ${BRAND_WIDTH[brand as keyof typeof BRAND_WIDTH]} 44`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={accessibilityLabel}
      role="img"
    >
      <LogoPath />
    </StyledSVG>
  );
};

export type { LogoProps };
