import React from "react";
import color from "@fdr/static-ui/ColorPalette";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const FavoriteTracksMask = () => (
  <Container>
    <SVG
      data-qa-label="favoriteTracks-loadingMask"
      width="100%"
      height="96"
      viewBox="0 0 100% 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100%" height="96" fill="white" />
      <rect width="100%" height="48" fill="white" />
      <rect
        className="pulse"
        x="16"
        y="16"
        width="166"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <mask id="path-2-inside-1_31980_141052" fill="white">
        <path d="M0 48H375V96H0V48Z" />
      </mask>
      <path d="M0 48H375V96H0V48Z" fill="white" />
      <path
        d="M375 95H0V97H375V95Z"
        fill="#EAF0F6"
        mask="url(#path-2-inside-1_31980_141052)"
      />
      <rect
        className="pulse"
        x="16"
        y="64"
        width="16"
        height="16"
        rx="8"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="40"
        y="64.5"
        width="166"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="95%"
        y="66"
        width="14"
        height="12"
        rx="1"
        fill="#0A0A0A"
      />
    </SVG>
  </Container>
);

export default FavoriteTracksMask;
