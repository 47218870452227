import styled, { createGlobalStyle, css } from "styled-components";
import { breakpoints, Theme } from "@tvg/design-system";

interface Props {
  isFullPageLayout: boolean;
  hasFixedBanner?: boolean;
}

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }: { theme: Theme }) =>
      theme.colorTokens.background.secondary};
  }
`;

export const Container = styled.div<Props>`
  width: 100%;
  ${({ isFullPageLayout }) =>
    !isFullPageLayout &&
    css`
      max-width: 1440px;
      margin: 0 auto;
    `}
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Main = styled.main<Props>`
  max-width: 100vw;
  ${flexColumn};
  ${({ isFullPageLayout, hasFixedBanner }) =>
    !isFullPageLayout &&
    css`
      margin-top: ${hasFixedBanner ? "14px" : `var(--hdr-height, 76px)`};
      /* stylelint-disable media-query-no-invalid */
      @media screen and ${breakpoints.desktop.min.md} {
        padding: 0 16px;
      }
    `};

  @media print {
    max-width: 100%;
  }
`;

interface ContentContainerStylesProps {
  isFullPageLayout: boolean;
  isLHNVisible: boolean;
  isLargeContent: boolean;
}

const contentContainerStyles = ({
  isFullPageLayout,
  isLHNVisible,
  isLargeContent
}: ContentContainerStylesProps) => {
  const smallScreenPadding = isLargeContent
    ? "calc(var(--lhn-collapsed-width, 48px) + 30px)"
    : "calc(var(--lhn-width, 292px) + 15px)";

  const paddingLeft =
    isFullPageLayout || !isLHNVisible ? 0 : smallScreenPadding;

  return css`
    @media screen and ${breakpoints.desktop.min.xl} {
      padding-left: ${paddingLeft};
    }
  `;
};

interface ContentContainerProps extends ContentContainerStylesProps {
  hasMinWidth: boolean;
  isHeaderVisible: boolean;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  ${flexColumn};
  width: 100%;
  min-width: ${({ hasMinWidth }) => (hasMinWidth ? "1024px" : "initial")};
  min-height: ${({ isHeaderVisible }) =>
    isHeaderVisible ? "calc(100vh - var(--hdr-height, 76px))" : "100vh"};

  ${contentContainerStyles};

  @media print {
    padding: 0;
    min-width: 100%;
  }
`;

export const TVG4Content = styled.div<{ showTVG4Content: boolean }>`
  width: 100%;
  min-width: 1024px;
  min-height: 768px;

  /* Hide tvg4 on routes that it's not needed */
  ${({ showTVG4Content }) =>
    !showTVG4Content &&
    css`
      display: none;
    `};

  @media print {
    min-width: 100%;
  }
`;
