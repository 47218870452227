import mediator from "@tvg/mediator";

export const lhnNavigationGtmEvent = ({
  trackName,
  mtp,
  destinationUrl,
  isFeatureRace,
  isInsideHamburgerMenu
}: {
  trackName: string;
  mtp: number;
  destinationUrl: string;
  isFeatureRace: boolean;
  isInsideHamburgerMenu: boolean;
}) => {
  mediator.base.dispatch({
    type: "NAVIGATION:MENU_CLICK",
    payload: {
      action: "LHN Navigation Link Clicked",
      link: `${trackName} - ${mtp}`,
      destinationUrl,
      menu: isFeatureRace ? "Your Featured Races" : "Scheduled Races",
      module: isInsideHamburgerMenu ? "Hamburger" : "LHN"
    }
  });
};
