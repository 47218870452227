import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import MyAccountButton from "@tvg/my-account-button";
import { InboxButton } from "@urp/braze-inbox";
// @ts-ignore
import { getIsLogged } from "@urp/store-selectors";
import { IconButton } from "@tvg/design-system/web";

import {
  IconButtonListWrapper,
  IconList,
  SupportHyperlink
} from "./styled-components";

const IconButtonList = () => {
  const isLogged = useSelector(getIsLogged);
  const supportLink = useSelector((store) =>
    get(store, "capi.messages.globalSupportLink", "https://support.tvg.com")
  );

  return useMemo(
    () => (
      <IconButtonListWrapper>
        {isLogged && (
          <>
            <IconList>
              <InboxButton />
            </IconList>
            <IconList>
              <MyAccountButton />
            </IconList>
          </>
        )}
        <IconList>
          <SupportHyperlink data-qa-label="link-button">
            <IconButton
              mode="dark"
              iconName="support"
              qaLabel="support-button"
              onClick={() => {
                window.open(supportLink, "_blank", "noopener noreferrer");
              }}
              textTooltip="Help"
              size="m"
              mx={16}
            />
          </SupportHyperlink>
        </IconList>
      </IconButtonListWrapper>
    ),
    [isLogged]
  );
};

export default IconButtonList;
