import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const EmptyContainer = styled.div`
  ${css`
    @media screen and ${breakpoints.desktop.min.sm} {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: ${({ theme }) => theme.colorTokens.background.surface};
    }
  `}
`;
