import mediator from "@tvg/mediator";

export const onLogin = (payload?: Record<string, unknown>) =>
  mediator.base.dispatch({
    type: "OPEN_LOGIN",
    payload: {
      module: "join_bar",
      ...payload
    }
  });

export const onSignUp = (payload?: Record<string, unknown>) =>
  mediator.base.dispatch({
    type: "OPEN_SIGNUP",
    payload: {
      module: "join_bar",
      ...payload
    }
  });
