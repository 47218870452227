import React from "react";
import { Loading, useColorTokens, ColorTokens } from "@tvg/design-system";
import { LoadingContainer } from "./styled-components";

const LoadingPage = (props: { id: string }) => {
  const { background, content } = useColorTokens() as ColorTokens;

  return (
    <LoadingContainer id={props.id}>
      <Loading
        size="xl"
        color={content.onDark}
        bgColor={background.secondary}
      />
      <p>...Loading</p>
    </LoadingContainer>
  );
};

export default LoadingPage;
