import React from "react";
import { DropdownSelectionProps } from "./types";
import { useQaLabel } from "../../../../../hooks/useQaLabel";
import { useEvents } from "../../../../../hooks/useEvents";
import { useColorTokens } from "../../../../../theming";
import { BetTypeText, BetTypeTouchable } from "./styled-components";

export const DropdownSelection: React.FC<DropdownSelectionProps> = ({
  onSelectValue,
  item,
  selectedValue,
  isParentHovered,
  qaLabel
}) => {
  const viewTestProps = useQaLabel(`${qaLabel}-${item.name}`);
  const { events, eventsHandlers, hoverHandlers } = useEvents({});
  const colorTokens = useColorTokens();

  return (
    // @ts-ignore
    <BetTypeTouchable
      onPress={() => onSelectValue(item.id)}
      onLongPress={() => onSelectValue(item.id)}
      underlayColor={colorTokens.component.input.pressed}
      {...eventsHandlers}
      {...events}
      {...hoverHandlers}
      isSelected={item.id === selectedValue}
      isParentHovered={isParentHovered}
      {...viewTestProps}
    >
      <BetTypeText
        {...events}
        isSelected={item.id === selectedValue}
        isParentHovered={isParentHovered}
      >
        {item.name.substring(item.name.indexOf(" ") + 1)}
      </BetTypeText>
    </BetTypeTouchable>
  );
};
