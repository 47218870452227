import { useSelector } from "react-redux";

import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { getBrazeContentCards, isBrazeModalOpen } from "../../redux/selectors";
import { filterBrazeCards, getNonViewedCards } from "./utils";
import { sendEventMenuClick } from "../../utils/amplitude";

const useInboxCards = () => {
  const isDesktop = useMediaQuery(breakpoints.desktop.min.lg);
  const isOpen = useSelector(isBrazeModalOpen);
  const brazeContentCards = useSelector(getBrazeContentCards);

  const listOfBrazeCards = filterBrazeCards(brazeContentCards);
  const numberOfUnreadCards = getNonViewedCards(listOfBrazeCards).length;

  return {
    listOfBrazeCards,
    numberOfUnreadCards,
    sendEventMenuClick,
    isOpen,
    isDesktop
  };
};

export default useInboxCards;
