import styled from "styled-components";
import Box from "../../containers/Box";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 27px;
`;

export const Avatar = styled.div`
  padding: 30.5px 17px;
  border-radius: 100px;
  background-color: ${({ theme }) =>
    theme.colorTokens.background.neutralSubtle};
  height: 96px;
  width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  max-width: 184px;
  width: 100%;
`;
