import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInMonths, parse } from "date-fns";
import mediator from "@tvg/mediator";
import { getIsLogged } from "@urp/store-selectors";
import { useGetRacesWithBetsByTrack } from "@urp/lib-racetracks/src/hooks/useGetRacesWithBetsByTrack";
import { TRACKS_FILTER_OPTIONS } from "@urp/lib-racetracks/src/constants";
import { toggleOnlyFavorites } from "@urp/lib-racetracks/src/redux/actions/tracks";
import { getOnlyFavoritesTracks } from "@urp/lib-racetracks/src/redux/selectors";
import { openMybetsPastPerformance } from "@tvg/sh-lib-my-bets/redux/actions";
import { MyBetsPastPerformanceTab } from "@tvg/sh-lib-my-bets/utils/types";
import { Checkbox } from "@tvg/design-system/web";
import { PastResultsSearchOptionKey as SearchOptionKey } from "../../types";
import SearchOptions from "../SearchOptions";
import ResultsSearchForm from "../ResultsSearchForm";
import PastResultsDateSearch from "../PastResultsDateSearch";
import PastResultsTrackSearch from "../PastResultsTrackSearch";
import PastResultsHorseSearch from "../PastResultsHorseSearch";
import {
  pastResultsInitialConfig as initialConfig,
  INTERNAL_DATE_FORMAT
} from "../../constants";
import { FieldConfig } from "../ResultsSearchForm/types";
import {
  Container,
  ContainerHeader,
  Body,
  SectionContainer,
  SearchOptionsContainer,
  CheckboxContainer
} from "./styled-components";
import { MyBetsClickProps } from "./types";

interface PastResultsProps {
  qaLabel?: string;
}

const PastResults: FC<PastResultsProps> = ({ qaLabel = "tracksResults" }) => {
  const isLogged = useSelector(getIsLogged);
  const onlyFavorites = useSelector(getOnlyFavoritesTracks) && isLogged;
  const dispatch = useDispatch();
  const [searchOption, setSearchOption] = useState<SearchOptionKey>(
    SearchOptionKey.DATE
  );
  const [fieldsConfig, setFieldsConfig] = useState<FieldConfig[]>([]);

  const { fetchRacesWithBets, races: racesWithBets } =
    useGetRacesWithBetsByTrack();

  useEffect(() => {
    const dateFilter = fieldsConfig.find(({ key }) => key === "date")?.value;
    const trackCode = fieldsConfig.find(({ key }) => key === "track")?.value;

    const parsedDate = parse(
      dateFilter as string,
      INTERNAL_DATE_FORMAT,
      new Date()
    );
    const isDateOlderThan17Months =
      differenceInMonths(new Date(), parsedDate) > 17;

    if (
      trackCode === TRACKS_FILTER_OPTIONS.ALL_TRACKS.value ||
      isDateOlderThan17Months
    )
      return;

    if (isLogged && dateFilter && trackCode) {
      fetchRacesWithBets({
        trackCode: trackCode as string,
        endDate: dateFilter as string,
        startDate: dateFilter as string
      });
    }
  }, [fieldsConfig, isLogged]);

  const handleFieldsConfigChange = useCallback(
    (newFieldsConfig: FieldConfig[]) => {
      setFieldsConfig(newFieldsConfig);
    },
    []
  );

  const handleMyBetsClick = ({
    trackCode,
    raceNumber,
    trackName
  }: MyBetsClickProps) => {
    const dateFilter = fieldsConfig.find(({ key }) => key === "date")?.value;

    dispatch(
      openMybetsPastPerformance(
        {
          trackCode,
          raceNumber: Number(raceNumber),
          raceDate: dateFilter as string
        },
        `${trackName} R${raceNumber} - Replay`,
        MyBetsPastPerformanceTab.BETS
      )
    );
  };

  const handleCheckboxChange = (selectedValues: Array<string>) => {
    const newOnlyFavorites = selectedValues.includes("only_favorites");
    if (!isLogged) {
      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error: Error | null, success: Object) => {
            if (!error && success) {
              dispatch(toggleOnlyFavorites(newOnlyFavorites));
            }
          },
          triggerAction: "tracks_results_favorites_tab"
        }
      });
    } else {
      dispatch(toggleOnlyFavorites(newOnlyFavorites));
    }
  };

  const renderSearchContent = () => {
    const commonProps = {
      onFieldsConfigChange: handleFieldsConfigChange,
      onMyBetsClick: handleMyBetsClick,
      racesWithBets,
      onlyFavorites
    };

    switch (searchOption) {
      case SearchOptionKey.DATE:
        return (
          <PastResultsDateSearch
            qaLabel={`${qaLabel}-dateSearch`}
            {...commonProps}
          />
        );
      case SearchOptionKey.TRACK:
        return (
          <PastResultsTrackSearch
            qaLabel={`${qaLabel}-trackSearch`}
            {...commonProps}
          />
        );
      case SearchOptionKey.HORSE:
        return (
          <PastResultsHorseSearch
            qaLabel={`${qaLabel}-horseSearch`}
            onFieldsConfigChange={handleFieldsConfigChange}
          />
        );
      default:
        return null;
    }
  };

  const handleSelectedChange = (newValue: string) => {
    if (searchOption === newValue) return;

    setFieldsConfig([]);
    setSearchOption(newValue as SearchOptionKey);
  };

  return (
    <Container>
      <ContainerHeader>
        <SearchOptionsContainer>
          <SearchOptions
            label={initialConfig.label}
            options={initialConfig.options}
            selectedOption={searchOption}
            onSelectedChange={handleSelectedChange}
            qaLabel={`${qaLabel}-searchOptions`}
          />
          {[SearchOptionKey.DATE, SearchOptionKey.TRACK].includes(
            searchOption
          ) && (
            <CheckboxContainer>
              <Checkbox.Group
                qaLabel="onlyFavoritesCheckbox"
                selectedValues={onlyFavorites ? ["only_favorites"] : []}
                onChange={handleCheckboxChange}
              >
                <Checkbox value="only_favorites" label="Only favorites" />
              </Checkbox.Group>
            </CheckboxContainer>
          )}
        </SearchOptionsContainer>
        {fieldsConfig.length > 0 && (
          <ResultsSearchForm fieldsConfig={fieldsConfig} qaLabel={qaLabel} />
        )}
      </ContainerHeader>
      <Body>
        <SectionContainer>{renderSearchContent()}</SectionContainer>
      </Body>
    </Container>
  );
};

export default PastResults;
