import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "@tvg/design-system";
import { IconButton } from "@tvg/design-system/web";
import { isFdrProduct, isMobile } from "@tvg/sh-utils/mobileUtils";
import tvgConf from "@tvg/conf";
import { useLocation } from "react-router";
import { getIsLogged } from "@urp/store-selectors";
import ContentCards from "../ContentCards";
import { BadgeContainer, ButtonContainer } from "./styled-components";
import { shouldRenderInboxBtn, subscribeWrapperEvents } from "../../utils";
import { isBrazeInboxEnabled } from "../../redux/selectors";
import { QA_LABELS } from "../../utils/constants";
import useInboxCards from "../../hooks/useInboxCards";
import { sendEventMenuClick } from "../../utils/amplitude";
import { closeInboxModal, openInboxModal } from "../../redux/actions";

const InboxButton = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isLogged = useSelector(getIsLogged);
  const isToggleOn = useSelector(isBrazeInboxEnabled);

  const { listOfBrazeCards, numberOfUnreadCards, isOpen, isDesktop } =
    useInboxCards();

  const handleIconPress = () => {
    dispatch(openInboxModal());
    sendEventMenuClick();
  };

  useEffect(() => {
    if (isMobile(tvgConf().product)) {
      subscribeWrapperEvents(dispatch);
    }
  }, []);

  if (!shouldRenderInboxBtn(pathname, isLogged, isToggleOn)) return null;

  return (
    <>
      <ButtonContainer>
        {numberOfUnreadCards > 0 && (
          <BadgeContainer isDesktop={isDesktop}>
            <Badge.Counter
              counter={numberOfUnreadCards}
              qaLabel={QA_LABELS.inbox.badge}
              counterLimit={99}
              variant="primary"
            />
          </BadgeContainer>
        )}
        <IconButton
          mode="dark"
          iconName="email"
          qaLabel={QA_LABELS.inbox.button}
          forceActive={isOpen}
          onClick={handleIconPress}
          textTooltip={isFdrProduct() ? undefined : "Notifications"}
          marginRight={isFdrProduct() ? 0 : 16}
          marginLeft={isFdrProduct() ? 8 : 0}
          size={isFdrProduct() ? "s" : "m"}
        />
      </ButtonContainer>
      {isOpen && (
        <ContentCards
          isDesktop={isDesktop}
          isModalOpen={isOpen}
          listOfBrazeCards={listOfBrazeCards}
          onClose={() => dispatch(closeInboxModal())}
        />
      )}
    </>
  );
};

export default InboxButton;
