import { gql } from "@apollo/client";

export const GET_ALL_TRANSACTIONS = gql`
  query GetAllTransactions(
    $accountId: Long!
    $startDateTime: DateTime
    $endDateTime: DateTime
    $currentPage: Int
    $maxResults: Int
    $types: [TransactionType]
    $dateSort: Sort
  ) {
    accountTransactions(
      accountId: $accountId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      page: { current: $currentPage, results: $maxResults }
      filter: { types: $types }
      sort: { byDate: $dateSort }
    ) {
      totalTransactions
      startBalance
      endBalance
      transactions {
        date
        amount
        type
        description
        details
        info
        runningBalance
      }
      transactionTypeSummary {
        type
        total
      }
    }
    fundsOnHoldHistory(
      accountId: $accountId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      sort: { byDate: $dateSort }
    ) {
      accountId
      depositId
      amount
      feeAmount
      netAmount
      operationDateUTC
      releaseAtUTC
      releasedAtUTC
      originalHoldDays
      paymentType
      userId
      isDepositOnHold
      transactionOnHoldId
    }
  }
`;

export default GET_ALL_TRANSACTIONS;
