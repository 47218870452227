import { buildColor } from "@tvg/design-system";
import styled, { keyframes } from "styled-components";

export const SVGContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  z-index: 30;
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "000")};
  }
  
  50% {
    fill: ${buildColor("white", "100")};
  }
  
  100% {
    fill: ${buildColor("blue", "000")};
  }
`;

export const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  background: ${buildColor("white", "100")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${buildColor("white", "100")};
    stroke-width: 1;
  }
`;
