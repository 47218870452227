import styled, { css } from "styled-components";

export const FieldsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space["space-5"]};
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space["space-3"]};
`;

export const FieldLabel = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTokens.content.subtle};
    font-family: ${theme.fonts.regular};
    font-size: ${theme.fontSizes.s};
  `}
`;
