import styled from "styled-components";

export const MessageStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colorTokens.content.default};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding-bottom: 26px;
`;

export const MessageContainer = styled.div`
  flex-direction: row;
  padding: 8px 8px 0 8px;
`;

export const FinalMessageContainer = styled.a`
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colorTokens.content.default};
  vertical-align: baseline;
`;
