import { Dispatch } from "redux";
import ufc from "@tvg/api/ufc";
import { GetAlertsResponse } from "@tvg/ts-types/Alerts";
import { FavoriteTrackWithId } from "@tvg/ts-types/User";

import {
  setUserFavoriteTracks,
  setUserFavoriteTracksWithId
} from "@tvg/tracks/src/v2/redux/actions";

export const getFavoriteTracks = (accountNumber: string, dispatch: Dispatch) =>
  typeof window !== "undefined" &&
  ufc
    .getUserAlerts(accountNumber, "track")
    .then((response: { data: GetAlertsResponse }) => {
      const userFavorites: FavoriteTrackWithId = {};
      const ufcFavoriteTracks = response.data.favorites.filter(
        (favorite) => !favorite.subscriptions.length
      );
      ufcFavoriteTracks.forEach((ufcFav) => {
        userFavorites[ufcFav.entityName] = ufcFav.favoriteId.toString();
      });

      const actualFavoriteTracks = Object.keys(userFavorites);

      dispatch(setUserFavoriteTracks(actualFavoriteTracks));
      dispatch(setUserFavoriteTracksWithId(userFavorites));
    });
