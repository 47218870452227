import React, { useEffect, useState } from "react";
import { PastResultsByYearProps, CollapsibleToggle } from "./types";
import { getRacesGroupedByYear } from "./utils";
import TracksAZLoading from "../../loadingMasks/TracksAZLoading";
import SpoilerToggle from "../SpoilerToggle";
import CollapsibleByYear from "./CollapsibleByYear";
import {
  BodyHeader,
  RaceResultsContainer,
  StyledHeader
} from "./styled-components";

const PastResultsByYear: React.FC<PastResultsByYearProps> = ({
  name,
  pastRaces,
  isLoading,
  qaLabel
}) => {
  const [isOpen, setIsOpen] = useState<CollapsibleToggle>({});

  useEffect(() => {
    setIsOpen({});
  }, [name]);

  const racesGroupedByYear = getRacesGroupedByYear(pastRaces);

  const handleOnClickCollapsible = (year: string) => {
    setIsOpen((prev) => ({
      ...prev,
      [year]: !prev[year]
    }));
  };

  const renderRaces = () => {
    if (isLoading) {
      return <TracksAZLoading />;
    }

    if (pastRaces.length === 0) {
      // TODO: Add empty state
      return <div>No results yet!</div>;
    }

    return Object.entries(racesGroupedByYear)
      .sort((a, b) => (+a[0] < +b[0] ? 1 : -1))
      .map(([year, races]) => (
        <CollapsibleByYear
          key={year}
          races={races}
          year={year}
          runnerNameHighlight={name}
          isOpen={isOpen}
          handleOnClick={handleOnClickCollapsible}
        />
      ));
  };

  return (
    <>
      <BodyHeader>
        <StyledHeader tag="h2" qaLabel={`${qaLabel}-track-name`}>
          {name}
        </StyledHeader>
        <SpoilerToggle qaLabel={qaLabel} />
      </BodyHeader>
      <RaceResultsContainer>{renderRaces()}</RaceResultsContainer>
    </>
  );
};

export default PastResultsByYear;
