export const computeScrollWidth = (
  scrollWidth?: number,
  clientWidth?: number
) => {
  let widthToScroll = 0;

  if (scrollWidth && clientWidth) {
    widthToScroll = scrollWidth - clientWidth;
  }
  return widthToScroll;
};
