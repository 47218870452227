import styled from "styled-components";

export const Container = styled.div`
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colorTokens.border.subtle}`};
  padding: 16px;
  display: flex;
  gap: 8px;
  background-color: ${({ theme }) =>
    theme.colors.white[
      "900"
    ]}; /* TODO: Background color is wrong, using hard coded value temporarily. Use: theme.colorTokens.background.surface */
`;

export const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
