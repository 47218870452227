import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colorTokens.content.default};
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;

  > h2 {
    position: relative;
    color: ${({ theme }) => theme.colorTokens.content.default};
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    margin-bottom: 16px;

    &:not(:first-of-type) {
      margin-top: 32px;

      &::before {
        position: absolute;
        content: "";
        top: -16px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.colorTokens.border.subtle};
      }
    }
  }

  > p {
    &:not(:blank),
    :not(:empty) {
      margin-bottom: 8px;
    }
  }

  a {
    display: inline-block;
    margin-top: -2px;
    color: ${({ theme }) => theme.colorTokens.content.link};
  }
`;
