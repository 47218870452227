import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --hdr-height: 76px;
  }
`;

export const Header = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1440px;
  height: var(--hdr-height, 76px);
  background-color: ${({ theme }) => theme.colorTokens.background.secondary};
  z-index: 1000;
  padding: 0 16px;

  @media print {
    width: 100%;
  }
`;

export const AlertContainer = styled.div`
  position: sticky;
  margin-top: var(--hdr-height, 76px);
  top: var(--hdr-height, 76px);
  width: 100%;
  max-width: 1440px;
  z-index: 999;
  padding: 0 16px;
`;

export const QuickDepositWrapper = styled.div`
  min-width: 130px;
  margin: 0 16px;
`;
