import React, { Fragment, useMemo } from "react";
import { Route, Outlet } from "react-router-dom";
import { get } from "lodash";
import { useSelector } from "react-redux";
import conf from "@tvg/conf";
import { getIsLogged, getWagerProfile } from "@urp/store-selectors";
import { getFeatureToggles } from "@tvg/sh-lib-capi-messages/redux/selectors";
import { getHasRequestedLogin } from "../store/selectors/general";
import routes from "./index";
import RequireAuth from "./RequireAuth";
import LoadingPage from "../pages/LoadingPage";

const RouteMapper = () => {
  const isLogged = useSelector(getIsLogged);
  const hasRequestedLogin = useSelector(getHasRequestedLogin);
  const userProfile = useSelector(getWagerProfile);
  const togglesStore = useSelector(getFeatureToggles);

  return useMemo(
    () =>
      routes().map((layout, layoutIndex) => {
        const isValidElement = typeof layout.component === "function";
        const LayoutComponent = isValidElement ? layout.component : Fragment;

        return (
          <Route
            key={`${layout.key}-${layoutIndex.toString()}`}
            element={
              <LayoutComponent {...layout.props}>
                <Outlet />
              </LayoutComponent>
            }
          >
            {layout.routes
              .filter((route) => {
                const isRouteAvailable = route.props?.toggle
                  ? togglesStore[route.props.toggle]
                  : true;
                return (
                  isRouteAvailable &&
                  !get(route, "props.brandsException", []).includes(
                    conf().brand
                  ) &&
                  !get(route, "props.profileTypeException", []).includes(
                    userProfile
                  )
                );
              })
              .map((route, routeIndex) => {
                const Component = route.component;
                const requiresLogin = get(route, "props.requiresLogin", false);
                return (
                  <Route
                    key={`${route.key}-${routeIndex.toString()}`}
                    path={route.path}
                    element={
                      <RequireAuth
                        protectedRoute={requiresLogin}
                        isLogged={isLogged}
                        loginRequest={hasRequestedLogin}
                        intendedPath={route.path}
                      >
                        <Component
                          fallback={<LoadingPage id="tvg5Loading" />}
                        />
                      </RequireAuth>
                    }
                  />
                );
              })}
          </Route>
        );
      }),
    [isLogged, hasRequestedLogin, userProfile, togglesStore]
  );
};
export default RouteMapper;
