import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { ModalHeader } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { useDrawer } from "@urp/lib-racetracks";
import {
  Container,
  Content,
  LefHeaderEmpty,
  Overlay,
  Panel
} from "./styled-components";
import { Props } from "./types";
import {
  COLLAPSIBLE,
  CONTAINER,
  CONTENT,
  DRAWER,
  OVERLAY,
  TITLE,
  FILTER
} from "../../../utils/constants";
import FiltersDrawerFooter from "../FiltersDrawerFooter";

import FilterGroup from "../FilterGroup";

export const Drawer: React.FC<Props> = ({
  isOpen,
  onClose,
  qaLabel,
  title,
  currentTab
}) => {
  const appContainer = document.getElementById("app");
  if (!document || !appContainer) return null;

  useEffect(() => {
    appContainer.style.overflow = isOpen ? "hidden" : "auto";

    return () => {
      appContainer.style.overflow = "auto";
    };
  }, [isOpen]);

  const {
    filterGroups,
    hasFiltersApplied,
    onChangeFilter,
    onClearFilters,
    onApplyFilters
  } = useDrawer();

  const onClear = () => {
    onClearFilters();
    onClose();
  };

  const onApply = () => {
    onApplyFilters(currentTab);
    onClose();
  };

  return createPortal(
    <Container
      isOpen={isOpen}
      data-qa-label={buildQaLabel([qaLabel, DRAWER, CONTAINER])}
    >
      <Overlay
        onClick={onClose}
        data-qa-label={buildQaLabel([qaLabel, DRAWER, OVERLAY])}
      />
      <Panel
        isOpen={isOpen}
        data-qa-label={buildQaLabel([qaLabel, DRAWER, COLLAPSIBLE])}
      >
        {title && (
          <ModalHeader
            title={title}
            onClose={onClose}
            qaLabel={buildQaLabel([qaLabel, DRAWER, COLLAPSIBLE, TITLE])}
            headerLeftElement={<LefHeaderEmpty />}
          />
        )}
        <Content
          data-qa-label={buildQaLabel([qaLabel, DRAWER, COLLAPSIBLE, CONTENT])}
        >
          {filterGroups.map((group) => (
            <FilterGroup
              key={group.key}
              options={group.options}
              title={group.label}
              selectedValues={group.selectedValues}
              onChange={(value) => onChangeFilter(group.key, value)}
              qaLabel={buildQaLabel([FILTER])}
            />
          ))}
        </Content>
        <FiltersDrawerFooter
          hasFiltersApplied={hasFiltersApplied}
          onClose={onClose}
          onApply={onApply}
          onClear={onClear}
        />
      </Panel>
    </Container>,
    appContainer
  );
};

export default Drawer;
