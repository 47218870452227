import styled from "styled-components";
import { fonts } from "@tvg/design-system/src/theming/themes/common/fonts";

export const LabelStyled = styled.span`
  font-family: ${fonts.condensedRegular};
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTokens.content.subtle2};
`;
