import parseCAPIMessage from "@tvg/utils/capiUtils";
import { get } from "lodash";

export const getPickDescriptionLhn = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.lhnDescriptionNotMatch", {});

export const getPickLabel = (store: unknown) =>
  parseCAPIMessage(store, "capi.messages.lhnPicksLabel", "starts");

export const getLhnEmptyStateMessage = (store: unknown): string =>
  get(
    store,
    "capi.messages.LhnEmptyStateMessage",
    "There are no races happening at this time. Please check back later."
  );

export const getLhnDefaultEmptyMessage = (store: unknown): string =>
  get(store, "capi.messages.lhnDefaultEmptyMessage", "No races available");

export const getLhnFeaturedRacesTitle = (store: unknown) =>
  get(store, "capi.messages.LhnFeaturedRacesTitle", "your featured races");

export const getLhnScheduledRacesTitle = (store: unknown) =>
  get(store, "capi.messages.LhnScheduledRacesTitle", "scheduled races");

export const getMinutesPastPost = (store: unknown) =>
  get(store, "capi.messages.minutesPastPost", "5");

export const getOptedInPromosStore = (store: unknown) =>
  get(store, "userData.optedInPromos", {});

export const getDateRace = (store: unknown) => get(store, "mtpStatus.raceDate");
