import { RaceProgram } from "@tvg/ts-types/Race";
import {
  InlinePPEmptyMessages,
  InlinePPMessage,
  InlinePastPerformance
} from "../src/types";

export const emptyInlinePPMessage: InlinePPEmptyMessages = {
  recentRaces: "No replays are available for this horse",
  filteredRaces: "No track available with the same conditions."
};

export const inlinePPContent: InlinePPMessage = {
  maxResults: 5,
  emptyMessages: emptyInlinePPMessage
};

export const pastRaceWinnerMock: InlinePastPerformance = {
  id: 1,
  date: "Tue Jan 31 2023 14:02:53 GMT+0000",
  distance: {
    code: "MT",
    value: 5
  },
  numRunners: "10",
  purse: 1000,
  raceClass: {
    shortName: "RaceClass1"
  },
  results: {
    runners: [
      {
        currentOdds: { denominator: 1, numerator: 2 },
        finishPosition: 1,
        timeform: null,
        favorite: true
      }
    ]
  },
  surface: {
    defaultCondition: "Wet",
    shortName: "Dirt"
  },
  track: {
    code: "AU"
  },
  video: { flashAvailable: true, mobileAvailable: true, replayFileName: "" }
};

export const pastRaceSecondMock: InlinePastPerformance = {
  id: 2,
  date: "Tue Jan 31 2023 14:02:53 GMT+0000",
  distance: {
    code: "FT",
    value: 4
  },
  numRunners: "13",
  purse: 23000,
  raceClass: {
    shortName: "RaceClass2"
  },
  results: {
    runners: [
      {
        currentOdds: { denominator: 5, numerator: 2 },
        finishPosition: 2,
        timeform: null,
        favorite: false
      }
    ]
  },
  surface: {
    defaultCondition: "Wet",
    shortName: "Wet"
  },
  track: {
    code: "PHI"
  }
};

export const pastRaceNoRunnersMock: InlinePastPerformance = {
  id: 3,
  date: "Tue Jan 31 2023 14:02:53 GMT+0000",
  distance: {
    code: "MT",
    value: 19
  },
  numRunners: "3",
  purse: 300,
  raceClass: {
    shortName: "RaceClass1"
  },
  results: {
    runners: []
  },
  surface: {
    defaultCondition: "Dry",
    shortName: "Wet"
  },
  track: {
    code: "PT"
  }
};

export const pastRaceNoNumeratorMock: InlinePastPerformance = {
  id: 4,
  date: "Tue Jan 31 2023 14:02:53 GMT+0000",
  distance: {
    code: "MT",
    value: 19
  },
  numRunners: "3",
  purse: 300,
  raceClass: {
    shortName: "RaceClass1"
  },
  results: {
    runners: [
      {
        currentOdds: { denominator: 5, numerator: undefined },
        finishPosition: 2,
        timeform: null,
        favorite: false
      }
    ]
  },
  surface: {
    defaultCondition: "Dry",
    shortName: "Wet"
  },
  track: {
    code: "PT"
  }
};

export const pastRaceNoDenominatorMock: InlinePastPerformance = {
  id: 5,
  date: "Tue Jan 31 2023 14:02:53 GMT+0000",
  distance: {
    code: "MT",
    value: 19
  },
  numRunners: "3",
  purse: 300,
  raceClass: {
    shortName: "RaceClass1"
  },
  results: {
    runners: [
      {
        currentOdds: { denominator: undefined, numerator: 3 },
        finishPosition: 2,
        timeform: null,
        favorite: false
      }
    ]
  },
  surface: {
    defaultCondition: "Dry",
    shortName: "Wet"
  },
  track: {
    code: "PT"
  }
};

export const pastRaceThirdMock: InlinePastPerformance = {
  id: 6,
  date: "Tue Jan 31 2023 14:02:53 GMT+0000",
  distance: {
    code: "MT",
    value: 5
  },
  numRunners: "13",
  purse: 1000,
  raceClass: {
    shortName: "RaceClass1"
  },
  results: {
    runners: [
      {
        currentOdds: { denominator: 5, numerator: 2 },
        finishPosition: 2,
        timeform: null,
        favorite: false
      }
    ]
  },
  surface: {
    defaultCondition: "Dirt",
    shortName: "Dirt"
  },
  track: {
    code: "GP"
  }
};

export const pastRaceFourthMock: InlinePastPerformance = {
  id: 7,
  date: "Tue Jan 31 2023 14:02:53 GMT+0000",
  distance: {
    code: "MT",
    value: 5
  },
  numRunners: "10",
  purse: 1000,
  raceClass: {
    shortName: "RaceClass1"
  },
  results: {
    runners: [
      {
        currentOdds: { denominator: 1, numerator: 2 },
        finishPosition: 1,
        timeform: null,
        favorite: true
      }
    ]
  },
  surface: {
    defaultCondition: "Dirt",
    shortName: "Dirt"
  },
  track: {
    code: "PHI"
  },
  video: { flashAvailable: true, mobileAvailable: true, replayFileName: "" }
};

export const currentRaceMock: RaceProgram = {
  id: "GP-6",
  tvgRaceId: 3297437,
  raceNumber: "6",
  mtp: 1339,
  postTime: "2022-09-02T08:00:00Z",
  raceDate: "2022-09-01",
  track: {
    trackName: "Parx Racing",
    trackCode: "PHI",
    shortName: "Parx Racing",
    featured: false,
    numberOfRaces: 8,
    trackLocation: { country: "POR" },
    trackDataSource: "USGSA"
  },
  description: "HCP  C4 Min. 112 lbs. . 7 furlongs. ",
  distance: "5mt",
  purse: 46033,
  numRunners: "12",
  numWagerableRunners: "12",
  claimingPrice: 0,
  surface: {
    name: "Dirt",
    shortName: "Dirt",
    defaultCondition: "Dirt"
  }
};
