import type { Dispatch } from "redux";
import { isEmpty, isUndefined } from "lodash";
import { History } from "@tvg/ts-types/History";
import { GeolocationError } from "@tvg/sh-geolocation/src/types";
import { resetProgramPageState } from "@tvg/pp/src/store/actions";
import { logoutUser } from "@fdr/shared-actions/UserActions";
import { geolocationStatusClear } from "@tvg/sh-geolocation/src/redux/actions";
import mediator from "@tvg/mediator";
import { resetGeoLocation } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import {
  clearAuthTokenCookie,
  clearLoginTokenCookie
} from "@tvg/sh-utils/sessionUtils";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { UserInfo } from "@fdr/types/ts/User";
import { clearDatadogRumUser } from "@fdr/datadog-rum";

export const logout = () => mediator.base.dispatch({ type: "TRIGGER_LOGOUT" });

export type ClearSessionProps = {
  history: History;
  dispatch: Dispatch;
  geolocationError: GeolocationError;
  allowRedirect?: boolean;
  user?: Partial<UserInfo>;
};

export const clearSession = (props: ClearSessionProps) => {
  const { history, dispatch, geolocationError, allowRedirect } = props;
  dispatch(logoutUser());
  if (props.user) {
    mediatorClassic.dispatch("TVG_LOGIN:USER_SESSION_UPDATE", {
      ...props.user
    });
  }
  clearAuthTokenCookie();
  clearLoginTokenCookie();
  clearDatadogRumUser();
  if (!isEmpty(geolocationError)) {
    dispatch(resetGeoLocation());
  }
  dispatch(geolocationStatusClear());
  dispatch(resetProgramPageState());
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem("userId");
  }

  const isWagerpad = history && history.location.pathname.includes("/wagerpad");

  if (!isWagerpad && (isUndefined(allowRedirect) || allowRedirect)) {
    history.push("/");
  }
};

export default logout;
