import React, { FC, memo } from "react";
import { RaceInfoProps } from "./types";
import { Paragraph } from "../../../typography";
import { useColorTokens } from "../../../../theming";

export const RaceInfo: FC<RaceInfoProps> = memo(({ label, align, qaLabel }) => {
  const color = useColorTokens("content.default");

  return (
    <Paragraph
      fontFamily="medium"
      textAlign={align}
      qaLabel={`${qaLabel}-race-info`}
      color={color}
    >
      {label}
    </Paragraph>
  );
});
