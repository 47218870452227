import React from "react";
import { Path } from "react-native-svg";

export const Australia: React.FC = () => (
  <>
    <Path d="M32 0H0V20H32V0Z" fill="#2959A3" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.43435 12.7273L7.87302 14.0693L9.21212 13.5714L8.42713 
      14.7403L9.65079 15.4545L8.21934 15.5844L8.42713 16.9913L7.43435 
      15.9524L6.44156 16.9913L6.64935 15.5844L5.2179 15.4545L6.46465 
      14.7403L5.65657 13.5714L6.99567 14.0693L7.43435 12.7273Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.834 13.7662L23.0649 14.4805L23.7806 14.2207L23.365 14.8484L24.0115 
      15.238L23.2496 15.303L23.365 16.0389L22.834 15.4978L22.303 16.0389L22.4184 
      15.303L21.6565 15.238L22.303 14.8484L21.8874 14.2207L22.6031 
      14.4805L22.834 13.7662Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5858 7.14278L18.8167 7.85707L19.5324 7.59733L19.0938 
      8.22504L19.7633 8.61465L19.0014 8.67958L19.0938 9.41551L18.5858 
      8.87439L18.0548 9.41551L18.1472 8.67958L17.3853 8.61465L18.0548 
      8.22504L17.6392 7.59733L18.355 7.85707L18.5858 7.14278Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.834 2.61904L23.0649 3.33333L23.7807 3.05194L23.3651 3.67965L24.0115 
      4.06926L23.2496 4.13419L23.3651 4.89177L22.834 4.329L22.303 
      4.89177L22.4185 4.13419L21.6566 4.06926L22.303 3.67965L21.8874 
      3.05194L22.6032 3.33333L22.834 2.61904Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6205 6.01726L26.8514 6.75319L27.5671 6.4718L27.1284 7.09951L27.798 
      7.48912L27.0361 7.55405L27.1284 8.28999L26.6205 7.74886L26.0895 
      8.28999L26.1818 7.55405L25.4199 7.48912L26.0895 7.09951L25.6508 
      6.4718L26.3665 6.75319L26.6205 6.01726Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5194 9.39395L24.7272 9.82685L25.2121 9.87014L24.8196 
      10.1732L24.9581 10.6494L24.5194 10.3896L24.1038 10.6494L24.2424 
      10.1732L23.8499 9.87014L24.3347 9.82685L24.5194 9.39395Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H1.36219L6.3723 3.29004V0H8.61183V3.29004L13.5988 
      0H14.9841V1.01732L10.9206 3.7013H14.9841V6.2987H10.9206L14.9841 
      8.98268V10H13.5988L8.61183 6.70996V10H6.3723V6.70996L1.36219 
      10H0V8.98268L4.06349 6.2987H0V3.7013H4.06349L0 1.01732V0Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9841 
      4.15584V5.84416H8.21934V10H6.74171V5.84416H0V4.15584H6.74171V0H8.21934V4.15584H14.9841Z"
      fill="#DE3131"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.59451 3.70127L0 0.670963V0.0432587L5.54113 3.70127H4.59451Z"
      fill="#DE3131"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3723 6.36365L0.877346 10H0V9.95672L5.54113 6.29871H6.3723V6.36365Z"
      fill="#DE3131"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9841 0.0649357L9.46611 3.7013H8.61185V3.65801L14.153 
      0H14.9841V0.0649357Z"
      fill="#DE3131"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9841 9.93508L9.46606 6.29871H10.4127L14.9841 9.30737V9.93508Z"
      fill="#DE3131"
    />
  </>
);

export default Australia;
