import React from "react";
import color from "@fdr/static-ui/ColorPalette";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const TracksFilterMask = () => (
  <Container>
    <SVG
      data-qa-label="filter-loadingMask"
      width="375"
      height="63"
      viewBox="0 0 375 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="375" height="63" fill="white" />
      <rect x="16.5" y="17.5" width="194" height="33" rx="3.5" fill="white" />
      <rect
        x="16.5"
        y="17.5"
        width="194"
        height="33"
        rx="3.5"
        stroke="#C9D1DB"
      />
      <rect
        className="pulse"
        x="26"
        y="27"
        width="57"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="91"
        y="27"
        width="52"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="151"
        y="27"
        width="52"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="16"
        width="103"
        height="11"
        rx="1"
        fill="#0A0A0A"
      />
      <rect x="218.5" y="17.5" width="140" height="33" rx="3.5" fill="white" />
      <rect
        x="218.5"
        y="17.5"
        width="140"
        height="33"
        rx="3.5"
        stroke="#C9D1DB"
      />
      <rect
        className="pulse"
        x="226"
        y="27"
        width="59"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="293"
        y="27"
        width="58"
        height="15"
        rx="1"
        fill="#0A0A0A"
      />
      <rect
        className="pulse"
        x="218"
        width="103"
        height="11"
        rx="1"
        fill="#0A0A0A"
      />
    </SVG>
  </Container>
);

export default TracksFilterMask;
