import React from "react";
import { NavBarWrapper } from "./styled-components";
import NavigationButton from "../../../../NavigationButton";
import { MonthNavbarProps } from "../types";

export const MonthNavbar = ({
  onPreviousClick,
  onNextClick
}: MonthNavbarProps) => (
  <div className="DayPicker-NavBar">
    <NavBarWrapper>
      <NavigationButton
        onLeftClick={() => onPreviousClick()}
        onRightClick={() => onNextClick()}
      />
    </NavBarWrapper>
  </div>
);

export default MonthNavbar;
