import React, { FC } from "react";
import { G, Path, Defs, ClipPath, Rect } from "react-native-svg";

export const Bahrain: FC = () => (
  <>
    <G clipPath="url(#clip0_22916_5967)">
      <Path d="M0 0H32V20H0V0Z" fill="white" />
      <Path
        d="M8 0H32V20H8L12.8 18L8 16L12.8 14L8 12L12.8 10L8 8L12.8 6L8 4L12.8 2L8 0Z"
        fill="#DA291C"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_22916_5967">
        <Rect width="32" height="20" rx="2" fill="white" />
      </ClipPath>
    </Defs>
  </>
);

export default Bahrain;
