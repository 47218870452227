import { useState, useEffect } from "react";
import { throttle } from "lodash";

const getPosition = () =>
  typeof window !== "undefined" ? window.pageYOffset : 0;

const useWindowScrollPosition = (throttleValue: number) => {
  const [position, setPosition] = useState(getPosition());

  useEffect(() => {
    const handleScroll = throttle(() => {
      setPosition(getPosition());
    }, throttleValue);

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      handleScroll.cancel();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return position;
};

export default useWindowScrollPosition;
