import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
    stroke: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
    stroke: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
    stroke: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: auto;

  .pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 110px;
`;

const Content = styled.div`
  overflow: hidden;
  padding: ${({ largerPadding }) => (largerPadding ? "12px" : "9px")} 24px
    ${({ largerPadding }) => (largerPadding ? "12px" : "9px")} 8px;
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  flex-direction: row;
  justify-content: space-between;
`;

const Divider = styled.hr`
  height: 1px;
  background-color: ${color("grey", "300")};
  border: none;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export default class RunnerInfoMaskV2 extends PureComponent {
  render() {
    return (
      <Container>
        <Content>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="38"
            viewBox="0 0 100 50"
            preserveAspectRatio="xMinYMin meet"
          >
            <g fillOpacity="0" strokeWidth="7">
              <path
                d="M5 50a45 45 0 1 1 90 0"
                stroke="#F0F3F8"
                className="pulse"
              />
            </g>
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="80" height="22" x="10" y="65" rx="1" />
              <rect width="30" height="22" x="34" y="30" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="38"
            viewBox="0 0 100 50"
            preserveAspectRatio="xMinYMin meet"
          >
            <g fillOpacity="0" strokeWidth="7">
              <path
                d="M5 50a45 45 0 1 1 90 0"
                stroke="#F0F3F8"
                className="pulse"
              />
            </g>
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="80" height="22" x="10" y="65" rx="1" />
              <rect width="30" height="22" x="34" y="30" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="38"
            viewBox="0 0 100 50"
            preserveAspectRatio="xMinYMin meet"
          >
            <g fillOpacity="0" strokeWidth="7">
              <path
                d="M5 50a45 45 0 1 1 90 0"
                stroke="#F0F3F8"
                className="pulse"
              />
            </g>
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="80" height="22" x="10" y="65" rx="1" />
              <rect width="30" height="22" x="34" y="30" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="38"
            viewBox="0 0 100 50"
            preserveAspectRatio="xMinYMin meet"
          >
            <g fillOpacity="0" strokeWidth="7">
              <path
                d="M5 50a45 45 0 1 1 90 0"
                stroke="#F0F3F8"
                className="pulse"
              />
            </g>
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="80" height="22" x="10" y="65" rx="1" />
              <rect width="30" height="22" x="34" y="30" rx="1" />
            </g>
          </SVG>
        </Content>
        <Divider />
        <Content largerPadding>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
        </Content>
      </Container>
    );
  }
}
