import React from "react";
import { useColorTokens } from "@tvg/design-system";
import { Container, Svg } from "./styled-components";

interface Props {
  qaLabel?: string;
}

export const Avatar = ({ qaLabel = "avatar" }: Props) => {
  const colorTokens = useColorTokens();

  return (
    <Container data-qa-label={qaLabel}>
      <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 26">
        <title>User avatar</title>
        <path
          fill={colorTokens.background.neutral}
          d="M6.759 7.228V8.75c0 5.043 2.896 9.892 7.241 9.892 4.332 0 7.241-4.85 7.241-9.892V7.228c0-5.042-2.986-7.228-7.24-7.228-4.346 0-7.242 2.186-7.242 7.228zM28 26H0c0-4.308 4.619-7.447 8.384-7.813 1.514 1.506 3.436 2.417 5.616 2.417 2.176 0 4.097-.912 5.611-2.418C23.377 18.55 28 21.69 28 26z"
        />
      </Svg>
    </Container>
  );
};

export default Avatar;
