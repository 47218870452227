import React from "react";
import ApolloContext from "@tvg/utils/apolloContext";
import type { ApolloContextProps } from "../types";

const withApolloContext =
  <P extends object>(
    Component: React.FunctionComponent<P>
  ): React.FunctionComponent<P> =>
  (props: P) =>
    (
      <ApolloContext.Consumer>
        {(value: ApolloContextProps) => (
          <Component {...props} {...(value as P)} />
        )}
      </ApolloContext.Consumer>
    );

export default withApolloContext;
