import { useSelector } from "react-redux";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import getOptedInPromos from "@tvg/utils/PromoUtils";
import {
  getOptedInPromos as getOptedInPromosStore,
  getIsLogged
} from "@urp/store-selectors";
import { PromotionTypeEnum } from "@tvg/ts-types/Promos";
import { TABS, useUserPromos } from "@urp/lib-racetracks";
import buildRaceUrl from "@tvg/formatter/url";
import type { Race, Promo } from "@tvg/ts-types/Race";
import { Module } from "@urp/amplitude/src/modules/raceCell/types";
import { sendRaceCellClickedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import type { Track } from "@tvg/ts-types/Track";
import {
  getDistancesFilter,
  getRacesFilter,
  getRaceTypesFilter,
  getRegionsFilter,
  getTrackTypesFilter
} from "@urp/lib-racetracks/src/redux/selectors";
import { getFiltersApplied } from "../utils";

const useRaceAmplitudeEvent = (currentTab: TABS | undefined) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const raceTypesFilter = useSelector(getRaceTypesFilter);
  const regionsFilter = useSelector(getRegionsFilter);
  const trackTypesFilter = useSelector(getTrackTypesFilter);
  const racesFilter = useSelector(getRacesFilter);
  const distancesFilter = useSelector(getDistancesFilter);
  const optedInPromos = useSelector(getOptedInPromosStore);
  const isUserLogged = useSelector(getIsLogged);
  const { checkRacePromos } = useUserPromos(PromotionTypeEnum.RACE);

  const trackRaceAmplitudeEvent = (
    race: Race,
    track: Track,
    module: Module
  ) => {
    const { filterName, filterValue } = getFiltersApplied(
      raceTypesFilter,
      regionsFilter,
      trackTypesFilter,
      racesFilter,
      distancesFilter
    );

    const promo: Promo | null = getOptedInPromos(
      race.promos || [],
      optedInPromos,
      isUserLogged
    );

    const raceUrl = buildRaceUrl(
      race.track.code,
      race.track.name,
      +race.number
    );

    const { isOptedInPromo } = checkRacePromos(race.promos);
    sendRaceCellClickedAnalyticEvt({
      module,
      trackName: race.track.name,
      raceNumber: race.number,
      linkUrl: raceUrl,
      promoOptedIn: isOptedInPromo ? "true" : "false",
      trackHasPromos: !!track.hasAboveTheLinePromo,
      raceHasPromos: !!promo,
      isFDTV: race.video.onTvg || race.video.onTvg2,
      trackCountry: track.location?.country || "",
      tabActive: currentTab,
      ...(isDesktop ? { filterName, filterValue } : {})
    });
  };

  return {
    trackRaceAmplitudeEvent
  };
};

export default useRaceAmplitudeEvent;
