import { ResultRunner } from "@tvg/ts-types/Race";
import formatCurrency from "@tvg/formatter/currency";
import { MAX_RUNNERS_RESULTS } from "../../constants";

export const getResultRaceRunners = (runners: ResultRunner[] | null = []) =>
  runners
    ?.slice(0, MAX_RUNNERS_RESULTS)
    .map(
      ({
        runnerName,
        runnerNumber,
        winPayoff,
        placePayoff,
        showPayoff,
        favorite,
        finishPosition
      }) => ({
        position: finishPosition,
        saddlecloth: runnerNumber,
        name: runnerName,
        win: winPayoff ? formatCurrency(winPayoff) : undefined,
        place: placePayoff ? formatCurrency(placePayoff) : undefined,
        show: showPayoff ? formatCurrency(showPayoff) : undefined,
        isFavorite: favorite
      })
    ) || [];
