import styled from "styled-components";
import {
  generateTransition,
  easeInOut,
  delayAction
} from "@fdr/static-ui/Transitions";
import buildColor from "@fdr/static-ui/ColorPalette";
import { buildText } from "../../_atoms/Text";

export const MessageContainer = styled.div`
  flex-grow: 1;
  background-color: ${buildColor("grey", "100")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.full-container-messages-enter {
    flex-grow: 0;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  &.full-container-messages-enter-active {
    flex-grow: 1;
    overflow: hidden;
    opacity: 1;
    height: 0;
    transition: ${generateTransition(easeInOut, "opacity", "2.5s")},
      ${generateTransition(delayAction, "flex", "2.5s")};
  }
`;

export const MessageTitle = styled(
  buildText({
    tag: "div",
    fontSize: 18,
    ellipsis: true,
    uppercase: false,
    color: buildColor("navy", "600")
  })
)`
  overflow: initial;
`;

export const MessageText = styled(
  buildText({
    tag: "div",
    fontSize: 16,
    color: buildColor("grey", "700"),
    capitalize: false
  })
)`
  text-align: center;
  letter-spacing: 0;
  padding: 0 24px;
  white-space: pre-line;
`;

export const MessageIconContainer = styled.div`
  margin-bottom: 24px;
`;
