import gql from "graphql-tag";

const ResultsFragments = {
  entry: gql`
    fragment Results on Race {
      results {
        runners {
          betAmount
          biNumber
          runnerName
          runnerNumber
          finishPosition
          winPayoff
          placePayoff
          showPayoff
        }
      }
    }
  `
};

export default ResultsFragments;
