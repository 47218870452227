import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Menu = styled.div`
  z-index: 20;
  position: absolute;
  top: 8px;
  background: ${buildColor("white", "900")};
  border-radius: 4px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  width: 375px;
`;

export const HeaderContainer = styled.div`
  font-size: 18px;
  padding: 12px;
  border-bottom: 1px solid ${buildColor("blue", "000")};
`;

export const CardsContainer = styled.div`
  ${flexColumn};
`;

export const CardsList = styled.ul<{ isDesktop: boolean }>`
  ${flexColumn};
  max-height: ${({ isDesktop }) => isDesktop && "563px"};
  overflow-y: scroll;
`;

export const MenuContainer = styled.div`
  ${flexColumn};
  height: 0;
  width: 100%;
  align-items: center;
  position: relative;
`;
