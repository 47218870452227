import { useEffect, useState } from "react";
import tvgConf from "@tvg/conf";
import App from "./model/app";

const useLoadAngular = () => {
  const [isTvg4Ready, setIsTvg4Ready] = useState<boolean | null>(null);
  const [tvg4, setTvg4] = useState<App | null>(null);

  // get tvg4
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window === "undefined") return () => setIsTvg4Ready(false);
    const app = new App(
      "tvg4",
      `https://${tvgConf().config("/")?.domain?.desktop}/tvg4`
    );
    setTvg4(app);

    app.fetchAppEntries(setIsTvg4Ready);
  }, []);

  useEffect(() => {
    if (!tvg4?.domMutator.jsEntries.length && isTvg4Ready) {
      reloadTvg4();
    }
  }, [isTvg4Ready]);

  const reloadTvg4 = async () => {
    if (tvg4?.domMutator) {
      tvg4.domMutator.clearActiveApp();
      tvg4.domMutator.addHTML(tvg4.entries.html);
      tvg4.entries.css.forEach((link) => tvg4.domMutator.addCSS(link));
      await tvg4.entries.js.reduce(async (acc, el) => {
        await acc;
        return tvg4.domMutator.addJS(el);
      }, Promise.resolve());
    }
  };

  return { isTvg4Ready, reloadTvg4 };
};

export default useLoadAngular;
