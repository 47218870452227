import { gql } from "@apollo/client";

export const GET_LHN_SCHEDULED_RACES_QUERY = gql`
  query getLhnInfo(
    $wagerProfile: String
    $withGreyhounds: Boolean
    $noLoggedIn: Boolean!
    $product: String
    $device: String
    $brand: String
  ) {
    scheduleRaces: tracks(profile: $wagerProfile) {
      id
      races(
        filter: {
          status: ["MO", "O", "SK", "IC"]
          allRaceClasses: $withGreyhounds
        }
        page: { results: 2, current: 0 }
        sort: { byMTP: ASC }
      ) {
        id
        tvgRaceId
        mtp
        number
        postTime
        isGreyhound
        location {
          country
        }
        track {
          id
          isFavorite @skip(if: $noLoggedIn)
          code
          name
          perfAbbr
          featured
          hasWagersToday @skip(if: $noLoggedIn)
        }
        highlighted(product: $product, device: $device, brand: $brand) {
          description
          pinnedOrder
          action
          style
        }
        promos(product: $product, brand: $brand) {
          rootParentPromoID
          isAboveTheLine
          promoPath
          isPromoTagShown
        }
        type {
          code
        }
        status {
          code
        }
        video {
          onTvg
          onTvg2
        }
      }
    }
  }
`;
