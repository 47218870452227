import { useQuery } from "@apollo/client";
import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { SeoTrack } from "@tvg/ts-types/Track";
import { getWagerProfile } from "@urp/store-selectors";
import { breakpoints, useMediaQuery } from "@tvg/design-system";

import { GET_TRACKS_SEO } from "../graphql/queries/TracksListSeo";
import {
  getFavoriteTracks,
  getRaceTracksIsSearchActive,
  getRaceTracksSearchQuery,
  getRaceTypesFilter,
  getRegionsFilter,
  getTrackTypesFilter
} from "../redux/selectors";
import { searchTracks } from "../utils/trackInfo";
import { filterTracksByRegion } from "../utils/races";
import { filterTrackByTrackType } from "../utils/filterTracks";
import { TrackFilterValueEnum } from "../types";

const useSeoTracks = (
  fcpClient: ApolloClient<NormalizedCacheObject> | undefined
) => {
  const wagerProfile = useSelector(getWagerProfile);
  const isSearchActive = useSelector(getRaceTracksIsSearchActive);
  const searchQuery = useSelector(getRaceTracksSearchQuery);
  const regionsFilter = useSelector(getRegionsFilter);
  const raceTypesFilter = useSelector(getRaceTypesFilter);
  const trackTypesFilter = useSelector(getTrackTypesFilter);
  const favoriteTracks = useSelector(getFavoriteTracks);
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  const { data, loading } = useQuery<{ pastTracks: SeoTrack[] }>(
    GET_TRACKS_SEO,
    {
      client: fcpClient,
      variables: {
        wagerProfile
      }
    }
  );

  const results = data?.pastTracks ?? [];

  const filtered = useMemo(() => {
    let tracksFilter = results;

    if (isSearchActive) {
      tracksFilter = searchTracks(tracksFilter, searchQuery) as Array<SeoTrack>;
    } else {
      if (regionsFilter) {
        tracksFilter = filterTracksByRegion(
          tracksFilter,
          regionsFilter
        ) as Array<SeoTrack>;
      }

      if (isMobile && raceTypesFilter) {
        tracksFilter = tracksFilter.filter(
          (track) =>
            !track.raceTypes ||
            track.raceTypes.some((raceType) =>
              raceTypesFilter.includes(raceType)
            )
        );
      }

      // TODO: This filter depends on the BE to add "featured" prop on SeoTracks service
      if (
        trackTypesFilter?.length &&
        trackTypesFilter.includes(TrackFilterValueEnum.FAVORITE)
      ) {
        tracksFilter = filterTrackByTrackType(
          tracksFilter,
          [TrackFilterValueEnum.FAVORITE],
          favoriteTracks
        );
      }
    }
    return tracksFilter;
  }, [searchQuery, results, regionsFilter, raceTypesFilter, trackTypesFilter]);

  return { results, filtered, loading };
};

export default useSeoTracks;
