import { isTvg5 } from "@tvg/utils/generalUtils";
import { get, orderBy } from "lodash";
import type { Dispatch } from "redux";
import type { BrazeContentCard } from "@tvg/braze/src/types";
import { setBrazeContentCards } from "@tvg/braze/src/store/actions";
import mediator from "@tvg/mediator";
import { EXTERNAL_LINK_REGEX } from "./constants";

const PATHS_EXCLUDED = ["racetracks", "my-bets", "promos"];

export const isPathExcluded = (path: string) =>
  PATHS_EXCLUDED.some((value) => path.includes(value));

export const shouldRenderInboxBtn = (
  path: string,
  isLogged: boolean,
  toggle: boolean
) => isLogged && (!isPathExcluded(path) || isTvg5()) && toggle;

export const isExternalLink = (url: string) => EXTERNAL_LINK_REGEX.test(url);

export const getLinkProps = (url: string) =>
  isExternalLink(url || "")
    ? { as: "a", href: url, target: "_blank", rel: "noopener noreferrer" }
    : { to: url };

export const subscribeWrapperEvents = (dispatch: Dispatch) => {
  mediator.ios.subscribe(
    "SET_CONTENT_CARDS",
    (data: { payload: BrazeContentCard[] }) => {
      const { payload } = data;
      if (get(payload, "cards")) {
        const objCards = get(payload, "cards", []);
        dispatch(
          setBrazeContentCards({
            unviewed: get(payload, "unviewed", 0),
            totalCards: get(payload, "cards.length", 0),
            cards: orderBy(
              typeof objCards === "string" ? JSON.parse(objCards) : objCards,
              ["pinned", "createdAt"],
              ["desc", "desc"]
            )
          })
        );
      }
    }
  );
};
