import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import Poller from "@tvg/poller";
import tvgConf from "@tvg/conf";
import { setReloadApp } from "../store/actions/page";

const useReloadApp = () => {
  const dispatch = useDispatch();
  const [refreshTimestamp, setRefreshTimestamp] = useState<number>(Infinity);

  useEffect(() => {
    const poller = new Poller();
    poller.start(
      () =>
        axios
          .get(`${tvgConf().config("service.capi")}/devices/desktop`)
          .then(({ data }) => {
            if (data && data.refreshTimestamp) {
              const newRefreshTimestamp = new Date(
                data.refreshTimestamp
              ).getTime();

              if (
                newRefreshTimestamp &&
                newRefreshTimestamp > refreshTimestamp
              ) {
                dispatch(setReloadApp(true));
              }
              setRefreshTimestamp(newRefreshTimestamp);
            }
          }),
      60000
    );

    return () => {
      poller.stop();
    };
  }, [refreshTimestamp]);
};

export default useReloadApp;
