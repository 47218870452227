import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BrazeContentCard } from "@tvg/braze/src/types";
import { brazeReadContentCard, dismissContentCard } from "@tvg/braze";
import { setBrazeContentCards } from "@tvg/braze/src/store/actions";
import { getBrazeMessages } from "../../redux/selectors";
import { closeInboxModal } from "../../redux/actions";
import {
  getSortedCards,
  getViewedCards,
  onClickContentCard as onClickCCUtil
} from "./utils";
import {
  sendEventBrazeCardImpression,
  sendEventBrazeDismissCard
} from "../../utils/amplitude";

const useContentCards = (listOfBrazeCards: BrazeContentCard[]) => {
  const [dismissCardIdShown, setDismissCardIdShown] = useState("");
  const listOfViewed = getViewedCards(listOfBrazeCards);
  const isMounted = useRef(false);
  const viewedCards = useRef<BrazeContentCard[]>(listOfViewed);
  const brazeMessages = useSelector(getBrazeMessages);
  const dispatch = useDispatch();

  const sortedCards = getSortedCards(listOfBrazeCards);

  useEffect(() => {
    if (isMounted.current) {
      brazeReadContentCard(viewedCards.current, true);
    } else {
      isMounted.current = true;
    }
  }, [viewedCards.current.length]);

  const onViewCard = useCallback((id) => {
    let position = 0;
    const viewedCard = sortedCards.find((item, index) => {
      position = index;
      return item.id === id;
    });

    if (viewedCard && viewedCards.current.indexOf(viewedCard) === -1) {
      viewedCards.current.push(viewedCard);
      dispatch(
        setBrazeContentCards({
          cards: sortedCards,
          totalCards: sortedCards.length,
          unviewed: sortedCards.length - viewedCards.current.length
        })
      );
      sendEventBrazeCardImpression(viewedCard, position);
    }
  }, []);

  const onDismissContentCard = (card: BrazeContentCard, index: number) => {
    sendEventBrazeDismissCard(card, index);
    dismissContentCard(card, dispatch);
  };

  const onClickContentCard = (card: BrazeContentCard, index: number) => {
    if (card.url) {
      dispatch(closeInboxModal());
    }
    onClickCCUtil(card, index);
  };

  return {
    brazeMessages,
    sortedCards,
    onViewCard,
    onClickContentCard,
    onDismissContentCard,
    dismissCardIdShown,
    setDismissCardIdShown
  };
};

export default useContentCards;
