import React from "react";
import { createPortal } from "react-dom";
import { WarningPortalProps } from "../../types";
import {
  WarningPortalOverlay,
  WarningPortalContent
} from "./styled-components";

const WarningPortal: React.FC<WarningPortalProps> = ({ children, position }) =>
  createPortal(
    <WarningPortalOverlay>
      <WarningPortalContent top={position.top} left={position.left}>
        {children}
      </WarningPortalContent>
    </WarningPortalOverlay>,
    document.body
  );

export default WarningPortal;
