import { gql } from "@apollo/client";
import TimeAndStatusFragments from "../fragments/TimeAndStatus";
import RaceDetailsFragments from "../fragments/RaceDetails";
import VideoFragments from "../fragments/Video";
import ResultsFragments from "../fragments/ResultsFragment";

export const GET_RESULTED_RACES = gql`
  query getGraphRace(
    $trackAbbr: String
    $wagerProfile: String
    $sortBy: RaceListSort
  ) {
    races: races(
      filter: { trackCode: [$trackAbbr], status: ["RO"] }
      profile: $wagerProfile
      sort: $sortBy
    ) {
      id
      track {
        name
        code
      }
      ...RaceDetails
      ...TimeAndStatus
      ...Video
      ...Results
    }
  }
  ${RaceDetailsFragments.entry}
  ${TimeAndStatusFragments.entry}
  ${VideoFragments.entry}
  ${ResultsFragments.entry}
`;
