export default class DomMutator {
  html: null | Element;

  cssEntries: Element[];

  jsEntries: Element[];

  constructor() {
    this.html = null;
    this.cssEntries = [];
    this.jsEntries = [];
  }

  addCSS(url: string) {
    // if (this.cssEntries.length) return;
    const link = document.createElement("link");
    link.setAttribute("rel", "preload");
    link.setAttribute("as", "style");
    link.setAttribute("onload", "this.onload=null;this.rel='stylesheet'");
    link.setAttribute("href", url);
    document.head.appendChild(link);
    this.cssEntries.push(link);
  }

  addJS(url: string) {
    // if (this.jsEntries.length) return;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", url);
      script.setAttribute("defer", "");
      script.setAttribute("async", "");
      script.onload = () => {
        resolve(url);
      };
      script.onerror = () => {
        reject();
      };
      document.getElementById("tvg4")?.appendChild(script);
      this.jsEntries.push(script);
    });
  }

  addHTML(html: string) {
    // if (html) return;
    const node = document.createElement("div");
    node.innerHTML = html;
    document.getElementById("loadingTvg4")?.remove();
    document.getElementById("tvg4")?.appendChild(node);
    this.html = node;
  }

  clearActiveApp = () => {
    if (typeof window !== "undefined") {
      // @ts-ignore
      window.angular = undefined;
    }
    this.html = null;
    this.cssEntries = [];
    this.jsEntries = [];
  };
}
