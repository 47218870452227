import styled from "styled-components";

export const Container = styled.div`
  padding: ${({ theme }) => theme.space["space-4"]};
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;

  > button:first-child,
  > button:last-child {
    flex: 1;
  }
`;
