import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

const useAnchorInterceptor = () => {
  const { pathname, hash } = useLocation();
  const [searchParams] = useSearchParams();
  const selectedRaceNumber = searchParams.get("race");
  // if not a hash link, scroll to top, else scroll to id
  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, selectedRaceNumber]);
};

export default useAnchorInterceptor;
