import React from "react";
import { FilterButtonBar, FilterButton } from "@tvg/design-system/web";
import { SearchOptionsProps, SearchOption } from "./types";
import { FiltersContainer, FilterLabel } from "./styled-components";

const SearchOptions: React.FC<SearchOptionsProps> = ({
  label,
  options,
  selectedOption,
  onSelectedChange,
  qaLabel
}) => (
  <FiltersContainer>
    <FilterLabel>{label}</FilterLabel>
    <FilterButtonBar
      mt="space-1"
      qaLabel={`${qaLabel}-filterButtonBar`}
      activeButtons={[selectedOption]}
      onButtonClick={onSelectedChange}
    >
      {options.map((option: SearchOption) => (
        <FilterButton
          key={option.key}
          value={option.key}
          qaLabel={`${qaLabel}-${option.key}`}
        >
          {option.label}
        </FilterButton>
      ))}
    </FilterButtonBar>
  </FiltersContainer>
);

export default SearchOptions;
