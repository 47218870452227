import styled from "styled-components";
import { IsDesktopComponent } from "../../types";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const BadgeContainer = styled.div<IsDesktopComponent>`
  position: absolute;
  top: 0;
  right: 12px;
  z-index: 2;
`;
