import { get } from "lodash";
import { TrackFilterValueEnum } from "@urp/lib-racetracks/src/types";
import { buildQaLabel } from "@tvg/test-utils/utils";
import type { FilterProps, MapFilterPropsArgs } from "./types";
import {
  BETTING_FEATURE_OPTIONS,
  DISTANCE_OPTIONS,
  RACE_TYPE_OPTIONS,
  REGION_OPTIONS,
  TRACKS_OPTIONS
} from "./constants";
import { SupportedFilters } from "../../types";
import { FILTER } from "../../utils/constants";

export const parseTrackOptions = (isLogged: boolean) =>
  TRACKS_OPTIONS.filter(
    (item) => !(item.value === TrackFilterValueEnum.FAVORITE && !isLogged)
  );

export const mergeFilter = (selections: string[], selectionChange: string) =>
  selections.includes(selectionChange)
    ? selections.filter((raceType) => raceType !== selectionChange)
    : [...selections, selectionChange];

export const mapFilterProps = ({
  currentTab,
  isLogged,
  qaLabel = "",
  handleRaceTypeChange,
  raceTypesFilter = [],
  handleRegionChange,
  regionsFilter = [],
  handleTrackChange,
  trackTypesFilter = [],
  handleRaceFilter,
  racesFilter = [],
  handleDistanceFilter,
  distancesFilter = []
}: MapFilterPropsArgs): Record<SupportedFilters, FilterProps> => ({
  [SupportedFilters.RACE_TYPE]: {
    options: RACE_TYPE_OPTIONS,
    qaLabel: buildQaLabel([qaLabel, FILTER, SupportedFilters.RACE_TYPE]),
    onChange: (values: string[]) => handleRaceTypeChange(values, currentTab),
    selectedValue: raceTypesFilter,
    filterKey: "raceTypesFilter"
  },
  [SupportedFilters.REGION]: {
    options: REGION_OPTIONS,
    qaLabel: buildQaLabel([qaLabel, FILTER, SupportedFilters.REGION]),
    onChange: (values: string[]) => handleRegionChange(values, currentTab),
    selectedValue: regionsFilter,
    filterKey: "regionsFilter"
  },
  [SupportedFilters.TRACK_TYPE]: {
    options: parseTrackOptions(isLogged),
    qaLabel: buildQaLabel([qaLabel, FILTER, SupportedFilters.TRACK_TYPE]),
    onChange: (values: string[]) => handleTrackChange(values, currentTab),
    selectedValue: trackTypesFilter,
    filterKey: "trackTypesFilter"
  },

  [SupportedFilters.BETTING_FEATURE]: {
    options: BETTING_FEATURE_OPTIONS,
    qaLabel: buildQaLabel([qaLabel, FILTER, SupportedFilters.BETTING_FEATURE]),
    onChange: (values: string[]) => handleRaceFilter(values, currentTab),
    selectedValue: racesFilter,
    filterKey: "racesFilter"
  },
  [SupportedFilters.DISTANCE]: {
    options: DISTANCE_OPTIONS,
    qaLabel: buildQaLabel([qaLabel, FILTER, SupportedFilters.DISTANCE]),
    onChange: (values: string[]) => handleDistanceFilter(values, currentTab),
    selectedValue: distancesFilter,
    filterKey: "distancesFilter"
  }
});

export const getMappedProps = (
  id: SupportedFilters | undefined,
  args: MapFilterPropsArgs
) => {
  if (!id) return undefined;

  const allProps = mapFilterProps(args);

  const filterProps = get(allProps, id);

  return filterProps;
};
