import React, { FC, memo } from "react";
import { Paragraph } from "../../../typography";
import { RaceInfo } from "../raceInfo";
import { Container } from "../../styled-components";
import { MtpCellProps } from "../../types";
import { useQaLabel } from "../../../../hooks";
import { useColor } from "../../../../theming";

export const Resulted: FC<
  Pick<
    MtpCellProps,
    "withBackground" | "align" | "qaLabel" | "trackCode" | "raceNumber"
  >
> = memo(
  ({
    align,
    withBackground,
    qaLabel = "mtp-cell",
    trackCode,
    raceNumber,
    ...rest
  }) => {
    const qaLabelProps = useQaLabel(qaLabel);
    const raceLabel = `${trackCode} - R${raceNumber}`;

    return (
      <Container
        withBackground={withBackground}
        align={align}
        bgColor={useColor("blue.000")}
        lineColor={useColor("blue.100")}
        {...qaLabelProps}
        {...rest}
      >
        <Paragraph
          fontFamily="medium"
          textAlign={align}
          qaLabel={`${qaLabel}-race-status`}
          fontSize="18px"
          lineHeight="22px"
          color="blue.400"
        >
          Resulted
        </Paragraph>
        <RaceInfo align={align} label={raceLabel} qaLabel={qaLabel} />
      </Container>
    );
  }
);
