import React from "react";
import { List, ListItem } from "./styled-components";
import { Props } from "./types";

const ListItems = ({
  items,
  searchTerm,
  handleOnClick,
  activeIndex,
  listRef
}: Props) => (
  <List ref={listRef} role="listbox">
    {items.map((item, index) => {
      const element = item.label.toLowerCase();
      const term = searchTerm.toLowerCase();
      const firstPos = element.indexOf(term);
      const termLength = term.length;

      return (
        <ListItem
          key={`${item.value}-${index.toString()}`}
          id={`item-${index}`}
          role="option"
          tabIndex={0}
          aria-selected={index === activeIndex}
          onClick={() => handleOnClick(item)}
        >
          <span>{item.label.substring(0, firstPos)}</span>
          <strong>
            {item.label.substring(firstPos, firstPos + termLength)}
          </strong>
          <span>{item.label.substring(firstPos + termLength)}</span>
        </ListItem>
      );
    })}
  </List>
);

export default ListItems;
