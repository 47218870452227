import React, { SyntheticEvent, FC } from "react";
import { Paragraph, Header } from "@tvg/design-system";
import {
  bettingOverride,
  IconButton,
  secondaryOverride,
  tertiaryOverride
} from "@tvg/design-system/web";
import PreferenceService from "@tvg/api/uam";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceIsShown } from "@tvg/sh-lib-preferences/redux/selectors";
import { ToggleStateEnum } from "@tvg/sh-lib-preferences/utils/types";
import { balanceShownAction } from "@tvg/sh-lib-preferences/redux/actions";
import { isFDR } from "@tvg/utils/generalUtils";
import {
  getBalance,
  getAccountNumber,
  getFdUserId,
  getFirstName,
  getLastName
} from "@urp/store-selectors";
import { attempt } from "lodash";
import { computeBalanceDisplay } from "@tvg/utils/balance";
import {
  Container,
  UserInfoContainer,
  BalanceContainer,
  HideButtonContainer,
  ButtonsContainer,
  ButtonsWidthText
} from "./styled-components";

type Variant = "light" | "default";

interface Props {
  variant?: Variant;
  depositOnClick: (event: SyntheticEvent) => void;
  withdrawOnClick: (event: SyntheticEvent) => void;
  walletOnClick: (event: SyntheticEvent) => void;
}

const AccountHeader: FC<Props> = ({
  variant = "light",
  withdrawOnClick,
  depositOnClick,
  walletOnClick
}) => {
  const dispatch = useDispatch();
  const accountNumberTvg = useSelector(getAccountNumber);
  const fdUserId = useSelector(getFdUserId);
  const firstName = useSelector(getFirstName);
  const balance = useSelector(getBalance);
  const lastName = useSelector(getLastName);
  const balanceIsShown = useSelector(getBalanceIsShown);
  const accountNumberToShow = isFDR() ? fdUserId : accountNumberTvg;

  const displayText = computeBalanceDisplay({
    balance,
    isBalanceHidden: balanceIsShown === ToggleStateEnum.OFF
  });

  const toggleBalanceVisibility = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (accountNumberTvg) {
      const hideBalance =
        balanceIsShown === ToggleStateEnum.OFF
          ? ToggleStateEnum.ON
          : ToggleStateEnum.OFF;

      await PreferenceService.setUserPref(
        accountNumberTvg,
        "balance_is_shown",
        hideBalance
      );

      attempt(() => {
        if (typeof window !== "undefined") {
          window.localStorage.setItem("balance_is_shown", hideBalance);
        }
      });

      dispatch(balanceShownAction(hideBalance));
    }
  };

  return (
    <Container data-qa-label="account-header" variant={variant}>
      <UserInfoContainer>
        <Paragraph
          fontFamily="medium"
          qaLabel="account-number-name"
          color={variant === "light" ? "grey.900" : "white.100"}
        >
          {`#${accountNumberToShow} • ${firstName} ${lastName}`}
        </Paragraph>
      </UserInfoContainer>
      <HideButtonContainer>
        <IconButton
          qaLabel="account-hide-balance-button"
          iconName={balanceIsShown === "1" ? "eyeHide" : "eyeShow"}
          overrideColors={tertiaryOverride}
          size="s"
          onClick={toggleBalanceVisibility}
        />
      </HideButtonContainer>
      <BalanceContainer>
        <Header
          tag="h1"
          fontFamily="bold"
          qaLabel="account-balance"
          color={variant === "light" ? "grey.900" : "white.100"}
        >
          {displayText}
        </Header>
        <Paragraph
          fontFamily="regular"
          qaLabel="account-balance-label"
          color={variant === "light" ? "grey.800" : "white.100"}
        >
          PLAYABLE BALANCE
        </Paragraph>
      </BalanceContainer>
      <ButtonsContainer>
        <ButtonsWidthText>
          <IconButton
            qaLabel="account-deposit-button"
            iconName="deposit"
            overrideColors={
              variant === "light" ? bettingOverride : secondaryOverride
            }
            onClick={depositOnClick}
          />
          <Paragraph
            pt="space-3"
            fontFamily="regular"
            fontSize="xs"
            textTransform="uppercase"
            qaLabel="deposit-button-label"
            color={variant === "light" ? "grey.800" : "white.100"}
          >
            deposit
          </Paragraph>
        </ButtonsWidthText>
        <ButtonsWidthText>
          <IconButton
            qaLabel="account-withdraw-button"
            iconName="withdraw"
            overrideColors={secondaryOverride}
            onClick={withdrawOnClick}
            hasBorder
          />
          <Paragraph
            pt="space-3"
            fontFamily="regular"
            fontSize="xs"
            textTransform="uppercase"
            qaLabel="withdraw-button-label"
            color={variant === "light" ? "grey.800" : "white.100"}
          >
            withdraw
          </Paragraph>
        </ButtonsWidthText>
        {!isFDR() && (
          <ButtonsWidthText>
            <IconButton
              qaLabel="account-wallet-button"
              iconName="wallet"
              overrideColors={secondaryOverride}
              onClick={walletOnClick}
              hasBorder
            />
            <Paragraph
              pt="space-3"
              fontFamily="regular"
              fontSize="xs"
              textTransform="uppercase"
              qaLabel="wallet-button-label"
              color={variant === "light" ? "grey.800" : "white.100"}
            >
              wallet
            </Paragraph>
          </ButtonsWidthText>
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default AccountHeader;
