import React, { useState, useCallback } from "react";
import ApolloContext from "@tvg/utils/apolloContext";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { Store } from "redux";
import { get } from "lodash";
import { formatISO, parseJSON, subYears } from "date-fns";
import type { NullaryFn } from "@tvg/ts-types/Functional";
import { Containers } from "@fdr/static-ui/BaseComponents";
import AccountTransactions from "@fdr/account-transactions";
import ApolloContextClients from "@tvg/utils/types/ApolloContext";

const YEARS_TO_SUBTRACT = 1;
type Props = {
  isLogged: boolean;
  xSellMigrationDate: string;
  onClose?: NullaryFn<void>;
};

const TransactionHistoryPage = (props: Props) => {
  const [page, setPage] = useState(1);

  const changePage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      window.scrollTo(0, 0);
    },
    [page]
  );

  const timeFrameStartDate = formatISO(
    subYears(parseJSON(props.xSellMigrationDate), YEARS_TO_SUBTRACT)
  );

  return props.isLogged ? (
    <Containers.Page>
      <ApolloContext.Consumer>
        {(value: ApolloContextClients) => (
          <AccountTransactions
            page={page}
            changePage={changePage}
            gasClient={value.gasClient}
            timeFrameFilter="CUSTOM"
            timeFrameEndDate={props.xSellMigrationDate}
            timeFrameStartDate={timeFrameStartDate}
            onClose={props.onClose}
          />
        )}
      </ApolloContext.Consumer>
    </Containers.Page>
  ) : (
    <Navigate replace to="/" />
  );
};

TransactionHistoryPage.defaultProps = {
  isLogged: false,
  xSellMigrationDate: new Date().toISOString()
};

const mapStateToProps = (store: Store) => ({
  isLogged: get(store, "userData.logged", false),
  xSellMigrationDate: get(store, "capi.messages.xSellMigrationDate")
});

export default connect(mapStateToProps)(TransactionHistoryPage);
