import { sortBy, uniqBy } from "lodash";
import { differenceInMinutes } from "date-fns";
import { RaceProgram } from "../types";

const STATUS_ORDER = ["SK", "IC", "O"];

export const getPickNumberFromString = (
  description?: string,
  parsePick?: string
) => {
  if (description && parsePick) {
    if (description?.toLowerCase().includes(parsePick.toLowerCase())) {
      const pickNumber = description.match(/\d+/);

      return pickNumber?.length ? pickNumber[0] : undefined;
    }
  }

  return undefined;
};

export const filterClosedRaces = (
  raceList: RaceProgram[],
  minutesInRaceOff: string
) =>
  raceList.filter(
    (race: RaceProgram) =>
      race.mtp !== 0 ||
      (race.mtp === 0 &&
        !(
          differenceInMinutes(new Date(), new Date(race.postTime)) >
          Number(minutesInRaceOff)
        ))
  );

export const sortByStatus = (raceList: RaceProgram[]): RaceProgram[] =>
  sortBy(raceList, [
    (race: RaceProgram): number => {
      const statusCode = race.raceStatus.code;
      const orderStatus = STATUS_ORDER.indexOf(statusCode);
      const orderStatusValue =
        orderStatus >= 0 ? orderStatus : STATUS_ORDER.length;

      return race.mtp === 0 || statusCode === "SK"
        ? orderStatusValue
        : race.mtp + STATUS_ORDER.length;
    }
  ]);

export const showDescription = (
  title?: string,
  excludedLhnDescription?: Array<string>
) => {
  if (title && excludedLhnDescription) {
    const isExcluded = excludedLhnDescription.find((element: string) =>
      title.toLowerCase().includes(element.toLowerCase())
    );

    if (!isExcluded) return title;
  }
  return undefined;
};

export const LhnRacesFilter = (races: RaceProgram[]) =>
  races.filter((race) => race.raceStatus.code !== "RO");

export const isRaceWagered = (wageredRaces: RaceProgram[], race: RaceProgram) =>
  !!wageredRaces.find(
    ({ trackAbbr, raceNumber }) =>
      trackAbbr === race.trackAbbr && raceNumber === race.raceNumber
  );

export const hasWagerToday = (wageredRaces: RaceProgram[], race: RaceProgram) =>
  !!wageredRaces.find(({ trackAbbr }) => trackAbbr === race.trackAbbr);

export const getTvgRaceIds = (allRaces: RaceProgram[]) =>
  uniqBy(allRaces, "trackAbbr").map((race) => race.tvgRaceId);

export const getTvgTrackAbbrs = (allRaces: RaceProgram[]) =>
  uniqBy(allRaces, "trackAbbr").map((race) => race.trackAbbr);

export const removeSimilarTracks = (
  races: RaceProgram[],
  featuredRaces: RaceProgram[]
) => {
  if (featuredRaces.length > 0) {
    return races.filter((race) =>
      featuredRaces.every((featRace) => featRace.trackAbbr !== race.trackAbbr)
    );
  }
  return races;
};
