import React, { memo } from "react";

const Race = () => (
  <svg
    width="184"
    height="auto"
    viewBox="0 0 184 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.25" y="0.5" width="182.001" height="83" rx="3.5" fill="white" />
    <rect
      x="1.25"
      y="0.5"
      width="182.001"
      height="83"
      rx="3.5"
      stroke="#EAF0F6"
    />
    <g clipPath="url(#clip0_39045_267465)">
      <rect
        x="8.75"
        y="8"
        width="18"
        height="18"
        rx="1"
        fill="#0A0A0A"
        fillOpacity="0.05"
        className="pulse"
      />
    </g>
    <g clipPath="url(#clip1_39045_267465)">
      <rect
        x="147.751"
        y="10"
        width="28"
        height="14"
        rx="1"
        fill="#0A0A0A"
        fillOpacity="0.05"
        className="pulse"
      />
    </g>
    <rect
      x="8.75"
      y="32"
      width="83.4951"
      height="16"
      rx="1"
      fill="#0A0A0A"
      fillOpacity="0.05"
      className="pulse"
    />
    <rect
      x="100.245"
      y="32"
      width="16"
      height="16"
      rx="1"
      fill="#0A0A0A"
      fillOpacity="0.05"
      className="pulse"
    />
    <rect
      x="8.75"
      y="58"
      width="16"
      height="16"
      rx="8"
      fill="#0A0A0A"
      fillOpacity="0.05"
      className="pulse"
    />
    <rect
      x="28.75"
      y="58"
      width="16"
      height="16"
      rx="1"
      fill="#0A0A0A"
      fillOpacity="0.05"
      className="pulse"
    />
    <rect
      x="119.751"
      y="58"
      width="16"
      height="16"
      rx="1"
      fill="#0A0A0A"
      fillOpacity="0.05"
      className="pulse"
    />
    <rect
      x="139.751"
      y="58"
      width="16"
      height="16"
      rx="1"
      fill="#0A0A0A"
      fillOpacity="0.05"
      className="pulse"
    />
    <rect
      x="159.751"
      y="58"
      width="16"
      height="16"
      rx="1"
      fill="#0A0A0A"
      fillOpacity="0.05"
      className="pulse"
    />
    <defs>
      <clipPath id="clip0_39045_267465">
        <rect x="8.75" y="8" width="18" height="18" rx="1" fill="white" />
      </clipPath>
      <clipPath id="clip1_39045_267465">
        <rect x="147.751" y="10" width="28" height="14" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(Race);
