import React, { memo } from "react";
import { LinkItem } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { get } from "lodash";
import SectionTitle from "../SectionTitle";
import type { Props } from "./types";
import FavoriteIcon from "../FavoriteIcon";
import { LINK_ITEM, LOCATION } from "../../utils/constants";

const TracksInformationLocation = ({
  title,
  dictionary,
  onTrackClick,
  isFavorite,
  toggleFavorite
}: Props) => (
  <div key={title}>
    <SectionTitle>{title}</SectionTitle>
    <LinkItem.List qaLabel={buildQaLabel([LINK_ITEM, LOCATION, title])}>
      {get(dictionary, title, []).map((track) => (
        <LinkItem
          key={track.code}
          qaLabel={track.name}
          title={{
            render: track.name
          }}
          start={
            <FavoriteIcon
              isFavorite={isFavorite(track.code)}
              toggleFavorite={() => toggleFavorite(track.code, track.name)}
              trackName={track.name}
            />
          }
          hasArrowRight
          onPress={() => onTrackClick(track)}
        />
      ))}
    </LinkItem.List>
  </div>
);

export default memo(TracksInformationLocation);
