import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { SessionInfoWrapper } from "./styled-components";

function formatToTwoDigits(n) {
  return n > 9 ? n : `0${n}`;
}

// format like: HH:MM:SS - where hours can go above 24 hours
function startTimeInterval(startAt, callback) {
  const interval = setInterval(() => {
    const currentTime = new Date();
    const secondsElapsed = (currentTime.getTime() - startAt.getTime()) / 1000;
    const hoursElapsed = secondsElapsed / 3600;
    const includeHours = hoursElapsed > 1;
    const time =
      (includeHours ? `${formatToTwoDigits(Math.floor(hoursElapsed))}:` : ``) +
      new Date(secondsElapsed * 1000).toISOString().substr(14, 5);

    callback(time);
  }, 1000);

  return interval;
}

export const SessionInfo = ({ sessionStartAt, qaLabel = "session-info" }) => {
  const [sessionTime, setSessionTime] = useState(null);

  useEffect(() => {
    const interval =
      sessionStartAt && startTimeInterval(sessionStartAt, setSessionTime);
    return () => clearInterval(interval);
  }, [sessionStartAt]);

  return (
    <SessionInfoWrapper data-qa-label={qaLabel}>
      <div>
        Current session time:{" "}
        <span data-qa-label={`${qaLabel}-time`}>{sessionTime}</span>
      </div>
      <div>
        Last login:{" "}
        <span data-qa-label={`${qaLabel}-date`}>
          {sessionStartAt && format(sessionStartAt, "MM/dd/yyyy, h:mm:ss a")}
        </span>
      </div>
    </SessionInfoWrapper>
  );
};

export default SessionInfo;
