import React, { useState, memo, useContext, useMemo } from "react";
import { useColorTokens } from "../../../../theming";
import { TvgConfContext } from "../../../../utils/tvgConfProvider";
import {
  ButtonContainer,
  ButtonText,
  StyledButton,
  TextContainer,
  ButtonRing
} from "./styled-components";
import { Icon } from "../../../icon";
import { ButtonProps } from "./types";

export const Button = memo(
  ({
    onPress,
    icon,
    qaLabel,
    text,
    isSelected = false,
    isFullWidth = false,
    isFirstChild = false,
    isLastChild = false,
    hasShadow = false,
    isHoveringAll = false,
    onMouseEnter,
    onMouseLeave,
    ...props
  }: ButtonProps) => {
    const colorTokens = useColorTokens();
    const { device } = useContext(TvgConfContext);
    const isMobile = device === "mobile";
    const [isHovering, setIsHovering] = useState(false);
    const [isPressing, setIsPressing] = useState(false);

    const handleTextColor = useMemo(() => {
      if (isSelected && isHovering) {
        return colorTokens.component.button.secondary.content.base;
      }
      if (isSelected || isPressing) {
        return colorTokens.component.button.secondary.content.hover;
      }
      if (isHovering || isHoveringAll) {
        return colorTokens.component.button.tertiary.content.hover;
      }
      return colorTokens.component.button.tertiary.content.base;
    }, [isSelected, isHovering, isHoveringAll, isPressing]);

    const onMouseLeaveFn = () => {
      setIsHovering(false);
      if (typeof onMouseLeave === "function") {
        onMouseLeave();
      }
    };

    return (
      <ButtonContainer
        onMouseEnter={() => {
          setIsHovering(true);
          if (typeof onMouseEnter === "function") {
            onMouseEnter();
          }
        }}
        onMouseLeave={onMouseLeaveFn}
        isFullWidth={isFullWidth}
        isActive={isSelected}
        isHovering={isHovering || isHoveringAll}
        {...props}
      >
        <StyledButton
          hasBorderRadius={isFirstChild && isLastChild}
          onPressIn={() => setIsPressing(true)}
          onPressOut={() => setIsPressing(false)}
          onPress={() => {
            onPress();
            if (isMobile) {
              onMouseLeaveFn();
            }
          }}
          qaLabel={qaLabel}
          isActive={isSelected}
          isFullWidth={isFullWidth}
          isHovering={isHovering || isHoveringAll}
          hasShadow={hasShadow}
        >
          <TextContainer>
            {icon && (
              <Icon
                name={icon}
                size="s"
                lineColor={handleTextColor}
                mr="space-3"
              />
            )}
            <ButtonText
              color={handleTextColor}
              isHovering={isHovering || isHoveringAll}
              isActive={isSelected}
              qaLabel="button-text"
            >
              {text}
            </ButtonText>
          </TextContainer>
        </StyledButton>
        <ButtonRing
          pointerEvents="none"
          isFirstChild={isFirstChild}
          isLastChild={isLastChild}
          isActive={isSelected || isPressing || (isSelected && isHovering)}
          isHovering={isHovering || isHoveringAll}
        />
      </ButtonContainer>
    );
  }
);

export default Button;
