import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { Track } from "@tvg/ts-types/Track";
import { getWagerProfile } from "@urp/store-selectors";
import { FilterOptions } from "../types";
import {
  findTrack,
  getOpenTracksRaces,
  filterTracksWithRaces,
  isTrackOpen,
  hasFiltersApplied
} from "../utils/races";
import { sendOpenAnalyticEvt } from "../utils/analytics";
import { getOpenTracks } from "../redux/selectors";
import { toggleOpenTrackRow as toggleOpenTrackRowAction } from "../redux/actions/tracks";
import useTodaysTracks from "./useTodaysTracks";
import useTodaysRaces from "./useTodaysRaces";

interface Props extends FilterOptions {
  tracksFilter?: Array<string>;
}

export default ({
  tracksFilter = [],
  regionsFilter = [],
  raceTypesFilter = [],
  raceStatusFilter = [],
  tracksSearchQuery = "",
  trackTypesFilter = [],
  racesFilter = [],
  distancesFilter = []
}: Props) => {
  const [tracksRaces, setTracksRaces] = useState<Array<Track>>([]);
  const [processingTracksRaces, setProcessingTracksRaces] = useState(true);

  const dispatch = useDispatch();
  const wagerProfile = useSelector(getWagerProfile);
  const openTracks = useSelector(getOpenTracks);
  const onlyTracksWithRaces = hasFiltersApplied(
    {
      raceTypesFilter,
      raceStatusFilter,
      distancesFilter,
      racesFilter
    },
    true
  );
  const { loading: tracksLoading, tracks } = useTodaysTracks({
    searchQuery: tracksSearchQuery,
    wagerProfile,
    tracksFilter,
    regionsFilter,
    trackTypesFilter
  });
  const { loading: todaysRacesLoading, races: todaysRaces } = useTodaysRaces({
    wagerProfile,
    tracksFilter: onlyTracksWithRaces
      ? tracks.map((track) => track.code)
      : openTracks,
    raceStatusFilter,
    raceTypesFilter,
    regionsFilter,
    distancesFilter,
    racesFilter
  });

  useEffect(() => {
    if (!tracksLoading && !todaysRacesLoading) {
      const tracksFiltered = onlyTracksWithRaces
        ? filterTracksWithRaces(tracks, todaysRaces)
        : tracks;
      const newOpenTracksRaces = getOpenTracksRaces(
        openTracks,
        tracksFiltered,
        todaysRaces
      );

      setTracksRaces(newOpenTracksRaces);
      setProcessingTracksRaces(false);
    }
  }, [
    tracksLoading,
    todaysRacesLoading,
    JSON.stringify(tracks),
    JSON.stringify(todaysRaces),
    JSON.stringify(openTracks)
  ]);

  const toggleOpenTracks = (trackCode: string): string[] =>
    isTrackOpen(openTracks, trackCode)
      ? openTracks.filter((openTrackCode) => openTrackCode !== trackCode)
      : [...openTracks, trackCode];

  const toggleOpenTrackRow = (trackCode: string) => {
    const newOpenTracks = toggleOpenTracks(trackCode);
    dispatch(toggleOpenTrackRowAction(newOpenTracks));
  };

  const onTrackClickHandler = useCallback(
    (trackCode: string, isResults: boolean = false) => {
      toggleOpenTrackRow(trackCode);
      const isOpen = isTrackOpen(openTracks, trackCode);
      const track = findTrack(tracks, trackCode);
      sendOpenAnalyticEvt({
        trackName: track?.name || "",
        isOpen,
        isResults
      });
    },
    [openTracks, tracks]
  );

  const isSearchOrFilterApplied = hasFiltersApplied(
    {
      raceTypesFilter,
      regionsFilter,
      distancesFilter,
      racesFilter,
      trackTypesFilter,
      raceStatusFilter,
      tracksSearchQuery
    },
    true
  );

  return {
    processingTracksRaces,
    loading: todaysRacesLoading || tracksLoading,
    tracksRaces,
    openTracks,
    isTrackOpen,
    onTrackClickHandler,
    isSearchOrFilterApplied,
    toggleOpenTrackRow
  };
};
