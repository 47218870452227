import styled, { css, keyframes } from "styled-components";
import { Props } from "./types";

const slideIn = keyframes`
  from {
    width: 0;
  }
  to {
    width: 375px;
  }
`;

const slideOut = keyframes`
  from {
    width: 375px;
  }
  to {
    width: 0;
  }
`;

const show = keyframes`
    0% {
        display: none;
        background-color: transparent;
    }
    10% {
        display: flex;
        background-color: transparent;
    }
    100% {
        display: flex;
        background-color: #00000080;
    }
`;

const hide = keyframes`
    0% {
        display: flex;
        background-color: #00000080;
    }
    90% {
        display: flex;
        background-color: transparent;
    }
    100% {
        display: none;
        background-color: transparent;
    }
`;

const showContent = keyframes`
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const hideContent = keyframes`
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
`;

const fadeAnimation = css<Pick<Props, "isOpen">>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
      background-color: transparent;
    `}
  animation: ${({ isOpen }) => (isOpen ? show : hide)} 0.6s forwards;
`;

const slideAnimation = css<Pick<Props, "isOpen">>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      width: 0;
    `}
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.5s forwards;
`;

const showContentAnimation = css<Pick<Props, "isOpen">>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
    `}
  animation: ${({ isOpen }) =>
    isOpen ? showContent : hideContent} 0.4s forwards;
`;

export const Panel = styled.div<Pick<Props, "isOpen">>`
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-child,
  & > div:last-child {
    ${showContentAnimation};
  }
`;

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
`;

export const Container = styled.div<Pick<Props, "isOpen">>`
  height: 100vh;
  width: 100%;
  z-index: 1;
  position: fixed;
  ${fadeAnimation};

  & > div:last-child {
    ${slideAnimation};
  }
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.space["space-5"]};
  height: 100%;
  overflow: auto;
`;

export const LefHeaderEmpty = styled.div`
  width: 2px;
`;
