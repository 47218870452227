import * as brazeInboxReducers from "./redux/reducers";

export enum CARD_SC {
  CONTROL_CARD = "ab-control-card",
  BANNER = "ab-image-only",
  CAPTIONED_IMG = "ab-captioned-image",
  CLASSIC_CARD = "ab-classic-card"
}

export type BrazeInboxState = typeof brazeInboxReducers;

export interface IsDesktopComponent {
  isDesktop: boolean;
}
