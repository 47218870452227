import React from "react";
import color from "@fdr/static-ui/ColorPalette";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const TracksSearchMask = () => (
  <Container>
    <SVG
      data-qa-label="search-loadingMask"
      width="375"
      height="66"
      viewBox="0 0 375 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="375" height="66" fill="white" />
      <g filter="url(#filter0_d_31985_162301)">
        <rect x="16" y="12" width="343" height="42" rx="2" fill="white" />
        <rect
          x="16.5"
          y="12.5"
          width="342"
          height="41"
          rx="1.5"
          stroke="#B0B7BF"
        />
        <rect
          className="pulse"
          x="28"
          y="24"
          width="16"
          height="16"
          rx="8"
          fill="#0A0A0A"
        />
        <rect
          className="pulse"
          x="52"
          y="24.5"
          width="166"
          height="15"
          rx="1"
          fill="#0A0A0A"
        />
      </g>
    </SVG>
  </Container>
);

export default TracksSearchMask;
