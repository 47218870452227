import styled, { css } from "styled-components";
import { fonts } from "@tvg/design-system/src/theming/themes/common/fonts";

export const AccountBalancesWrapper = styled.div`
  background-color: rgba(10, 10, 10, 0.4);
  padding: 16px;

  button {
    background: transparent;
  }
`;

const columnStyles = css`
  font-family: ${fonts.regular};
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
`;

const highlighted = (props: { isHighlighted?: boolean }) =>
  props.isHighlighted &&
  css`
    font-family: ${fonts.bold};
    color: ${({ theme }) => theme.colorTokens.content.default};
  `;

export const ColumnLabel = styled.div<{
  bolded?: boolean;
  isHighlighted?: boolean;
}>`
  ${columnStyles};
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  ${highlighted};
  ${({ bolded }) => bolded && "font-weight: bold;"}
`;

export const ColumnValue = styled.div<{
  withCursor?: boolean;
  bolded?: boolean;
  isHighlighted?: boolean;
}>`
  ${columnStyles};
  text-align: right;
  ${highlighted};
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  ${({ withCursor }) => withCursor && "cursor: pointer;"}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  height: 34px;
  &:first-of-type,
  &:last-of-type {
    padding-bottom: 16px;
  }
`;
