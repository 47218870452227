import { RaceProgram } from "@tvg/ts-types/Race";
import {
  PreferencesPayload,
  PreferencesType,
  ToastMessageStore,
  RunnerModifierEnum
} from "../types";

export type SetUserFavoriteTracks = {
  type: "SET_USER_FAVORITE_TRACKS";
  payload: {
    favoriteTracks: string[];
  };
};

export type SetFreePastPerformancesType = {
  type: "SET_FREE_PAST_PERFORMANCES";
  payload: Record<string, string>[];
};

export type UserPrefsSet = {
  type: "USER_PREFS_SET";
  payload: PreferencesPayload;
};

export type BetSlipExpandedAction = {
  type: "SET_BET_SLIP_EXPANDED";
  payload: boolean;
};

export type AddToast = {
  type: "ADD_TOAST";
  payload: {
    toastProps: ToastMessageStore;
  };
};

export type RemoveToast = {
  type: "REMOVE_TOAST";
  payload: {
    toastId: string;
  };
};

export type SetUserFavoriteRunners = {
  type: "SET_USER_FAVORITE_RUNNERS";
  payload: {
    favoriteRunners: Array<string>;
  };
};

export type AddUserFavoriteRunner = {
  type: "ADD_USER_FAVORITE_RUNNER";
  payload: {
    runner: string;
  };
};

export type RunnerModifierExpanded = {
  type: "SET_RUNNER_MODIFIER_EXPANDED";
  payload: boolean;
};

export type ClearToasts = {
  type: "CLEAR_TOASTS";
};

export type FixWagerSelector = {
  type: "FIX_WAGER_SELECTOR";
  payload: {
    isWagerSelectorFixed: boolean;
    fixedOffset: number;
  };
};

export type IsPoolsNInfoModalOpen = {
  type: "SET_IS_POOLS_AND_INFO_MODAL_OPEN";
  payload: PoolsInfoRaceModal;
};

export type SelectedRunnerModifier = {
  type: "SET_SELECTED_RUNNER_MODIFIER";
  payload: RunnerModifierEnum;
};

export type ResetProgramPageState = {
  type: "RESET_PROGRAM_PAGE_STATE";
};

export type SetBetSlipHeight = {
  type: "SET_BETSLIP_HEIGHT";
  payload: number;
};

export type Actions =
  | SetUserFavoriteTracks
  | UserPrefsSet
  | BetSlipExpandedAction
  | AddToast
  | RemoveToast
  | SetUserFavoriteRunners
  | AddUserFavoriteRunner
  | RunnerModifierExpanded
  | ClearToasts
  | FixWagerSelector
  | IsPoolsNInfoModalOpen
  | SetFreePastPerformancesType
  | SelectedRunnerModifier
  | ResetProgramPageState
  | SetBetSlipHeight;

export const setUserFavoriteTracks = (
  favoriteTracks: string[]
): SetUserFavoriteTracks => ({
  type: "SET_USER_FAVORITE_TRACKS",
  payload: { favoriteTracks }
});

export const setUserPrefs = (
  userPrefs: PreferencesType,
  gotPreferences: boolean = false
): UserPrefsSet => ({
  type: "USER_PREFS_SET",
  payload: {
    preferences: userPrefs,
    gotPreferences
  }
});

export const setBetSlipIsExpanded = (isBetSlipExpanded: boolean): Actions => ({
  type: "SET_BET_SLIP_EXPANDED",
  payload: isBetSlipExpanded
});

export const addToast = (toastProps: ToastMessageStore): Actions => ({
  type: "ADD_TOAST",
  payload: {
    toastProps
  }
});

export const removeToast = (toastId: string): Actions => ({
  type: "REMOVE_TOAST",
  payload: { toastId }
});

export const clearToasts = (): Actions => ({
  type: "CLEAR_TOASTS"
});

export const setUserFavoriteRunners = (
  favoriteRunners: Array<string>
): Actions => ({
  type: "SET_USER_FAVORITE_RUNNERS",
  payload: { favoriteRunners }
});

export const addUserFavoriteRunner = (runner: string): Actions => ({
  type: "ADD_USER_FAVORITE_RUNNER",
  payload: { runner }
});

export const setRunnerModifierExpanded = (
  isRunnerModifierExpanded: boolean
): Actions => ({
  type: "SET_RUNNER_MODIFIER_EXPANDED",
  payload: isRunnerModifierExpanded
});

export const fixWagerSelector = (
  payload: wagerSelectorFixedType
): FixWagerSelector => ({
  type: "FIX_WAGER_SELECTOR",
  payload: {
    isWagerSelectorFixed: payload.isWagerSelectorFixed,
    fixedOffset: payload.fixedOffset
  }
});

export const setIsPoolsNInfoModalOpen = (
  payload: PoolsInfoRaceModal
): Actions => ({
  type: "SET_IS_POOLS_AND_INFO_MODAL_OPEN",
  payload: {
    isPoolsNInfoModalOpen: payload.isPoolsNInfoModalOpen,
    PIRace: payload.PIRace
  }
});

export const setFreePastPerformances = (
  payload: Record<string, string>[]
): SetFreePastPerformancesType => ({
  type: "SET_FREE_PAST_PERFORMANCES",
  payload
});

export const setSelectedRunnerModifier = (
  runnerModifier: RunnerModifierEnum
): Actions => ({
  type: "SET_SELECTED_RUNNER_MODIFIER",
  payload: runnerModifier
});

export const resetProgramPageState = (): Actions => ({
  type: "RESET_PROGRAM_PAGE_STATE"
});

export const setBetSlipHeight = (height: number) => ({
  type: "SET_BETSLIP_HEIGHT",
  payload: height
});

type wagerSelectorFixedType = {
  isWagerSelectorFixed: boolean;
  fixedOffset: number;
};

export type PoolsInfoRaceModal = {
  isPoolsNInfoModalOpen: boolean;
  PIRace: RaceProgram | undefined;
};
