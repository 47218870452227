import styled from "styled-components";
import { Header } from "@tvg/design-system/web/";

export const StyledSummary = styled(Header)`
  color: var(--fd-colors-content-default);
  font-size: var(--fd-font-sizes-font-size-040);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  overflow-x: scroll;
  padding: 0 var(--fd-space-space-3) var(--fd-space-space-3);
`;
