import React from "react";
import { Dispatch } from "redux";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { useSelector } from "react-redux";
import { get } from "lodash";
import GWAccountMenu from "./GWAccountMenu";
import AccountMenuMain from "./AccountMenu";
import { AccountXSellProps, MenuRoutes } from "./types";

const AccountMenu = ({
  menuRoutes,
  feedbackUrl,
  isExternalDeposit,
  device,
  dispatch,
  onLogout,
  onTransactionHistoryClick,
  deskAccountLinks
}: Partial<AccountXSellProps>) => {
  const disableGWWallet = useSelector((store) =>
    get(store, "capi.featureToggles.disableGWallet", true)
  );

  return !disableGWWallet ? (
    <GWAccountMenu
      isExternalDeposit={isExternalDeposit}
      device={device || "mobile"}
      dispatch={dispatch as Dispatch}
      onLogout={onLogout as NullaryFn<Promise<void>>}
      onTransactionHistoryClick={onTransactionHistoryClick}
      deskAccountLinks={deskAccountLinks}
    />
  ) : (
    <AccountMenuMain
      menuRoutes={menuRoutes as MenuRoutes}
      feedbackUrl={feedbackUrl as string}
      isExternalDeposit={isExternalDeposit}
      device={device || "mobile"}
      dispatch={dispatch as Dispatch}
      onLogout={onLogout as NullaryFn<Promise<void>>}
      onTransactionHistoryClick={onTransactionHistoryClick}
    />
  );
};

export default AccountMenu;
