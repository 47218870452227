import React from "react";

import Icon from "@fdr/static-ui/Icons";
import { logout, questionSolo } from "@fdr/static-ui/Icons/icons";
import buildColor from "@fdr/static-ui/ColorPalette";
import mediator from "@tvg/mediator";
import { JoinBar } from "@urp/mobile-footer";

import {
  AccountSection,
  ActionsWrapper,
  LinksSection,
  AccountLinkGroup,
  HeaderText,
  LinkText,
  AccountLinkRow,
  Footer
} from "./styled-components";
import { AccountBalanceDetails } from "../../_organisms/AccountBalanceDetails";
import Button from "../../_atoms/Button";
import Toggle from "../../_atoms/Toggle";

import AccountMenuLink from "../../_molecules/AccountMenuLink";
import { LinksGroup } from "../../_organisms/LinksGroup";
import { SessionInfo } from "../../_molecules/SessionInfo";
import { RGMessageState } from "../../_atoms/RGMessageState";

const AccountMenu = ({
  isBalanceShown,
  onToggleShownBalance,
  fullName,
  userName,
  balancesSummary,
  balances,
  accountLinks = [],
  otherLinks = [],
  device,
  isPreferencesShown,
  isBetConfirmationEnabled,
  onBetConfirmationToggle,
  sessionStartAt,
  deposits,
  onLogout,
  supportUrl,
  onSupport,
  rgMessagePerState,
  noLoginRequiredForAccountPage,
  isLogged
}) => {
  // The user can only view the Account Menu without being logged in
  // if noLoginRequiredForAccountPage is set to true.
  return (
    <AccountSection data-qa-label="account-balance">
      <JoinBar isVisible={noLoginRequiredForAccountPage && !isLogged} />
      {isLogged && (
        <React.Fragment>
          <AccountBalanceDetails
            isBalanceVisible={isBalanceShown}
            onVisibilityToggle={onToggleShownBalance}
            sessionStartAt={sessionStartAt}
            fullName={fullName}
            username={userName}
            balancesSummary={balancesSummary}
            balances={balances}
          />
          <ActionsWrapper>
            <Button
              qaLabel="account-balance-add-funds-button"
              type="betting"
              size="big"
              text="Add funds"
              onClick={deposits.depositHandler}
              isStretched
            />
            <Button
              qaLabel="account-balance-withdraw-funds-button"
              type="primary"
              size="big"
              text="Withdraw funds"
              onClick={deposits.withdrawHandler}
              isStretched
            />
          </ActionsWrapper>
          {
            // Account overview start
          }
          <LinksSection data-qa-label="account-overview-section">
            <LinksGroup
              headerText="Account overview"
              links={accountLinks}
              device={device}
            />
          </LinksSection>
          {
            // Preferences start
          }
          {isPreferencesShown && (
            <LinksSection data-qa-label="preferences-section">
              <AccountLinkGroup
                device={device}
                data-qa-label="account-preferences"
              >
                <HeaderText data-qa-label="account-preferences-title">
                  Preferences
                </HeaderText>
                <li>
                  <AccountLinkRow data-qa-label="account-nav-betConfirmation">
                    <LinkText>Bet Confirmation</LinkText>
                    <Toggle
                      on={isBetConfirmationEnabled}
                      onClick={onBetConfirmationToggle}
                      qaLabel={`account-betConfirm-toggle-${
                        isBetConfirmationEnabled ? "on" : "off"
                      }`}
                    />
                  </AccountLinkRow>
                </li>
              </AccountLinkGroup>
            </LinksSection>
          )}
        </React.Fragment>
      )}
      {
        // Other links start
      }
      <LinksSection data-qa-label="other-section">
        <LinksGroup
          headerText={isLogged ? "Other Links" : "Links"}
          links={otherLinks}
          device={device}
        />
      </LinksSection>

      {
        // Support and Logout buttons
      }
      <LinksSection data-qa-label="support-section">
        <AccountLinkGroup device={device} data-qa-label="account-support">
          <li>
            <AccountMenuLink
              qaLabel="account-supportBtn"
              to={supportUrl}
              onClick={onSupport}
              linkText="Support"
              rightIcon={{
                icon: questionSolo,
                color: buildColor("blue", "D1")
              }}
              hideLeftIcon
            />
          </li>
        </AccountLinkGroup>
      </LinksSection>

      {isLogged && (
        <React.Fragment>
          <LinksSection data-qa-label="log-out-section">
            <AccountLinkGroup device={device} data-qa-label="account-logout">
              <button
                onClick={onLogout}
                type="button"
                data-qa-label="account-logoutBtn"
              >
                <AccountLinkRow isDanger>
                  <LinkText>Log out</LinkText>
                  <Icon icon={logout} color={buildColor("red")} />
                </AccountLinkRow>
              </button>
            </AccountLinkGroup>
          </LinksSection>
          <Footer>
            {rgMessagePerState?.firstMessage && (
              <RGMessageState
                firstMessage={rgMessagePerState?.firstMessage}
                secondMessage={rgMessagePerState?.secondMessage}
                logo={rgMessagePerState?.image}
                link={rgMessagePerState?.link}
                qaLabel="rg-message-state"
              />
            )}
            <SessionInfo sessionStartAt={sessionStartAt} />
          </Footer>
        </React.Fragment>
      )}
    </AccountSection>
  );
};

export default AccountMenu;
