import { gql } from "@apollo/client";

export const GET_HEADER_RACE = gql`
  query getHeaderRace(
    $trackAbbr: String
    $wagerProfile: String
    $withGreyhounds: Boolean
  ) {
    nextRaces: races(
      filter: {
        status: ["MO", "O", "SK", "IC"]
        allRaceClasses: $withGreyhounds
      }
      page: { results: 10, current: 0 }
      profile: $wagerProfile
      sort: { byMTP: ASC }
    ) {
      id
      mtp
      number
      track {
        id
        code
        name
        perfAbbr
        featured
      }
      status {
        code
      }
    }

    currentRaces: races(
      filter: { trackCode: [$trackAbbr] }
      profile: $wagerProfile
      sort: { byRaceNumber: ASC }
    ) {
      id
      mtp
      number
      track {
        id
        code
        name
        perfAbbr
        featured
      }
      status {
        code
      }
    }
  }
`;

export default GET_HEADER_RACE;
