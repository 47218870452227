import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";

const TransactionList = styled.ul`
  background: ${buildColor("white", "100")};
  padding: 0 12px;

  & > li:not(:last-child) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: calc(100vw - 40px);
      bottom: 0;
      left: 30px;
      height: 1px;
      background: ${buildColor("grey", "300")};
    }
  }
`;

export default TransactionList;
