import { get } from "lodash";
import states from "@tvg/conf/src/states";
import TvgGeolocation from "@tvg/geolocation";
import { getRegionByType } from "@tvg/utils/geoUtils";

import { RegionType } from "@tvg/ts-types/Geolocation";
import {
  checkValidWageringState,
  createSportsbookRegionCookie,
  getSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";

export const getGeoLocation = (locationData) => ({
  type: "GEO_LOCATION_GET",
  payload: locationData
});

export const setGeoLocationState = (state) => ({
  type: "GEO_LOCATION_SET_STATE",
  payload: { state }
});

export const resetGeoLocation = () => ({
  type: "GEO_LOCATION_RESET"
});

export const setShowStateSelector = (showStateSelector) => ({
  type: "GEO_SHOW_STATE_SELECTOR",
  payload: showStateSelector
});

const getLocationError = (err) => {
  switch (get(err, "code", -1)) {
    case 1:
      return "PERMISSION_DENIED";
    case 2:
      return "POSITION_UNAVAILABLE";
    case 3:
      return "TIMEOUT";
    default:
      return "LOCATION_SERVICE_ERROR";
  }
};

const requestGeolocation = ({
  loading,
  dispatch,
  allowedStates,
  isUserLogged
}) => {
  if (TvgGeolocation.isGeolocationAvailable()) {
    const tvgGeolocation = new TvgGeolocation();

    if (loading) {
      loading();
    }

    return tvgGeolocation
      .requestLocation()
      .then((locationData) => {
        const location = locationData;

        const region = getRegionByType(
          get(locationData, "regions", []),
          RegionType.STATE
        );

        const stateAbbr = get(
          states.find((state) => state.name === get(region, "name")),
          "abbreviation",
          ""
        );
        location.state = stateAbbr || getSportsbookRegionCookie();

        dispatch(getGeoLocation(location));

        const isUserStateAllowedBets = checkValidWageringState(
          stateAbbr,
          allowedStates
        );

        if (!isUserLogged && isUserStateAllowedBets) {
          createSportsbookRegionCookie(stateAbbr);
        } else if (!getSportsbookRegionCookie()) {
          dispatch(setShowStateSelector(true));
        }

        if (!stateAbbr) {
          throw new Error("geolocation didnt get the user location state");
        }

        return Promise.resolve({
          location: get(location, "payload", {})
        });
      })
      .catch((err) => {
        dispatch(getGeoLocation(TvgGeolocation.defaultRegionObject));
        return Promise.reject(new Error(getLocationError(err)));
      });
  }
  return Promise.reject(new Error("UNAVAILABLE_GEOLOCATION"));
};

export default requestGeolocation;
