import React, { memo } from "react";
import { Container, RacesContainer } from "./styled-components";
import Header from "./Header";
import Race from "./Race";
import { Props } from "./types";

const LoadingMask = ({ numItems = 7 }: Props) => (
  <Container data-qa-label="upcomingRaces-loadingMask">
    <Header />
    <RacesContainer>
      {[...Array(numItems)].map(() => (
        <Race />
      ))}
    </RacesContainer>
  </Container>
);

export default memo(LoadingMask);
