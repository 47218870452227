import React from "react";
import { Path } from "react-native-svg";

export const Japan: React.FC = () => (
  <>
    <Path d="M32 0H0V20H32V0Z" fill="#F8F8F9" />
    <Path
      d="M21.2464 10.0671C21.2464 7.02128 18.7922 4.56709 15.7464 
      4.56709C12.7006 4.56709 10.2464 7.02128 10.2464 10.0671C10.2464 13.1129 
      12.7006 15.5671 15.7464 15.5671C18.7703 15.5671 21.2464 13.1129 21.2464 
      10.0671Z"
      fill="#DE3131"
    />
  </>
);

export default Japan;
