import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { difference } from "lodash";
import { getIsLogged } from "@urp/store-selectors";
import {
  setRaceTypeFilters,
  setRegionFilters,
  clearFilters,
  setTrackTypeFilter,
  setDistanceFilter,
  setRaceFilters
} from "../redux/actions/raceTracksFilter";
import {
  getDistancesFilter,
  getRacesFilter,
  getRaceTypesFilter,
  getRegionsFilter,
  getTracksFilterMsg,
  getTrackTypesFilter
} from "../redux/selectors";
import { sendTracksFilterAnalyticEvt } from "../utils/analytics";
import {
  FilterType,
  TABS,
  TABS_NAMES,
  TrackFilterValueEnum,
  FilterName
} from "../types";

const useRaceTracksFilter = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(getIsLogged);
  const raceTypesFilter = useSelector(getRaceTypesFilter);
  const regionsFilter = useSelector(getRegionsFilter);
  const trackTypesFilter = useSelector(getTrackTypesFilter);
  const racesFilter = useSelector(getRacesFilter);
  const distancesFilter = useSelector(getDistancesFilter);
  const tracksFilterMsg = useSelector(getTracksFilterMsg);

  useEffect(() => {
    if (!isLogged) {
      dispatch(
        setTrackTypeFilter(
          (trackTypesFilter.filter(
            (trackType) => trackType !== TrackFilterValueEnum.FAVORITE
          ) as TrackFilterValueEnum[]) || []
        )
      );
    }
  }, []);

  const getCurrentActiveFilters = () => [
    ...raceTypesFilter.map((race) => ({
      "Filter Name": "race_type",
      "Filter Value": race
    })),
    ...regionsFilter.map((region) => ({
      "Filter Name": "region",
      "Filter Value": region
    })),
    ...trackTypesFilter.map((trackType) => ({
      "Filter Name": "track_type",
      "Filter Value": trackType
    })),
    ...racesFilter.map((race) => ({
      "Filter Name": "betting_features",
      "Filter Value": race
    })),
    ...distancesFilter.map((distance) => ({
      "Filter Name": "distance",
      "Filter Value": distance
    }))
  ];

  const handleRaceTypeChange = (raceType: string[], currentTab: TABS) => {
    const newValue = difference(raceType, raceTypesFilter);
    dispatch(setRaceTypeFilters(raceType as RaceTypeCodeEnum[]));
    if (newValue.length > 0) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: FilterName.RACE_TYPE,
        filterValue: newValue[0],
        activeTab: TABS_NAMES[`${currentTab}`]
      });
    }
  };

  const handleRegionChange = (region: string[], currentTab: TABS) => {
    const newValue = difference(region, regionsFilter);
    dispatch(setRegionFilters(region));
    if (newValue.length > 0) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: FilterName.REGION,
        filterValue: newValue[0],
        activeTab: TABS_NAMES[`${currentTab}`]
      });
    }
  };

  const handleTrackChange = (trackType: string[], currentTab: TABS) => {
    const newValue = difference(trackType, trackTypesFilter);
    dispatch(setTrackTypeFilter(trackType as TrackFilterValueEnum[]));
    if (newValue.length > 0) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: FilterName.TRACK_TYPE,
        filterValue: newValue[0],
        activeTab: TABS_NAMES[`${currentTab}`]
      });
    }
  };

  const handleRaceFilter = (bettingFeatures: string[], currentTab: TABS) => {
    const newValue = difference(bettingFeatures, racesFilter);
    dispatch(setRaceFilters(bettingFeatures));
    if (newValue.length > 0) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: FilterName.BETTING_FEATURES,
        filterValue: newValue[0],
        activeTab: TABS_NAMES[`${currentTab}`]
      });
    }
  };

  const handleDistanceFilter = (distance: string[], currentTab: TABS) => {
    const newValue = difference(distance, distancesFilter);
    dispatch(setDistanceFilter(distance));
    if (newValue.length > 0) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: FilterName.DISTANCE,
        filterValue: newValue[0],
        activeTab: TABS_NAMES[`${currentTab}`]
      });
    }
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  return {
    tracksFilterMsg,
    raceTypesFilter,
    regionsFilter,
    trackTypesFilter,
    racesFilter,
    distancesFilter,
    handleRaceTypeChange,
    handleRegionChange,
    handleClearFilters,
    handleTrackChange,
    handleRaceFilter,
    handleDistanceFilter,
    getCurrentActiveFilters
  };
};

export default useRaceTracksFilter;
