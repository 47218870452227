import React from "react";
import { BrazeContentCard } from "@tvg/braze/src/types";
import ListItem from "../ListItem";
import EmptyMessage from "../EmptyMessage";
import { CardsList } from "./styled-components";
import { Props } from "./types";
import useContentCards from "../../hooks/useContentCards";
import ViewMobile from "../ViewMobile";
import ViewDesktop from "../ViewDesktop";

const ContentCards = ({
  isModalOpen,
  listOfBrazeCards,
  onClose,
  isDesktop
}: Props) => {
  const {
    brazeMessages,
    sortedCards,
    onViewCard,
    onClickContentCard,
    onDismissContentCard,
    dismissCardIdShown,
    setDismissCardIdShown
  } = useContentCards(listOfBrazeCards);

  const renderCardsList = sortedCards.map(
    (card: BrazeContentCard, index: number) => (
      <ListItem
        key={card.id}
        card={card}
        onViewCard={onViewCard}
        onClickContentCard={() => onClickContentCard(card, index)}
        dismissCardClick={() => onDismissContentCard(card, index)}
        showDismiss={dismissCardIdShown === card.id}
        toggleShowDismiss={() => {
          if (dismissCardIdShown !== card.id) {
            setDismissCardIdShown(card.id);
          } else {
            setDismissCardIdShown("");
          }
        }}
        isDesktop={isDesktop}
      />
    )
  );

  const renderContent =
    sortedCards && sortedCards.length ? (
      <CardsList isDesktop={isDesktop}>{renderCardsList}</CardsList>
    ) : (
      <EmptyMessage
        title={brazeMessages.emptyTitle}
        description={brazeMessages.emptyDescription}
      />
    );

  const sharedProps = {
    renderContent,
    isModalOpen,
    title: brazeMessages.modalTitle,
    onClose
  };

  return isDesktop ? (
    <ViewDesktop {...sharedProps} />
  ) : (
    <ViewMobile {...sharedProps} />
  );
};

export default ContentCards;
