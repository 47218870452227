import React from "react";
import { format, parse } from "date-fns";
import { InputCalendar, InputSelect, SearchBar } from "@tvg/design-system/web";
import { ResultsSearchFormProps, FieldConfig } from "./types";
import { Field, FieldLabel, FieldsContainer } from "./styled-components";
import { DISPLAY_DATE_FORMAT, INTERNAL_DATE_FORMAT } from "../../constants";

const ResultsSearchForm: React.FC<ResultsSearchFormProps> = ({
  fieldsConfig,
  qaLabel
}) => {
  const renderField = (field: FieldConfig) => {
    switch (field.type) {
      case "date-picker": {
        // change to MM/dd/yyyy format - US date format
        const displayValue = field.value
          ? format(
              parse(field.value as string, INTERNAL_DATE_FORMAT, new Date()),
              DISPLAY_DATE_FORMAT
            )
          : "";
        const disabledDays = [
          { after: new Date() },
          field.availableDates
            ? (date: Date) =>
                !field.availableDates?.some((availableDate: string) =>
                  availableDate.includes(format(date, INTERNAL_DATE_FORMAT))
                )
            : undefined
        ];

        return (
          <Field key={field.key}>
            <FieldLabel>{field.label}</FieldLabel>
            <InputCalendar
              qaLabel={`${qaLabel}-${field.key}`}
              value={displayValue}
              onDayChange={(day) => {
                field.onChange(format(day, INTERNAL_DATE_FORMAT));
              }}
              disabledDays={disabledDays}
              isDisabled={
                field.availableDates && field.availableDates.length < 2
              }
              inputOverlayAlignment="right"
              isReadOnly
            />
          </Field>
        );
      }
      case "dropdown":
        return (
          <Field key={field.key}>
            <FieldLabel>{field.label}</FieldLabel>
            <InputSelect
              selectList={field.options || []}
              qaLabel={`${qaLabel}-${field.key}`}
              selectedValue={field.value as string}
              onChangeText={field.onChange}
              width={200}
            />
          </Field>
        );
      case "search": {
        return (
          <Field key={field.key}>
            <FieldLabel>{field.label}</FieldLabel>
            <SearchBar
              qaLabel={`${qaLabel}-${field.key}`}
              items={field.options || []}
              placeholder={field.placeholder || ""}
              searchTerm={field.value as string}
              setSearchTerm={field.onChange}
              warningMessages={field.warningMessages}
              handleOnClick={(itemValue) => {
                field.onSubmit?.(itemValue as number);
              }}
              isLoading={field.isLoading}
            />
          </Field>
        );
      }

      default:
        return null;
    }
  };

  return <FieldsContainer>{fieldsConfig.map(renderField)}</FieldsContainer>;
};

export default ResultsSearchForm;
