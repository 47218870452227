import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";

export const MainContainer = styled.div`
  cursor: auto;
  z-index: 20;
  position: absolute;
  top: 50px;
  background: ${buildColor("white", "900")};
  border-radius: 4px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  width: 375px;
  left: 0;
  transform: translate(calc(-50% + 22px), 0);
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  position: absolute;
  padding-top: 8px;
  top: 0;
`;

export const LogOutContainer = styled.div`
  & > button {
    width: 100%;
  }

  padding: 12px;
  border-top: 1px solid ${buildColor("blue", "100")};
`;

export const OverLayContainer = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `}
  position: absolute;
  inset: 0;
  z-index: 10;
`;
