import { BinaryFn } from "@tvg/ts-types/Functional";
import { TABS } from "@urp/lib-racetracks";
import { FilterItem, FilterValueTypes } from "../../types";

export const processFilter = (
  options: FilterItem[],
  filterValues: FilterValueTypes[],
  handlerCb: BinaryFn<string[], TABS, void>,
  currentTab: TABS
) =>
  options
    .filter((option: FilterItem) =>
      filterValues.find((a) => a === option.value)
    )
    .map((item) => ({
      ...item,
      cb: () =>
        handlerCb(
          filterValues.filter((a) => a !== item.value),
          currentTab
        )
    }));
