import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const flexCenter = css`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  ${flexCenter};
  width: 100%;
`;

export const LogoContainer = styled(Link)`
  ${flexCenter};
  margin-right: 15px;
`;

export const LeftColumn = styled.div`
  display: flex;
  margin-right: auto;
`;

export const RightColumn = styled.div`
  display: flex;
  margin-left: 16px;
`;

export const TvgLiveLink = styled(Link)`
  text-decoration: none;
`;

export const HamburgerButtonContainer = styled.div<{ mediaQuery: string }>`
  @media ${({ mediaQuery }) => mediaQuery} {
    display: none;
  }
`;

export const TextContainer = styled.span<{ mediaQuery: string }>`
  @media ${({ mediaQuery }) => mediaQuery} {
    display: none;
  }
`;
