import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: auto;

  .pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 132.5px;
`;

const Content = styled.div`
  overflow: hidden;
  padding-left: 8px;
  padding-right: 27px;
  width: 100%;
  margin-top: 14px;
  display: flex;
  flex-flow: nowrap;
  flex-direction: row;
  justify-content: space-between;
`;

const Divider = styled.hr`
  border: 1px solid ${color("grey", "300")};
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: auto;
  margin-inline-end: auto;
  border-top-style: none;
  border-top-width: 0;
  margin-top: 12px;
`;

export default class RunnerInfoMask extends PureComponent {
  render() {
    return (
      <Container>
        <Content>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="55"
            viewBox="0 0 65 55"
            preserveAspectRatio="xMinYMid meet"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="65" height="10" y="45" rx="1" />
              <path d="M33 0a20 20 0 110 40 20 20 0 010-40zm0 3a17 17 0 100 34 17 17 0 000-34z" />
              <rect width="17" height="12" x="25" y="14" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="55"
            viewBox="0 0 65 55"
            preserveAspectRatio="xMinYMid meet"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="65" height="10" y="45" rx="1" />
              <path d="M33 0a20 20 0 110 40 20 20 0 010-40zm0 3a17 17 0 100 34 17 17 0 000-34z" />
              <rect width="17" height="12" x="25" y="14" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="55"
            viewBox="0 0 45 55"
            preserveAspectRatio="xMinYMid meet"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="45" height="10" y="45" rx="1" />
              <path d="M23 0a20 20 0 110 40 20 20 0 010-40zm0 3a17 17 0 100 34 17 17 0 000-34z" />
              <rect width="17" height="12" x="15" y="14" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="55"
            viewBox="0 0 45 55"
            preserveAspectRatio="xMinYMid meet"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="45" height="10" y="45" rx="1" />
              <path d="M23 0a20 20 0 110 40 20 20 0 010-40zm0 3a17 17 0 100 34 17 17 0 000-34z" />
              <rect width="17" height="12" x="15" y="14" rx="1" />
            </g>
          </SVG>
        </Content>
        <Divider />
        <Content>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="23"
            viewBox="0 0 37 23"
          >
            <g fill="#F0F3F8" className="pulse" fillRule="evenodd">
              <rect width="21" height="12" x="8" rx="1" />
              <rect width="37" height="8" y="15" rx="1" />
            </g>
          </SVG>
        </Content>
      </Container>
    );
  }
}
