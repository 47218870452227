// import PendingWithdrawals from "@tvg/wallet/src/components/PendingWithdrawals";
// import QuickDeposit from "@tvg/wallet/src/components/QuickDeposit";
// import QuickWithdraw from "@tvg/wallet/src/components/QuickWithdraw";
// import EmailReferralModal from "./modals"
import React from "react";
import loadable from "@loadable/component";

const WalletQuickDepositModal = loadable(
  () =>
    import(
      /* webpackChunkName: 'walletQuickDeposit' */ "@tvg/wallet/src/components/QuickDeposit"
    )
);
const WalletQuickWithdrawModal = loadable(
  () =>
    import(
      /* webpackChunkName: 'walletQuickWithdraw' */ "@tvg/wallet/src/components/QuickWithdraw"
    )
);
const WalletPendingWithdrawModal = loadable(
  () =>
    import(
      /* webpackChunkName: 'walletPendingWithdrawals' */ "@tvg/wallet/src/components/PendingWithdrawals"
    )
);

const EmailReferralModal = loadable(
  () =>
    import(
      /* webpackChunkName: 'EmailReferralModal' */ "./modals/EmailReferralModal"
    )
);

const WelcomeModal = loadable(
  () => import(/* webpackChunkName: 'WelcomeModal' */ "./modals/WelcomeModal")
);

const GlobalModals = (props: { enableAWFlows: boolean }) => (
  <>
    {!props.enableAWFlows ? (
      <>
        <WalletQuickDepositModal />
        <WalletQuickWithdrawModal />
        <WalletPendingWithdrawModal />
        <WelcomeModal />
      </>
    ) : null}
    <EmailReferralModal />
  </>
);

export default GlobalModals;
