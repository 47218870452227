import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector, DefaultRootState } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { get } from "lodash";
import ApolloContext from "@tvg/utils/apolloContext";
import tvgConf from "@tvg/conf";
import buildRaceUrl from "@tvg/formatter/url";
import { isFDR } from "@tvg/utils/generalUtils";
import { getWagerProfile } from "@urp/store-selectors";
import TrackInfoComponent from "@tvg/tracks/src/v2/components/track-individual-info";
import { getIsNewTILEnabled } from "@urp/lib-racetracks/src/redux/selectors";
import {
  getRaceTrackDefaultMessage,
  getRaceTrackContent,
  getRaces,
  getIsFromQuickLinks,
  getEnableSeoRaceTracks,
  getTracks
} from "@tvg/tracks/src/v2/redux/selectors";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import {
  setFromQuickLinks,
  setSeoTrackList
} from "@urp/lib-racetracks/src/utils/analytics";
import { TrackDetails } from "@urp/tracks-builder";
import type { MtpStatus, ApolloContextValue } from "./types";
import RaceTrackDetailsMask from "./RaceTrackDetailsMask";

const RaceTrackDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const defaultMessage = useSelector(getRaceTrackDefaultMessage);
  const content = useSelector((store: DefaultRootState) =>
    getRaceTrackContent(store, params.trackAbbr as string, defaultMessage)
  );
  const races = useSelector(getRaces);
  const fromQuickLinks = useSelector(getIsFromQuickLinks);
  const enableSeoRaceTracks = useSelector(getEnableSeoRaceTracks);
  const wagerProfile = useSelector(getWagerProfile);
  const tracks = useSelector(getTracks);
  const isNewTILEnabled = useSelector(getIsNewTILEnabled);

  const [isLoading, setLoading] = useState(false);
  const searchRaceNumber = location.search.match(/race=([^&#]*)/);
  const shouldRenderTrackInfo = !get(searchRaceNumber, "[1]", null);

  useEffect(() => {
    const { trackAbbr, trackName } = params;
    const nextRaceFromTrack = getNextRaceFromTrack(trackAbbr as string);

    if (
      (!searchRaceNumber || !trackAbbr) &&
      races.length &&
      (!enableSeoRaceTracks ||
        (enableSeoRaceTracks && fromQuickLinks && nextRaceFromTrack))
    ) {
      redirectToNextRace(
        trackAbbr as string,
        trackName as string,
        get(searchRaceNumber, "[1]", null)
      );
    }
    return () => {
      dispatch(setFromQuickLinks(false));
    };
  }, []);

  const getNextRaceFromTrack = useCallback(
    (trackAbbr: string) => {
      const nextRace = races.filter(
        (r: MtpStatus) =>
          r.status &&
          (r.status.code === RaceStatusEnum.OPEN ||
            r.status.code === RaceStatusEnum.UP_NEXT)
      )[0];
      const nextRaceForTrack = races.find(
        (r: MtpStatus) => r.trackCode === trackAbbr
      );
      return nextRace && nextRaceForTrack;
    },
    [races]
  );

  const redirectToNextRace = useCallback(
    (trackAbbr: string, trackName: string, raceNumber: string | null) => {
      let nextRaceUrl = "";

      if (trackAbbr && !raceNumber) {
        const nextRaceForTrack = races.find(
          (r: MtpStatus) => r.trackCode === trackAbbr
        );

        if (nextRaceForTrack) {
          nextRaceUrl = buildRaceUrl(
            nextRaceForTrack.trackCode,
            nextRaceForTrack.trackName,
            nextRaceForTrack.number
          );
        } else {
          nextRaceUrl = buildRaceUrl(trackAbbr, trackName, 1);
        }

        if (nextRaceUrl) {
          setLoading(true);

          setTimeout(() => {
            navigate(nextRaceUrl, {
              state: {
                ...(get(location, "state", {}) as object),
                fromQuickLinks
              }
            });

            setLoading(false);
          }, 0);
        }
      }
    },
    [races]
  );

  if (isLoading) {
    return <RaceTrackDetailsMask />;
  }

  return shouldRenderTrackInfo && enableSeoRaceTracks ? (
    <ApolloContext.Consumer>
      {(value: ApolloContextValue) =>
        isFDR() && isNewTILEnabled ? (
          <TrackDetails />
        ) : (
          <TrackInfoComponent
            // @ts-ignore
            device={tvgConf().device}
            fcpClient={value.fcpClient}
            rdaClient={value.rdaClient}
            content={content}
            tracksData={tracks}
            wagerProfile={wagerProfile}
            fromQuickLinks={fromQuickLinks}
            isLoading={isLoading}
            isLoadingContent={false}
            shouldUpdate={false}
            setTracksData={setSeoTrackList}
          />
        )
      }
    </ApolloContext.Consumer>
  ) : null;
};

export default RaceTrackDetails;
