import {
  MapFeatureToggles,
  FeatureToggles,
  Messages
} from "@tvg/ts-types/capi";
import { CapiActions } from "./actions";

export type state = {
  featureToggles: MapFeatureToggles | {};
  messages: Messages;
  features: FeatureToggles;
  seoMessages: Messages;
  namespaces: Record<string, boolean>;
};

export const initialState = {
  featureToggles: {},
  features: [],
  messages: {},
  seoMessages: {},
  namespaces: {}
};

export default function capiReducer(
  state: state = initialState,
  action: CapiActions
) {
  switch (action.type) {
    case "FEATURES_GET_SUCCESS": {
      return { ...state, featureToggles: action.payload.featureToggles };
    }
    case "FEATURES_CHANGE": {
      return {
        ...state,
        featureToggles: {
          ...state.featureToggles,
          [action.payload.toggleName]: action.payload.newValue
        }
      };
    }
    case "FEATURES_RESPONSE": {
      return { ...state, features: action.payload.features };
    }
    case "MESSAGES_GET_SUCCESS":
      return { ...state, messages: action.payload.messages };
    case "MESSAGES_UPDATE":
      // eslint-disable-next-line no-case-declarations
      const messages = {
        ...state.messages,
        ...action.payload.messages
      };
      return { ...state, messages };
    case "MESSAGES_ADD_NAMESPACES":
      return {
        ...state,
        namespaces: { ...state.namespaces, ...action.payload.namespaces }
      };
    case "SEO_MESSAGES_GET_SUCCESS":
      return {
        ...state,
        seoMessages: {
          [action.payload.name]: action.payload.content
        }
      };
    case "MESSAGES_GET_FAIL": {
      return { ...state };
    }
    case "SEO_MESSAGES_GET_FAIL": {
      return { ...state };
    }
    case "FEATURES_GET_FAIL": {
      return { ...state };
    }
    case "SEO_MESSAGES_SUCCESS":
      return { ...state, seoMessages: action.payload.seoMessages };
    default: {
      return state;
    }
  }
}
