import React, { memo } from "react";
import { Container } from "./styled-components";

const Header = () => (
  <Container>
    <svg
      width="174"
      height="24"
      viewBox="0 0 174 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        width="173.241"
        height="24"
        rx="1"
        fill="#0A0A0A"
        fillOpacity="0.05"
        className="pulse"
      />
    </svg>
    <svg
      width="65"
      height="32"
      viewBox="0 0 65 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_39045_267626" fill="white">
        <path d="M0.75 4C0.75 1.79086 2.54086 0 4.75 0H32.75V32H4.75C2.54086 32 0.75 30.2091 0.75 28V4Z" />
      </mask>
      <path
        d="M0.75 4C0.75 1.79086 2.54086 0 4.75 0H32.75V32H4.75C2.54086 32 0.75 30.2091 0.75 28V4Z"
        fill="white"
      />
      <path
        d="M-0.25 4C-0.25 1.23858 1.98858 -1 4.75 -1H32.75V1H4.75C3.09315 1 1.75 2.34315 1.75 4H-0.25ZM32.75 33H4.75C1.98858 33 -0.25 30.7614 -0.25 28H1.75C1.75 29.6569 3.09315 31 4.75 31H32.75V33ZM4.75 33C1.98858 33 -0.25 30.7614 -0.25 28V4C-0.25 1.23858 1.98858 -1 4.75 -1V1C3.09315 1 1.75 2.34315 1.75 4V28C1.75 29.6569 3.09315 31 4.75 31V33ZM32.75 0V32V0Z"
        fill="#EAF0F6"
        mask="url(#path-1-inside-1_39045_267626)"
      />
      <rect
        x="8.75"
        y="8"
        width="16"
        height="16"
        rx="8"
        fill="#0A0A0A"
        fillOpacity="0.05"
        className="pulse"
      />
      <path
        d="M33.25 0.5H60.75C62.683 0.5 64.25 2.067 64.25 4V28C64.25 29.933 62.683 31.5 60.75 31.5H33.25V0.5Z"
        fill="white"
      />
      <path
        d="M33.25 0.5H60.75C62.683 0.5 64.25 2.067 64.25 4V28C64.25 29.933 62.683 31.5 60.75 31.5H33.25V0.5Z"
        stroke="#EAF0F6"
      />
      <rect
        x="40.75"
        y="8"
        width="16"
        height="16"
        rx="8"
        fill="#0A0A0A"
        fillOpacity="0.05"
        className="pulse"
      />
    </svg>
  </Container>
);

export default memo(Header);
