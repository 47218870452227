import React, { FC, memo } from "react";
import { Paragraph } from "../../../typography";
import { RaceInfo } from "../raceInfo";
import { Container } from "../../styled-components";
import { MtpCellProps } from "../../types";
import { useQaLabel } from "../../../../hooks";
import { useColorTokens } from "../../../../theming";

export const RaceOff: FC<
  Pick<
    MtpCellProps,
    "withBackground" | "align" | "qaLabel" | "trackCode" | "raceNumber"
  >
> = memo(
  ({
    align,
    withBackground,
    qaLabel = "mtp-cell",
    trackCode,
    raceNumber,
    ...rest
  }) => {
    const qaLabelProps = useQaLabel(qaLabel);
    const raceLabel = `${trackCode} - R${raceNumber}`;
    const colorTokens = useColorTokens();

    return (
      <Container
        withBackground={withBackground}
        align={align}
        bgColor={colorTokens.background.positiveSubtle}
        lineColor={colorTokens.border.default}
        {...qaLabelProps}
        {...rest}
      >
        <Paragraph
          fontFamily="bold"
          textAlign={align}
          qaLabel={`${qaLabel}-race-status`}
          fontSize="18px"
          lineHeight="22px"
          color={colorTokens.background.positive}
        >
          Race Off
        </Paragraph>
        <RaceInfo align={align} label={raceLabel} qaLabel={qaLabel} />
      </Container>
    );
  }
);
