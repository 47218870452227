import { Paragraph, Image } from "@tvg/design-system/web";
import tvgConf from "@tvg/conf";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { getTrackLocation } from "../../utils";
import {
  getRaceTrackContent,
  getRaceTrackDefaultMessage
} from "../../redux/selectors";
import { Avatar, Container, ImageWrapper } from "./styled-components";
import { Props } from "./types";

const TrackIllustration = ({ track }: Props) => {
  const { gcs } = tvgConf().config("google");
  const defaultContent = useSelector(getRaceTrackDefaultMessage);
  const content =
    useSelector(getRaceTrackContent(track.code)) ?? defaultContent;

  if (!content) return null;

  return (
    <Container>
      {track.imageName ? (
        <ImageWrapper>
          <Image
            qaLabel="track-illustration"
            isResponsive
            source={`${gcs.url}/${gcs.tvgStatic}/static/cms_files/${track.imageName}`}
            accessibilityLabel={track.imageName}
          />
        </ImageWrapper>
      ) : (
        <Avatar>
          <Paragraph qaLabel="" fontFamily="bold" fontSize="28px">
            {track.code}
          </Paragraph>
        </Avatar>
      )}
      <Paragraph qaLabel="" fontFamily="regular" color="content.subtle">
        {getTrackLocation(content)}
      </Paragraph>
    </Container>
  );
};

export default memo(TrackIllustration);
