import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "@fdr/static-ui/ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 720px;
  background: ${color("white", "100")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class ResultsMask extends PureComponent {
  render() {
    return (
      <div data-qa-label="homepageMask">
        <SVG>
          <g>
            <rect fill={color("white", "100")} height="100%" width="100%" />
            <rect fill={color("blue", "000")} height="40" width="100%" />
            <rect
              className="pulse"
              width="120"
              height="8"
              x="8"
              y="16"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="40" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="126"
              height="8"
              x="8"
              y="64"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="96" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="86"
              height="8"
              x="8"
              y="120"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="152" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="166"
              height="8"
              x="8"
              y="176"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="208" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="246"
              height="8"
              x="8"
              y="232"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="264" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="134"
              height="8"
              x="8"
              y="288"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="320" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="190"
              height="8"
              x="8"
              y="344"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="376" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="182"
              height="8"
              x="8"
              y="400"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="432" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="222"
              height="8"
              x="8"
              y="456"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="488" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="126"
              height="8"
              x="8"
              y="512"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="544" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="86"
              height="8"
              x="8"
              y="568"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="600" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="166"
              height="8"
              x="8"
              y="624"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="656" fill={color("blue", "000")} />
            <rect
              className="pulse"
              width="246"
              height="8"
              x="8"
              y="680"
              rx="1"
              ry="1"
              fill={color("blue", "000")}
            />
            <rect width="100%" height="1" y="712" fill={color("blue", "000")} />
          </g>
        </SVG>
      </div>
    );
  }
}
