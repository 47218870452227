import { useLazyQuery } from "@apollo/client";
import { getAccountNumber } from "@urp/store-selectors";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { get } from "lodash";
import { WAGERS_HISTORY_BY_RACE } from "../graphql/queries/WagersHistoryByRace";

export type RaceWithBets = {
  trackCode?: string;
  [key: string]: number | string | undefined;
};

type Wagers = Array<{
  raceNumber: string;
  trackCode: string;
}>;

interface RacesWithBets {
  wagerHistory: {
    wagers: Wagers;
  };
}

const serializeWagers = (wagers: Wagers) =>
  wagers.reduce<RaceWithBets>((accum, { raceNumber, trackCode }) => {
    if (accum[raceNumber]) {
      (accum[raceNumber] as number) += 1;
    } else {
      accum[raceNumber] = 1;
    }

    if (!accum.trackCode) {
      accum.trackCode = trackCode;
    }

    return accum;
  }, {});

export function useGetRacesWithBetsByTrack() {
  const accountNumber = useSelector(getAccountNumber);
  const [races, setRaces] = useState<RaceWithBets>({});
  const [fetch, { data, loading }] = useLazyQuery<RacesWithBets>(
    WAGERS_HISTORY_BY_RACE,
    {
      variables: {
        accountId: Number(accountNumber),
        statusList: ["PENDING", "SETTLED"]
      }
    }
  );

  useEffect(() => {
    if (data && data.wagerHistory) {
      const wagers: Wagers = get(data, "wagerHistory.wagers", []);
      const racesWithBets = serializeWagers(wagers);
      setRaces(racesWithBets);
    }
  }, [data]);

  const fetchRacesWithBets = async ({
    trackCode,
    startDate,
    endDate
  }: {
    trackCode: string;
    startDate: string;
    endDate: string;
  }) => {
    const { data: lazyQueryData } = await fetch({
      variables: { trackCode, startDate, endDate }
    });

    const wagers: Wagers = get(lazyQueryData, "wagerHistory.wagers", []);

    return serializeWagers(wagers);
  };

  return { fetchRacesWithBets, races, loading };
}
