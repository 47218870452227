import React from "react";
import { useTransition, config, animated } from "@react-spring/web";
import { ContentContainer, Menu } from "./styled-components";

export interface AnimatedMenuProps {
  isMenuVisible: boolean;
  mouseEvents: {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
  hasContent: boolean;
}

const AnimatedContent = animated(ContentContainer);

const AnimatedMenu: React.FC<AnimatedMenuProps> = ({
  isMenuVisible,
  mouseEvents,
  children,
  hasContent = true
}) => {
  const menuTransition = useTransition(isMenuVisible, {
    config: isMenuVisible ? { ...config.stiff } : { duration: 150 },
    from: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` },
    enter: { opacity: 1, transform: `translate3d(0px, 0px, 0px)` },
    leave: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` }
  });

  return (
    <>
      {menuTransition(
        (styles, isOpen) =>
          isOpen &&
          hasContent && (
            <Menu data-qa-label="dropdown-menu">
              <AnimatedContent
                data-qa-label="dropdown-menu-content"
                {...mouseEvents}
                style={styles}
              >
                {children}
              </AnimatedContent>
            </Menu>
          )
      )}
    </>
  );
};

export default AnimatedMenu;
