import React, { useState, useCallback, useRef, useEffect } from "react";
import { Platform, View } from "react-native";
import { WagerType } from "@tvg/ts-types/Wager";
import useIntersectionObserver from "@tvg/custom-hooks/hooks/useIntersect";
import { useQaLabelActive } from "../../../../hooks/useQaLabelActive";
import { useEvents } from "../../../../hooks/useEvents";
import { useColorTokens } from "../../../../theming";
import { Icon } from "../../../icon";
import {
  BetTypesText,
  BetTypesTouchable,
  Container,
  ButtonContent
} from "./styled-components";
import { BetTypeExpandableButtonProps } from "./types";
import { AnimatedDropdown } from "./animatedDropdown";
import { AnimatedDropdownNative } from "./animatedDropdown/index.native";

const getComponentHeight = () => {
  let headerHeight;
  if (typeof window !== "undefined") {
    headerHeight =
      document.querySelector("header")?.getBoundingClientRect().height || 0;
  }

  return { headerHeight };
};

export const BetTypeExpandableButton: React.FC<BetTypeExpandableButtonProps> =
  ({
    betTypes,
    selectedValue,
    isSelected = false,
    qaLabel = betTypes[0].code,
    onPress
  }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const viewTestProps = useQaLabelActive(qaLabel, isSelected ? "active" : "");
    const { events, eventsHandlers, hoverHandlers } = useEvents({});
    const colorTokens = useColorTokens();
    const isWeb = Platform.OS === "web";

    const { headerHeight } = getComponentHeight();
    const containerRef = useRef<View & HTMLDivElement>(null);

    const entry = useIntersectionObserver(containerRef, {
      root: null,
      rootMargin: `${-((headerHeight || 0) + 3)}px`,
      threshold: 1.0
    });

    const onClick = useCallback(() => {
      setIsDropdownOpen((prevState) => !prevState);
    }, []);

    const onSelectValue = (val: number) => {
      onPress(val);
      setIsDropdownOpen(false);
    };

    const onHoverOut = useCallback(() => {
      setIsDropdownOpen(false);
    }, []);

    const onHoverIn = useCallback(() => {
      setIsDropdownOpen(true);
    }, []);

    const getTabLabel = () =>
      betTypes.find((type) => type.id === selectedValue) as WagerType;

    const hoverHandlersEdit = {
      ...hoverHandlers,
      onMouseEnter: () => onHoverIn(),
      onMouseLeave: () => onHoverOut()
    };

    const dropdownPosition = () => {
      if (entry) {
        const { top, height, left, width } =
          entry.target.getBoundingClientRect();

        return {
          top: entry.isIntersecting
            ? window.scrollY + top + height
            : top + height,
          left,
          width
        };
      }
      return {
        top: 0,
        left: 0,
        width: 0
      };
    };

    // close dropdown when user scrolls in the app
    useEffect(() => {
      const handleDropdownToClose = () => setIsDropdownOpen(false);
      if (window !== undefined) {
        window.addEventListener("scroll", handleDropdownToClose);
      }
      return () => {
        if (window !== undefined) {
          window.removeEventListener("scroll", handleDropdownToClose);
        }
      };
    }, []);

    return (
      <Container ref={containerRef} {...hoverHandlersEdit}>
        <BetTypesTouchable
          accessibilityRole="tab"
          onPress={onClick}
          onLongPress={onClick}
          underlayColor={colorTokens.component.input.pressed}
          {...eventsHandlers}
          {...events}
          isHovered={isDropdownOpen}
          isSelected={isSelected}
          {...viewTestProps}
        >
          <ButtonContent>
            <BetTypesText {...events} isSelected={isSelected}>
              {getTabLabel() ? getTabLabel().name : betTypes[0].name}
            </BetTypesText>
            <Icon
              ml="auto"
              name={isDropdownOpen ? "chevronUp" : "chevronDown"}
              size="s"
              lineColor={
                isSelected
                  ? colorTokens.content.link
                  : colorTokens.content.default
              }
            />
          </ButtonContent>
        </BetTypesTouchable>
        {isWeb && typeof window !== undefined ? (
          <AnimatedDropdown
            isTop={!entry?.isIntersecting}
            isDropdownVisible={isDropdownOpen}
            onSelectValue={onSelectValue}
            selectedValue={selectedValue}
            values={betTypes}
            {...hoverHandlersEdit}
            {...dropdownPosition()}
            qaLabel="bet-type-dropdown"
          />
        ) : (
          <AnimatedDropdownNative
            isDropdownVisible={isDropdownOpen}
            onSelectValue={onSelectValue}
            selectedValue={selectedValue}
            values={betTypes}
            {...hoverHandlersEdit}
            {...dropdownPosition()}
            qaLabel="bet-type-dropdown"
            isTop={!entry?.isIntersecting}
          />
        )}
      </Container>
    );
  };
