import React from "react";
import {
  AccountDetailsWrapper,
  LabelWrapper,
  Spacer
} from "./styled-components";
import Label from "./Label";
import Avatar from "./Avatar";

interface Props {
  username: string;
  qaLabel?: string;
}

export const AccountDetails = ({
  username,
  qaLabel = "account-details"
}: Props) => (
  <AccountDetailsWrapper data-qa-label={qaLabel}>
    <Avatar />
    <Spacer />
    <Label qaLabel={`${qaLabel}-user-name`}>
      <LabelWrapper>{username}</LabelWrapper>
    </Label>
  </AccountDetailsWrapper>
);

export default AccountDetails;
