import { uniqBy } from "lodash";
import { useTransition } from "@react-spring/web";
import { RaceProgram } from "../../types";

const useLHNTransitionInOut = (
  races: RaceProgram[],
  isFeatured: boolean = false,
  showMore: boolean = false
) => {
  if (races) {
    const uniqueRaces = uniqBy(races, "trackAbbr");
    const uniqueRacesSliced = isFeatured
      ? uniqueRaces.slice(0, showMore ? uniqueRaces.length : 8)
      : uniqueRaces;
    return useTransition(uniqueRacesSliced, {
      from: { opacity: 0, maxHeight: "0px" },
      enter: { opacity: 1, maxHeight: "64px" },
      leave: { opacity: 0, maxHeight: "0px" },
      config: { duration: 600 },
      keys: (race) => race.id
    });
  }

  return null;
};

export default useLHNTransitionInOut;
