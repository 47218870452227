import React from "react";
import { Path } from "react-native-svg";

export const Finland: React.FC = () => (
  <>
    <Path d="M31.9769 0H0V20H31.9769V0Z" fill="#F8F8F9" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 7.8355H14.7426V0H10.0591V7.8355H0V12.1645H10.0591V20H14.7426V12.1645H32V7.8355Z"
      fill="#2959A3"
    />
  </>
);

export default Finland;
