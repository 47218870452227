import { UserJurisdiction, User } from "@fdr/types/ts/User";

type UserUpdateAction = { type: "USER_DATA_UPDATE"; payload: User };

export type UserLogout = {
  type: "USER_LOGOUT";
};

export type SetIsVerified = {
  type: "SET_IS_VERIFIED";
  payload: {
    isVerified: boolean;
  };
};

export type SetUserJurisdiction = {
  type: "SET_USER_JURISDICTION";
  payload: {
    jurisdiction: UserJurisdiction;
  };
};

export type Actions =
  | UserUpdateAction
  | UserLogout
  | SetIsVerified
  | SetUserJurisdiction;

export type SetRaceAlertsUserId = {
  type: "SET_RACE_ALERTS_USER_ID";
  payload: string;
};

export const setUserData = (userData: User): UserUpdateAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const logoutUser = (): UserLogout => ({
  type: "USER_LOGOUT"
});

export const setIsVerified = (isVerified: boolean): SetIsVerified => ({
  type: "SET_IS_VERIFIED",
  payload: {
    isVerified
  }
});

export const setUserJurisdiction = (
  jurisdiction: UserJurisdiction
): SetUserJurisdiction => ({
  type: "SET_USER_JURISDICTION",
  payload: {
    jurisdiction
  }
});

export const openInvalidResidentialAddressModal = () => ({
  type: "OPEN_INVALID_RESIDENTIAL_ADDRESS_MODAL"
});

export const setRaceAlertsUserId = (userId: string): SetRaceAlertsUserId => ({
  type: "SET_RACE_ALERTS_USER_ID",
  payload: userId
});
