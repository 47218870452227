import React from "react";
import { Path, G, Defs, Rect, ClipPath } from "react-native-svg";

export const SouthAfrica: React.FC = () => (
  <>
    <G clipPath="url(#clip0_18753_2496)">
      <path
        d="M0 0H6.4L17.04 6.66667H32V13.3333H17.04L6.4 20H0V0Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6.66667V0H6.40002L17.04 6.66667H32Z"
        fill="#DE3131"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40003 20H32L32 13.3333H17.04L6.40003 20Z"
        fill="#2959A3"
      />
      <Path
        d="M0 0H3.88L16.6 7.97753H32V12.0225H16.6L3.88 20H0V0Z"
        fill="#246F33"
      />
      <Path d="M0 2.43446L12.16 10L0 17.5655V2.43446Z" fill="#FFB80C" />
      <Path d="M0 4.00749L9.59999 10L0 15.9925V4.00749Z" fill="black" />
    </G>
    <Defs>
      <ClipPath id="clip0_18753_2496">
        <Rect width="32" height="20" rx="2" fill="white" />
      </ClipPath>
    </Defs>
  </>
);

export default SouthAfrica;
