import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const FeaturedContainer = styled.div``;

export const FeaturedRaces = styled.ul`
  ${flexColumn};
  padding-bottom: 32px;
`;

export const ListItem = styled.li`
  display: flex;
`;

export const ScheduledContainer = styled.div``;

export const ScheduledRaces = styled.ul`
  ${flexColumn};
`;

export const ExpandedMessage = styled.div`
  text-align: center;
  margin-top: 12px;
`;
