import React, { useCallback, useMemo } from "react";
import { LinkItem, Icon, AlertInline, WebScrollbar } from "@tvg/design-system";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { Withdrawal } from "@tvg/ts-types/Withdrawal";
import { IconNames } from "@tvg/design-system/src/_static/icons/types";
import mediator from "@tvg/mediator";
import {
  userRoles
  // @ts-ignore - while tvg/login-controller is not refactored to TS
} from "@tvg/sh-lib-account-actions/src/selectors/user";
import { useSelector, useDispatch } from "react-redux";
import { GestureResponderEvent } from "react-native";
import { get } from "lodash";
import { isUserInAWFlows } from "@tvg/sh-lib-account-actions/src/selectors/user-ts";
import {
  AWPathsMap,
  openAWPage
} from "@tvg/sh-lib-account-wallet/src/utils/AWPages";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import { isTransactionHistoryModalOpen } from "@urp/transaction-history/src/redux/selectors";
import {
  openTransactionHistoryModal,
  closeTransactionHistoryModal
} from "@urp/transaction-history/src/redux/actions";
import { TransactionHistoryModal } from "./TransactionHistoryModal";

import { LinksContainer, SectionTitle } from "./styled-components";
import defaultMenus from "./defaultMenus.json";

type myAccountLink = {
  href: string;
  target: string;
  description: string;
  iconName: IconNames;
  qaLabel: string;
  needTalentRole: boolean;
};

const RolesMap = {
  TALENT: "TALENT",
  WAGEROPS: "WAGEROPS",
  ADMIN: "ADMIN"
};

interface Props {
  goTo: (
    route: string,
    target: string,
    event?: GestureResponderEvent,
    description?: string
  ) => void;
  withdrawals: Withdrawal[];
  openPendingWithdrawals: () => void;
}

const AccountLinks: React.FC<Props> = ({
  goTo,
  withdrawals,
  openPendingWithdrawals
}) => {
  const dispatch = useDispatch();
  const isAWFlowEnabled = useSelector(isUserInAWFlows);
  const roles: Array<keyof typeof RolesMap> = useSelector(userRoles);
  const menus = useSelector((store) =>
    parseCAPIMessage(store, "capi.messages.accountPanelMenuLinks", {})
  );
  const isTransactionHistoryModalOpened = useSelector(
    isTransactionHistoryModalOpen
  );
  const toggles = useSelector((store) => get(store, "capi.featureToggles"));
  const transactionHistoryToggle = get(toggles, "transactionHistory", false);
  const isAccountCompliant = useSelector(isAccountCompliantSelector);

  // load default links if the capi does not bring any
  const mainLinks =
    menus && menus.mainLinks ? menus.mainLinks : defaultMenus.mainLinks;
  const userAccountLinks =
    menus && menus.userAccountLinks
      ? menus.userAccountLinks
      : defaultMenus.userAccountLinks;

  const hasTalentPickTool = () =>
    roles.some((role: keyof typeof RolesMap) => RolesMap[role]);

  const linksMap = useMemo(
    () =>
      mainLinks.map((link: myAccountLink, index: number) => (
        <LinkItem
          key={link.qaLabel}
          description={link.description}
          start={
            <Icon
              name={link.iconName}
              size="s"
              mr="space-5"
              lineColor="blue_accent.500"
              backgroundColor="blue_accent.100"
            />
          }
          onPress={(event?: GestureResponderEvent) => {
            mediator.base.dispatch({
              type: "NAVIGATION:MENU_CLICK",
              payload: {
                action: "Account Panel Link Clicked",
                link: link.description,
                destinationUrl: link.href,
                module: "Account Panel"
              }
            });
            goTo(link.href, link.target, event);
          }}
          hasDividerLine={index !== mainLinks.length - 1}
          hasArrowRight={false}
          qaLabel={`account-menu-${link.qaLabel}`}
        />
      )),
    [mainLinks]
  );

  const onClickLinkItem = useCallback(
    (
      href: string,
      target: string,
      event: GestureResponderEvent,
      // eslint-disable-next-line @typescript-eslint/default-param-last
      description: string = "",
      qaLabel: string
    ) => {
      // temporary edge case until component is moved to DS
      if (qaLabel === "my-account-summary-transactions-before") {
        if (isAccountCompliant) {
          dispatch(openTransactionHistoryModal());
        } else {
          onOpenComplianceModal();
        }
      } else if (isAWFlowEnabled && Object.keys(AWPathsMap).includes(qaLabel)) {
        openAWPage(AWPathsMap[qaLabel], { forceWeb: true });
      } else {
        goTo(href, target, event, description);
      }
    },
    []
  );

  const userAccountLinksMap = useMemo(
    () =>
      userAccountLinks.map((link: myAccountLink) =>
        !link.needTalentRole || (link.needTalentRole && hasTalentPickTool()) ? (
          <LinkItem
            key={link.qaLabel}
            description={link.description}
            start={
              <Icon
                name={link.iconName}
                size="s"
                mr="space-5"
                lineColor="blue_accent.500"
                backgroundColor="blue_accent.100"
              />
            }
            onPress={(event?: GestureResponderEvent) => {
              mediator.base.dispatch({
                type: "NAVIGATION:MENU_CLICK",
                payload: {
                  action: "Account Panel Link Clicked",
                  link: link.description,
                  destinationUrl: link.href,
                  module: "Account Panel"
                }
              });
              onClickLinkItem(
                link.href,
                link.target,
                event as GestureResponderEvent,
                link.description,
                link.qaLabel
              );
            }}
            hasDividerLine
            hasArrowRight={false}
            qaLabel={`account-menu-${link.qaLabel}`}
          />
        ) : null
      ),
    [userAccountLinks]
  );

  return (
    <>
      <WebScrollbar scrollInternalPadding={0} shadowPadding="0" useShadow>
        <LinksContainer data-qa-label="account-links">
          {withdrawals && withdrawals.length ? (
            <AlertInline
              type="fixed"
              variant="warning"
              message={`You have ${withdrawals.length} pending ${
                withdrawals.length > 1 ? "withdrawals" : "withdrawal"
              }`}
              qaLabel="message-box"
              actionLink={
                <AlertInline.Link
                  qaLabel="alert-inline-link"
                  onPress={openPendingWithdrawals}
                >
                  manage
                </AlertInline.Link>
              }
            />
          ) : null}
          <SectionTitle
            tag="h3"
            fontFamily="bold"
            qaLabel="Main Menu"
            color="grey.900"
            px="space-4"
            py="space-4"
          >
            Main Menu
          </SectionTitle>
          {linksMap}
          <SectionTitle
            tag="h3"
            fontFamily="bold"
            qaLabel="Your Account"
            color="grey.900"
            p="space-4"
          >
            Your Account
          </SectionTitle>
          {userAccountLinksMap}
        </LinksContainer>
      </WebScrollbar>
      <TransactionHistoryModal
        isOpen={isTransactionHistoryModalOpened}
        onClose={() => {
          dispatch(closeTransactionHistoryModal());
        }}
        isTransactionHistoryToggleOn={transactionHistoryToggle}
      />
    </>
  );
};

export default AccountLinks;
