import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 20;
  bottom: 0;
  width: 100%;
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1); /* TODO: Elevation color is wrong, using hard coded value temporarily. Use: theme.elevations.low */
  background-color: ${({ theme }) =>
    theme.colors.white[
      "900"
    ]}; /* TODO: Background color is wrong, using hard coded value temporarily. Use: theme.colorTokens.background.surface */
`;
