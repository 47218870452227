import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  flex: 1;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  svg {
    min-width: fit-content;
  }
`;
