import gql from "graphql-tag";

const TimeAndStatusFragments = {
  entry: gql`
    fragment TimeAndStatus on Race {
      date: raceDate
      postTime
    }
  `
};

export default TimeAndStatusFragments;
